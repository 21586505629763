import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import '../../assets/styles/_calculateTaxes.scss';
import { Toast } from 'primereact/toast';
import '../../assets/styles/_customer.scss';
import { Checkbox } from 'primereact/checkbox';

const SaleTaxInfo = ({ formData, setFormData, extraFeatureForm }) => {
  const toast = useRef(null);

  const [netProfit, setNetProfit] = useState(0);
  const [otvCheck, setOtvCheck] = useState(false);
  const [disableRaporlu, setDisableRaporlu] = useState(false);

  useEffect(() => {

    if (formData.otvCheck !== undefined && formData.otvCheck !== null) {
      setOtvCheck(formData.otvCheck);
    }
    if (formData.netProfit !== undefined && formData.netProfit !== null) {
      setNetProfit(formData.netProfit);
    }

    if (formData.customerName !== undefined && formData.customerName !== null) {
      setDisableRaporlu(true);
    }

  }, [formData.netProfit]);

  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const calculateSalePrice = async (event) => {

    event.preventDefault();

    let tempSalePrice = formData.salePrice;
    let tempLastSalePrice = 0;
    let tempTotalSalePrice = 0;
    let discount = 0;

    discount = formData.stockSalePrice - formData.salePrice;

    const tempSalePriceNoMtv = tempSalePrice - formData.mtv - formData.plakaTescil;

    // ÖTV raporlu ise ötvyi dahil etmeden hesaplayacak.
    if (otvCheck) {

      if (formData.raporluSalePrice !== null) {

        const salePriceNoKdv = parseFloat(formData.raporluSalePrice - formData.plakaTescil) / (1 + parseFloat(formData.kdv));

        let totalBaseSalePrice = salePriceNoKdv;

        tempTotalSalePrice = parseFloat(formData.raporluSalePrice);

        if (formData.isFeatured) {
          const aksesuar = parseFloat(extraFeatureForm.aksesuar) ?? 0;
          const tb = parseFloat(extraFeatureForm.tb) ?? 0;
          const mini = parseFloat(extraFeatureForm.miniRepair) ?? 0;

          tempTotalSalePrice = parseFloat(formData.raporluSalePrice) + aksesuar + tb + mini;

          totalBaseSalePrice = salePriceNoKdv + aksesuar + tb + mini;
        }

        const calcProfit = parseFloat(totalBaseSalePrice - formData.basePrice).toFixed(2);

        tempLastSalePrice = formData.raporluSalePrice;

        setNetProfit(calcProfit);

        const calcSalePrice = salePriceNoKdv * ( 1 + parseFloat(formData.otv) / 100 ) * (1 + parseFloat(formData.kdv)) + formData.plakaTescil + formData.mtv;

        tempSalePrice = parseFloat(calcSalePrice).toFixed(2);

        discount = formData.stockSalePrice - tempSalePrice;

      }
      else{
        const salePriceNoOtv = tempSalePriceNoMtv / (1 + parseFloat(formData.otv) / 100);
        const salePriceNoKdv = salePriceNoOtv / (1 + parseFloat(formData.kdv))
  
        const raporluSalePrice = parseFloat(salePriceNoOtv + formData.plakaTescil).toFixed(2);
  
        let totalBaseSalePrice = salePriceNoKdv;
  
        tempTotalSalePrice = parseFloat(raporluSalePrice);
  
        if (formData.isFeatured) {
          const aksesuar = parseFloat(extraFeatureForm.aksesuar) ?? 0;
          const tb = parseFloat(extraFeatureForm.tb) ?? 0;
          const mini = parseFloat(extraFeatureForm.miniRepair) ?? 0;
  
          tempTotalSalePrice = parseFloat(raporluSalePrice) + aksesuar + tb + mini;
  
          totalBaseSalePrice = salePriceNoKdv + aksesuar + tb + mini;
        }
  
        const calcProfit = parseFloat(totalBaseSalePrice - formData.basePrice).toFixed(2);
  
        tempLastSalePrice = raporluSalePrice;
  
        setNetProfit(calcProfit);
      }
    }
    else {

      let aksesuar = 0;
      let tb = 0;
      let mini = 0;

      if (formData.isFeatured) {
        aksesuar = extraFeatureForm.aksesuar ? parseFloat(extraFeatureForm.aksesuar) : 0;
        tb = extraFeatureForm.tb ? parseFloat(extraFeatureForm.tb) : 0;
        mini = extraFeatureForm.miniRepair ? parseFloat(extraFeatureForm.miniRepair) : 0;
      };

      const salePriceNoOtv = tempSalePriceNoMtv / (1 + formData.otv / 100);
      const salePriceNoKdv = salePriceNoOtv / (1 + formData.kdv);

      const calcProfit = salePriceNoKdv + aksesuar + tb + mini - formData.basePrice;

      tempTotalSalePrice = formData.salePrice + aksesuar + tb + mini;
      setNetProfit(parseFloat(calcProfit).toFixed(2));
    }

    setFormData((prevData) => ({
      ...prevData,
      ['lastSalePrice']: tempLastSalePrice,
      ['salePrice']: tempSalePrice,
      ['otvCheck']: otvCheck,
      ['discount']: discount,
      ['totalSalePrice']: parseFloat(tempTotalSalePrice).toFixed(2),
    }));

  };

  const handleSetOtvCheck = (e) => {
    setOtvCheck(e.checked);

    if(!e.checked)
    {
      setFormData((prevData) => ({
        ...prevData,
        ['isFeatured']: false,
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      ['otvCheck']: e.checked,
    }));
  }


  return (
    <div className="customerstyles">
      <Toast ref={toast} />

      <div className="my-3">
        <div className="row col-12 mt-4 d-flex align-items-start justify-content-start">
          {/* Results */}
          <div className="col-12 col-lg-4">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ minWidth: '120px' }}>Fiyat Bilgisi</th>
                  <th style={{ minWidth: '150px' }}>Tutar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stok Satış Fiyatı</td>
                  {formData.stockSalePrice ? (<td>{formatNumber(formData.stockSalePrice)}</td>) : (<td>Değer Girilmedi</td>)}
                </tr>
                <tr>
                  <td>İndirim Miktarı</td>
                  {formData.discount ? (<td>{formatNumber(formData.discount)}</td>) : (<td>Hesaplanmadı</td>)}
                </tr>
                <tr>
                  <td>Satış Fiyatı</td>
                  {formData.salePrice ? (<td>{formatNumber(formData.salePrice)}</td>) : (<td>Hesaplanmadı</td>)}
                </tr>
                {otvCheck && (
                  <tr>
                    <td>Raporlu Satış Fiyatı</td>
                    {formData.lastSalePrice ? (<td>{formatNumber(formData.lastSalePrice)}</td>) : (<td>Hesaplanmadı</td>)}
                  </tr>
                )}
                <tr>
                  <td>Anahtar Teslim Tutar</td>
                  {formData.totalSalePrice ? (<td>{formatNumber(formData.totalSalePrice)}</td>) : (<td>Hesaplanmadı</td>)}
                </tr>
                <tr>
                  <td>Net Kâr</td>
                  {(netProfit ? (<td>{formatNumber(netProfit)}</td>) : (<td>Hesaplanmadı</td>))}
                </tr>
                <tr>
                  <td>KDV</td>
                  {formData.kdv ? (<td>{formData.kdv}</td>) : (<td>Hesaplanmadı</td>)}
                </tr>
                <tr>
                  <td>ÖTV</td>
                  {formData.otvCheck ? (<td>{'Raporlu'}</td>) : (formData.otv ? (<td>{formData.otv}</td>) : (<td>Hesaplanmadı</td>))}
                </tr>
                <tr>
                  <td>MTV</td>
                  {formData.otvCheck ? (<td>0</td>) : (formData.mtv ? (<td>{formData.mtv}</td>) : (<td>Hesaplanmadı</td>))}
                </tr>
                <tr>
                  <td>Plaka Tescil</td>
                  {formData.plakaTescil ? (<td>{formData.plakaTescil}</td>) : (<td>Hesaplanmadı</td>)}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-column w-auto gap-3 align-items-center justify-content-center ms-4">
            <div className='d-flex align-items-center justify-content-center'>
              <Checkbox
                id="otvCheck"
                name="otvCheck"
                className="me-2"
                onChange={(e) => handleSetOtvCheck(e)}
                checked={otvCheck}
                disabled={disableRaporlu}
              />
              <label htmlFor="otvCheck" >Raporlu Ötv</label>
            </div>
            <div>
              <Button
                id="calculateTaxesButton"
                onClick={calculateSalePrice}
                className="j-button bg-green"
                label="Tutar Hesapla"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleTaxInfo;
