import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const responseDto = {
  isSuccess: null,
  message: null,
  dataId: null
};

export async function getAllTestDrives() {
  try {
      const token = getToken();
      const response = await fetch(`${baseUrl}/api/TestDrive/GetAllTestDrives`, {
          method: "GET",
          headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`,
          },
      });

      if (!response.ok) {
          throw new Error("Failed to fetch test drives");
      }

      return await response.json();
  } catch (error) {
      throw new Error("Failed to fetch test drives");
  }
};