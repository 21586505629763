import React, { useEffect, useRef, useState } from 'react'
import { getEmployeePerformance } from '../services/reportService';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as XLSX from 'xlsx'; // Import the xlsx library
import { Toast } from 'primereact/toast';

import "../../assets/styles/_graph.scss";

const EmployeeGraphReport = () => {

    // Fiyat formatlama 111.111,11
    const formatNumber = (number) => {

      if(number===0) return 0;
      if (!number || isNaN(number)) return '';
  
      const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  
      // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
      if (formattedNumber.endsWith('00')) {
        return parseFloat(number).toLocaleString('tr-TR', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      }
  
      return formattedNumber;
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getEmployeePerformance();
        if(data=== null){
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail:
              'Ay bazında çalışan raporu verileri çekilemedi.',
          });
        }
        else{
          setEmployeeReport(data);
        }
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında çalışan raporu verileri çekilemedi.',
        });
      }
    }
    fetchData();
  }, []);

  const [employeeReport, setEmployeeReport] = useState([]);

  const toast = useRef(null);

  return (
    <>
        <Toast ref={toast} />

        <div className="customerstyles">
            <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
                <h4>Aylık Çalışan Raporu</h4>
                <Button
                    label="Excel İndir"
                    className="j-button bg-green text-light"
                    id="downloadExcel"
                />
            </div>
            <DataTable
                value={employeeReport}
                sortMode="multiple"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: '50rem' }}
                stripedRows
                removableSort
                emptyMessage="Satış bulunamadı."
            >
                <Column field="userName" header="Çalışan Adı" sortable   />
                <Column field="phoneNumber" header="Telefon Numarası" />
                <Column field="roleName" header="Görevi" />
                <Column field="customerCount" header="Müşteri Sayısı" />
                <Column field="saleCount" header="Satış Sayısı" sortable />
                <Column field="totalSalePrice" header="Toplam Satış Miktarı" body={(rowData) => formatNumber(rowData.totalSalePrice)}/>
                <Column field="totalProfit" header="Toplam Net Kar"  body={(rowData) => formatNumber(rowData.totalProfit)}/>
                <Column field="aksesuar" header="Aksesuar Miktarı" body={(rowData) => formatNumber(rowData.aksesuar)}/>
                <Column field="tb" header="Tb Miktarı" body={(rowData) => formatNumber(rowData.tb)}/>
                <Column field="miniRepair" header="Mini Onarım Miktarı" body={(rowData) => formatNumber(rowData.miniRepair)}/>
                <Column field="branch" header="Marka" />
            </DataTable>
        </div>
    </>
)
}

export default EmployeeGraphReport