import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { getAllLoanRequestsByCustomerId } from '../services/saleService';
import '../../assets/styles/_customer.scss';

const ListCustomerLoanRequest = ({ customerId }) => {
  const toast = useRef(null);

  const [loanRequest, setLoanRequest] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllLoanRequestsByCustomerId(customerId);
        setLoanRequest(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşterinin kredi talebi listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }
    fetchData();
  }, [])

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()}`;
    const formattedTime = `${dateTime.getHours()}:${
      dateTime.getMinutes() < 10 ? '0' : ''
    }${dateTime.getMinutes()}`;
    return `${formattedDate} ${formattedTime}`;
  };

  const deleteRow = (row) => {};

  return (
    <>
        <div className="customerstyles mb-5">
          <h4 className="mb-2">Kredi Talep Listesi</h4>
          <DataTable
            value={loanRequest}
            sortMode="multiple"
            tableStyle={{ minWidth: '70rem' }}
            emptyMessage="Müşterinin kredi talebi bulunmamaktadır"
          >
            <Column field="loanBankName" header="Banka" />
            <Column field="amount" header="Miktar" />
            <Column
              field="createdAt"
              header="Oluşturulma Tarihi"
              body={(rowData) => formatDateTime(rowData.createdAt)}
            />
            <Column field="status" header="Durumu" />
          </DataTable>
          <Toast ref={toast} />
        </div>
    </>
  );
};

export default ListCustomerLoanRequest;
