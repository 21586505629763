import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};


// GetBranchSaleReportByMonth
export async function GetBranchSaleReportByMonth(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetSaleReportMonthlyByBranch`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}
// GetBranchExchangeCountByMonth
export async function GetBranchExchangeCountByMonth(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetBranchExchangeCountByMonth`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}
// GetBranchTbCostByMonth
export async function GetBranchTbCostByMonth(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetBranchTbCostByMonth`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}
// GetBranchAksesuarCostByMonth
export async function GetBranchAksesuarCostByMonth(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetBranchAksesuarCostByMonth`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}
// getMontlySaleCountReport
export async function getMontlySaleCountReport(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetMontlySaleCountReport`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}

export async function getKaskoCountReportByMonth(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetKaskoCountReportByMonth`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}

export async function getInsuranceCountReportByMonth(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetInsuranceCountReportByMonth`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}

export async function getMonthlyNetProfit(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetMonthlyNetProfit`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}

export async function getMonthlyCustomerCountReport(month, year) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetMonthlyCustomerCountReport`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ month: month, year: year }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}

export async function getDailySaleReport() {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetDailySaleReport`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}

export async function getEmployeePerformance() {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/SaleReport/GetEmployeePerformance`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}