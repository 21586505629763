import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'react-bootstrap';
import { Dropdown as DropdownPrime } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';

import { getAllCustomerCallsByUserId } from '../services/customerCallService';
import { getAllCustomerNamesList } from '../services/customerService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import '../../assets/styles/_customer.scss';

const baseUrl = process.env.REACT_APP_API_URL + '/api';

const getToken = () => {
  return Cookies.get('token');
};

const CustomerCallCom = () => {
  const toast = useRef(null);

  const [reload, setReload] = useState(false);

  // Müşteri Listesi
  const [customerList, setCustomerList] = useState([]);

  const [customerCallList, setCustomerCallList] = useState([]);

  const [formData, setFormData] = useState({
    id: null,
    customer: null,
    callDate: null,
    note: null,
    isClosed: false,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllCustomerNamesList();
        setCustomerList(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşteriler yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllCustomerCallsByUserId();
        setCustomerCallList(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşteriler yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }
    fetchData();
  }, [reload]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClean = () => {
    setFormData({
      ...formData,
      id: null,
      customer: null,
      callDate: null,
      note: '',
      isClosed: false,
    });
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const updatedFormData = { ...formData };

    updatedFormData[name] = checked;

    setFormData(updatedFormData);
  };

  // Kayıt tarihini formatlar
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate() }/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const optionsTemplate = (rowData) => {
    return (
      <div>
        
        {rowData.isClosed === false ? (
          <Dropdown>
          <Dropdown.Toggle className="bg-green">
            <i className="pi pi-cog fs-5"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
            <Dropdown.Item
              onClick={() => handleEdit(rowData)}
              className="text-light border-bottom"
            >
              Düzenle
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleCloseCall(rowData)}
              className="text-light border-bottom"
            >
              Arandı Olarak İşaretle
            </Dropdown.Item>
            <Dropdown.Item
              className="text-light"
              onClick={() => handleDelete(rowData)}
            >
              Sil
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        ) : (
          <Dropdown>
          <Dropdown.Toggle className="bg-green">
            <i className="pi pi-cog fs-5"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
            <Dropdown.Item
              className="text-light"
              onClick={() => handleDelete(rowData)}
            >
              Sil
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        )}
      </div>
    );
  };

  // Müşteri arama notunu kaydeder.
  const handleSave = async () => {
    if (formData.id === null) {
      const requestBody = {
        customerId: formData.customer.id,
        callDate: new Date(formData.callDate),
        note: formData.note,
      };

      try {
        const token = getToken();
        if (!token) {
          //TODO
          return;
        }
        const response = await fetch(
          `${baseUrl}/CustomerCall/AddCustomerCall`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: errorData.message,
          });
          return;
        }

        const data = await response.json();
        handleClean();
        toast.current.show({
          severity: 'success',
          summary: 'Kayıt Başarılı',
          detail: data.message,
        });
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
        });
      }
    } else {
      const requestBody = {
        id: formData.id,
        note: formData.note,
        isClosed: formData.isClosed,
      };

      try {
        const token = getToken();
        if (!token) {
          //TODO
          return;
        }
        const response = await fetch(
          `${baseUrl}/CustomerCall/UpdateCustomerCall`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: errorData.message,
          });
          return;
        }

        const data = await response.json();
        handleClean();
        toast.current.show({
          severity: 'success',
          summary: 'Kayıt Başarılı',
          detail: data.message,
        });
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
        });
      }
    }

    setReload(!reload);
  };

  const handleEdit = (rowData) => {
    const customer = {
      id: rowData.customerId,
      name: rowData.customerName,
    };

    setFormData({
      ...formData,
      id: rowData.id,
      customer: customer,
      callDate: new Date(rowData.callDate),
      isClosed: rowData.isClosed,
      note: rowData.note,
    });
  };

  const handleCloseCall = async (rowData) => {
    const requestBody = {
      id: rowData.id,
      note: rowData.note,
      isClosed: true,
    };

    try {
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
      const response = await fetch(
        `${baseUrl}/CustomerCall/UpdateCustomerCall`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: errorData.message,
        });
        return;
      }

      const data = await response.json();
      handleClean();
      toast.current.show({
        severity: 'success',
        summary: 'Kayıt Başarılı',
        detail: data.message,
      });

      setReload(!reload);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
      });
    }
  };

  const handleDelete = async (rowData) => {
    try {
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
      const response = await fetch(
        `${baseUrl}/CustomerCall/DeleteCustomerCall`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ id: rowData.id }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: errorData.message,
        });
        return;
      }

      const data = await response.json();
      handleClean();
      toast.current.show({
        severity: 'success',
        summary: 'Silme İşlemi Başarılı',
        detail: data.message,
      });

      setReload(!reload);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
          <h4>Müşteri Arama</h4>
        </div>

        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <DropdownPrime
              id="customer"
              name="customer"
              className="inputgap"
              value={formData.customer}
              options={customerList}
              onChange={(e) => handleChange(e)}
              optionLabel="name"
              filter
              showClear
            />
            <label htmlFor="customer">Müşteri</label>
          </span>
        </div>
        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <Calendar
              id="callDate"
              name="callDate"
              className="inputgap"
              value={formData.callDate}
              onChange={(e) => handleChange(e)}
              dateFormat="dd/mm/yy"
            />
            <label htmlFor="callDate">Arama Tarihi</label>
          </span>
        </div>
        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <InputTextarea
              className="inputgap"
              id="note"
              name="note"
              value={formData.note}
              rows={15}
              cols={40}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="note">Arama Notu</label>
          </span>
        </div>
        {formData.id && (
          <div>
            <input
              type="checkbox"
              id="isClosed"
              name="isClosed"
              className="me-3 mb-4"
              checked={formData.isClosed}
              onChange={handleCheckbox}
            />
            <label htmlFor="isClosed">Arama Yapıldı mı?</label>
          </div>
        )}
        {/* Kaydet ve İptal butonları */}
        <div className="mb-5">
          <Button
            label="İptal"
            severity="secondary"
            className="rounded-3 me-2"
            onClick={handleClean}
          />
          <Button
            label="Gönder"
            severity="success"
            className="rounded-3"
            onClick={handleSave}
          />
        </div>

        <div className="customerstyles">
          <h4 className="mb-5">Eklenmiş Müşteri Arama Notları</h4>
          <DataTable
            value={customerCallList}
            sortMode="multiple"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: '50rem' }}
            stripedRows
            removableSort
            emptyMessage="Arama notu bulunamadı."
          >
            <Column field="customerName" header="Müşteri" sortable />
            <Column
              field="callDate"
              header="Arama Tarihi"
              body={(rowData) => formatDateTime(rowData.callDate)}
              sortable
            />
            <Column field="note" header="Arama Notu" />
            <Column
              field="isClosed"
              header="Arandı mı?"
              body={(rowData) => (rowData.isClosed ? 'Evet' : 'Hayır')}
              sortable
            />
            <Column
              field="createdAt"
              header="Kayıt Tarihi"
              body={(rowData) => formatDateTime(rowData.createdAt)}
              sortable
            />
            <Column
              body={(rowData) => optionsTemplate(rowData)}
              header="İşlem"
            />
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default CustomerCallCom;
