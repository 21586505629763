import "../../assets/styles/_login.scss";
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import occoCarLogo from "../../assets/images/occo_car_logo.webp";
import tekbasLogo from "../../assets/images/tekbas_logo.webp";
import loginBackground from "../../assets/images/login_background.webp";
import EnterPhoneNumberDialog from "./EnterPhoneNumberDialog";
import { useNavigate } from "react-router-dom";
import { login } from "../services/authMiddleware";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import Cookies from 'js-cookie';

const Login = () => {
  const navigate = useNavigate();

  
  const toast = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showEnterPhoneDialog, setShowEnterPhoneDialog] = useState(false);

  // handle onClicks

  // Şifremi unuttum butonu telefon numaranızı girin dialog açar.
  const handleForgotPassword = () => {
    if (!showEnterPhoneDialog) {
      setShowEnterPhoneDialog(true);
    }
  };

  const handleLogin = async () => {

    const response = await login(email, password);

    // Giriş başarılı ise Dashboard 'a yönlendirecek.
    if (response && response.isSuccess) {
      // Cookies.set('token', response.token, { expires: 1 / 48 });
      navigate("/dashboard");
    }
    else if (response && response.isSuccess === false){
      toast.current.show({ severity: "error", summary: "Hata", detail: response.message ?? "Bir hata oluştu."});
    }
    else{
      toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu."});  
    }
  };
  // şifre inputunda enter a basınca login fonksiyonunu çalıştırır
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <>
    <Toast ref={toast}/>
        <div
      className="login-container"
      style={{
        backgroundImage: `url(${loginBackground})`,
      }}
    >
      <div className="login-card">
        <div className="login-logo">
          <img alt="logo" src={tekbasLogo}></img>
          <img alt="logo" src={occoCarLogo}></img>
        </div>
        <div className="login-input-container">
          <span className="p-float-label">
            <InputText
              id="emailCustomer"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="emailCustomer" className="small">E-Mail</label>
          </span>
        </div>
        <div className="login-input-container mt-4">
          <span className="p-float-label">
          <Password className="w-100" id="password" onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} feedback={false} toggleMask />
            <label htmlFor="password" className="small">Şifre</label>
          </span>
        </div>
        <Button
          onClick={handleLogin}
          label="Giriş Yap"
          className="bg-green"
        />
        <div
          onClick={handleForgotPassword}
          className="mt-2 me-1 text-end small"
          style={{ cursor: "pointer" }}
        >
          Şifremi Unuttum
        </div>
      </div>
      <div>
        {showEnterPhoneDialog && (
          <EnterPhoneNumberDialog
            showDialogue={showEnterPhoneDialog}
            setShowDialog={setShowEnterPhoneDialog}
          />
        )}
      </div>
    </div>
    </>

  );
};

export default Login;
