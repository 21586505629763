import React, { useEffect, useRef, useState } from 'react'
import { getAllLoanBanks } from '../services/systemService';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { addLoanBank } from '../services/systemService';

const ListCreditBanks = () => {

  const toast = useRef(null);

  const [loanBankList, setLoanBankList] = useState([]);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    rate: "",
    comission: ""
  })

  useEffect(() => {

    async function fetchData() {
      try {
        const data = await getAllLoanBanks();
        setLoanBankList(data);
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Kredi banka listesi yüklenemedi. Sayfayı yenileyiniz.' });
      }
    }

    fetchData();
  }, []);

  const handleSave = async () => {

    if (!formData.name || !formData.rate || !formData.comission) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Lütfen tüm alanları doldurunuz.' });
      return;
    } else {
      await addLoanBank(formData);

      setLoanBankList([...loanBankList, formData]);
      toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Kredi bankası eklendi.' });
    }
  }

  const handleClean = () => {
    setFormData({
      ...formData,
      id: null,
      name: "",
      rate: "",
      comission: ""
    });
  }

  return (
    <div className='customerstyles'>
      <Toast ref={toast} />
      <h4 className='mb-5'>Banka & Kredi Ekle</h4>
      <div className="col-12 col-lg-8 col-xxl-5">
        
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
              <label htmlFor="name">Banka Adı</label>
            </span>
          </div>
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="rate"
                name="rate"
                value={formData.rate}
                onChange={(e) => setFormData({ ...formData, rate: e.target.value })}

              />
              <label htmlFor="rate">Oran</label>
            </span>
          </div>
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="comission"
                name="comission"
                value={formData.comission}
                onChange={(e) => setFormData({ ...formData, comission: e.target.value })}
              />
              <label htmlFor="comission">Komisyon</label>
            </span>
          </div>
          <div className="d-flex justify-content-end gap-2 col-12">
            <Button label="İptal" className="p-button-secondary rounded-3"
              onClick={handleClean}
            />
            <Button
              label="Kaydet"
              className="p-button-success rounded-3"
              onClick={handleSave}
            />
          </div>
        </div>
      <div className="mt-4 col-12 col-lg-8">
        <h4 className="mb-5">Kredi Listesi</h4>
        <DataTable value={loanBankList} sortMode="multiple" paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} style={{ maxWidth: "40rem" }}>
          <Column field="name" header="Banka Adı" sortable style={{width:"60%"}}/>
          <Column field="rate" header="Oran"  style={{width:"20%"}}/>
          <Column field="comission" header="Komisyon"  style={{width:"20%"}}/>
        </DataTable>
      </div>

    </div>
  )
}

export default ListCreditBanks;