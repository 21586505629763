import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import {
  getAllModelCodesByBranchId,
  getAllModelNamesByModelCodeId,
} from "../services/modelService";
import { Button } from "primereact/button";
import { getAllColors } from "../services/colorService";
import { addColorToModel } from "../services/systemService";
import { MultiSelect } from "primereact/multiselect";

const AddColorToModel = () => {
  const toast = useRef(null);

  const [modelCodeList, setModelCodeList] = useState([]);
  const [modelNameList, setModelNameList] = useState(null);
  const [colorList, setColorList] = useState([]);
  const [selectedModelCode, setSelectedModelCode] = useState(null);
  const [selectedModelName, setSelectedModelName] = useState(null);
  const [selectedColor, setSelectedColor] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {

        const data = await getAllModelCodesByBranchId();
        setModelCodeList(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Model kodu listesi yüklenemedi. Yeniden deneyiniz.",
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (selectedModelCode === null) return;
      try {
        const data = await getAllModelNamesByModelCodeId(selectedModelCode);
        setModelNameList(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Model listesi yüklenemedi. Yeniden deneyiniz.",
        });
      }
    }

    fetchData();
  }, [selectedModelCode]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllColors();
        setColorList(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Model listesi yüklenemedi. Yeniden deneyiniz.",
        });
      }
    }

    fetchData();
  }, []);

  const handleClean = async () => {
    setSelectedModelCode(null);
    setSelectedModelName(null);
    setSelectedColor([]);
  }

  const handleSave = async () => {
    try {
      if (!selectedModelCode || !selectedModelName || !selectedColor) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Lütfen tüm alanları doldurunuz.",
        });
        return;
      }
      const requestBody = {
        colorIds: selectedColor.map((color) => ({
          id: color
        })),
        modelNameId: selectedModelName,
        modelCodeId: selectedModelCode,
      };

      const result = await addColorToModel(requestBody);

      if (result && result.isSuccess) {
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Seçtiğiniz renk modele başarıyla eklenmiştir.",
        });

        await handleClean();

      } else {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: result.message
            ? result.message
            : "Seçtiğiniz renk modele eklenememiştir.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Seçtiğiniz renk modele eklenememiştir.",
      });
    }
  };

  return (
    <div className="mb-5">
      <Toast ref={toast} />
      <h4 className="mb-5">Araç Modeline Renk Ekle</h4>
      <div className="p-grid p-fluid mt-4 col-12 col-xxl-4 d-flex flex-column gap-3">
        <div className="p-col">
          <label htmlFor="customerDropdown">Model Kodu</label>
          <Dropdown
            className="rounded-3"
            id="customerDropdown"
            optionLabel="name"
            optionValue="id"
            value={selectedModelCode}
            options={modelCodeList}
            onChange={(e) => setSelectedModelCode(e.value)}
            filter
            showClear
            placeholder="Model Kodu Seçiniz"
          />
        </div>
        <div className="p-col">
          <label htmlFor="modelDropdown">Model Adı</label>
          <Dropdown
            className="rounded-3"
            id="modelDropdown"
            optionLabel="name"
            optionValue="id"
            value={selectedModelName}
            options={
              modelNameList === null
                ? []
                : modelNameList.map((modelName) => ({
                  id: modelName.id,
                  name: modelName.name,
                }))
            }
            onChange={(e) => setSelectedModelName(e.value)}
            filter
            showClear
            placeholder="Model Adı Seçiniz"
          />
        </div>
        <div className="p-col">
          <label htmlFor="colorDropdown">Renk</label>
          <MultiSelect
            className="rounded-3"
            id="colorDropdown"
            optionLabel="name"
            optionValue="id"
            value={selectedColor}
            options={colorList}
            onChange={(e) => setSelectedColor(e.value)}
            filter
            showClear
            placeholder="Renk Seçiniz"
            multiple
          />
        </div>
        <div className="p-col mt-3">
          <Button
            label="Kaydet"
            severity="success"
            className="rounded-3"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default AddColorToModel;
