import Cookies from 'js-cookie';


const baseUrl = process.env.REACT_APP_API_URL + '/api';

const getToken = () => {
  return Cookies.get('token');
};

export async function getCarRequestList(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/CarRequest/GetCarRequestListByCustomerId`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function closeCarRequest(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/CarRequest/CloseCarRequest`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function deleteCarRequest(id) {
    try {
  
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
  
      const response = await fetch(`${baseUrl}/CarRequest/DeleteCarRequest`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id })
      });
  
      if (!response.ok) {
        return await response.json();
      }
  
      return await response.json();
    } catch (error) {
      return null;
    }
  };

  export async function addCarRequest(formData) {

    const requestBody = {
      customerId: formData.customer,
      modelCodeId: formData.modelCode,
      modelNameId: formData.modelName,
      colorId: formData.color,
      until: formData.until,
      note: formData.note
    };

    try {
  
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
  
      const response = await fetch(`${baseUrl}/CarRequest/AddCarRequest`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        return await response.json();
      }
  
      return await response.json();
    } catch (error) {
      return null;
    }
  };

  export async function getUpdCarRequestDataById(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/CarRequest/GetUpdCarRequestDataById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function updateCarRequest(formData) {

  const requestBody = {
    id: formData.id,
    customerId: formData.customer,
    modelCodeId: formData.modelCode,
    modelNameId: formData.modelName,
    colorId: formData.color,
    until: formData.until,
    note: formData.note
  };

  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/CarRequest/UpdateCarRequest`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};