import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllInsuranceOffices } from '../services/systemService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
}


const ApproveInsuranceRequest = () => {
  const toast = useRef(null);
  const location = useLocation();
  const stateId = location.state ? location.state.id : null;

  const [insuranceOffices, setInsuranceOffices] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([
    { name: 'Kasko', id: 0 },
    { name: 'Trafik', id: 1 },
    { name: 'Diğer', id: 2 },
  ]);

  const [formData, setFormData] = useState({
    insuranceOfficeId: null,
    insuranceType: null,
    amount: null,
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getAllInsuranceOffices();
      console.log('data', data);

      if (data && data.message && data.isSuccess === false) {
        return toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            data.message ??
            'Sigorta/Kasko yüklenirken hata oluştu. Tekrar deneyiniz.',
        });
      } else {
        setInsuranceOffices(data);
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAmountChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      amount: e.value,
    }));
  };

  const handleAddInsurance = () => {
    const newInsurance = {
      insuranceOfficeId: formData.insuranceOfficeId,
      insuranceType: formData.insuranceType,
      amount: formData.amount,
    };
    const newData = [...tableData, newInsurance];
    setTableData(newData);
    // ekledikten sonra formu sıfırla
    setFormData({
      insuranceOfficeId: null,
      insuranceType: null,
      amount: null,
    });
  };

  const handleEdit = (rowData) => {
    const newTableData = tableData.filter((item) => item !== rowData);
    setTableData(newTableData);

    setFormData({
      insuranceOfficeId: rowData.insuranceOfficeId,
      insuranceType: rowData.insuranceType,
      amount: rowData.amount,
    });
  };

  const handleDelete = (rowData) => {
    const newData = tableData.filter((item) => item !== rowData);
    setTableData(newData);
    toast.current.show({
      severity: 'success',
      summary: 'Başarılı',
      detail: 'Kayıt başarıyla silindi.',
    });
  };

  const handleSave = async () => {

    const requestDto = {
      insuranceRequestId : stateId,
      insuranceList: tableData
    };

    try {
        const token = getToken();
        if (!token) {
            //TODO
            return;
        }
        const response = await fetch(`${baseUrl}/api/InsuranceRequest/ApproveInsuranceRequest`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(requestDto),
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
            return;
        }

        const data = await response.json();

        toast.current.show({ severity: "success", summary: "Güncelleme Başarılı", detail: data.message });

    } catch (error) {
        toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
    }

};

  return (
    <>
      <Toast ref={toast} />
      <div>
        <div className="customerstyles">
          <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
            <h4>Sigorta Ekle</h4>
          </div>
          <div className="d-block d-lg-flex align-items-center col-12 mb-4">
            <span className="p-float-label col-12 col-lg-3 mb-3">
              <Dropdown
                className="inputgap"
                id="insuranceOfficeId"
                name="insuranceOfficeId"
                optionLabel="name"
                optionValue="id"
                value={formData.insuranceOfficeId}
                options={insuranceOffices}
                onChange={(e) => handleChange(e)}
                filter
                showClear
                placeholder="Sigorta Şirketi Seçiniz"
              />
              <label htmlFor="insuranceOffice">Sigorta Şirketi</label>
            </span>
            <span className="p-float-label col-12 col-lg-3 mb-3">
              <Dropdown
                className="inputgap"
                id="insuranceType"
                name="insuranceType"
                optionLabel="name"
                optionValue="id"
                value={formData.insuranceType}
                options={insuranceTypes}
                onChange={(e) => handleChange(e)}
                filter
                showClear
                placeholder="Sigorta Tipi Seçiniz"
              />
              <label htmlFor="insuranceType">Sigorta Tipi</label>
            </span>
            <span className="p-float-label col-12 col-lg-3 mb-3">
              <InputNumber
                className="inputgap"
                id="amount"
                name="amount"
                value={formData.amount}
                onValueChange={(e) => handleAmountChange(e)}
                currency="TRY"
                locale="tr-TR"
                placeholder="Fiyat Giriniz"
              />
              <label htmlFor="amount">Fiyat</label>
            </span>
            <Button
              className="rounded-3 col-auto mb-3"
              label="Ekle"
              icon="pi pi-plus"
              severity="success"
              onClick={handleAddInsurance}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <DataTable
              className="col-12 col-lg-10"
              emptyMessage="Kayıt bulunamadı."
              value={tableData}
            >
              <Column
                body={(rowData) => {
                  const insuranceOfficeId = rowData.insuranceOfficeId;

                  const foundInsuranceOffice = insuranceOffices.find(
                    (office) => office.id === insuranceOfficeId
                  );

                  if (foundInsuranceOffice) {
                    return foundInsuranceOffice.name;
                  } else {
                    return 'Sigorta Ofisi Bulunamadı';
                  }
                }}
                header="Sigorta Şirketi"
              ></Column>
              <Column
                body={(rowData) => {
                  const insuranceTypeId = rowData.insuranceType;
                  const foundInsuranceType = insuranceTypes.find(
                    (type) => type.id === insuranceTypeId
                  );

                  if (foundInsuranceType) {
                    return foundInsuranceType.name;
                  } else {
                    return 'Sigorta Tipi Bulunamadı';
                  }
                }}
                header="Sigorta Tipi"
              ></Column>
              <Column field="amount" header="Fiyat"></Column>
              <Column
                field="actions"
                header="İşlemler"
                body={(rowData) => (
                  <div className="d-flex gap-2">
                    <Button
                      className="rounded-3"
                      icon="pi pi-pencil"
                      severity="success"
                      onClick={() => handleEdit(rowData)}
                    />
                    <Button
                      className="rounded-3"
                      icon="pi pi-trash"
                      severity="danger"
                      onClick={() => handleDelete(rowData)}
                    />
                  </div>
                )}
              ></Column>
            </DataTable>
            <Button
              className="rounded-3 col-12 col-lg-2"
              label="Kaydet"
              severity="success"
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveInsuranceRequest;
