import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getModelCodesWithModelNamesByBranchId } from "../services/modelService";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import SharedNameDialog from "./SharedNameDialog";
import SharedDeleteDialog from "./SharedDeleteDialog";

const getBranch = () => {
  return JSON.parse(Cookies.get("userInfo")).branchId;
};

const ExpandableDataTable = () => {
  const toast = useRef(null);

  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedModelCodeId, setSelectedModelCodeId] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [value, setValue] = useState(null);
  const [header, setHeader] = useState(null);
  const [apiOption, setApiOption] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);

  const [modelData, setModelData] = useState([]);

  useEffect(() => {
    let branchId = getBranch();

    async function fetchData() {
      try {
        const data = await getModelCodesWithModelNamesByBranchId(branchId);
        setModelData(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Model listesi yüklenemedi. Sayfayı yenileyiniz.",
        });
      }
    }

    fetchData();
  }, [
    selectedId,
    selectedModelCodeId,
    apiOption,
    showDialog,
    showDeleteDialog,
  ]);

  const handleAddOrUpdateModelCode = (rowData) => {
    if (rowData.id === null) {
      setHeader("Model Kodu Ekle");
    } else {
      setHeader("Model Kodu Düzenle");
    }
    setSelectedId(rowData.id);
    setValue(rowData.name);
    setApiOption("modelCode");
    setShowDialog(true);
  };

  const handleAddOrUpdateModelName = (rowData) => {
    if (rowData.id === null) {
      setHeader("Model Adı Ekle");
    } else {
      setHeader("Model Adı Düzenle");
    }
    setSelectedModelCodeId(rowData.modelCodeId);
    setSelectedId(rowData.id);
    setValue(rowData.name);
    setApiOption("modelName");
    setShowDialog(true);
  };

  const expandableRowTemplate = (rowData) => {
    return (
      <div>
        <DataTable className="my-2" value={rowData.modelNames}>
          <Column className="col-8" field="name" header="Model Adı"
            style={{ width: "60%"}}
          />
          <Column className="col-8" field="name" header="Renkler"
          style={{ width: "20%"}}
          body={(modelNames) => (
            <div>
              {modelNames.colors.map((color, index) => (
                <div key={index}>{color.name}</div>
              ))}
            </div>
          )} />
          <Column
            className="col-4 col-lg-2"
            header="İşlem"
            style={{ width: "20%", minWidth:"130px"}}
            body={(row) => (
              <div>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded rounded-3 p-button-success"
                  onClick={() => handleAddOrUpdateModelName(row)}
                />
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded rounded-3 ms-1 bg-blue"
                  onClick={() => handleDeleteModelName(row)}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
    );
  };

  const rowExpansionTemplate = (rowData) => {
    return expandableRowTemplate(rowData);
  };

  const onRowToggle = (event) => {
    setExpandedRows(event.data);
  };

  const handleEditModelName = (rowData) => {
    // Implement edit functionality here
  };

  const handleDeleteModelName = (rowData) => {
    setHeader("Model Adı Sil");
    setSelectedId(rowData.id);
    setApiOption("modelName");
    setShowDeleteDialog(true);
  };

  const handleDeleteModelCode = (rowData) => {
    setHeader("Model Kodu Sil");
    setSelectedId(rowData.id);
    setApiOption("modelCode");
    setShowDeleteDialog(true);
  };

  return (
    <div className="customerstyles">
      <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-8">
        <h4>Araç Model Ekle</h4>
        <div className="d-flex gap-2">
          <Button
            severity="success"
            className="rounded-3"
            onClick={() => handleAddOrUpdateModelCode({ id: null, name: null })}
          >
            Model Kodu Ekle
          </Button>
          <Button
            severity="success"
            className="rounded-3"
            onClick={() =>
              handleAddOrUpdateModelName({
                modelCodeId: null,
                id: null,
                name: null,
              })
            }
          >
            Model Adı Ekle
          </Button>
        </div>
      </div>
      <div className="col-12 col-xxl-8 system-models">
        <DataTable
          value={modelData}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}
          rowExpansionTemplate={rowExpansionTemplate}
          stripedRows
        >
          <Column expander />
          <Column className="col-8" field="name" header="Model Kodu" />
          <Column
            className="col-4 col-lg-2"
            header="İşlem"
            body={(row) => (
              <div>
                <Button icon="pi pi-pencil" className="p-button-rounded rounded-3 p-button-success" onClick={() => handleAddOrUpdateModelCode(row)} />
                <Button icon="pi pi-trash" className="p-button-rounded rounded-3 ms-1 bg-blue" onClick={() => handleDeleteModelCode(row)} />
              </div>
            )}
          />
        </DataTable>
      </div>

      <SharedNameDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        id={selectedId}
        setSelectedId={setSelectedId}
        header={header}
        setHeader={setHeader}
        value={value}
        setValue={setValue}
        apiOption={apiOption}
        setApiOption={setApiOption}
      />

      <SharedDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={selectedId}
        setSelectedId={setSelectedId}
        header={header}
        setHeader={setHeader}
        apiOption={apiOption}
        setApiOption={setApiOption}
      />
    </div>
  );
};

export default ExpandableDataTable;
