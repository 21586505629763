import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { addInsuranceOffice, getAllInsuranceOffices } from '../services/systemService';
import SharedDeleteDialog from "./SharedDeleteDialog";

const ListInsuranceOffices = () => {

    const toast = useRef(null);

    const [insuranceOfficeList, setInsuranceOfficeList] = useState([]);
    const [apiOption, setApiOption] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [header, setHeader] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [formData, setFormData] = useState({
        id: null,
        name: null,
    });

    const handleClean = () => {
        setFormData({
            ...formData,
            id: null,
            name: "",
        });
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getAllInsuranceOffices();
                setInsuranceOfficeList(data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Sigorta kurumu listesi yüklenemedi. Sayfayı yenileyiniz.' });
            }
        }
        fetchData();
    }, []);

    const handleSave = async () => {

        if (!formData.name) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Lütfen tüm alanları doldurunuz.' });
            return;
        } else {
            const response = await addInsuranceOffice(formData);

            if(response && response.isSuccess){
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: response.message ?? 'Sigorta kurumu eklendi.' });
                handleClean();
            }
            else{
                toast.current.show({ severity: 'error', summary: 'Hata', detail: response.message ?? 'Sigorta kurumu eklenemedi.' });
            }
        }
    };

    const handleDelete = (rowData) => {
        setHeader("Sigorta Kurumu Sil");
        setSelectedId(rowData.id);
        setApiOption("insuranceOffice");
        setShowDeleteDialog(true);
      };

    return (
        <div className='customerstyles'>
            <Toast ref={toast} />
            <h4 className='mb-5'>Sigorta Kurumu Ekle</h4>
            <div className="col-12 col-lg-8 col-xxl-5 mb-5">

                <div className="mb-4">
                    <span className="p-float-label">
                        <InputText
                            className="col-12"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            style={{ maxWidth: "25rem" }}
                        />
                        <label htmlFor="name">Sigorta Kurumu Adı</label>
                    </span>
                </div>
                <div className="d-flex justify-content-end gap-2 col-12" style={{ maxWidth: "25rem" }}>
                    <Button label="İptal" className="p-button-secondary rounded-3"
                        onClick={handleClean}
                    />
                    <Button
                        label="Kaydet"
                        className="p-button-success rounded-3"
                        onClick={handleSave}
                    />
                </div>
            </div>
            <div className="mt-4 col-12 col-lg-8">
                <h4 className="mb-5">Sigorta Kurumu Listesi</h4>
                <DataTable value={insuranceOfficeList} sortMode="multiple" paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} style={{ maxWidth:"40rem" }} >
                    <Column field="name"
                    style={{ width: '70%'}}
                    header="Sigorta Kurumu Adı" sortable />
                    <Column
                        header="İşlem"
                        className='text-center'
                        style={{ width: '15%' }}
                        body={(rowData) => (
                            <>
                                {/* <Button
                                    className="p-button-rounded rounded-3 p-button-success"
                                    icon="pi pi-pencil"
                                    onClick={() => handleEdit(formData)}
                                /> */}
                                <Button
                                    className="p-button-rounded rounded-3 bg-blue ms-1"
                                    icon="pi pi-trash"
                                    onClick={() => handleDelete(rowData)}
                                />
                            </>
                        )}
                    />
                </DataTable>
            </div>

            <SharedDeleteDialog
            showDeleteDialog={showDeleteDialog}
            setShowDeleteDialog={setShowDeleteDialog}
            id={selectedId}
            setSelectedId={setSelectedId}
            header={header}
            setHeader={setHeader}
            apiOption={apiOption}
            setApiOption={setApiOption}
          />
        </div>
    )
}

export default ListInsuranceOffices;