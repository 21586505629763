import React from 'react';
import cherrylogo from '../../assets/images/cherylogo.png';
import hondalogo from '../../assets/images/hondalogo.png';

const Proforma = ({ data, date }) => {
  let image = null;

  if (data.branch === 'Chery') {
    image = cherrylogo;
  } else if (data.branch === 'Honda') {
    image = hondalogo;
  }

  const mtvPlakaTescil = parseFloat(data.mtv) + parseFloat(data.plakaTescil);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  return (
    <div className="customerstyles">
      <div className="pdfs">
        <div className="d-flex justify-content-between align-items-center">
          <img src={image} alt="logo" width="160px" />
          <span>{formatDateTime(date)}</span>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column my-4">
          <h5>PROFORMA FATURA</h5>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column mb-5"></div>
        <div className="mb-5">
          <div className="d-flex flex-column gap-1 mt-3 mb-3"
            style={{
              fontFamily: 'Arial, sans-serif',
            }}>
            <div className="mb-3 d-flex flex-column fw-bold col-12">
              <div className="d-flex flex-row">
                <span className="col-4">ALICI:</span>
                <span className="col-8 ps-4">{data.customerName}</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">T.C.:</span>
                <span className="col-8 ps-4">{data.customerIdNumber}</span>
              </div>
            </div>
            <div className="mb-3 d-flex flex-column fw-bold col-12">
              <div className="d-flex flex-row">
                <span className="col-4">MAL BEDELİ:</span>
                <span className="col-8 ps-4">₺{
                  formatNumber(data.basePrice)
                }</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">ÖTV %{data.otv}:</span>
                <span className="col-8 ps-4">₺{
                  formatNumber(data.otvPrice)
                }</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">ÖTV DAHİL MAL BEDELİ:</span>
                <span className="col-8 ps-4">₺{
                  formatNumber(data.priceWithOtv)
                }</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">KDV %{parseFloat(data.kdv) * 100}:</span>
                <span className="col-8 ps-4">₺{
                  formatNumber(data.kdvPrice)
                }</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">FATURA TOPLAMI:</span>
                <span className="col-8 ps-4">₺{
                  formatNumber(data.priceWithOtvKdv)
                }</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">MTV+PLAKA:</span>
                <span className="col-8 ps-4">₺{
                  formatNumber(mtvPlakaTescil)
                }</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">GENEL TOPLAM:</span>
                <span className="col-8 ps-4">₺{
                  formatNumber(data.totalPrice)
                }</span>
              </div>
            </div>
            <div className="mb-3 d-flex flex-column fw-bold">
              <div className="d-flex flex-row">
                <span className="col-4">ARAÇ:</span>
                <span className="col-8 ps-4">{data.model}</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">ŞASE:</span>
                <span className="col-8 ps-4">{data.sasiNo}</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">MOTOR:</span>
                <span className="col-8 ps-4">{data.motorNo}</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">MODEL:</span>
                <span className="col-8 ps-4">{data.year}</span>
              </div>
              <div className="d-flex flex-row">
                <span className="col-4">RENK:</span>
                <span className="col-8 ps-4">{data.color}</span>
              </div>
            </div>
            <span className="small mt-3">
              Yukarıda yer alan araç ve fiyat bilgisi bugün geçerlidir.
            </span>
            <span className="small text-end fw-bold">SAYGILARIMIZLA</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proforma;
