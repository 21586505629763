import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useState } from 'react';

const AddExtraFuture = ({ extraFeatureForm, setExtraFeatureForm }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;


        setExtraFeatureForm({
            ...extraFeatureForm,
            [name]: value
        });
    }

    return (
        <>
            <div className="d-flex flex-column gap-3">
                <span className="p-float-label">
                    <InputText
                        className="col-12 col-lg-4 border-success"
                        id="aksesuar"
                        name="aksesuar"
                        value={extraFeatureForm.aksesuar}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="aksesuar">Aksesuar</label>
                </span>
                <span className="p-float-label">
                    <InputText
                        className="col-12 col-lg-4 border-success"
                        id="tb"
                        name="tb"
                        value={extraFeatureForm.tb}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="tb">TB</label>
                </span>
                <span className="p-float-label">
                    <InputText
                        className="col-12 col-lg-4 border-success"
                        id="miniRepair"
                        name="miniRepair"
                        value={extraFeatureForm.miniRepair}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="miniRepair">Mini Onarım</label>
                </span>
                <span className="p-float-label">
                    <InputTextarea
                        className="col-12 col-lg-4 border-success"
                        id="description"
                        name="description"
                        value={extraFeatureForm.description}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="description">Not</label>
                </span>
            </div>
        </>
    );
};

export default AddExtraFuture;
