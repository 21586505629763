import React, { useEffect, useRef, useState } from 'react';
import { deleteStockById, getStockListByBranchId } from '../services/stockService';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import ListDetailedStock from './ListDetailedStock';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'react-bootstrap';
import CalculateStockProfit from './CalculateStockProfit';
import { useNavigate } from 'react-router-dom';

const ListStock = () => {
  const navigate = useNavigate();
  const [stockList, setStockList] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);

  const [showDetailedList, setShowDetailedList] = useState(false);
  const [showCalculateProfit, setShowCalculateProfit] = useState(false);
  const [selectedStock, setselectedStock] = useState(null);
  const toast = useRef(null);

  // datatable filter
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  // datatable search input
  const onFilter = (value, field) => {
    let _filters = { ...filters };

    if (value === null || value === undefined) {
      delete _filters[field];
    } else {
      _filters[field] = { value: value, matchMode: FilterMatchMode.CONTAINS };
    }

    setFilters(_filters);
  };

  // datatable search input
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-start">
        {advancedSearch ? (
          <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'stockCode')}
              placeholder="Stok Kodu"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'branchName')}
              placeholder="Marka"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'modelCode')}
              placeholder="Model Kodu"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'modelName')}
              placeholder="Model Adı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'colorName')}
              placeholder="Renk"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'year')}
              placeholder="Yıl"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'sasiNumber')}
              placeholder="Şasi Numarası"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'motorNumber')}
              placeholder="Motor Numarası"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'listPrice')}
              placeholder="Liste Fiyatı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'salePrice')}
              placeholder="Satış Fiyatı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'netProfit')}
              placeholder="Net Kar"
              className="rounded-3"
            />
            <Dropdown
              className="rounded-3"
              onSelect={(e) => onFilter(e, 'status')}
            >
              <Dropdown.Toggle className="border text-secondary bg-white rounded-3 text-start" style={{ width: "14rem", padding: "8px" }}>
                Stok Durumu
              </Dropdown.Toggle>
              <Dropdown.Menu className="bg-green p-2 border border-dark text-light">
                <Dropdown.Item className='text-light' eventKey="Stokta">Stokta</Dropdown.Item>
                <Dropdown.Item className='text-light' eventKey="Rezerve">Rezerve</Dropdown.Item>
                <Dropdown.Item className='text-light' eventKey="Bağlantı Satışta">Bağlantı Satışta</Dropdown.Item>
                <Dropdown.Item className='text-light' eventKey={null}>Seçimi Sıfırla</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'createdAt')}
              placeholder="Kayıt Tarihi"
              className="rounded-3"
            />
            <Button
              type="button"
              icon="pi pi-times-circle"
              className="p-button-success rounded-3"
              tooltip="Filtrelemeyi Kapat"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })
              }
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Arama Yapın"
                className='rounded-3'
              />
            </div>
            <Button
              type="button"
              icon="pi pi-filter"
              className="p-button-success rounded-3 ms-2"
              tooltip="Detaylı Filtreleme"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })}
            />
          </div>
        )}
      </div>
    );
  };
  const header = renderHeader();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getStockListByBranchId();

        if (data === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
        setStockList(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }
    fetchData();
  }, []);

  const handleDeleteStock = async (rowData) => {
    try {
      const response = await deleteStockById(rowData.id);

      if (response === null || !response.isSuccess) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Stok verileri silinemedi. Bir hata oluştu.',
        });
      }
      else{
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: response.message,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Stok verileri silinemedi. Bir hata oluştu.',
      });
    }
  };

  const optionsTemplate = (rowData) => {
    return (
      // Stokta veya Rezerve ise işlem yapılabilir
      <>
        {(rowData.status === 'Stokta' || rowData.status === 'Rezerve') && (
          <Dropdown>
            <Dropdown.Toggle className="btn btn-success bg-green">
              <i className="pi pi-cog fs-5"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
              <Dropdown.Item
                className="text-light border-bottom"
                onClick={() => handleCalculateProfit(rowData)}
              >
                {' '}
                Kar Hesapla{' '}
              </Dropdown.Item>
              {rowData.status == 'Stokta' && (
                <Dropdown.Item
                  className="text-light border-bottom"
                  onClick={() => handleGetSaleForStock(rowData)}
                >
                  {' '}
                  Satışa Git{' '}
                </Dropdown.Item>
              )}
              <Dropdown.Item className="text-light" onClick={() => handleUpdateStock(rowData)}>Düzenle</Dropdown.Item>
              <Dropdown.Item className="text-light border-bottom" onClick={() => handleDeleteStock(rowData)}> Sil </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
      }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const handleShowDetailedList = () => {
    setShowDetailedList(true);
  };

  const handleCalculateProfit = (id) => {
    setShowCalculateProfit(true);
    setselectedStock(id);
  };

  const handleGetSaleForStock = (rowData) => {
    navigate('/sale/create', {
      state: { id: rowData.id, sasiNo: rowData.sasiNumber },
    });
  };

  const handleUpdateStock = (rowData) => {
    navigate('/stock/add', {
      state: { id: rowData.id },
    });
  };

  // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';
    return parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      {showDetailedList ? (
        <ListDetailedStock />
      ) : showCalculateProfit ? (
        <CalculateStockProfit id={selectedStock.id} salePrice={selectedStock.salePrice} listPrice={selectedStock.listPrice} sasiNo={selectedStock.sasiNumber} />
      ) : (
        <div className="customerstyles">
          <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
            <h4>Stok Listesi</h4>
            <Button
              label="Detaylı Liste"
              className="j-button bg-green text-light"
              onClick={handleShowDetailedList}
            />
          </div>
          <DataTable
            value={stockList}
            sortMode="multiple"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: '50rem' }}
            stripedRows
            removableSort
            filters={filters}
            globalFilterFields={[
              'stockCode',
              'branchName',
              'model',
              'sasiNumber',
              'motorNumber',
            ]}
            header={header}
            emptyMessage="Eşleşme bulunamadı."
          >
            <Column field="stockCode" header="Stok Kodu" sortable />
            <Column field="branchName" header="Marka" />
            <Column
              field="modelCode"
              header="Model Kodu"
            />
            <Column
              field="modelName"
              header="Model Adı"
              style={{ minWidth: '300px' }}
            />
            <Column field="colorName" header="Renk" />
            <Column field="year" header="Yıl" sortable />
            <Column field="sasiNumber" header="Şasi Numarası" />
            <Column field="motorNumber" header="Motor Numarası" />
            <Column
              field="listPrice"
              header="Kdv'li Alış Fiyatı"
              body={(rowData) => formatNumber(rowData.listPrice)}
              sortable
            />
            <Column
              field="salePrice"
              header="Satış Fiyatı"
              body={(rowData) => formatNumber(rowData.salePrice)}
              sortable
            />
            <Column
              field="netProfit"
              header="Net Kar"
              body={(rowData) => formatNumber(rowData.netProfit)}
              sortable
            />
            <Column field="status" header="Stok Durumu" />
            <Column
              field="createdAt"
              header="Kayıt Tarihi"
              body={(rowData) => formatDateTime(rowData.createdAt)}
              sortable
            />
            <Column body={optionsTemplate} header="İşlem" />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default ListStock;
