import React, { useEffect, useRef, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import Cookies from 'js-cookie';
import axios, { all } from 'axios';
import "../../assets/styles/_system.scss";

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

const ListAnnouncements = () => {
    const token = getToken();
    const toast = useRef(null);

    const [saveAnnouncements, setSaveAnnouncements] = useState({
        id: null,
        header: '',
        description: '',
        startDate: null,
        endDate: null,
    });

    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        async function fetchData() {
            axios.get(`${baseUrl}/api/Announcement/GetAllAnnouncements`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    console.log(response.data);
                    if (response.data) {
                        setAnnouncements(response.data);
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
                })
        }

        fetchData();
    }, [announcements.id, token, saveAnnouncements]);

    const addAnnouncement = (announcement) => {
        axios.post(`${baseUrl}/api/Announcement/AddAnnouncement`, announcement, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data.isSuccess) {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: response.data.message });
                    handleClean();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
            })
    };

    const updateAnnouncement = (announcement) => {
        axios.post(`${baseUrl}/api/Announcement/UpdateAnnouncement`, announcement, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data.isSuccess) {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: response.data.message });
                    handleClean();
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
            })
    };

    const handleDelete = (announcements) => {
        
        axios.post(`${baseUrl}/api/Announcement/DeleteAnnouncement`, { id: announcements.id }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data.isSuccess) {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: response.data.message });
                    setAnnouncements(prevAnnouncements => prevAnnouncements.filter(item => item.id !== announcements.id));
                } else {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
            })
    };

    const handleSave = async () => {
        try {
            let result = '';
            if (saveAnnouncements.id === null) {
                result = await addAnnouncement(saveAnnouncements);
            }
            else {
                result = await updateAnnouncement(saveAnnouncements);
            }
            handleClean();
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu. Tekrar deneyiniz.' });
        }
    };


    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
            }/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
    };

    const handleHeaderChange = (e) => {
        setSaveAnnouncements(prevState => ({
            ...prevState,
            header: e.target.value
        }));
    };

    const handleDescriptionChange = (e) => {
        setSaveAnnouncements(prevState => ({
            ...prevState,
            description: e.target.value
        }));
    };

    const handleClean = async () => {
        setSaveAnnouncements({
            id: null,
            header: '',
            description: '',
            startDate: null,
            endDate: null
        });
    };

    const handleEdit = (data) => {
        setSaveAnnouncements(prevState => ({
            ...prevState,
            id: data.id,
            header: data.header,
            description: data.description,
            startDate: data.startDate ? new Date(data.startDate) : null,
            endDate: data.endDate ? new Date(data.endDate) : null
        }));
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setSaveAnnouncements({
            ...saveAnnouncements,
            [name]: value,
        });
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="systemstyles">
                <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-8">
                    <h4>Duyuru Ayarları</h4>
                </div>
                <div className="col-12 col-lg-8 col-xxl-5">
                    <div className="mb-4">
                        <span className="p-float-label">
                            <InputText
                                className="col-12"
                                id="header"
                                value={saveAnnouncements.header}
                                onChange={handleHeaderChange}
                            />
                            <label htmlFor="header">Duyuru Başlığı</label>
                        </span>
                    </div>
                    <div className="mb-2">
                        <span className="p-float-label">
                            <InputTextarea
                                className="col-12"
                                id="description"
                                value={saveAnnouncements.description}
                                onChange={handleDescriptionChange}
                            />
                            <label htmlFor="description">Duyuru Açıklaması</label>
                        </span>
                        <div className='d-flex'>
                            <span className="p-float-label col-12 col-lg-6 me-3 mb-4">
                                <Calendar
                                    id="startDate"
                                    name="startDate"
                                    className="inputgap"
                                    value={saveAnnouncements.startDate}
                                    onChange={(e) => handleChange(e)}
                                    dateFormat="dd/mm/yy"
                                />
                                <label htmlFor="startDate">Başlangıç Tarihi</label>
                            </span>
                            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                                <Calendar
                                    id="endDate"
                                    name="endDate"
                                    className="inputgap"
                                    value={saveAnnouncements.endDate}
                                    onChange={(e) => handleChange(e)}
                                    dateFormat="dd/mm/yy"
                                />
                                <label htmlFor="endDate">Bitiş Tarihi</label>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 col-12">
                        <Button label="İptal" className="p-button-secondary rounded-3" onClick={handleClean} />
                        <Button
                            label="Kaydet"
                            className="p-button-success rounded-3"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-8 col-xxl-5 mt-4">
                {announcements.map((announcements, index) => (
                    <React.Fragment key={index}>
                        <Card className="rounded-3" title={announcements.header}>
                            <div>{announcements.description}</div>
                            <div className='mt-3'><i>Başlangıç Tarihi: </i>{formatDateTime(announcements.startDate)}</div>
                            <div><i>Bitiş Tarihi: </i>{formatDateTime(announcements.endDate)}</div>
                            <div style={{ marginTop: "10px", textAlign: "right" }}>
                                <Button
                                    icon="pi pi-pencil"
                                    className="p-button-rounded rounded-3 p-button-success"
                                    onClick={() => handleEdit(announcements)}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded rounded-3 ms-1 bg-blue"
                                    onClick={() => handleDelete(announcements)}
                                />
                            </div>
                        </Card>
                        {index !== announcements.length - 1 && <hr />}{" "}
                        {/* Add a horizontal line after each card except for the last one */}

                    </React.Fragment>
                ))}
            </div>
        </>
    )
}

export default ListAnnouncements;