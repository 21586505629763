import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import '../../assets/styles/_calculateTaxes.scss';
import { getAllOtvOptions } from '../services/systemService';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import '../../assets/styles/_customer.scss';

const CalculateTaxes = ({ formData, setFormData }) => {
  const toast = useRef(null);

  const [totalPrice, setTotalPrice] = useState('');
  const [netProfit, setNetProfit] = useState('');
  const [calculateButtonDisabled, setCalculateButtonDisabled] = useState(true);
  const [otvOptions, setOtvOptions] = useState([]);

  useEffect(() => {

    setCalculateButtonDisabled(
      !formData.listPrice ||
      !formData.salePrice ||
      !formData.otvOptionsId ||
      !formData.kdv ||
      !formData.mtv ||
      !formData.plakaTescil
    );
  }, [
    formData.listPrice,
    formData.salePrice,
    formData.otvOptionsId,
    formData.kdv,
    formData.mtv,
    formData.plakaTescil,
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllOtvOptions();

        setOtvOptions(fetchedData);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Yakıt tipi verileri yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const handleChange = (e, fieldName) => {

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const calculateTaxPrices = (event) => {

    event.preventDefault(); // Prevent default form submission behavior

    // Calculate the basePrice from list price --kdvli alış fiyatından taban fiyat hesaplanır.
    const basePrice = formData.listPrice / (1 + parseFloat(formData.kdv)) - formData.campaign;

    // Calculate sale price with no taxes
    const saleBasePrice = (formData.salePrice - formData.mtv - formData.plakaTescil) / (1 + formData.otv / 100) / (1 + parseFloat(formData.kdv));

    // Calculate net profit
    const netProfit = (parseFloat(saleBasePrice) - parseFloat(basePrice)).toFixed(2);

    setNetProfit(netProfit);

    setFormData((prevData) => ({
      ...prevData,
      basePrice: basePrice.toFixed(2),
      netProfit: parseFloat(netProfit).toFixed(2),
      totalPrice: parseFloat(formData.salePrice).toFixed(2),
    }));
  };

  const handleDropdownChange = (e, fieldName) => {
    const selectedValue = e.value;

    const selectedOption = otvOptions.find(
      (item) => item.id === selectedValue
    ).option;

    setFormData({
      ...formData,
      [fieldName]: selectedValue,
      'otv': selectedOption,
    });
  };

  return (
    <div className="customerstyles">
      <Toast ref={toast} />

      <div className="my-3">
        <div className="d-block d-lg-flex col-12 col-xxl-8 mb-3">
          <div className="col-12 col-lg-6 me-4 mb-3">
            <label htmlFor="listPrice">Kdv'li Alış Fiyatı</label>
            <InputNumber
              minFractionDigits={2}
              maxFractionDigits={2}
              className="inputgap"
              inputId="listPrice"
              name="listPrice"
              locale='tr-TR'
              value={formData.listPrice}
              onChange={(e) => handleChange(e, 'listPrice')}
            />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <label htmlFor="salePrice">Satış Fiyatı</label>
            <InputNumber
              minFractionDigits={2}
              maxFractionDigits={2}
              className="inputgap"
              inputId="salePrice"
              name="salePrice"
              locale='tr-TR'
              value={formData.salePrice || ''}
              onChange={(e) => handleChange(e, 'salePrice')}
            />
          </div>
        </div>
        <div className="row">
          {/* Özel ÖTV */}
          <div className="d-block d-lg-flex col-12 col-lg-6 col-xxl-3 mb-3">
            <Form.Group className="col-12">
              <label htmlFor='otvOptionsId'>ÖTV Seçenekleri</label>
              <div className="col-12">
                <span className="p-float-label col-12 me-4">
                  <Dropdown
                    className="inputgap"
                    id="otvOptionsId"
                    optionLabel="option"
                    optionValue="id"
                    name='otvOptionsId'
                    value={formData.otvOptionsId}
                    options={otvOptions}
                    onChange={(e) => handleDropdownChange(e, 'otvOptionsId')}
                    filter
                    showClear
                  />
                </span>
              </div>
            </Form.Group>
          </div>
          {/* KDV */}
          <div className="col-lg-6 col-xxl-3 mb-3">
            <label htmlFor="kdv">KDV</label>
            <InputNumber
              className="inputgap"
              inputId="kdv"
              name="kdv"
              value={formData.kdv}
              disabled
            />
          </div>
          {/* MTV */}
          <div className="col-lg-6 col-xxl-3 mb-3">
            <label htmlFor="mtv">MTV</label>
            <InputNumber
              className="inputgap"
              inputId="mtv"
              name="mtv"
              value={formData.mtv}
              disabled
            />
          </div>
          {/* Plaka Tescil */}
          <div className="col-lg-6 col-xxl-3">
            <label htmlFor="plakaTescil">Plaka Tescil</label>
            <InputNumber
              className="inputgap"
              inputId="plakaTescil"
              name="plakaTescil"
              value={formData.plakaTescil}
              disabled
            />
          </div>
        </div>
        <div className="row col-12 mt-4 d-flex align-items-start justify-content-between">
          {/* Results */}

          <div className="col-12 col-lg-4">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ minWidth: '120px' }}>Fiyat Bilgisi</th>
                  <th style={{ minWidth: '150px' }}>Tutar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Alış Mal Bedeli</td>
                  {formData.basePrice ? (
                    <td>{formatNumber(formData.basePrice)}</td>
                  ) : (
                    <td>Değer Girilmedi</td>
                  )}
                </tr>
                <tr>
                  <td>Satış Fiyatı</td>
                  {formData.salePrice ? (
                    <td>{formatNumber(formData.salePrice)}</td>
                  ) : (
                    <td>Değer Girilmedi</td>
                  )}
                </tr>
                <tr>
                  <td>Net Kâr</td>
                  {netProfit ? (
                    <td>{formatNumber(netProfit)}</td>
                  ) : (
                    <td>Hesaplanmadı</td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 col-lg-8 text-end">
            <Button
              id="calculateTaxesButton"
              onClick={calculateTaxPrices}
              className="j-button bg-green calculate-taxes-button"
              disabled={calculateButtonDisabled}
              // style={calculateButtonDisabled}
              label="Tutar Hesapla"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculateTaxes;
