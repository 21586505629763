import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/styles/_navbar.scss";
import logoWebp from "../../assets/images/occo_car_logo.webp";
import Cookies from "js-cookie";
import { logout } from "../services/authMiddleware";
import { Toast } from "primereact/toast";

const Navbar = ({ username }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Set the user info
  const [userInfo, setUserInfo] = useState(null);

  // Get user info from the Cookies
  useEffect(() => {
    const userInfoFromCookies = Cookies.get('userInfo');
    if (userInfoFromCookies) {
      setUserInfo(JSON.parse(userInfoFromCookies));
    }
  }, []);

  const handleLogout = async () => {
    const response = await logout();
    if (response === null || !response.isSuccess) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: response.message
          ? response.message
          : 'Çıkış Yapılamadı. Lütfen tekrar deneyiniz.',
      });
    } else {
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı',
        detail: response.message
          ? response.message
          : 'Başarıyla çıkış yapıldı.',
      });

      navigate('/');
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="navbar">
        <div className="navbar-mobile">
          <div className="navbar-mobile-logo">
            <Link to="/dashboard">
            <img src={logoWebp} alt="Logo" />
            </Link>
          </div>
          <div className="navbar-dropdown">
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic">

              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/user/view">Profilim</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLogout()}>Çıkış Yap</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="navbar-right">
          <Dropdown show={isOpen} onClick={handleToggle}>
            <Dropdown.Toggle
              variant="none"
              id="dropdown-basic"
              style={{ border: "none" }}
            >
              <i className="pi pi-user user-icon"></i>
              <span className="username">{userInfo ? userInfo.userName : ''}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ backgroundColor: "#f5f5f5" }}>
              <Dropdown.Item as={Link} to="/user/view">
                Profili Gör
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout()}>
                Çıkış Yap
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>

  );
};

export default Navbar;
