import Cookies from 'js-cookie';


const baseUrl = process.env.REACT_APP_API_URL + '/api';

const getToken = () => {
  return Cookies.get('token');
};

const responseDto = {
  isSuccess: null,
  message: null,
  dataId: null
};

export async function getAllOtvOptions() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/OtvOptions/GetAllOtvOptions`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const res = await response.json();
      responseDto.isSuccess = res
    }

    return await response.json();

  } catch (error) {
    responseDto.isSuccess = false;
    responseDto.message = "Markalar yüklenirken bir hata oluştu.";
    responseDto.dataId = null;
    return responseDto;
  }
};

export async function addOtvOptions(option, type) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/OtvOption/AddOtvOption`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ option: option, type: type })
    });

    if (!response.ok) {
      throw new Error("Failed to fetch colors");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch colors");
  }
};

export async function updateOtvOptions(id, name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/OtvOptions/UpdateOtvOptions`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id, name: name })
    });

    if (!response.ok) {
      throw new Error("Failed to fetch colors");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch colors");
  }
};


export async function deleteOtvOption(id) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/OtvOption/DeleteOtvOption`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id }),
    });

    return await response.json();

  } catch (error) {
    return null;
  }
}
