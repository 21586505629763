import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import '../../assets/styles/_dashboard.scss';

const DashboardCalendar = () => {

  //Burada datepicker'la ne yaptığımızı bilmiyorum o yüzden css tarafında biraz boyutlarını değiştirerek olduğu gibi ekledim.
  
    const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="calendar">
    <DatePicker
      inline
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  </div>
  )
}

export default DashboardCalendar
