import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import { getStockInfoById } from '../services/stockService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ListSaleCarData = ({id}) => {

    const toast = useRef(null);

    const [stockInfo, setStockInfo] = useState([]);

    useEffect(() => {
        async function fetchData() {
          try {
            const data = await getStockInfoById(id);
            setStockInfo(data);
          } catch (error) {
            toast.current.show({
              severity: "error",
              summary: "Hata",
              detail: "Stoktaki aracın detayı yüklenemedi. Yeniden deneyiniz.",
            });
          }
        }
    
        fetchData();
      }, [id]);

    return (
        <>
            <Toast ref={toast} />
            <div className='customerstyles mb-5'>
                    <h6 className="mb-2">Bağlantıya Alınan Araç Detayı</h6>
                    <DataTable value={stockInfo} tableStyle={{ minWidth: "70rem" }}>
                        <Column field="branchName" header="Marka" />
                        <Column field="model" header="Model" />
                        <Column field="color" header="Renk" />
                        <Column field="year" header="Yılı" />
                    </DataTable>
            </div>
        </>
    )
}

export default ListSaleCarData