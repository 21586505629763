import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import '../../assets/styles/_customer.scss';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useAsyncError, useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const CalculateStockProfit = ({ id, salePrice, listPrice, sasiNo }) => {
  const navigate = useNavigate();
  const [newSalePrice, setSalePrice] = useState(salePrice);
  const [raporlu, setRaporlu] = useState(false);
  const [raporluSalePrice, setRaporluSalePrice] = useState(null);
  const [netProfit, setNetProfit] = useState(null);

  // stoğa kaydedilmiş olan vergi değerleri
  const [taxValues, setTaxValues] = useState({
    kdv: null,
    otv: null,
    mtv: null,
    plakaTescil: null,
    campaign: null,
  });

  // api den stoğa ait olan vergi değerleri gelir ve setlenir
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();
        if (!token) {
          //TODO
          return;
        }

        const response = await fetch(
          `${baseUrl}/api/Stock/GetStockTaxesByStockId`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        setTaxValues((prevState) => ({
          ...prevState,
          kdv: data.kdv,
          otv: data.otv,
          mtv: data.mtv,
          plakaTescil: data.plakaTescil,
          campaign: data.campaign ?? 0,
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error
      }
    };

    if (id) {
      fetchData();
    }
  }, []);

  // kar hesapla fonksiyonu
  const calculateProfit = () => {
    // satış fiyatı
    const salePrice = newSalePrice;

    // alış fiyatının taban fiyatı (alış mal bedeli)
    const basePrice =
      parseFloat(listPrice) / (1 + taxValues.kdv) - taxValues.campaign;

    // mtv ve plaka tescil olmadan satış fiyatı
    const priceWithoutMtv = salePrice - taxValues.mtv - taxValues.plakaTescil;

    let otvValue = taxValues.otv;

    // raporlu satışsa
    if (raporlu) {
      // ötv sıfır
      otvValue = 0;

      const priceWithoutOtv = priceWithoutMtv / (1 + taxValues.otv / 100);
      // mtv eklenmeyecek
      const raporluSalePrice = priceWithoutOtv + taxValues.plakaTescil;

      setRaporluSalePrice(parseFloat(raporluSalePrice).toLocaleString('tr-TR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }));
      // kar hesapla
      const raporluBasePrice = priceWithoutOtv / (1 + taxValues.kdv);
      const calcProfit = raporluBasePrice - basePrice;
      setNetProfit(parseFloat(calcProfit).toLocaleString('tr-TR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }));
    } else {
      const priceWithoutOtv = priceWithoutMtv / (1 + otvValue / 100);
      const saleBasePrice = priceWithoutOtv / (1 + taxValues.kdv);

      const calcProfit = saleBasePrice - basePrice;
      setNetProfit(parseFloat(calcProfit).toLocaleString('tr-TR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }));
    }
  };

  // girilen değerler ile satışa git
  const handleGoToSale = () => {
    navigate('/sale/create', {
      state: {
        id: id,
        sasiNo: sasiNo,
        newSalePrice,
        raporluSalePrice,
        raporlu,
      },
    });
  };

  return (
    <>
      <div className="customerstyles">
        <div className="d-block d-lg-flex col-12 col-xxl-8 mb-5">
          <h4>Kâr Hesapla</h4>
        </div>
        <div className="d-flex flex-wrap align-items-center col-12">
          <div className="col-12 col-lg-4 col-xxl-3 mb-3">
            <label htmlFor="newSalePrice">Satış Fiyatı</label>
            <InputNumber
              className="inputgap"
              inputId="newSalePrice"
              name="newSalePrice"
              value={newSalePrice}
              onChange={(e) => setSalePrice(e.value)}
            />
          </div>
          <div className="col-12 col-lg-3 col-xxl-2 d-flex align-items-center ">
            <Checkbox
              id="raporlu"
              name="raporlu"
              className="me-3"
              onChange={(e) => setRaporlu(e.checked)}
              checked={raporlu}
            ></Checkbox>
            <label htmlFor="raporlu">ÖTV Raporlu</label>
          </div>
          <div className="col-12 col-lg-4 mb-3 mt-3">
            <Button
              id="calculatePrice"
              onClick={calculateProfit}
              className="j-button bg-green calculate-taxes-button"
              label="Tutar Hesapla"
            />
          </div>
        </div>

        <div>
          <div className="col-12 col-lg-4">
            {netProfit && (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Net Kâr</th>
                    {raporlu && raporluSalePrice && (
                      <th scope="col">Raporlu Satış Fiyatı</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{netProfit}</td>
                    {raporlu && raporluSalePrice && <td>{raporluSalePrice}</td>}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div>
        <Button
          id="goToSale"
          onClick={handleGoToSale}
          className="j-button bg-green calculate-taxes-button mt-3"
          label="Satışa Git"
        />
      </div>
    </>
  );
};

export default CalculateStockProfit;
