import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { getAllCustomerNamesList } from '../services/customerService';
import { Toast } from 'primereact/toast';
import {
  getAllModelCodesByBranchId,
  getAllModelNamesByModelCodeId,
  getModelNamesList,
} from '../services/modelService';
import {
  addCarRequest,
  getUpdCarRequestDataById,
  updateCarRequest,
} from '../services/carRequestService';
import { getColorsByModelNameId } from '../services/stockService';
import { useLocation } from 'react-router-dom';

const AddCarRequest = () => {
  const toast = useRef(null);
  const location = useLocation();
  const stateId = location.state ? location.state.id : null;

  const [customerList, setCustomerList] = useState([]);
  const [modelCodeList, setModelCodeList] = useState([]);
  const [modelNameList, setModelNameList] = useState([]);
  const [colorList, setColorList] = useState([]);

  const [formData, setFormData] = useState({
    id: stateId ? stateId : null,
    customer: null,
    until: null,
    modelCode: null,
    modelName: null,
    color: null,
    note: null,
  });

  useEffect(() => {
    async function fetchData() {
      if (stateId) {
        // get the requestid's data
        const data = await getUpdCarRequestDataById(stateId);
        //check if response ok
        if (data && data.message && data.isSuccess === false) {
          return toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail:
              data.message ??
              'Güncellenecek veriler yüklenirken hata oluştu. Tekrar deneyiniz.',
          });
        }
        // set the formData according to the response returned
        setFormData((prevState) => ({
          ...prevState,
          id: stateId,
          customer: data.customerId,
          modelCode: data.modelCodeId,
          modelName: data.modelNameId,
          color: data.colorId,
          until: data.until,
          note: data.note,
        }));
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = await getAllCustomerNamesList();

      if (data && data.message && data.isSuccess === false) {
        return toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            data.message ??
            'Müşteriler yüklenirken hata oluştu. Tekrar deneyiniz.',
        });
      } else {
        setCustomerList(data);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = await getAllModelCodesByBranchId();
      if (data && data.message && data.isSuccess === false) {
        return toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            data.message ??
            'Model kodları yüklenirken hata oluştu. Tekrar deneyiniz.',
        });
      } else {
        setModelCodeList(data);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (formData.modelCode === null) return;
      const data = await getAllModelNamesByModelCodeId(formData.modelCode);
      if (data && data.message && data.isSuccess === false) {
        return toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            data.message ??
            'Model adları yüklenirken hata oluştu. Tekrar deneyiniz.',
        });
      } else {
        setModelNameList(data);
      }
    }

    fetchData();
  }, [formData.modelCode]);

  useEffect(() => {
    async function fetchData() {
      if (formData.modelName === null) return;
      const data = await getColorsByModelNameId(formData.modelName);
      if (data && data.message && data.isSuccess === false) {
        return toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            data.message ??
            'Renkler yüklenirken hata oluştu. Tekrar deneyiniz.',
        });
      } else {
        setColorList(data);
      }
    }

    fetchData();
  }, [formData.modelName]);

  const handleSave = async () => {
    try {
      // inputların doluluk kontrolü
      if (
        !formData.customer ||
        !formData.until ||
        !formData.modelCode ||
        !formData.modelName ||
        !formData.color
      ) {
        return toast.current.show({
          severity: 'warn',
          summary: 'Hata',
          detail:
            `Lütfen ` +
            (!formData.customer ? 'müşteri, ' : '') +
            (!formData.until ? 'talep süresi, ' : '') +
            (!formData.modelCode ? 'model kodu, ' : '') +
            (!formData.modelName ? 'model adı, ' : '') +
            (!formData.color ? 'renk ' : '') +
            'alanlarını doldurunuz.',
        });
      }

      if (formData.id === null || formData.id === undefined) {
        //add
        const result = await addCarRequest(formData);

        if (result && result.isSuccess) {
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı',
            detail: 'Araç talebi başarıyla kaydedilmiştir.',
          });
          handleClean();
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: result.message
              ? result.message
              : 'Araç talebi kaydedilemedi.',
          });
        }
      } else {
        //update
        const result = await updateCarRequest(formData);

        if (result && result.isSuccess) {
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı',
            detail: 'Araç talebi başarıyla güncellenmiştir.',
          });
          handleClean();
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: result.message
              ? result.message
              : 'Araç talebi güncellenemedi.',
          });
        }
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Araç talebi kaydedilemedi.',
      });
    }
  };

  const handleClean = () => {
    setFormData({
      ...formData,
      id: null,
      customer: null,
      until: null,
      note: '',
      modelCode: null,
      modelName: null,
      color: null,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'modelCode') {
      setFormData((prevState) => ({
        ...prevState,
        ['modelName']: null,
        ['color']: null,
      }));
    }

    if (name === 'modelName') {
      setFormData((prevState) => ({
        ...prevState,
        ['color']: null,
      }));
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <h4 className="mb-5">Araç Talepleri</h4>
      <div className="p-fluid col-12 col-lg-6 col-xxl-4 d-flex flex-column gap-3">
        <div className="p-col">
          <label htmlFor="customerDropdown">Müşteri</label>
          <Dropdown
            className="rounded-3"
            id="customer"
            name="customer"
            optionLabel="name"
            optionValue="id"
            value={formData.customer}
            options={customerList}
            onChange={(e) => handleChange(e)}
            disabled={
              formData.id === null || formData.id === undefined ? false : true
            }
            filter
            showClear
            placeholder="Müşteri Seçiniz"
          />
        </div>
        <div className="p-col">
          <label htmlFor="modelCode">Model Kodu</label>
          <Dropdown
            className="rounded-3"
            id="modelCode"
            name="modelCode"
            optionLabel="name"
            optionValue="id"
            value={formData.modelCode}
            options={modelCodeList}
            onChange={(e) => handleChange(e)}
            filter
            showClear
            placeholder="Model Kodu Seçiniz"
          />
        </div>
        <div className="p-col">
          <label htmlFor="modelName">Model Adı</label>
          <Dropdown
            className="rounded-3"
            id="modelName"
            name="modelName"
            optionLabel="name"
            optionValue="id"
            value={formData.modelName}
            options={modelNameList}
            onChange={(e) => handleChange(e)}
            filter
            showClear
            placeholder="Model Adı Seçiniz"
          />
        </div>
        <div className="p-col">
          <label htmlFor="color">Renk</label>
          <Dropdown
            className="rounded-3"
            id="color"
            name="color"
            optionLabel="name"
            optionValue="id"
            value={formData.color}
            options={colorList}
            onChange={(e) => handleChange(e)}
            filter
            showClear
            placeholder="Renk Seçiniz"
          />
        </div>
        <div className="p-col">
          <label htmlFor="until">Talep Süresi</label>
          <Calendar
            className="rounded-3"
            id="until"
            name="until"
            value={formData.until ? new Date(formData.until) : null}
            onChange={(e) => handleChange(e)}
            dateFormat="dd/mm/yy"
            placeholder="gg/aa/yyyy"
          />
        </div>
        <div className="p-col">
          <label htmlFor="note">Not</label>
          <InputText
            id="note"
            name="note"
            className="rounded-3"
            value={formData.note}
            placeholder="Not Yazınız"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="d-flex flex-row col-12 col-lg-8 gap-2">
          <Button
            label="İptal"
            severity="secondary"
            className="rounded-3"
            onClick={() => handleClean()}
          />
          <Button
            label="Kaydet"
            className="p-button-success rounded-3"
            onClick={() => handleSave()}
          />
        </div>
      </div>
    </>
  );
};

export default AddCarRequest;
