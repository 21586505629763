import React, { useState, useEffect, useRef } from 'react';
import { getAllCustomerNamesList } from '../services/customerService';
import { getAllSmsTemplates } from '../services/smsService';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
}

const CustomerSmsCom = () => {
  const toast = useRef(null);

  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const [smsList, setSmsList] = useState([]);
  const [selectedSms, setSelectedSms] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllCustomerNamesList();
        setCustomerList(data);
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Müşteriler yüklenemedi. Yeniden deneyiniz.' });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllSmsTemplates();
        if (Array.isArray(data)) {
          setSmsList(data);
        } else {
          throw new Error("Sms list is not an array");
        }
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Sms listesi yüklenemedi. Yeniden deneyiniz.' });
      }
    }
    fetchData();
  }, []);

  const handleCustomerChange = (e) => {
    const customer = e.value;
    setSelectedCustomers(e.value);
  };

  const handleSmsSelect = (sms) => {
    setSelectedSms(sms);
  };

  const handleSmsChange = (e) => {

    const sms = {id: null, header: 'Tekbaş', description: e.target.value};

    setSelectedSms(sms);
  }

  const handleCancel = () => {
    setSelectedCustomers([]);
    setSelectedSms(null);
    toast.current.show({ severity: 'info', className: 'text-dark' , summary: 'İptal', detail: 'İşlem iptal edildi.' });
  }

  const handleSave = async() => {

    const requestBody = {
      smsTemplateId: selectedSms.id,
      message: selectedSms.id == null ? selectedSms.description : null,
      customerList: selectedCustomers.map((customer) => ({
        id: customer,
        name: customerList.find(cl => cl.id === customer).name
      }))
    };

    try {
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
      const response = await fetch(`${baseUrl}/api/Sms/SendSmsToSelectedCustomers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
        return;
      }

      const data = await response.json();

      toast.current.show({ severity: "success", summary: "Kayıt Başarılı", detail: data.message });

    } catch (error) {
      toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
    }

  }

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles">
        <div className="col-12 col-lg-8 col-xxl-6">
        <h4 className='mb-5'>Müşteri SMS</h4>
          <div className="p-float-label">
            <MultiSelect
            className='col-12 rounded-3'
              id="customerDropdown"
              optionLabel="name"
              optionValue="id"
              value={selectedCustomers}
              options={customerList}
              onChange={handleCustomerChange}
              filter
              showClear
              placeholder="Müşteri Seçiniz"
              multiple
            />
            <label htmlFor="customerDropdown">Müşteri</label>
          </div>
          <div className="mt-3">
            <InputTextarea
            className='col-12 rounded-3'
              rows={5}
              cols={30}
              value={selectedSms ? selectedSms.description : ''}
              onChange={(e) => handleSmsChange(e)}
              placeholder="SMS Açıklaması"
            />
          </div>
          <div className="my-2 col-12 d-flex justify-content-end">
            <Button
              label="İptal"
              severity="secondary"
              className="rounded-3 me-2"
              onClick={handleCancel}
            />
            <Button
              label="Gönder"
              severity="success"
              className="rounded-3"
              onClick={handleSave}
            />
          </div>
        </div>
        {/* smsList 0'a eşitse başlığı gizle */}
        {smsList.length > 0 && <h4 className='mt-4'>Hazır Mesajlar</h4>}
        <div className="col-12 col-lg-8 col-xxl-6 mt-4 d-flex flex-column justify-content-start align-items-center" style={{maxHeight:"500px", overflow:"auto"}}>
          {smsList.map((sms, index) => (
            <Card
              key={index}
              title={sms.header}
              subTitle={sms.description}
              style={{ marginBottom: '20px', cursor: 'pointer', width:"96%", borderRadius:"20px"}}
              onClick={() => handleSmsSelect(sms)}
              className={selectedSms === sms ? 'selected' : ''}
            />
          ))}

        </div>

      </div>
    </>
  );
};

export default CustomerSmsCom;
