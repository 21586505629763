import { createBrowserRouter, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "../assets/styles/_graph.scss";

import NoPermission from '../components/shared/NoPermission';
import NotFound from '../components/shared/NotFound';

import Login from "../components/auth/Login";
import Wrapper from "../components/shared/Wrapper";
import Dashboard from "../components/dashboard/Dashboard";

import AddIndividualCustomer from "../components/customer/AddIndividualCustomer";
import ListCustomerApprovalWaiting from "../components/customer/ListCustomerApprovalWaiting";
import ListCustomer from "../components/customer/ListCustomer";
import CustomerSmsCom from "../components/customer/CustomerSmsCom";
import CustomerCallCom from "../components/customer/CustomerCallCom";

import AddStock from "../components/stock/AddStock";
import ListStock from "../components/stock/ListStock";
import AddReservedSale from "../components/sale/AddReservedSale";
import ListReservedSales from "../components/sale/ListReservedSales";
import ListActiveSales from "../components/sale/ListActiveSales";

import SaleGraphReport from "../components/reports/SaleGraphReport";
import EmployeeGraphReport from "../components/reports/EmployeeGraphReport";

import AddInsuranceRequest from "../components/request/AddInsuranceRequest";
import AddLoanRequest from "../components/request/AddLoanRequest";
import AddCarRequest from "../components/request/AddCarRequest";

import ListModels from "../components/system/ListModels";
import ListColors from "../components/system/ListColors";
import TaxSettings from "../components/system/TaxSettings";
import ListPaymentBanks from "../components/system/ListPaymentBanks";
import ListCreditBanks from "../components/system/ListCreditBanks";
import ListFuelTypes from "../components/system/ListFuelTypes";
import AddSmsTemplate from "../components/system/AddSmsTemplate";
import ViewIndividualCustomer from "../components/customer/ViewIndividualCustomer";
import RegisterUser from "../components/user/RegisterUser";
import EditIndividualCustomer from "../components/customer/EditIndividualCustomer";
import ListAllUsers from "../components/user/ListAllUsers";
import EditStock from "../components/stock/EditStock";
import ListMarketingChanel from "../components/system/ListMarketingChanel";
import ListTestDrives from "../components/customer/ListTestDrives";
import ListInsuranceOffices from "../components/system/ListInsuranceOffices";
import ViewUserInformation from "../components/user/ViewUserInformation";
import ListFeatures from "../components/sale/ListFeatures";
import ListAnnouncements from "../components/system/ListAnnouncements";
import UpdateReservedSale from "../components/sale/UpdateReservedSale";
import ViewReservedSale from "../components/sale/ViewReservedSale";
import ListAccountingSales from "../components/sale/ListAccountingSales";
import ListAllInsuranceRequest from "../components/request/ListAllInsuranceRequest";
import ApproveInsuranceRequest from "../components/request/ApproveInsuranceRequest";
import ListApprovedInsurance from "../components/request/ListApprovedInsurance";
import EditCustomer from "../components/customer/EditCustomer";
import UpdateUser from "../components/user/UpdateUser";
import DailySaleReport from "../components/reports/DailySaleReport";
import Cookie from "js-cookie";

const userInfo = Cookie.get("userInfo");
const user = userInfo ? JSON.parse(userInfo) : null;

function checkPermission(user, permission) {
    return user && user.permissions && user.permissions.includes(permission);
}

function GuardedRoute({ element, permission }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (userInfo) {
            if (!checkPermission(user, permission)) {
                navigate("/no-permission");
            }
        }
    }, [navigate, permission]);
    return element;
}

const routes = createBrowserRouter([
    {
        path:"/no-permission",
        element: <GuardedRoute element={<Wrapper><NoPermission /></Wrapper>} permission="/dashboard" />,
   },
    {
         path:"/",
         element:<Login/>
    },
    {
        path:"/dashboard",
        element: <GuardedRoute element={<Wrapper><Dashboard /></Wrapper>} permission="/dashboard" />,
    },
    //Customer
    {
        path: "/customer/add",
        element: <GuardedRoute element={<Wrapper><AddIndividualCustomer /></Wrapper>} permission="/customer/add" />,
    },
    {
        path: "/customer/approval-waiting",
        element: <GuardedRoute element={<Wrapper><ListCustomerApprovalWaiting /></Wrapper>} permission="/customer/approval-waiting" />,
    },
    {
        path: "/customer/list",
        element: <GuardedRoute element={<Wrapper><ListCustomer /></Wrapper>} permission="/customer/list" />,
    },
    {
        path: "/customer/sms",
        element: <GuardedRoute element={<Wrapper><CustomerSmsCom /></Wrapper>} permission="/customer/sms" />,
    },
    {
        path: "/customer/call",
        element: <GuardedRoute element={<Wrapper><CustomerCallCom /></Wrapper>} permission="/customer/call" />,
    },
    {
        path: "/customer/view",
        element: <GuardedRoute element={<Wrapper><ViewIndividualCustomer /></Wrapper>} permission="/customer/view" />,
    },
    {
        path: "/customer/edit",
        element: <GuardedRoute element={<Wrapper><EditCustomer /></Wrapper>} permission="/customer/edit" />,
    },
    {
        path: "/customer/test-drive",
        element: <GuardedRoute element={<Wrapper><ListTestDrives /></Wrapper>} permission="/customer/test-drive" />,
    },
    // Stock
    {
        path: "/stock/add",
        element: <GuardedRoute element={<Wrapper><AddStock /></Wrapper>} permission="/stock/add" />,
    },
    {
        path: "/stock/list",
        element: <GuardedRoute element={<Wrapper><ListStock /></Wrapper>} permission="/stock/list" />,
    },
    {
        path: "/stock/edit",
        element: <GuardedRoute element={<Wrapper><EditStock /></Wrapper>} permission="/stock/edit" />,
    },
    // Sale
    {
        path: "/sale/create",
        element: <GuardedRoute element={<Wrapper><AddReservedSale /></Wrapper>} permission="/sale/create" />,
    },
    {
        path: "/sale/reserved-list",
        element: <GuardedRoute element={<Wrapper><ListReservedSales /></Wrapper>} permission="/sale/reserved-list" />,
    },
    {
        path: "/sale/waiting-list",
        element: <GuardedRoute element={<Wrapper><ListActiveSales /></Wrapper>} permission="/sale/waiting-list" />,
    },
    {
        path: "/sale/features-list",
        element: <GuardedRoute element={<Wrapper><ListFeatures /></Wrapper>} permission="/sale/features-list" />,
    },
    {
        path: "/sale-reserved/update",
        element: <GuardedRoute element={<Wrapper><UpdateReservedSale /></Wrapper>} permission="/sale-reserved/update" />,
    },
    {
        path: "/sale/view",
        element: <GuardedRoute element={<Wrapper><ViewReservedSale /></Wrapper>} permission="/sale/view" />,
    },
    {
        path: "/sale/accounting-list",
        element: <GuardedRoute element={<Wrapper><ListAccountingSales /></Wrapper>} permission="/sale/accounting-list" />,
    },
    // Reports
    {
        path: "/reports/sale-graphs",
        element: <GuardedRoute element={<Wrapper><SaleGraphReport /></Wrapper>} permission="/reports/sale-graphs" />,
    },
    {
        path: "/reports/employee-graphs",
        element: <GuardedRoute element={<Wrapper><EmployeeGraphReport /></Wrapper>} permission="/reports/employee-graphs" />,
    },
    {
        path: "/reports/daily",
        element: <GuardedRoute element={<Wrapper><DailySaleReport /></Wrapper>} permission="/reports/daily" />,
    },
    // Request
    {
        path: "/request/create-insurance-kasko",
        element: <GuardedRoute element={<Wrapper><AddInsuranceRequest /></Wrapper>} permission="/request/create-insurance-kasko" />,
    },
    {
        path: "/request/create-loan",
        element: <GuardedRoute element={<Wrapper><AddLoanRequest /></Wrapper>} permission="/request/create-loan" />,
    },
    {
        path: "/request/create-car",
        element: <GuardedRoute element={<Wrapper><AddCarRequest /></Wrapper>} permission="/request/create-car" />,
    },
    {
        path: "/request/list-insurances",
        element: <GuardedRoute element={<Wrapper><ListAllInsuranceRequest /></Wrapper>} permission="/request/list-insurances" />,
    },
    {
        path: "/request/approve-insurance",
        element: <GuardedRoute element={<Wrapper><ApproveInsuranceRequest /></Wrapper>} permission="/request/approve-insurance" />,
    },
    {
        path: "/request/list-approved-insurances",
        element: <GuardedRoute element={<Wrapper><ListApprovedInsurance /></Wrapper>} permission="/request/list-approved-insurances" />,
    },
    // System
    {
        path: "/system/colors",
        element: <GuardedRoute element={<Wrapper><ListColors /></Wrapper>} permission="/system/colors" />,
    },
    {
        path: "/system/models",
        element: <GuardedRoute element={<Wrapper><ListModels /></Wrapper>} permission="/system/models" />,
    },
    {
        path: "/system/fuel-types",
        element: <GuardedRoute element={<Wrapper><ListFuelTypes /></Wrapper>} permission="/system/fuel-types" />,
    },
    {
        path: "/system/tax-settings",
        element: <GuardedRoute element={<Wrapper><TaxSettings /></Wrapper>} permission="/system/tax-settings" />,
    },
    {
        path: "/system/payment-banks",
        element: <GuardedRoute element={<Wrapper><ListPaymentBanks /></Wrapper>} permission="/system/payment-banks" />,
    },
    {
        path: "/system/credit-banks",
        element: <GuardedRoute element={<Wrapper><ListCreditBanks /></Wrapper>} permission="/system/credit-banks" />,
    },
    {
        path: "/system/sms-templates",
        element: <GuardedRoute element={<Wrapper><AddSmsTemplate /></Wrapper>} permission="/system/sms-templates" />,
    },
    {
        path: "/system/marketing-channels",
        element: <GuardedRoute element={<Wrapper><ListMarketingChanel /></Wrapper>} permission="/system/marketing-channels" />,
    },
    {
        path: "/system/insurance-offices",
        element: <GuardedRoute element={<Wrapper><ListInsuranceOffices /></Wrapper>} permission="/system/insurance-offices" />,
    },
    {
        path: "/system/announcements",
        element: <GuardedRoute element={<Wrapper><ListAnnouncements /></Wrapper>} permission="/system/announcements" />,
    },
    // User
    {
        path: "/user/create",
        element: <GuardedRoute element={<Wrapper><RegisterUser /></Wrapper>} permission="/user/create" />,
    },
    {
        path: "/user/view",
        element: <GuardedRoute element={<Wrapper><ViewUserInformation /></Wrapper>} permission="/dashboard" />,
    },
    {
        path: "/user/list",
        element: <GuardedRoute element={<Wrapper><ListAllUsers /></Wrapper>} permission="/user/list" />,
    },
    {
        path: "/user/update",
        element: <GuardedRoute element={<Wrapper><UpdateUser /></Wrapper>} permission="/user/update" />,
    },
    // 404
    {
        path: "*",
        element: <Wrapper><NotFound /></Wrapper>,
    }
]);

export default routes;
