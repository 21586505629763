import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};
let userInfoData = null; 

// Function to log in
export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_ENDPOINT}/api/Auth/Login`, { email, password });

        if (response.data && response.data.isSuccess) {
            const { token, refreshToken } = response.data;
            
            Cookies.set('token', token);
            Cookies.set('refreshToken', refreshToken);
            
            userInfoData = await getUserInfo();
            Cookies.set('userInfo', JSON.stringify(userInfoData));
            return response.data;
        }
        else if(response.data && response.data.isSuccess === false){
            response.message = response.data.message ? response.data.message : "Giriş yapılamadı. Tekrar deneyiniz.";
            return response;
        }
        else{
            response.message = "Giriş yapılamadı. Tekrar deneyiniz.";
        }
    }
    catch (error) {
        if(error.response && error.response.data && error.response.data.message){
            return error.response.data;
        }
        else{
            return null;
        }
    }
};

// TODO yarım
// Function to get refresh token
export const refreshToken = async () => {
    try {
        const refreshToken = Cookies.get('refreshToken');

        if (!refreshToken) {
            // TODO kullanıcıyı giriş ekranına yönlendirecek.
            throw new Error('Refresh token not found.');
        }

        const response = await axios.post("/api/Auth/RefreshToken", { refreshToken });

        if (response.data.isSuccess) {
            // If refresh token is successful, update the token in cookies
            const { token, refreshToken } = response.data;
            Cookies.set('token', token);
            Cookies.set('refreshToken', refreshToken);
        } else {
            // TODO kullanıcı giriş ekranına yönlendirilecek.
            throw response.data;
        }

        return response.data;
    } catch (error) {
        // TODO kullanıcı giriş ekranına yönlendirilecek.
        if (error.response || error.response.data) {
            return error.response.data ? error.response.data : error.response;
        }
        else {
            return null;
        }
    }
};

export const getUserInfo = async () => {
    try {
        const token = Cookies.get('token');
        if (!token) {
            throw new Error('Token not found.');
        }

        const response = await axios.get(`${API_ENDPOINT}/api/Auth/GetUserInfo`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data) {
            userInfoData = response.data; 
            return response.data; 
        } else {
            throw new Error(response.data.message || 'Failed to get user info.');
        }
    } catch (error) {
        if (error.response || error.response.data) {
            return error.response.data ? error.response.data : error.response;
        }
        else {
            return null;
        }
    }
};

export async function logout() {

    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    return fetch(`${API_ENDPOINT}/api/Auth/Logout`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        }
    })
    .then(response => {
        if (response.ok){
            Cookies.remove('token');
            Cookies.remove('refreshToken');
            Cookies.remove('userInfo');
        };
        return response.json();
    })
    .catch(error => {
        return null;
    });
};

export default userInfoData;
