import React, { useEffect, useRef, useState } from "react";
import SharedNameDialog from "./SharedNameDialog";
import { Button } from "primereact/button";
import { getAllPaymentTypes } from "../services/paymentService";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SharedDeleteDialog from "./SharedDeleteDialog";

const ListPaymentTypes = () => {
  const toast = useRef(null);
  // Set Dialog data
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [paymentTypesData, setPaymentTypesData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [value, setValue] = useState(null);
  const [header, setHeader] = useState(null);
  const [apiOption, setApiOption] = useState(null);

  // Get the Payment Types list
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllPaymentTypes();
        setPaymentTypesData(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Ödeme yöntemi listesi yüklenemedi. Sayfayı yenileyiniz.",
        });
      }
    }

    fetchData();
  }, [selectedId, value, showDialog, apiOption]);

  // Handle the add or edit data. If the id is set, Update, if not add.
  const handleEdit = (rowData) => {
    if (rowData.id === null) {
      setHeader("Ödeme Yöntemi Ekle");
    } else {
      setHeader("Ödeme Yöntemi Düzenle");
    }
    setSelectedId(rowData.id);
    setValue(rowData.name);
    setApiOption("paymentType");
    setShowDialog(true);
  };

  // Handle delete function. Sets the delete dialog true.
  const handleDelete = (rowData) => {
    setHeader("Ödeme Yöntemi Sil");
    setSelectedId(rowData.id);
    setApiOption("paymentType");
    setShowDeleteDialog(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles mt-5">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-8">
          <h4>Ödeme Yöntemleri</h4>
          <Button
            severity="success"
            className="rounded-3"
            onClick={() => handleEdit({ id: null, name: null })}
          >
            Ödeme Yöntemi Ekle
          </Button>
        </div>

        <div className="col-12 col-xxl-8">
          <DataTable value={paymentTypesData}>
            <Column
              className="col-8"
              field="name"
              header="Ödeme Yöntemi"
            ></Column>
            <Column
              className="col-4 col-lg-2"
              body={(rowData) => (
                <>
                  <Button
                    className="p-button-rounded rounded-3 p-button-success"
                    icon="pi pi-pencil"
                    onClick={() => handleEdit(rowData)}
                  />
                  <Button
                    className="p-button-rounded rounded-3 bg-blue ms-1"
                    icon="pi pi-trash"
                    onClick={() => handleDelete(rowData)}
                  />
                </>
              )}
            />
          </DataTable>
        </div>
        <SharedNameDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          id={selectedId}
          setSelectedId={setSelectedId}
          header={header}
          setHeader={setHeader}
          value={value}
          setValue={setValue}
          apiOption={apiOption}
          setApiOption={setApiOption}
        />
        <SharedDeleteDialog
          showDeleteDialog={showDeleteDialog}
          setShowDeleteDialog={setShowDeleteDialog}
          id={selectedId}
          setSelectedId={setSelectedId}
          header={header}
          setHeader={setHeader}
          apiOption={apiOption}
          setApiOption={setApiOption}
        />
      </div>
    </>
  );
};

export default ListPaymentTypes;
