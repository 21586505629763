import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { getAllBranches } from '../services/modelService';
import { Dropdown } from 'primereact/dropdown';
import { getAllManagersByBranchId, getAllRoleNames, getAllUserNamesList } from '../services/userService';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { InputMask } from 'primereact/inputmask';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const RegisterUser = () => {
  const token = getToken();

  const toast = useRef(null);

  const [passwordControl, setPasswordControl] = useState(null);

  const [branchList, setBranchList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const [formData, setFormData] = useState([
    {
      name: null,
      surname: null,
      email: null,
      phone: null,
      internalPhone: null,
      image: ' ',
      branchId: null,
      password: null,
      roleId: null,
      parentId: null,
      idNumber: null,
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllBranches();
        setBranchList(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Marka listesi yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllRoleNames();
        setRoleList(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Rol listesi yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        if (formData.branchId === null) return;
        const data = await getAllManagersByBranchId(formData.branchId);
        setUserList(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Rol listesi yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }

    fetchData();
  }, [formData.branchId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const numericValue = value.replace(/\D/g, '');
      setPhoneNumber(numericValue)
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    
  };

  const handleDropdownChange = (e, fieldName) => {
    const selectedValue = e.value;

    setFormData({
      ...formData,
      [fieldName]: selectedValue,
    });
  };

  const handleSave = () => {
    const requestBody = {
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      phone: phoneNumber,
      internalPhone: formData.internalPhone,
      image: formData.image,
      parentId: formData.parentId,
      branchId: formData.branchId,
      password: formData.password,
      roleId: formData.roleId,
      idNumber: formData.idNumber,
    };

    fetchAndUpdateData(requestBody);
  };

  const fetchAndUpdateData = async (requestBody) => {
    try {
      // inputların boş olup olmadığını kontrol et
      if (
        !formData.name ||
        !formData.surname ||
        !formData.email ||
        !formData.phone ||
        !formData.image ||
        !formData.branchId ||
        !formData.password ||
        !formData.roleId
      ) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: `Lütfen ` + (
            !formData.name
                ? 'Ad, '
                : ''
            ) + (
            !formData.surname
                ? 'Soyad, '
                : ''
            ) + (
            !formData.email
                ? 'Email, '
                : ''
            ) + (
            !formData.phone
                ? 'Telefon numarası, '
                : ''
            ) + (
            !formData.image
                ? 'Resim, '
                : ''
            ) + (
            !formData.branchId
                ? 'Marka, '
                : ''
            ) + (
            !formData.password
                ? 'Şifre, '
                : ''
            ) + (
            !formData.roleId
                ? 'Rol '
                : ''
            ) + 'alanlarını doldurunuz.'
        });

        return;
      }
      if (formData.idNumber.length !== 11) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'TC Kimlik numarası 11 haneli olmalıdır.',
        });
        return;
      }
      if (formData.password !== passwordControl) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Şifreleriniz uyuşmamaktadır.',
        });
        return;
      }
      if (!token) {
        toast.current.show('error', 'Hata', 'Yetki bulunamadı.');
        return;
      }

      const response = await fetch(`${baseUrl}/api/Auth/Register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      if (result && result.isSuccess) {
        handleCleanData();
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: result.message
            ? result.message
            : 'Kullanıcı başarıyla oluşturuldu.',
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: result.message
            ? result.message
            : 'Kullanıcı oluşturulamadı. Tekrar deneyiniz.',
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Kullanıcı oluşturulamadı. Tekrar deneyiniz.',
      });
    }
  };

  const onUpload = (event) => {
    const file = event.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData({
        ...formData,
        image: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const handleCleanData = () => {
    setFormData({
      name: '',
      surname: '',
      email: '',
      phone: '',
      internalPhone: '',
      image: null,
      branchId: null,
      password: '',
      roleId: null,
      parentId: null,
      idNumber: '',
    });
    setPasswordControl('');
  };

  return (
    <div className="customerstyles">
      <h4 className="mb-5">Kullanıcı Oluştur</h4>
      <Toast />
      <div className="d-block d-lg-flex col-12 col-xxl-8">
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <InputText
            id="name"
            name="name"
            className="inputgap"
            value={formData.name}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="name">Ad</label>
        </span>
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <InputText
            id="surname"
            name="surname"
            className="inputgap"
            value={formData.surname}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="surname">Soyad</label>
        </span>
      </div>
      <div className="d-block d-lg-flex col-12 col-xxl-8">
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <InputText
            id="email"
            name="email"
            className="inputgap"
            value={formData.email}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="email">Email</label>
        </span>
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <InputMask
            id="phone"
            name="phone"
            className="inputgap"
            value={formData.phone}
            onChange={(e) => handleChange(e)}
            mask="(999) 999 99 99"
          />
          <label htmlFor="surname">Telefon Numarası</label>
        </span>
      </div>
      <div className="d-block d-lg-flex col-12 col-xxl-8">
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <InputText
            id="internalPhone"
            name="internalPhone"
            className="inputgap"
            value={formData.internalPhone}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="internalPhone">Sabit Numara</label>
        </span>
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <InputMask
            id="idNumber"
            name="idNumber"
            className="inputgap"
            value={formData.idNumber}
            onChange={(e) => handleChange(e)}
            maxLength={11}
            mask="99999999999"
          />
          <label htmlFor="internalPhone">TC Kimlik Numarası</label>
        </span>
      </div>
      <div className="d-block d-lg-flex col-12 col-xxl-8">
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Password
            name="password"
            value={formData.password}
            onChange={(e) => handleChange(e)}
            toggleMask
          />

          <label htmlFor="password">Şifre</label>
        </span>
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Password
            name="controlPassword"
            value={passwordControl}
            onChange={(e) => setPasswordControl(e.target.value)}
            toggleMask
          />
          <label htmlFor="controlPassword">Şifre Tekrarı</label>
        </span>
      </div>
      <div className="d-block d-lg-flex col-12 col-xxl-8">
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Dropdown
            className="inputgap"
            id="branchDropdown"
            optionLabel="name"
            optionValue="id"
            value={formData.branchId}
            options={branchList}
            onChange={(e) => handleDropdownChange(e, 'branchId')}
            filter
            showClear
            placeholder="Marka Seçiniz"
          />
          <label htmlFor="branchDropdown">Marka</label>
        </span>
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Dropdown
            className="inputgap"
            id="roleDropdown"
            optionLabel="name"
            optionValue="id"
            value={formData.roleId}
            options={roleList}
            onChange={(e) => handleDropdownChange(e, 'roleId')}
            filter
            showClear
            placeholder="Rol Seçiniz"
          />
          <label htmlFor="roleDropdown">Rol</label>
        </span>
      </div>
      <div className="d-block d-lg-flex col-12 col-xxl-8">
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Dropdown
            className="inputgap"
            id="userDropdown"
            optionLabel="name"
            optionValue="id"
            value={formData.parentId}
            options={userList}
            onChange={(e) => handleDropdownChange(e, 'parentId')}
            filter
            showClear
            placeholder="Varsa Yönetici Seçiniz"
          />
          <label htmlFor="userDropdown">Yönetici</label>
        </span>
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Toast ref={toast}></Toast>
          <FileUpload
            mode="basic"
            name="image"
            url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={onUpload}
            chooseLabel="Kullanıcı Resmi"
          />
        </span>
      </div>

      <Button
        label="İptal"
        severity="secondary"
        className="rounded-3 me-2"
        onClick={handleCleanData}
      />
      <Button
        label="Kaydet"
        severity="success"
        className="rounded-3"
        onClick={handleSave}
      />
    </div>
  );
};

export default RegisterUser;
