import React, { useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import AddTestDrive from './AddTestDrive';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const getBranch = () => {
  return JSON.parse(Cookies.get('userInfo')).branchId;
};

const AddConfirmKvkkOtp = ({ customerData }) => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const [visible, setVisible] = useState(false);

  const [otp, setOtp] = useState('');
  const [dataConfirm, setDataConfirm] = useState(false);
  const [communicationConfirm, setCommunicationConfirm] = useState(false);
  const [formData, setFormData] = useState(customerData);

  const [showTestDrive, setShowTestDrive] = useState(false);

  const handleSave = async () => {
    // Prepare the request dto.
    const CreateCustomerDto = {
      idNumber: formData.idNumber, // Access idNumber from customerData
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      personalEmail: formData.personalEmail,
      personalPhoneNumber: formData.personalPhoneNumber,
      birthday: formData.birthday,
      job: formData.job,
      adress: formData.adress,
      gender: formData.gender.name,
      taxNumber: formData.taxNumber,
      taxOffice: formData.taxOffice,
      company: formData.company,
      specialNote: formData.specialNote,
      marketingChanelId: formData.marketingChanelId,
      clientType: formData.customerType,
      branchId: getBranch(),
    };

    const ConfirmKvkkDto = {
      otp: otp,
      dataConfirm: dataConfirm,
      comConfirm: communicationConfirm,
      customer: CreateCustomerDto,
    };

    try {
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
      const response = await fetch(`${API_URL}/api/Customer/ConfirmKvkkOtp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(ConfirmKvkkDto),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: errorData.message,
        });
        return;
      }

      const data = await response.json();

      setFormData({
        ...formData,
        customerId: data.dataId,
      });

      toast.current.show({
        severity: 'success',
        summary: 'Kayıt Başarılı',
        detail: data.message,
      });

      if (formData.testDrive) {
        setShowTestDrive(true);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
      });
    }
  };

  // İptal butonu Emin misiniz? Dialog
  const handleCancel = () => {
    setVisible(true);
  };
  // İptal butonu için onaylama "Evet" butonu
  const confirmCancel = () => {
    setVisible(false);
    window.location.reload(
      toast.current.show({
        severity: 'info',
        summary: 'İptal Edildi',
        detail: 'İşlem iptal edildi.',
      })
    );
  };
  // Dialog için footer butonları
  const cancelFooter = (
    <div>
      <Button
        label="Hayır"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="j-button bg-secondary text-light"
      />
      <Button
        label="Evet"
        icon="pi pi-check"
        onClick={confirmCancel}
        autoFocus
        className="j-button bg-green ms-2 text-light"
      />
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      {showTestDrive ? (
        <AddTestDrive customerData={formData} />
      ) : (
        <div className="enterotp">
          <h3 className="text-center">Telefonunuzu Doğrulayın</h3>
          <div className="d-flex flex-column gap-3 mt-4">
            <div className="d-flex flex-column">
              <label
                htmlFor="otp"
                className="text-center small text-secondary mb-1"
              >
                SMS ile gelen 6 haneli kodu giriniz
              </label>
              <InputMask
                id="otp"
                mask="999999"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="otpinput"
              />
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <input
                type="checkbox"
                id="dataConfirm"
                value={dataConfirm}
                onChange={(e) => setDataConfirm(e.target.checked)}
                style={{ opacity: '.6' }}
              />
              <label htmlFor="dataConfirm"> Veri Onayı</label>
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <input
                type="checkbox"
                id="communicationConfirm"
                value={communicationConfirm}
                onChange={(e) => setCommunicationConfirm(e.target.checked)}
                style={{ opacity: '.6' }}
              />
              <label htmlFor="communicationConfirm"> İletişim Onayı</label>
            </div>
            <div className="mt-3 d-flex gap-2">
              <Button
                className="rounded-3"
                severity="secondary"
                label="İptal"
                onClick={handleCancel}
              />
              <Button
                className="rounded-3"
                severity="success"
                label="Doğrula"
                onClick={handleSave}
              />
            </div>
          </div>
          <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                header="İşlemi İptal Et"
                footer={cancelFooter}
                modal
                style={{ width: '450px' }}
                className='text-center'
              >
                <div className="confirmation-content d-flex align-items-center">
                  <i
                    className="pi pi-exclamation-triangle me-3 text-danger"
                    style={{ fontSize: '3rem' }}
                  />
                  <span>İşlemi iptal etmek istediğinize emin misiniz?</span>
                </div>
              </Dialog>
        </div>
      )}
    </div>
  );
};

export default AddConfirmKvkkOtp;
