import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllManagersByBranchId, getAllRoleNames, getUserUpdateData } from '../services/userService';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

const UpdateUser = () => {
    const token = getToken();

    const toast = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const stateId = location.state ? location.state.id : null;

    const [branchId, setBranchId] = useState(null);
    const [roleList, setRoleList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(null);

    const [formData, setFormData] = useState([
        {
            id: stateId ? stateId : null,
            name: null,
            surname: null,
            email: null,
            phoneNumber: null,
            internalPhone: null,
            password: null,
            roleId: null,
            parentId: null,
            idNumber: null,
        },
    ]);

    useEffect(() => {
        async function fetchData() {
            if (stateId) {
                // get the requestid's data
                const data = await getUserUpdateData(stateId);
                //check if response ok
                if (data && data.message && data.isSuccess === false) {
                    return toast.current.show({
                        severity: 'error',
                        summary: 'Hata',
                        detail:
                            data.message ??
                            'Güncellenecek veriler yüklenirken hata oluştu. Tekrar deneyiniz.',
                    });
                }
                // set the formData according to the response returned
                setFormData((prevState) => ({
                    ...prevState,
                    id: stateId,
                    name: data.name,
                    surname: data.surname,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    internalPhone: data.internalPhone,
                    parentId: data.parentId,
                    roleId: data.roleId,
                    idNumber: data.idNumber,
                }));

                setPhoneNumber(data.phoneNumber);
                setBranchId(data.branchId);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getAllRoleNames();
                setRoleList(data);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: 'Rol listesi yüklenemedi. Yeniden deneyiniz.',
                });
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                if (branchId === null) return;
                const data = await getAllManagersByBranchId(branchId);
                setUserList(data);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: 'Rol listesi yüklenemedi. Yeniden deneyiniz.',
                });
            }
        }

        fetchData();
    }, [branchId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phoneNumber') {
            const numericValue = value.replace(/\D/g, '');
            setPhoneNumber(numericValue)
            setFormData({
                ...formData,
                [name]: value,
            });
        }
        else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleDropdownChange = (e, fieldName) => {
        const selectedValue = e.value;

        setFormData({
            ...formData,
            [fieldName]: selectedValue,
        });
    };

    const handleSave = () => {
        const requestBody = {
            id: stateId ? stateId : null,
            name: formData.name,
            surname: formData.surname,
            email: formData.email,
            phoneNumber: phoneNumber,
            internalPhone: formData.internalPhone,
            parentId: formData.parentId,
            roleId: formData.roleId,
            idNumber: formData.idNumber,
        };

        fetchAndUpdateData(requestBody);
    };

    const fetchAndUpdateData = async (requestBody) => {
        try {
            // inputların boş olup olmadığını kontrol et
            if (
                formData.name == "" ||
                formData.surname == "" ||
                formData.email == "" ||
                formData.phoneNumber == "" ||
                formData.roleId == "" ||
                formData.internalPhone == "" ||
                formData.idNumber == ""
            ) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: `Lütfen ` + (
                        formData.name == ""
                            ? 'Ad, '
                            : ""
                    ) + (
                            formData.surname == ""
                                ? 'Soyad, '
                                : ""
                        ) + (
                            formData.email == ""
                                ? 'Email, '
                                : ""
                        ) + (
                            formData.phoneNumber == ""
                                ? 'Telefon numarası, '
                                : ""
                        ) + (
                            formData.roleId == ""
                                ? 'Rol '
                                : ""
                        ) + (
                            formData.idNumber == ""
                                ? 'TC Kimlik Numarası'
                                : ""
                        )(
                            formData.internalPhone == ""
                                ? 'Sabit Numara'
                                : ""
                        ) + 'alanlarını doldurunuz.'
                });

                return;
            }
            if (formData.idNumber.length !== 11) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: 'TC Kimlik numarası 11 haneli olmalıdır.',
                });
                return;
            }
            if (!token) {
                toast.current.show('error', 'Hata', 'Yetki bulunamadı.');
                return;
            }

            const response = await fetch(`${baseUrl}/api/User/UpdateUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            const result = await response.json();

            if (result && result.isSuccess) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Başarılı',
                    detail: result.message
                        ? result.message
                        : 'Kullanıcı başarıyla güncellendi.',
                });
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: result.message
                        ? result.message
                        : 'Kullanıcı güncellenemedi. Tekrar deneyiniz.',
                });
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Hata',
                detail: 'Kullanıcı güncellenemedi. Tekrar deneyiniz.',
            });
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <div className="customerstyles">
            <h4 className="mb-5">Kullanıcı Güncelle</h4>
            <Toast ref={toast} />
            <div className="d-block d-lg-flex col-12 col-xxl-8">
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <InputText
                        id="name"
                        name="name"
                        className="inputgap"
                        value={formData.name}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="name">Ad</label>
                </span>
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <InputText
                        id="surname"
                        name="surname"
                        className="inputgap"
                        value={formData.surname}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="surname">Soyad</label>
                </span>
            </div>
            <div className="d-block d-lg-flex col-12 col-xxl-8">
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <InputText
                        id="email"
                        name="email"
                        className="inputgap"
                        value={formData.email}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="email">Email</label>
                </span>
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <InputText
                        id="phoneNumber"
                        name="phoneNumber"
                        className="inputgap"
                        value={formData.phoneNumber}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="surname">Telefon Numarası</label>
                </span>
            </div>
            <div className="d-block d-lg-flex col-12 col-xxl-8">
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <InputText
                        id="internalPhone"
                        name="internalPhone"
                        className="inputgap"
                        value={formData.internalPhone}
                        onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="internalPhone">Sabit Numara</label>
                </span>
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <InputText
                        id="idNumber"
                        name="idNumber"
                        className="inputgap"
                        value={formData.idNumber}
                        onChange={(e) => handleChange(e)}
                        maxLength={11}
                    />
                    <label htmlFor="internalPhone">TC Kimlik Numarası</label>
                </span>
            </div>
            <div className="d-block d-lg-flex col-12 col-xxl-8">
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <Dropdown
                        className="inputgap"
                        id="roleDropdown"
                        optionLabel="name"
                        optionValue="id"
                        value={formData.roleId}
                        options={roleList}
                        onChange={(e) => handleDropdownChange(e, 'roleId')}
                        filter
                        showClear
                        placeholder="Rol Seçiniz"
                    />
                    <label htmlFor="roleDropdown">Rol</label>
                </span>
                <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                    <Dropdown
                        className="inputgap"
                        id="userDropdown"
                        optionLabel="name"
                        optionValue="id"
                        value={formData.parentId}
                        options={userList}
                        onChange={(e) => handleDropdownChange(e, 'parentId')}
                        filter
                        showClear
                        placeholder="Varsa Yönetici Seçiniz"
                    />
                    <label htmlFor="userDropdown">Yönetici</label>
                </span>
            </div>

            <Button
                label="İptal"
                severity="secondary"
                className="rounded-3 me-2"
                onClick={handleCancel}
            />
            <Button
                label="Kaydet"
                severity="success"
                className="rounded-3"
                onClick={handleSave}
            />
        </div>
    );
};

export default UpdateUser;