import React from 'react';
import '../../assets/styles/_dashboard.scss';

//Card içerisindeki her şey (arkaplan resmi, rengi, header, body, icon) kartın çağırıldığı yerde prop olarak verilmeli.
const DashboardCard = (props) => {
    return (
        <div className={"dashboard-card " + props.bgColor}>
            <img src={props.bg} className="card-img-absolute" alt="circle"></img>
            <h2>{props.header}</h2>
            <span>
                <i className={props.icon}></i>
            </span>
            <p>{props.body}</p>
        </div>
    )
}

export default DashboardCard
