import React, { useEffect, useRef, useState } from 'react';
import {
    getAllPaymentBanks,
    getAllPaymentTypes,
} from '../services/paymentService';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Cookies from 'js-cookie';

// Base Url of API
const baseUrl = process.env.REACT_APP_API_URL + '/api';

// Get Token
const getToken = () => {
    return Cookies.get('token');
};

// Satışın ödeme planını günceller.
const UpdatePaymentPlan = ({ totalPrice, deposit, saleId }) => {
    const toast = useRef(null);

    // Kaydı atılacak ödeme planının form verisi.
    const [formData, setFormData] = useState({
        id: null,
        paymentType: null,
        paymentBank: null,
        amount: null,
        paymentDate: null,
    });

    // Apiden gelen ödeme tipleri listesi
    const [paymentTypesList, setPaymentTypeList] = useState([]);

    // Apiden gelen ödeme bankaları listesi
    const [paymentBanks, setPaymentBanks] = useState([]);

    // Kayıtlı olan ödeme planları
    const [paymentPlans, setPaymentPlans] = useState([]);

    // Banka içeriyorsa bankaları gösterme 
    const [showPaymentBank, setShowPaymentBank] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await getAllPaymentTypes();
                setPaymentTypeList(fetchedData);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to load payment data. Please refresh the page.',
                });
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await getAllPaymentBanks();
                setPaymentBanks(fetchedData);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to load payment data. Please refresh the page.',
                });
            }
        }
        fetchData();
    }, []);

    // Satışa bağlı olan kaydedilmiş ödeme planı listesini getirir.
    useEffect(() => {
        async function fetchData() {
            try {
                const token = getToken();
                if (!token) {
                    //TODO
                    return;
                }
                const response = await fetch(`${baseUrl}/Payment/GetPaymentPlansBySaleId`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ id: saleId }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: errorData.message || 'Bir hata oluştu.', });
                    return;
                }

                const data = await response.json();

                setPaymentPlans(data);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
                });
            }
        }
        fetchData();
    }, []);

    const handleDropdownChange = (e, fieldName) => {
        const selectedValue = e.value;

        setFormData({
            ...formData,
            [fieldName]: selectedValue,
        });

        if(fieldName === 'paymentType') {

            if(selectedValue.hasBank){
                setShowPaymentBank(true);
            }
            else{
                setShowPaymentBank(false);
            }
        }
    };

    const handleAddPaymentPlan = () => {

        if (formData.id === null) {
            // listeye eklemek için yeni obje 
            const newPaymentPlan = {
                paymentType: formData.paymentType.name,
                paymentTypeId: formData.paymentType.id,
                paymentBank:
                    formData.paymentBank && formData.paymentBank.name
                        ? formData.paymentBank.name
                        : '',
                paymentBankId:
                    formData.paymentBank && formData.paymentBank.id
                        ? formData.paymentBank.id
                        : null,
                amount: formData.amount,
                paymentDate: formData.paymentDate,
            };

            // Öddeme planı listesine ekler.
            setPaymentPlans([...paymentPlans, newPaymentPlan]);
            // Inputları sıfırlar
            handleCleanForm();
        }
        // güncelleme ise
        else {
            // datayı bul
            const index = paymentPlans.findIndex(plan => plan.id === formData.id);

            // bulunduysa
            if (index !== -1) {

                // listeyi updatele
                const updatedPaymentPlans = [...paymentPlans];
                updatedPaymentPlans[index] = {
                    ...updatedPaymentPlans[index],
                    amount: formData.amount,
                    paymentDate: formData.paymentDate,
                };

                setPaymentPlans(updatedPaymentPlans);
            }

            handleCleanForm();

            // Banka inputunu kapatır.
            setShowPaymentBank(false);
        };
    };

    const handleCleanForm = () => {
        // Inputları sıfırlar.
        setFormData({
            id: null,
            paymentType: null,
            paymentBank: null,
            amount: null,
            paymentDate: null,
        });
        setShowPaymentBank(false);
    }

    const handleDeletePaymentPlan = (rowData) => {
        // Listeden silinen ödeme planını siler.
        const updatedPaymentPlans = paymentPlans.filter((plan) => plan !== rowData);
        setPaymentPlans(updatedPaymentPlans);
    };

    const handleEditPaymentPlan = (rowData) => {

        // Seçilen ödeme 
        const selectedPaymentType = paymentTypesList.filter(pt => pt.id === rowData.paymentTypeId)[0];
        const selectedPaymentBank = paymentBanks.filter(pb => pb.id === rowData.paymentBankId)[0] || {};

        if (selectedPaymentType.hasBank) {
            setShowPaymentBank(true);
        }
        else {
            setShowPaymentBank(false);
        }

        setFormData({
            ...formData,
            ['id']: rowData.id,
            ['paymentType']: selectedPaymentType,
            ['paymentBank']: selectedPaymentBank,
            ['amount']: rowData.amount,
            ['paymentDate']: rowData.paymentDate,
        });
    };

    const handleSave = async () => {
        // request body i oluşturur.
        const requestBody = {
            AddPaymentList: paymentPlans.map((plan) => ({
                id: plan.id,
                paymentTypeId: plan.paymentTypeId,
                paymentBankId: plan.paymentBankId,
                amount: plan.amount,
                payDate: plan.paymentDate,
            })),
            saleId: saleId,
        };

        try {
            const token = getToken();
            if (!token) {
                //TODO
                return;
            }
            const response = await fetch(`${baseUrl}/Payment/UpdatePayments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: errorData.message,
                });
                return;
            }

            const data = await response.json();

            toast.current.show({
                severity: 'success',
                summary: 'Kayıt Başarılı',
                detail: data.message,
            });
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Hata',
                detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
            });
        }
    };

    // Düzenle ve sil seçenekleri
    const optionsTemplate = (rowData) => {
        return (
            <div>
                <Button
                    className="rounded-3 me-1"
                    icon="pi pi-pencil"
                    severity="success"
                    onClick={() => handleEditPaymentPlan(rowData)}
                />
                <Button
                    className="rounded-3"
                    icon="pi pi-times"
                    severity="danger"
                    onClick={() => handleDeletePaymentPlan(rowData)}
                />
            </div>

        );
    };

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
            }/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
    };

    // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

    return (
        <>
            <Toast ref={toast} />
            <div>
                <div className="customerstyles">
                    <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
                        <h4>Ödeme Planı Düzenle</h4>
                    </div>
                    <div className="d-block d-lg-flex align-items-center col-10 mb-4">
                        <span className="p-float-label col-12 col-lg-3 ">
                            <Dropdown
                                id="paymentType"
                                name="paymentType"
                                className="inputgap"
                                value={formData.paymentType}
                                onChange={(e) => handleDropdownChange(e, 'paymentType')}
                                options={paymentTypesList}
                                optionLabel="name"
                                disabled={formData.id ? true : false}
                                filter
                            />
                            <label htmlFor="paymentType">Ödeme Tipi</label>
                        </span>
                        {showPaymentBank && (
                            <span className="p-float-label col-12 col-lg-3">
                                <Dropdown
                                    id="paymentBank"
                                    name="paymentBank"
                                    className="inputgap"
                                    value={formData.paymentBank}
                                    onChange={(e) => handleDropdownChange(e, 'paymentBank')}
                                    options={paymentBanks}
                                    optionLabel="name"
                                    disabled={formData.id ? true : false}
                                    filter
                                />
                                <label htmlFor="paymentType">Ödenen Banka</label>
                            </span>
                        )}
                        <span className="p-float-label col-12 col-lg-3">
                            <InputNumber
                                className="inputgap"
                                inputId="amount"
                                name="amount"
                                value={formData.amount}
                                minFractionDigits={2}
                                maxFractionDigits={5}
                                onChange={(e) => handleDropdownChange(e, 'amount')}
                            />
                            <label htmlFor="amount">Ödeme Miktarı</label>
                        </span>
                        <span className="p-float-label col-12 col-lg-3">
                            <Calendar
                                id="paymentDate"
                                name="paymentDate"
                                className="inputgap"
                                value={formData.paymentDate}
                                minDate={new Date()}
                                onChange={(e) =>
                                    setFormData({ ...formData, paymentDate: e.value })
                                }
                                dateFormat="dd/mm/yy"
                            />
                            <label htmlFor="paymentDate">Ödeme Tarihi</label>
                        </span>
                        <Button
                            className="rounded-3 col-auto"
                            label="Ekle"
                            icon="pi pi-plus"
                            severity="success"
                            onClick={handleAddPaymentPlan}
                        />
                    </div>
                    <DataTable value={paymentPlans} className="col-12 col-lg-10">
                        <Column field="paymentType" header="Ödeme Tipi" />
                        <Column field="paymentBank" header="Banka" />
                        <Column field="amount" header="Ödeme Miktarı"
                        body={(rowData) => formatNumber(rowData.amount)}
                        />
                        <Column
                            field="paymentDate"
                            header="Ödeme Tarihi"
                            body={(rowData) => formatDateTime(rowData.paymentDate)}
                        />
                        <Column field="actions" header="İşlem" body={optionsTemplate} />
                    </DataTable>
                    <div className="mt-4 mb-5">
                        <Button
                            label="İptal"
                            severity="secondary"
                            className="rounded-3 me-2"
                            onClick={handleCleanForm}
                        />
                        <Button
                            label="Kaydet"
                            severity="success"
                            className="rounded-3"
                            onClick={handleSave}
                        />
                    </div>

                    {/* "Ödenen Toplam Tutar" ve "Satış Toplam Tutarı" tablosu */}
                    <DataTable
                        value={[{ totalPrice }]}
                        className="col-12 col-lg-8 col-xxl-6"
                    >
                        <Column
                            field="totalPrice"
                            header="Satış Toplam Tutarı"
                            body={(rowData) => formatNumber(rowData.totalPrice)}
                        />
                        <Column
                            field="totalAmount"
                            header="Ödenen Toplam Tutar"
                            body={formatNumber(paymentPlans.reduce((acc, curr) => acc + curr.amount, 0))}
                        />
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default UpdatePaymentPlan;
