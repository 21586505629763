import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast';
import Cookies from 'js-cookie';
import axios from 'axios';
import '../../assets/styles/_dashboard.scss';
import { Link } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

const HrAnnounceEvent = () => {

    const token = getToken();

    const toast = useRef(null);

    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        async function fetchData() {
            axios.get(`${baseUrl}/api/Announcement/GetAllAnnouncements`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.data) {
                        setAnnouncements(response.data);
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
                })
        }

        fetchData();
    }, []);

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
            }/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
    };

    return (
        <div className='p-3'>
            <h5 className="pb-1">İnsan Kaynakları Duyuruları</h5>
            <table>
                <tbody>
                    {announcements.length === 0 ? 
                        <tr>
                            <td>
                                <div className='rounded-4 p-3 d-flex flex-column justify-content-center gap-2 announce'>
                                    <div className='fw-bold d-flex'><i className="pi pi-megaphone pe-3 text-success h4"></i>
                                        Duyuru Bulunamadı.
                                    </div>
                                </div>
                            </td>
                        </tr>
                         : announcements.map((announcement, index) => (
                        <tr key={index}>
                            <td>
                                <div className='rounded-4 p-3 d-flex flex-column justify-content-center gap-2 announce'>
                                    <div className='fw-bold d-flex'><i className="pi pi-megaphone pe-3 text-success h4"></i>{announcement.header}</div>
                                    <div className="fs-6">{announcement.description}</div>
                                    <div className="text-secondary small">Tarih Aralığı: {formatDateTime(announcement.startDate)} - {formatDateTime(announcement.endDate)}</div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default HrAnnounceEvent
