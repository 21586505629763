import React, { useEffect, useRef, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Card } from 'primereact/card';
import { addSmsTemplate, deleteSmsTemplate, getAllSmsTemplates, updateSmsTemplate } from '../services/smsService';
import { Toast } from 'primereact/toast';
import "../../assets/styles/_system.scss";

const AddSmsTemplate = () => {

  const toast = useRef(null);

  const [tooltipText, setTooltipText] = useState('');
  const [saveSmsData, setSaveSmsData] = useState({
    id: null,
    header: '',
    description: '',
  });
  const [smsData, setSmsData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllSmsTemplates();
        setSmsData(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Sms mesaj listesi yüklenemedi. Sayfayı yenileyiniz.",
        });
      }
    }

    fetchData();
  }, [saveSmsData.id, saveSmsData.header, saveSmsData.description]);

  const handleHeaderChange = (e) => {
    setSaveSmsData(prevState => ({
      ...prevState,
      header: e.target.value
    }));
  };

  const handleDescriptionChange = (e) => {
    setSaveSmsData(prevState => ({
      ...prevState,
      description: e.target.value
    }));
  };

  const handleSave = async () => {
    try {
      if (!saveSmsData.header || !saveSmsData.description) {
        toast.current.show({ severity: 'warn', summary: 'Hata', detail: 'Lütfen tüm alanları doldurun.' });
        return;
      }

      let result = '';
      if (saveSmsData.id === null) {
        result = await addSmsTemplate(saveSmsData.header, saveSmsData.description);
      }
      else {
        result = await updateSmsTemplate(saveSmsData.id, saveSmsData.header, saveSmsData.description);
      }

      if (result && result.isSuccess) {
        handleClean();
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: result.message });
      } else {
        toast.current.show({ severity: 'warn', summary: 'Hata', detail: result.message ? result.message : 'Kayıt alınamadı. Tekrar deneyiniz.' });
      }
    } catch(error) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu. Tekrar deneyiniz.' });
    }
  };

  const handleClean = async () => {
    setSaveSmsData({
      id: null,
      header: '',
      description: ''
    });
  };

  const showTooltip = () => {
    setTooltipText('You can enter the SMS templates on this page.');
  };

  const hideTooltip = () => {
    setTooltipText('');
  };

  const handleEdit = (data) => {
    setSaveSmsData(prevState => ({
      ...prevState,
      id: data.id,
      header: data.header,
      description: data.description
    }));
  };

  const handleDelete = async (id) => {

    try {

      let result = '';
      result = await deleteSmsTemplate(id);

      if (result && result.isSuccess) {
        handleClean();
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: result.message });
      } else {
        toast.current.show({ severity: 'warn', summary: 'Hata', detail: result.message ? result.message : 'Sms mesajı silinemedi. Tekrar deneyiniz.' });
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu. Tekrar deneyiniz.' });
    }

  };

  return (
    <>
      <Toast ref={toast} />
      <div className="systemstyles">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-8">
          <h4>Hazır SMS Mesajları</h4>
        </div>
        <div className="col-12 col-lg-8 col-xxl-5">
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="header"
                value={saveSmsData.header}
                onChange={handleHeaderChange}
              />
              <label htmlFor="header">Mesaj Başlığı</label>
            </span>
          </div>
          <div className="mb-4">
            <span className="p-float-label">
              <InputTextarea
                className="col-12"
                id="description"
                value={saveSmsData.description}
                onChange={handleDescriptionChange}
              />
              <label htmlFor="description">Mesaj İçeriği</label>
            </span>
          </div>
          <div className="d-flex justify-content-end gap-2 col-12">
            <Button label="İptal" className="p-button-secondary rounded-3" onClick={handleClean} />
            <Button
              label="Kaydet"
              className="p-button-success rounded-3"
              onClick={handleSave}
            />

            {/* <Tooltip target=".info-icon" content={tooltipText} position="top" />
          <i
              className="pi pi-info-circle info-icon"
              data-pr-tooltip="Tooltip"
              data-pr-position="right"
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            ></i> */}
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-8 col-xxl-5 mt-4">
        {smsData.map((sms, index) => (
          <React.Fragment key={index}>
            <Card className="rounded-3" title={sms.header}>
              <div>{sms.description}</div>
              <div style={{ marginTop: "10px", textAlign: "right" }}>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded rounded-3 p-button-success"
                  onClick={() => handleEdit(sms)}
                />
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded rounded-3 ms-1 bg-blue"
                  onClick={() => handleDelete(sms.id)}
                />
              </div>
            </Card>
            {index !== smsData.length - 1 && <hr />}{" "}
            {/* Add a horizontal line after each card except for the last one */}

          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default AddSmsTemplate;
