import React from 'react';
import ReportCards from './ReportCards';
import '../../assets/styles/_graph.scss';

const SaleGraphReport = () => {
  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-3 col-12">
          <h4>Aylık Satış Raporu</h4>
        </div>
        <ReportCards />
      </div>
    </>
  );
};

export default SaleGraphReport;
