import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

export async function getAllRoleNames() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/User/GetAllRoleNames`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function getAllUserNamesListByBranchId() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/User/GetAllUserNamesListByBranchId`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function getAllUserNamesList() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/User/GetAllUserNamesList`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function getAllUsers() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/User/GetAllAppUsers`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function getProfileInformation() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/User/GetProfileInformation`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function getAllManagersByBranchId(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/User/GetAllManagersByBranchId`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id })
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function sendOtpConfirmation(phoneNumber) {

    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

    return fetch(`${baseUrl}/api/User/SendOtpConfirmation`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: formattedPhoneNumber }),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
};

export async function sendOtpConfirmationForUser(phoneNumber) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

    return fetch(`${baseUrl}/api/User/SendOtpConfirmationForUser`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ phoneNumber: formattedPhoneNumber }),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
};

export async function approveOtpConfirmation(phoneNumber, otp) {

    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

    return fetch(`${baseUrl}/api/User/ApproveOtpConfirmation`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: formattedPhoneNumber, otp: otp }),
    })
        .then(response => response.json())
        .then(responseData => {
            if (responseData && responseData.isSuccess && responseData.message) {
                Cookies.set('passwordToken', responseData.message);
            }
            return responseData;
        })
        .catch(error => {
            return null;
        });
}

export async function forgotPasswordChange(userId, password) {

    const passwordToken = Cookies.get('passwordToken');
    if(passwordToken === null) return;

    const requestDto = {
        userId: userId,
        password: password,
        token: passwordToken
    };

    return fetch(`${baseUrl}/api/User/ForgotPasswordChange`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestDto),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
}

export async function changePassword(oldPassword, newPassword) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    return fetch(`${baseUrl}/api/User/ChangePassword`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({oldPassword: oldPassword, newPassword: newPassword}),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
}

export async function updateProfileInformation(userData) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    const requestDto = {
        name: userData.name,
        surname: userData.surname,
        idNumber: userData.idNumber,
        internalPhone: userData.internalPhone,
        email: userData.email
    };

    return fetch(`${baseUrl}/api/User/UpdateProfileInformation`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(requestDto),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
}

export async function updateProfileImage(image) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    return fetch(`${baseUrl}/api/User/UpdateProfileImage`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({id: image}),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
};

export async function changePhoneNumber(phoneNumber, otp) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    return fetch(`${baseUrl}/api/User/UpdatePhoneNumber`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({phoneNumber: phoneNumber, otp: otp}),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
};

export async function getUserUpdateData(id) {

    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    return fetch(`${baseUrl}/api/User/GetUserUpdateData`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id })
    })
    .then(response => response.json())
    .then(responseData => {
        return responseData;
    })
    .catch(error => {
        return null;
    });
};