import React, { useEffect, useRef, useState } from "react";
import { getCorporateCustomerCardById, getIndividualCustomerCardById } from "../services/customerService";
import { Toast } from "primereact/toast";
import { useNavigate, useLocation } from "react-router-dom";
import ListCustomerCarRequest from "./ListCustomerCarRequest";
import ListCustomerCar from "./ListCustomerCar";
import ListCustomerLoanRequest from "./ListCustomerLoanRequest";
import ListCustomerExchangeData from "./ListCustomerExchangeData";
import ListCustomerCallNotes from "./ListCustomerCallNotes";
import { Button } from "primereact/button";
import { deleteCustomer } from "../services/customerService";
import { Dialog } from "primereact/dialog";

const ViewIndividualCustomer = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state ? location.state.id : null;
  const type = location.state ? location.state.type : null;
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [customerData, setCustomerData] = useState({
    name: null,
    surname: null,
    compNameCorporate: null,
    positionCorporate: null,
    phoneNumberCorporate: null,
    emailCorporate: null,
    createdAt: null,
    phoneNumber: null,
    adress: null,
    customerType: null,
    interestedVehichle: null,
    advisorName: null,
    note: null,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        if (type === 'Kurumsal') {
          const data = await getCorporateCustomerCardById(id);
          setCustomerData(data);
        }
        else {
          const data = await getIndividualCustomerCardById(id);
          setCustomerData(data);
        }

      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Müşteri bilgisi yüklenemedi. Yeniden deneyiniz.",
        });
      }
    }

    fetchData();
  }, [id]);

  const showDialog = (id, name) => {
    setSelectedId(id);
    setSelectedCustomer(name);
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const deleteCustomerHandler = async () => {
    try {
      await deleteCustomer(selectedId);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: `${selectedCustomer} adlı müşteri silindi.`,
      });
      hideDialog();
      navigate("/customer/list");
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Müşteri silinirken bir hata oluştu.",
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles">
        <div className="col-6">
          <h4>Müşteri Bilgileri</h4>
        </div>
        <div className="my-4 bg-white rounded-3 py-4 px-5 d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex flex-column align-items-start gap-1 col-12 col-lg-auto">
            <span className="fw-bold h4">{customerData.name} {customerData.surname}</span>
            <span className="small">Müşteri Tipi: {customerData.customerType}</span>
            {customerData.customerType === "Kurumsal" ? <span className="small">Şirketi: {customerData.company}</span> : ""}
          </div>
          <div className="d-flex align-items-center gap-2 d-none d-lg-flex">
            <Button
              label="Düzenle"
              icon="pi pi-pencil"
              className="j-button px-3 bg-green small"
              onClick={() => navigate("/customer/edit", { state: { id: id, type: customerData.customerType } })}
            />
          </div>
          <div className="d-flex align-items-center gap-2 d-flex d-lg-none justify-content-center w-100 mt-3">
            <Button
              icon="pi pi-pencil"
              className="j-button px-3 bg-green"
            />
            <Button
              icon="pi pi-phone"
              className="j-button px-3 bg-green"
            />
            <Button
              icon="pi pi-envelope"
              className="j-button px-3 bg-green"
            />
            <Button
              icon="pi pi-trash"
              className="j-button px-3 bg-green"
            />
          </div>
        </div>
        <div className="my-5 d-flex justify-content-center">
          <div className="col-12 col-lg-6">
            <table className="customercard-info-right-list">
              <tbody>
                <tr>
                  <td>
                    <i className="pi pi-user"></i> Ad
                  </td>
                  <td> {customerData.name ? customerData.name : "-"}</td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-user"></i> Soyad
                  </td>
                  <td>{customerData.surname ? customerData.surname : "-"}</td>
                </tr>
                {/* Kurumsal müşteri ise */}
                {customerData.customerType === "Kurumsal" && (
                  <>
                    <tr>
                      <td>
                        <i className="pi pi-building"></i> Şirket Adı
                      </td>
                      <td>
                        {customerData.company ? customerData.company : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="pi pi-briefcase"></i> Görevi
                      </td>
                      <td>
                        {customerData.job ? customerData.job : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="pi pi-phone"></i> Şirket Telefonu
                      </td>
                      <td>
                        {customerData.phoneNumber ? customerData.phoneNumber : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="pi pi-at"></i> Şirket E-mail Adresi
                      </td>
                      <td>
                        {customerData.email ? customerData.email : "-"}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <i className="pi pi-calendar"></i> Kayıt Tarihi
                  </td>
                  <td>
                    {new Date(customerData.createdAt).toLocaleDateString()
                      ? new Date(customerData.createdAt).toLocaleDateString()
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-at"></i> Email
                  </td>
                  <td>{customerData.customerType === "Kurumsal" ? (customerData.personalEmail ? customerData.personalEmail : "-") : customerData.email ? customerData.email : "-"}</td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-phone"></i> Telefon
                  </td>
                  <td>
                    {customerData.customerType === "Kurumsal" ? (customerData.personalPhoneNumber ? customerData.personalPhoneNumber : "-") : customerData.phoneNumber
                      ? customerData.phoneNumber
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-map-marker"></i> Adres
                  </td>
                  <td>{customerData.adress ? customerData.adress : "-"}</td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-user-edit"></i> Müşteri Tipi
                  </td>
                  <td>{customerData.customerType === "Kurumsal" ? "Kurumsal Müşteri" : "Bireysel Müşteri"}</td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-car"></i> İlgilendiği Araç
                  </td>
                  <td>
                    {customerData.interestedVehichle
                      ? customerData.interestedVehichle
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-users"></i> Danışmanı
                  </td>
                  <td>{customerData.advisorName}</td>
                </tr>
                <tr>
                  <td>
                    <i className="pi pi-user-edit"></i> Müşteri Notu
                  </td>
                  <td>{customerData.note}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Dialog
        visible={visible}
        style={{ width: "450px" }}
        header="Müşteri Sil"
        className="text-center"
        modal
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              className="j-button bg-secondary text-light"
              onClick={hideDialog}
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              className="j-button bg-green ms-2 text-light"
              onClick={deleteCustomerHandler}
            />
          </div>
        }
        onHide={hideDialog}
      >
        <div className="d-flex align-items-center text-center">
          <i className="pi pi-exclamation-triangle pe-3 text-danger fs-1" />
          {selectedCustomer && (
            <span>
              <span className="fw-bold">{customerData.name + " " + customerData.surname}</span> adlı müşteriyi silmek istediğinize emin misiniz? <span className="text-danger fw-bolder">Bu işlem geri alınamaz.</span>
            </span>
          )}
        </div>
      </Dialog>

      <ListCustomerCar customerId={id} />
      <ListCustomerExchangeData customerId={id} />
      <ListCustomerCarRequest customerId={id} />
      <ListCustomerLoanRequest customerId={id} />
      <ListCustomerCallNotes customerId={id} />
    </>
  );
};

export default ViewIndividualCustomer;
