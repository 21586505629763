import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { getApprovedInsuranceRequestsByMonth } from '../services/insuranceService';

const ListApprovedInsurance = () => {

    const toast = useRef();

    const [insuranceList, setInsuranceList] = useState([]);

    const [selectedMonth, setSelectedMonth] = useState(null);

    const [month, setMonth] = useState([
        { name: 'Ocak', value: 1 },
        { name: 'Şubat', value: 2 },
        { name: 'Mart', value: 3 },
        { name: 'Nisan', value: 4 },
        { name: 'Mayıs', value: 5 },
        { name: 'Haziran', value: 6 },
        { name: 'Temmuz', value: 7 },
        { name: 'Ağustos', value: 8 },
        { name: 'Eylül', value: 9 },
        { name: 'Ekim', value: 10 },
        { name: 'Kasım', value: 11 },
        { name: 'Aralık', value: 12 }
    ]);

    useEffect(() => {
        async function fetchData() {
            if (selectedMonth === null) return;
            const data = await getApprovedInsuranceRequestsByMonth(selectedMonth);

            if (data && data.message && data.isSuccess === false) {
                return toast.current.show({ severity: 'error', summary: 'Hata', detail: data.message ?? "Sigorta/Kasko yüklenirken hata oluştu. Tekrar deneyiniz." });
            }
            else {
                setInsuranceList(data);
            }
        }

        fetchData();
    }, [selectedMonth]);

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
            }/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.value);
        const x = selectedMonth;
    };

    return (
        <div className='customerstyles mb-5'>
            <h4 className="mb-5">Onaylanan Trafik Kasko Listesi</h4>
            <div className="mb-3 col-2">
                <Dropdown id="selectedMonth" name="selectedMonth" optionLabel="name" optionValue="value" value={selectedMonth} options={month} onChange={(e) => handleMonthChange(e)}
                    placeholder="Tarih seçin" className="w-100 rounded-3" />
            </div>
            <DataTable value={insuranceList} sortMode="multiple" tableStyle={{ minWidth: "70rem" }}
                emptyMessage="Trafik/Kasko talebi bulunmamaktadır"
            >
                <Column field="customerName" header="Müşteri Adı" />
                <Column field="phoneNumber" header="Telefon" />
                <Column field="branch" header="Marka" />
                <Column field="model" header="Model" style={{ minWidth: "200px" }} />
                <Column field="trafik" header="Trafik" />
                <Column field="kasko" header="Kasko" />
                <Column field="other" header="Diğer" />
                <Column field="sasiNumber" header="Şasi Numarası" />
                <Column field="salePrice" header="Satış Fiyatı" />
                <Column field="advisorName" header="Satışı Yapan" />
                <Column field="saleStatus" header="Satış Durumu" />
                <Column field="saleDate" header="Satış Tarihi" body={(rowData) => formatDateTime(rowData.saleDate)} />
            </DataTable>
        </div>
    )
}

export default ListApprovedInsurance;
