import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};


export async function getAllCustomerNamesList() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Customer/GetAllCustomerNamesList`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getAllSasiNoStocks() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Stock/GetAllSasiNoStocks`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getAllSasiNoStocksForRequest() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Stock/GetAllSasiNoStocksForRequest`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getAllSalesInPresale() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Sale/GetAllInPresaleSales`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }    
};

export async function getCustomerSaleByCustomerId(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Sale/GetCustomerSaleByCustomerId`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getCustomersExchangeRequest(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/ExchangeRequest/GetCustomersExchangeRequest`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getAllLoanRequestsByCustomerId(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/LoanRequest/GetAllLoanRequestsByCustomerId`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getAllFeaturesByBranchId(month, year) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Feature/GetAllFeaturesByBranchId`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({month: month, year: year})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }    
};

export async function getUpdReservedSaleDataById(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Sale/GetUpdReservedSaleDataById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(id),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getRequestDataForSaleView(id) {

    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    return fetch(`${baseUrl}/api/Sale/GetRequestDataForSaleView`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id })
    })
    .then(response => response.json())
    .then(responseData => {
        return responseData;
    })
    .catch(error => {
        return null;
    });
};
