import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react'
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
}
const UpdateExchangeRequestDialog = ({ showDialog, setShowDialog, exchangeForm, setExchangeForm }) => {

    const [exchangeData, setExchangeData] = useState(exchangeForm);

    const toast = useRef(null);

    const handleHide = () => {
            setShowDialog(false);
    };

    const handleUpdate = async () => {
        const UpdateExchangeRequestDto = {
            id: exchangeData.id,
            year: exchangeData.year,
            brand: exchangeData.brand,
            model: exchangeData.model,
            package: exchangeData.package,
            gearType: exchangeData.gearType,
            fuelType: exchangeData.fuelType,
            km: exchangeData.km,
            color: exchangeData.color,
            damage: exchangeData.damage,
            note: exchangeData.note,
            customerExpectation: exchangeData.customerExpectation
        };
    
        try {
            const token = getToken();
            if (!token) {
                //TODO: Handle missing token
                return;
            }
            const response = await fetch(`${baseUrl}/api/ExchangeRequest/UpdateExchangeRequest`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(UpdateExchangeRequestDto),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
                return;
            }
    
            const data = await response.json();
            setShowDialog(false);
            toast.current.show({ severity: "success", summary: "Başarılı", detail: data.message });
    
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
        }
    }
    
    const handleChange = (e) => {

        const { name, value } = e.target;

            setExchangeData({
                ...exchangeData,
                [name]: value
            })

    }

    return (
        <div className='customerstyles'>
            <Toast ref={toast} />
            <Dialog className='dialog' visible={showDialog} onHide={handleHide} header="Takas Formu" modal>

                <label htmlFor="brand">Marka</label>
                <InputText id="brand" name="brand" value={exchangeData.brand} onChange={(e) => handleChange(e)} />

                <label htmlFor="model">Model</label>
                <InputText id="model" name="model" value={exchangeData.model} onChange={(e) => handleChange(e)} />

                <label htmlFor="year">Yıl</label>
                <InputText id="year" name="year" value={exchangeData.year} onChange={(e) => handleChange(e)} />

                <label htmlFor="package">Paket</label>
                <InputText id="package" name="package" value={exchangeData.package} onChange={(e) => handleChange(e)} />

                <label htmlFor="gearType">Vites Tipi</label>
                <InputText id="gearType" name="gearType" value={exchangeData.gearType} onChange={(e) => handleChange(e)} />

                <label htmlFor="fuelType">Yakıt Tipi</label>
                <InputText id="fuelType" name="fuelType" value={exchangeData.fuelType} onChange={(e) => handleChange(e)} />

                <label htmlFor="color">Renk</label>
                <InputText id="color" name="color" value={exchangeData.color} onChange={(e) => handleChange(e)} />

                <label htmlFor="km">KM</label>
                <InputText id="km" name="km" value={exchangeData.km} onChange={(e) => handleChange(e)} />

                {/* //TODO InputNumber a çekilecek */}
                <label htmlFor="customerExpectation">Müşteri Beklentisi</label>
                <InputText id="customerExpectation" name="customerExpectation" value={exchangeData.customerExpectation} onChange={(e) => handleChange(e)} />

                <label htmlFor="damage">Hasar Kaydı</label>
                <InputText id="damage" name="damage" value={exchangeData.damage} onChange={(e) => handleChange(e)} />

                <label htmlFor="note">Not</label>
                <InputText id="note" name="note" value={exchangeData.note} onChange={(e) => handleChange(e)} />

                <div className="mt-3 text-end">
                    <Button className="rounded-3" severity="success" label="Ekle" onClick={handleUpdate} />
                </div>
            </Dialog>
        </div>
    )
}

export default UpdateExchangeRequestDialog