import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { getCorporateCustomerEditCardById } from '../services/customerService';
import { getAllUserNamesListByBranchId } from '../services/userService';
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const getUserInfo = () => {
  return JSON.parse(Cookies.get('userInfo'));
};

const EditCorporateCustomer = ({ id }) => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);

  const toast = useRef(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const userData = getUserInfo();

    if (userData !== null) setUserInfo(userData);
  }, []);

  const [formData, setFormData] = useState({
    name: null,
    surname: null,
    email: null,
    phoneNumber: null,
    personalPhoneNumber: null,
    personalEmail: null,
    company: null,
    birthday: null,
    job: null,
    adress: null,
    gender: null,
    specialNote: null,
    marketingChanel: null,
    customerId: null,
    advisorName: null,
    advisorId: null,
    interestedVehicle: null,
    taxOffice: null,
    taxNumber: null,
    idNumber: null,
  });

  const handleDropdownChange = (e) => {
    const selectedValue = e.value;

    setFormData({
      ...formData,
      ['advisorId']: selectedValue, // Enclose advisorId in quotes
    });
  };

  const formatDateTime = (dateTimeString) => {
    return dateTimeString ? new Date(dateTimeString) : null;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCorporateCustomerEditCardById(id);
        setFormData(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşteri yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllUserNamesListByBranchId();
        setUserList(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Çalışan listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    const requestBody = {
      id: id,
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      birthday: formData.birthday,
      job: formData.job,
      adress: formData.adress,
      specialNote: formData.specialNote,
      customerId: id,
      appUserId: formData.advisorId,
      interestedVehicle: formData.interestedVehicle,
      personalEmail: formData.personalEmail,
      personalPhoneNumber: formData.personalPhoneNumber,
      idNumber: formData.idNumber,
    };

    fetchAndUpdateData(requestBody);
  };

  const fetchAndUpdateData = async (requestBody) => {
    try {
      const token = getToken();
      if (!token) {
        toast.current.show('error', 'Hata', 'Yetki bulunamadı.');
        return;
      }

      const response = await fetch(
        `${baseUrl}/api/Customer/UpdateCorporateCustomer`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody), // Pass requestBody directly
        }
      );

      const result = await response.json();

      if (result && result.isSuccess) {
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: result.message
            ? result.message
            : 'Müşteri bilgileri başarıyla güncellendi.',
        });
        navigate('/customer/view', { state: { id: id } });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: result.message
            ? result.message
            : 'Müşteri bilgileri güncellenemedi. Tekrar deneyiniz.',
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Müşteri bilgileri güncellenemedi. Tekrar deneyiniz.',
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div>
        <div className="customerstyles">
          <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
            <h4>Müşteri Bilgilerini Güncelle</h4>
          </div>

          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="name"
                name="name"
                className="inputgap"
                value={formData.name}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="name">Ad</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="surname"
                name="surname"
                className="inputgap"
                value={formData.surname}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="surname">Soyad</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="idNumber"
                name="idNumber"
                className="inputgap"
                value={formData.idNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="idNumber">TC Kimlik Numarası</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <Calendar
                id="birthday"
                name="birthday"
                className="inputgap"
                value={formatDateTime(formData.birthday)} // Pass Date object here
                onChange={(e) => handleChange(e)}
                dateFormat="dd/mm/yy"
              />
              <label htmlFor="birthday">Doğum Tarihi</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="phoneNumber"
                name="phoneNumber"
                className="inputgap"
                value={formData.phoneNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="phoneNumber">Telefon Numarası</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="personalPhoneNumber"
                name="personalPhoneNumber"
                className="inputgap"
                value={formData.personalPhoneNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="personalPhoneNumber">
                Şahsi Telefon Numarası
              </label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="email"
                name="email"
                className="inputgap"
                value={formData.email}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="email">E-Mail</label>
            </span>

            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="personalEmail"
                name="personalEmail"
                className="inputgap"
                value={formData.personalEmail}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="personalEmail">Şahsi E-Mail</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="company"
                name="company"
                className="inputgap"
                value={formData.company}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="company">Şirketi</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="job"
                name="job"
                className="inputgap"
                value={formData.job}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="job">Görevi</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="taxOffice"
                name="taxOffice"
                className="inputgap"
                value={formData.taxOffice}
                onChange={(e) => handleChange(e)}
                disabled
              />
              <label htmlFor="taxOffice">Vergi Dairesi</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="taxNumber"
                name="taxNumber"
                className="inputgap"
                value={formData.taxNumber}
                onChange={(e) => handleChange(e)}
                disabled
              />
              <label htmlFor="taxNumber">Vergi Numarası</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="gender"
                name="gender"
                className="inputgap"
                value={formData.gender === 0 ? 'Erkek' : 'Kadın'}
                disabled
              />
              <label htmlFor="gender">Cinsiyet</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="adress"
                name="adress"
                className="inputgap"
                value={formData.adress}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="adress">Adres</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="interestedVehicle"
                name="interestedVehicle"
                className="inputgap"
                value={formData.interestedVehicle}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="interestedVehicle">İlgilendiği Araç</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="marketingChanel"
                name="marketingChanel"
                className="inputgap"
                value={formData.marketingChanel}
                disabled
              />
              <label htmlFor="marketingChanel">Etkileşim</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            {userInfo && (userInfo.isManager || userInfo.isAdmin) && (
              <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                <Dropdown
                  id="advisorDropdown"
                  optionLabel="name"
                  optionValue="id"
                  className="inputgap"
                  value={formData.advisorId}
                  options={userList}
                  onChange={(e) => handleDropdownChange(e)}
                  filter
                  showClear
                  placeholder="Danışman Seçiniz"
                />
                <label htmlFor="advisorDropdown">Danışman</label>
              </span>
            )}
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputTextarea
                className="inputgap"
                id="specialNote"
                name="specialNote"
                value={formData.specialNote}
                rows={15}
                cols={40}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="specialNote">Müşteri Notu</label>
            </span>
          </div>
          {/* Butonlar */}
          <Button
            label="İptal"
            severity="secondary"
            className="rounded-3 me-2"
            onClick={() => navigate('/customer/view', { state: { id: id } })}
          />
          <Button
            label="Güncelle"
            severity="success"
            className="rounded-3"
            onClick={handleSave}
          />
        </div>
      </div>
    </>
  );
};

export default EditCorporateCustomer;
