import React, { useRef, useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'react-bootstrap';
import { getCarRequestList } from '../services/carRequestService';
import SharedDeleteDialog from '../system/SharedDeleteDialog';
import '../../assets/styles/_customer.scss';
import { useNavigate } from 'react-router-dom';
import { sendSmsForCarRequest } from '../services/smsService';

const ListCustomerCarRequest = ({ customerId }) => {

  const toast = useRef(null);
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [header, setHeader] = useState(null);
  const [apiOption, setApiOption] = useState(null);

  const [carRequest, setCarRequest] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCarRequestList(customerId);
        setCarRequest(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşterinin araç talep listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }
    fetchData();
  }, []);

  const handleSave = async (rowData) => {
    const response = await sendSmsForCarRequest(rowData.id);
    if (response === null || response.isSuccess !== true){
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: response.message ? response.message : 'Müşteriye sms gönderilemedi. Lütfen tekrar deneyiniz.',
      });
    } else {
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı',
        detail: response.message ? response.message : 'Müşteriye sms başarıyla gönderildi.',
      });
    }
  }

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
      }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const handleUpdate = (rowData) => {
    navigate('/request/create-car', {state: {id: rowData.id}});
  };

  const handleDelete = (rowData) => {
    setHeader("Araç Talebini Sil");
    setSelectedId(rowData.id);
    setApiOption("carRequest");
    setShowDeleteDialog(true);
  };

  const handleClose = (rowData) => {
    setHeader("Araç Talebini Tamamla");
    setSelectedId(rowData.id);
    setApiOption("closeCarRequest");
    setShowDeleteDialog(true);
  };

  const optionsTemplate = (rowData) => {
    return (
      <Dropdown>
        <Dropdown.Toggle className="btn btn-success bg-green">
          <i className="pi pi-cog fs-5"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
          <Dropdown.Item
            className="text-light border-bottom"
            onClick={() => handleUpdate(rowData)}
          >
            Güncelle
          </Dropdown.Item>
          <Dropdown.Item
            state={{ id: rowData.id }}
            className="text-light border-bottom"
            onClick={() => handleSave(rowData)}
          >
            Sms Gönder
          </Dropdown.Item>
          <Dropdown.Item
            className="text-light border-bottom"
            onClick={() => handleClose(rowData)}
          >
            Tamamla
          </Dropdown.Item>
          <Dropdown.Item
            className="text-light"
            onClick={() => handleDelete(rowData)}
          >
            Sil
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>

      <div className="customerstyles mb-5">
        <h4 className="mb-2">Araç Talep Listesi</h4>
        <DataTable
          value={carRequest}
          sortMode="multiple"
          tableStyle={{ minWidth: '70rem' }}
          emptyMessage="Müşterinin talep ettiği araç bulunmamaktadır"
        >
          <Column field="branch" header="Marka" />
          <Column field="model" header="Model" />
          <Column field="color" header="Renk" body={(rowData) => rowData.color ? rowData.color : "-"} />
          <Column
            field="createdAt"
            header="Oluşturulma Tarihi"
            body={(rowData) => formatDateTime(rowData.createdAt)}
          />
          <Column
            field="until"
            header="Talep Süresi"
            body={(rowData) => formatDateTime(rowData.until)}
          />
          <Column field="status" header="Durumu" />
          <Column field="isSmsSent" header="Sms" />
          <Column field="note" header="Not" />
          <Column body={optionsTemplate} header="İşlem" />
        </DataTable>
        <Toast ref={toast} />
      </div>
      <SharedDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={selectedId}
        setSelectedId={setSelectedId}
        header={header}
        setHeader={setHeader}
        apiOption={apiOption}
        setApiOption={setApiOption}
      />
    </>
  );
};

export default ListCustomerCarRequest;
