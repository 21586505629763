import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

export async function addSmsTemplate(header, description) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/api/Sms/AddSmsTemplate`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({header: header, description: description }),
    });

    const result = await response.json();
    return result;

  } catch (error) {
    return null;
    throw new Error("Failed to fetch model codes");
  }
}

export async function updateSmsTemplate(id, header, description) {
    try {
      const token = getToken();
      const response = await fetch(`${baseUrl}/api/Sms/UpdateSmsTemplate`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id, header: header, description: description }),
      });

      return await response.json();
      
    } catch (error) {
      throw new Error("Failed to fetch model codes");
    }
}

export async function deleteSmsTemplate(id) {
    try {
      const token = getToken();
      const response = await fetch(`${baseUrl}/api/Sms/DeleteSmsTemplate`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id }),
      });

      return await response.json();
      
    } catch (error) {
      throw new Error("Failed to fetch model codes");
    }
}

export async function getAllSmsTemplates() {
    try {
      const token = getToken();
      const response = await fetch(`${baseUrl}/api/Sms/GetAllSmsTemplates`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        }
      });
  
      if (!response.ok) {
        const result = await response.json();
        return result;
      } else {
        const result = await response.json();
        return result;
      }
    } catch (error) {
      throw new Error("Failed to fetch model codes");
    }
  }
  
  export async function sendSmsForCarRequest(id) {
    try {
      const token = getToken();
      const response = await fetch(`${baseUrl}/api/Sms/SendSmsForCarRequest`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id }),
      });

      return await response.json();
      
    } catch (error) {
      return null;
    }
}