import React, { useEffect, useRef, useState } from 'react'
import { getDailySaleReport } from '../services/reportService';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as XLSX from 'xlsx'; // Import the xlsx library
import { Toast } from 'primereact/toast';

const DailySaleReport = () => {

    const [saleReport, setSaleReport] = useState([]);

    const toast = useRef(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getDailySaleReport();
                setSaleReport(data);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail:
                        'Ay bazında satış raporu verileri çekilemedi.',
                });
            }
        }
        fetchData();
    }, []);


    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
            }/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
    };

    const handleDownloadExcel = () => {
        // Convert DataTable data to Excel format
        // Convert DataTable data to Excel format
        const excelData = saleReport.map(row => ({
            'Satış Kodu': row.saleNumber,
            'Tarih': formatDateTime(row.createdAt),
            'Araç': row.model,
            'Müşteri Adı': row.customerName,
            'Danışman': row.advisorName,
            'Şasi Numarası': row.sasiNo,
            'ÖTV': row.otv,
            'Satış Fiyatı': row.totalSalePrice,
            'Net Kar': row.netProfit,
            'Kasko': row.isKasko,
            'Sigorta': row.isInsuranced,
            'Kredi': row.isLoaned,
            'Aksesuar': row.aksesuar,
            'TB': row.tb,
            'Mini Onarım': row.mini,
            'Satış Mal Bedeli': row.baseSalePrice,
            'İndirimli Mal Bedeli': row.salePriceWtihDiscount,
            'Takas': row.isExchanged,
            'Plaka': row.exchangePlaka,
            'Takas Tutarı': row.exchangePrice,
            'İkinci El Masraf': row.expenditure
        }));

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(excelData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Gunluk Satış Raporu');

        // Save Excel file and initiate download
        XLSX.writeFile(workbook, 'Gunluk_Satış_Raporu.xlsx');
    };

    return (
        <>
            <Toast ref={toast} />

            <div className="customerstyles">
                <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
                    <h4>Günlük Satış Raporu</h4>
                    <Button
                        label="Excel İndir"
                        className="j-button bg-green text-light"
                        id="downloadExcel"
                        onClick={handleDownloadExcel}
                    />
                </div>
                <DataTable
                    value={saleReport}
                    sortMode="multiple"
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    stripedRows
                    removableSort
                    emptyMessage="Satış bulunamadı."
                >
                    <Column field="saleNumber" header="Satış Kodu" sortable />
                    <Column field="createdAt" header="Tarih" body={(rowData) => formatDateTime(rowData.createdAt)} />
                    <Column field="model" header="Araç" />
                    <Column field="customerName" header="Müşteri Adı" />
                    <Column field="advisorName" header="Danışman" />
                    <Column field="sasiNo" header="Şasi Numarası" sortable />
                    <Column field="otv" header="ÖTV" />
                    <Column field="totalSalePrice" header="Satış Fiyatı" />
                    <Column field="netProfit" header="Net Kar" />
                    <Column field="isKasko" header="Kasko" />
                    <Column field="isInsuranced" header="Sigorta" />
                    <Column field="isLoaned" header="Kredi" />
                    <Column field="aksesuar" header="Aksesuar" />
                    <Column field="tb" header="TB" />
                    <Column field="mini" header="Mini Onarım" />
                    <Column field="baseSalePrice" header="Satış Mal Bedeli" />
                    <Column field="salePriceWtihDiscount" header="İndirimli Mal Bedeli" />
                    <Column field="isExchanged" header="Takas" />
                    <Column field="exchangePlaka" header="Plaka" />
                    <Column field="exchangePrice" header="Takas Tutarı" />
                    <Column field="expenditure" header="İkinci El Masraf" />
                </DataTable>
            </div>
        </>
    )
}

export default DailySaleReport