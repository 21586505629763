import Cookies from 'js-cookie';


const baseUrl = process.env.REACT_APP_API_URL + '/api';

const getToken = () => {
  return Cookies.get('token');
};

const responseDto = {
  isSuccess: null,
  message: null,
  dataId: null
};


// Aylık müşteri sayısını getirir.
export async function getMonthlyCustomerCount() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/Customer/GetMonthlyCustomerCount`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch customer counts");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch customer counts");
  }
};

// Stoktaki araç sayısını döndürür.
export async function getStockCount() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/Stock/GetStockCount`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch stock counts");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch stock counts");
  }
};

export async function getDailyCustomers() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/Customer/GetDailyCustomers`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch stock counts");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch stock counts");
  }
};

export async function getTodaysCalls() {
  try {

      const token = getToken();
      if (!token) {
          //TODO
          return;
      }

      const response = await fetch(`${baseUrl}/CustomerCall/GetTodaysCalls`, {
          method: "GET",
          headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`,
          },
      });

      if (!response.ok) {
          const res = await response.json();
          responseDto.isSuccess = res
      }

      return await response.json();

  } catch (error) {
      responseDto.isSuccess = false;
      responseDto.message = "Günlük müşteri listesi yüklenirken bir hata oluştu.";
      responseDto.dataId = null;
      return responseDto;
  }
};

export async function getMonthlySaleForUser() {

  const token = getToken();
  if (!token) {
      //TODO
      return;
  }

  return fetch(`${baseUrl}/Sale/GetMonthlySaleForUser`, {
      method: "GET",
      headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
      }
  })
  .then(response => response.json())
  .then(responseData => {
      return responseData;
  })
  .catch(error => {
      return null;
  });
};