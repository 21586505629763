import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

const responseDto = {
    isSuccess: null,
    message: null,
    dataId: null
};

export async function getColorsByModelNameId(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/StockModelDetail/GetColorsByModelNameId`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            const res = await response.json();
            responseDto.isSuccess = res
        }

        return await response.json();

    } catch (error) {
        responseDto.isSuccess = false;
        responseDto.message = "Markalar yüklenirken bir hata oluştu.";
        responseDto.dataId = null;
        return responseDto;
    }
};

export async function getStockListByBranchId() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Stock/GetStockListByBranchId`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getDetailedStockListByBranchId() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Stock/GetDetailedStockListByBranchId`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getStockCalculationById(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Stock/GetStockCalculationById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getStockInfoById(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Stock/GetStockInfoById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function getAllStockModelDetailsByBranchId() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/StockModelDetail/GetAllStockModelDetailsByBranchId`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function deleteStockById(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/Stock/DeleteStockById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};