import React, { useState, useEffect, useRef } from 'react';
import {
  GetBranchSaleReportByMonth,
  GetBranchExchangeCountByMonth,
  GetBranchTbCostByMonth,
  GetBranchAksesuarCostByMonth,
  getMontlySaleCountReport,
  getKaskoCountReportByMonth,
  getInsuranceCountReportByMonth,
  getMonthlyNetProfit,
  getMonthlyCustomerCountReport
} from '../services/reportService';
import { Toast } from 'primereact/toast';
import '../../assets/styles/_graph.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import excel_icon from "../../assets/images/icons8-excel.svg";
import * as XLSX from 'xlsx'; // Import the xlsx library

const ReportCards = () => {
  const [saleReportByMonth, setSaleReportByMonth] = useState([]);
  const [exchangeCountByMonth, setExchangeCountByMonth] = useState([]);
  const [tbCostByMonth, setTbCostByMonth] = useState([]);
  const [aksesuarCostByMonth, setAksesuarCostByMonth] = useState([]);
  const [netProfitCost, setNetProfitCost] = useState([]);
  const [saleCountReportByMonth, setSaleCountReportByMonth] = useState([]);
  const [kaskoCount, setKaskoCount] = useState([]);
  const [insuranceCount, setInsuranceCount] = useState([]);
  const [customer, setCustomer] = useState([]);
  const toast = useRef(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await getMonthlyNetProfit(month, year);
        setNetProfitCost(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında satış raporu verileri çekilemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Ay bazında satış raporu verilerini çekme
  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await GetBranchSaleReportByMonth(month, year);
        setSaleReportByMonth(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında satış raporu verileri çekilemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // ay bazında kasko sayısı
  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await getKaskoCountReportByMonth(month, year);
        setKaskoCount(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında kasko sayısı çekilemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Ay bazında sigorta sayısı
  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await getInsuranceCountReportByMonth(month, year);
        setInsuranceCount(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında sigorta sayısı yüklenemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Ay bazında takas sayısını çekme
  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await GetBranchExchangeCountByMonth(month, year);
        setExchangeCountByMonth(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında takas sayısı çekilemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Ay bazında TB maliyetini çekme
  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await GetBranchTbCostByMonth(month, year);
        setTbCostByMonth(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında TB maliyeti çekilemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Ay bazında aksesuar maliyetini çekme
  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await GetBranchAksesuarCostByMonth(month, year);
        setAksesuarCostByMonth(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında aksesuar maliyeti çekilemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Ay bazında satış sayısını çekme
  useEffect(() => {
    async function fetchData() {
      try {
        const month = selectedMonth;
        const year = selectedYear;
        const data = await getMontlySaleCountReport(month, year);
        setSaleCountReportByMonth(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Ay bazında satış sayısı çekilemedi.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  //TODO Müşteri sayısını çekme. Belirtilen ay ve yıl bazında müşteri sayısı çekilecek.
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getMonthlyCustomerCountReport(selectedMonth, selectedYear);
        setCustomer(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Aylık müşteri girişi sayısı yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  const data = {
    satisCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'Satış',
      body: saleCountReportByMonth.count === 0 ? "-" : saleCountReportByMonth.count,
      icon: 'pi pi-shopping-cart',
    },
    karlilikCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'Kârlılık',
      body: netProfitCost.cost === 0 ? "-" : formatNumber(netProfitCost.cost),
      icon: 'pi pi-money-bill',
    },
    aksesuarCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'Aksesuar',
      body: aksesuarCostByMonth.cost === 0 ? "-" : formatNumber(aksesuarCostByMonth.cost),
      icon: 'pi pi-wrench',
    },
    tbCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'TB',
      body: tbCostByMonth.cost === 0 ? "-" : formatNumber(tbCostByMonth.cost),
      icon: 'pi pi-sun',
    },
    kaskoCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'Kasko',
      body: kaskoCount.count === 0 ? "-" : formatNumber(kaskoCount.count),
      icon: 'pi pi-shield',
    },
    sigortaCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'Sigorta',
      body: insuranceCount.count === 0 ? "-" : formatNumber(insuranceCount.count),
      icon: 'pi pi-exclamation-circle',
    },
    takasCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'Takas',
      body: exchangeCountByMonth.count === 0 ? "-" : formatNumber(exchangeCountByMonth.count),
      icon: 'pi pi-arrow-right-arrow-left',
    },
    musteriCard: {
      size: 'card-size',
      bgColor: 'card-bg',
      header: 'Müşteri',
      body: customer.count === 0 ? "-" : customer.count,
      icon: 'pi pi-users',
    },
  };

  // aylar
  const months = [
    { name: 'Ocak', value: 1 },
    { name: 'Şubat', value: 2 },
    { name: 'Mart', value: 3 },
    { name: 'Nisan', value: 4 },
    { name: 'Mayıs', value: 5 },
    { name: 'Haziran', value: 6 },
    { name: 'Temmuz', value: 7 },
    { name: 'Ağustos', value: 8 },
    { name: 'Eylül', value: 9 },
    { name: 'Ekim', value: 10 },
    { name: 'Kasım', value: 11 },
    { name: 'Aralık', value: 12 },
  ];

  // yıllar
  const years = [
    { name: '2024', value: 2024 },
    { name: '2023', value: 2023 },
    { name: '2022', value: 2022 },
    { name: '2021', value: 2021 },
    { name: '2020', value: 2020 },
  ];

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate() }/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const handleDownloadExcel = () => {
    // Convert DataTable data to Excel format
    // Convert DataTable data to Excel format
    const excelData = saleReportByMonth.map(row => ({
      'Satış Kodu': row.saleNumber,
      'Tarih': formatDateTime(row.createdAt),
      'Araç': row.model,
      'Müşteri Adı': row.customerName,
      'Danışman': row.advisorName,
      'Şasi Numarası': row.sasiNo,
      'ÖTV': row.otv,
      'Satış Fiyatı': row.totalSalePrice,
      'Net Kar': row.netProfit,
      'Kasko': row.isKasko,
      'Sigorta': row.isInsuranced,
      'Kredi': row.isLoaned,
      'Aksesuar': row.aksesuar,
      'TB': row.tb,
      'Mini Onarım': row.mini,
      'Satış Mal Bedeli': row.baseSalePrice,
      'İndirimli Mal Bedeli': row.salePriceWtihDiscount,
      'Takas': row.isExchanged,
      'Plaka': row.exchangePlaka,
      'Takas Tutarı': row.exchangePrice,
      'İkinci El Masraf': row.expenditure
    }));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Aylık Satış Raporu');

    // Save Excel file and initiate download
    XLSX.writeFile(workbook, 'Aylık_Satış_Raporu.xlsx');
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="reportcards">
        <div className="date-info">
          <span id="selectedYear" className="fw-bold">
            {new Date().getFullYear()}
          </span>
          <span> Yılı </span>
          <span id="selectedMonth" className="fw-bold">
            {new Date().toLocaleString('tr', { month: 'long' })}
          </span>
          <span> verileri gösteriliyor.</span>
        </div>

        <div className="card-col">
          {Object.keys(data)
            .slice(0, 4)
            .map((key) => {
              const item = data[key];
              let bodyContent;

              if (Array.isArray(item.body)) {
                // item.body bir dizi ise, uygun şekilde işleme al
                bodyContent = (
                  <ul>
                    {item.body.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>
                );
              } else if (typeof item.body === 'object') {
                // item.body bir nesne ise, uygun şekilde işleme al
                bodyContent = <span>{item.body.name}</span>;
              } else {
                // Diğer durumlarda, direk olarak kullan
                bodyContent = item.body;
              }

              return (
                <div className={`${item.size} ${item.bgColor}`} key={key}>
                  <div className="item-header">{item.header}</div>
                  <div className="item-body">{bodyContent}</div>
                  <i className={item.icon}></i>
                </div>
              );
            })}
        </div>

        <div className="card-col">
          {Object.keys(data)
            .slice(4, 8)
            .map((key) => {
              const item = data[key];
              let bodyContent;

              if (Array.isArray(item.body)) {
                // item.body bir dizi ise, uygun şekilde işleme al
                bodyContent = (
                  <ul>
                    {item.body.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>
                );
              } else if (typeof item.body === 'object') {
                // item.body bir nesne ise, uygun şekilde işleme al
                bodyContent = <span>{item.body.name}</span>;
              } else {
                // Diğer durumlarda, direkt olarak kullan
                bodyContent = item.body;
              }

              return (
                <div className={`${item.size} ${item.bgColor}`} key={key}>
                  <div className="item-header">{item.header}</div>
                  <div className="item-body">{bodyContent}</div>
                  <i className={item.icon}></i>
                </div>
              );
            })}
        </div>
        {/* Aylık ve Yıllık seçmeli list */}
        <div className="w-100 d-flex justify-content-center gap-4">
          <div className="d-flex align-items-end gap-2">
            <div className="h6">Ay</div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById('selectedMonth').innerText =
                  e.target.options[e.target.selectedIndex].text;
                setSelectedMonth(e.target.value);
              }}
              defaultValue={new Date().getMonth() + 1}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex align-items-end gap-2">
            <div className="h6">Yıl</div>
            <select
              className="form-select"
              onChange={(e) => {
                document.getElementById('selectedYear').innerText =
                  e.target.value;
                setSelectedYear(e.target.value);
              }}
            >
              {years.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="customerstyles">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
          <h4>Aylık Satış Raporu</h4>
          <Button
            label={<span><img src={excel_icon} alt="Excel icon" className="pe-2" />Excel İndir</span>}
            className="j-button bg-green text-light"
            id="downloadExcel"
            onClick={handleDownloadExcel}
          />
        </div>
        <DataTable
          value={saleReportByMonth}
          sortMode="multiple"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: '50rem' }}
          stripedRows
          removableSort
          emptyMessage="Satış bulunamadı."
        >
          <Column field="saleNumber" header="Satış Kodu" sortable />
          <Column field="createdAt" header="Tarih" body={(rowData) => formatDateTime(rowData.createdAt)} sortable/>
          <Column field="model" header="Araç"
          style={{ minWidth: '9rem' }}
          />
          <Column field="customerName" header="Müşteri Adı"
          style={{ minWidth: '7rem' }}
          />
          <Column field="advisorName" header="Danışman" />
          <Column field="sasiNo" header="Şasi Numarası" sortable />
          <Column field="otv" header="ÖTV" />
          <Column field="totalSalePrice" header="Satış Fiyatı"
          body = {(rowData) => formatNumber(rowData.totalSalePrice)}
          />
          <Column field="netProfit" header="Net Kâr"
          body={(rowData) => formatNumber(rowData.netProfit)}
          />
          <Column field="isKasko" header="Kasko"
          body = {(rowData) => rowData.isKasko ? <i className="pi pi-check fw-bold text-success"></i> : <i className="pi pi-times fw-bold text-danger"></i>}
          />
          <Column field="isInsuranced" header="Sigorta"
          body = {(rowData) => rowData.isInsuranced ? <i className="pi pi-check fw-bold text-success"></i> : <i className="pi pi-times fw-bold text-danger"></i>}
          />
          <Column field="isLoaned" header="Kredi"
          body = {(rowData) => rowData.isLoaned ? <i className="pi pi-check fw-bold text-success"></i> : <i className="pi pi-times fw-bold text-danger"></i>}
          />
          <Column field="aksesuar" header="Aksesuar" />
          <Column field="tb" header="TB" />
          <Column field="mini" header="Mini Onarım" />
          <Column field="baseSalePrice" header="Satış Mal Bedeli"
          body = {(rowData) => formatNumber(rowData.baseSalePrice)}
          />
          <Column field="salePriceWtihDiscount" header="İndirimli Mal Bedeli"
          body = {(rowData) => formatNumber(rowData.salePriceWtihDiscount)}
          />
          <Column field="isExchanged" header="Takas"
          body = {(rowData) => rowData.isExchanged ? <i className="pi pi-check fw-bold text-success"></i> : <i className="pi pi-times fw-bold text-danger"></i>}
          />
          <Column field="exchangePlaka" header="Plaka" />
          <Column field="exchangePrice" header="Takas Tutarı" />
          <Column field="expenditure" header="İkinci El Masraf" />
        </DataTable>
      </div>
    </>

  );
};

export default ReportCards;
