import React, { useEffect, useRef, useState } from 'react';
import ButtonBg from '../../assets/images/bgbutton.png';
import '../../assets/styles/_dashboard.scss';
import DashboardCard from './DashboardCard';
import { getMonthlyCustomerCount, getMonthlySaleForUser, getStockCount } from '../services/dashboardService';
import { Toast } from 'primereact/toast';

const DashboardRow = ({waitingCallCount}) => {

    const [stocks, setStocks] = useState({stockCount: null});
    const [customer, setCustomer] = useState({monthlyCustomerCount: null});
    const [saleCount, setSaleCount] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        async function fetchData() {
          try {
            const data = await getStockCount();
            setStocks(data);
          } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Stok sayısı yüklenemedi. Sayfayı yenileyiniz.' });
          }
        }
        fetchData();
      }, []);

    useEffect(() => {
        async function fetchData() {
          try {
            const data = await getMonthlyCustomerCount();
            setCustomer(data);
          } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Aylık müşteri girişi sayısı yüklenemedi. Sayfayı yenileyiniz.' });
          }
        }
        fetchData();
      }, []);

      useEffect(() => {
        async function fetchData() {
          try {
            const data = await getMonthlySaleForUser();
            setSaleCount(data);
          } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Aylık satış sayısı yüklenemedi. Sayfayı yenileyiniz.' });
          }
        }
        fetchData();
      }, []);
    
    //mock data
    const data = {
        monthlyCustomers:{
            bg: ButtonBg,
            bgColor:"bg-gradient-blue",
            header: "Aylık Müşteri Girişi",
            body: customer.monthlyCustomerCount,
            icon: "pi pi-users position-absolute end-0 me-5 fs-1"
        },
        monthlySales:{
            bg: ButtonBg,
            bgColor:"bg-gradient-orange",
            header: "Aylık Satışım",
            body: saleCount,
            icon: "pi pi-cart-plus position-absolute end-0 me-5 fs-1"
        },
        waitingCalls:{
            bg: ButtonBg,
            bgColor:"bg-gradient-darkGreen",
            header: "Bekleyen Arama",
            body: waitingCallCount,
            icon: "pi pi-phone position-absolute end-0 me-5 fs-1"
        },
        stocks:{
            bg: ButtonBg,
            bgColor:"bg-gradient-yellow",
            header: "Stok",
            body: stocks.stockCount,
            icon: "pi pi-box position-absolute end-0 me-5 fs-1"
        }        
    };

    return (
        <>
        <Toast ref={toast} />
        <div className="dashboard-cards">
                        
            {/* Aynı kart, mock data içerisindeki verilere göre dinamik olarak oluşturulup gösteriliyor. Veri formatı ve kaynağı değiştirilebilir. Mevcut format: JSON*/}
            <DashboardCard bgColor={data.monthlyCustomers.bgColor} icon={data.monthlyCustomers.icon}  bg={data.monthlyCustomers.bg}  header={data.monthlyCustomers.header}  body={data.monthlyCustomers.body} />
            
            <DashboardCard bgColor={data.monthlySales.bgColor} icon={data.monthlySales.icon}  bg={data.monthlySales.bg}  header={data.monthlySales.header}  body={data.monthlySales.body} />
            
            <DashboardCard bgColor={data.waitingCalls.bgColor} icon={data.waitingCalls.icon}  bg={data.waitingCalls.bg}  header={data.waitingCalls.header}  body={data.waitingCalls.body} />
            
            <DashboardCard bgColor={data.stocks.bgColor} icon={data.stocks.icon}  bg={data.stocks.bg}  header={data.stocks.header}  body={data.stocks.body} />

        </div>
        </>
    )
}

export default DashboardRow
