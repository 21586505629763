import React, { useRef, useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { getCustomersExchangeRequest } from '../services/saleService';
import ApproveExchangeRequestDialog from './ApproveExchangeRequestDialog';
import SharedDeleteDialog from '../system/SharedDeleteDialog';
import UpdateExchangeRequestDialog from '../sale/UpdateExchangeRequestDialog';

const getUserInfo = () => {
  return JSON.parse(Cookies.get('userInfo'));
};

const ListCustomerExchangeData = ({ customerId }) => {

  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showExchangeDataDialog, setShowExchangeDataDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [header, setHeader] = useState(null);
  const [apiOption, setApiOption] = useState(null);

  const [approveForm, setApproveForm] = useState({
    note: null,
    noterPrice: null,
    paidPrice: null,
    expenditure: null,
    totalPrice: null,
    plaka: null,
    exchangeRequestId: null,
  });

  const [userInfo, setUserInfo] = useState();
  // Get the user data from Cookies.
  useEffect(() => {
    const userData = getUserInfo();

    if (userData !== null) setUserInfo(userData);
  }, []);

  const toast = useRef(null);

  const [exchangeData, setExchangeData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCustomersExchangeRequest(customerId);
        // data[0] yoksa hata vermemesi için
        if (!data || data.length === 0) {
          return;
        }

        setExchangeData(data);

        setApproveForm({
          ...approveForm,
          exchangeRequestId: data[0].id
        });

      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşterinin talep ettiği araç listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }
    fetchData();
  }, []);

  //TODO edit, approve, reject, delete functions
  const handleEdit = () => {
    setShowExchangeDataDialog(true);
  };

  const handleApprove = () => {
    setShowApproveDialog(true);
  };

  const handleReject = (rowData) => {
    setHeader("Talebi Reddet");
    setSelectedId(rowData.id);
    setApiOption("rejectExchangeReq");
    setShowDeleteDialog(true);
  };

  const handleDelete = (rowData) => {
    setHeader("Talebi İptal Et (Sil)");
    setSelectedId(rowData.id);
    setApiOption("cancelExchangeReq");
    setShowDeleteDialog(true);
  };

  const optionsTemplate = (rowData) => {
    return (
      <>
      {(rowData.status === "Oluşturuldu") && (
        <Dropdown>
          <Dropdown.Toggle className="btn btn-success bg-green">
            <i className="pi pi-cog fs-5"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">

            <Dropdown.Item
              onClick={() => handleEdit()}
              className="text-light border-bottom"
            >
              Düzenle
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleApprove()}
              className="text-light border-bottom"
            >
              Onayla
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleReject(rowData)}
              className="text-light border-bottom"
            >
              Reddet
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDelete(rowData)} className="text-light">
              Sil
            </Dropdown.Item>
          </Dropdown.Menu>

        </Dropdown>
      )}
      </>
      );
    };

    // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

return (
  <>
    {showApproveDialog && (
      <ApproveExchangeRequestDialog approveForm={approveForm} setApproveForm={setApproveForm} showApproveDialog={showApproveDialog} setShowApproveDialog={setShowApproveDialog} />)}
    <div className="customerstyles mb-5">
      <h4 className="mb-2">Araç Takas Talep Listesi</h4>
      <DataTable
        value={exchangeData}
        sortMode="multiple"
        tableStyle={{ minWidth: '70rem' }}
        emptyMessage="Müşterinin takas talebi bulunmamaktadır."
      >
        <Column field="brand" header="Marka" />
        <Column field="model" header="Model" />
        <Column field="km" header="KM" />
        <Column field="note" header="Not" />
        <Column field="prePrice" header="Fiyat"
        body={(rowData) => formatNumber(rowData.prePrice)}
        />
        <Column field="status" header="Durumu" />
        {(userInfo && (userInfo.isManager || userInfo.isAdmin) && ((rowData) => rowData.status === 'Oluşturuldu')) && (
          <Column header="İşlemler" body={optionsTemplate} />
        )}
      </DataTable>
    </div>
    <SharedDeleteDialog
      showDeleteDialog={showDeleteDialog}
      setShowDeleteDialog={setShowDeleteDialog}
      id={selectedId}
      setSelectedId={setSelectedId}
      header={header}
      setHeader={setHeader}
      apiOption={apiOption}
      setApiOption={setApiOption}
    />
    {showExchangeDataDialog &&
      <UpdateExchangeRequestDialog
        showDialog={showExchangeDataDialog}
        setShowDialog={setShowExchangeDataDialog}
        exchangeForm={exchangeData[0]}
        setExchangeForm={setExchangeData} />}
    <Toast ref={toast} />
  </>
);
  };

export default ListCustomerExchangeData;
