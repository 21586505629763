import React, { useEffect, useRef, useState } from 'react'
import { getDetailedStockListByBranchId } from '../services/stockService';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ListStock from './ListStock';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

const ListDetailedStock = () => {
  const [showList, setShowList] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    stockCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    branchName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    modelCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    modelName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    colorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    year: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sasiNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    motorNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    listPrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
    salePrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
    netProfit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const toast = useRef(null);

  const [detailedStockList, setDetailedStockList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getDetailedStockListByBranchId();

        if (data === null) {
          toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.' });

        }
        setDetailedStockList(data);
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.' });
      }
    }
    fetchData();
  }, []);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const handleShowList = () => {
    setShowList(true);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  // datatable search input
  const onFilter = (value, field) => {
    let _filters = { ...filters };

    if (value === null || value === undefined) {
      delete _filters[field];
    } else {
      _filters[field] = { value: value, matchMode: FilterMatchMode.CONTAINS };
    }

    setFilters(_filters);
  };

  // datatable search input
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-start">
        {advancedSearch ? (
          <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'stockCode')}
              placeholder="Stok Kodu"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'branchName')}
              placeholder="Marka"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'modelCode')}
              placeholder="Model Kodu"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'modelName')}
              placeholder="Model Adı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'colorName')}
              placeholder="Renk"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'year')}
              placeholder="Yıl"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'sasiNumber')}
              placeholder="Şasi Numarası"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'motorNumber')}
              placeholder="Motor Numarası"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'listPrice')}
              placeholder="Liste Fiyatı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'salePrice')}
              placeholder="Satış Fiyatı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'netProfit')}
              placeholder="Net Kar"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'createdAt')}
              placeholder="Kayıt Tarihi"
              className="rounded-3"
            />
            <Button
              type="button"
              icon="pi pi-times-circle"
              className="p-button-success rounded-3"
              tooltip="Filtrelemeyi Kapat"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })
              }
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Arama Yapın"
                className='rounded-3'
              />
            </div>
            <Button
              type="button"
              icon="pi pi-filter"
              className="p-button-success rounded-3 ms-2"
              tooltip="Detaylı Filtreleme"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })}
            />
          </div>
        )}
      </div>
    );
  };
  const header = renderHeader();

  // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  return (
    <>
      <Toast ref={toast} />
      {showList ? (
        <ListStock />
      ) : 
      <div className="customerstyles">
      <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
                        <h4>Detaylı Stok Listesi</h4>
                        <Button label="Normal Liste" className="j-button bg-green text-light" onClick={handleShowList} />
                    </div>
        <DataTable
        value={detailedStockList}
        sortMode="multiple"
        paginator
        header={header}
        globalFilter={globalFilterValue}
        filters={filters}
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: "70rem" }}>
          <Column field="stockCode" header="Stok Kodu" sortable />
          <Column field="branchName" header="Marka" />
          <Column field="modelCode" header="Model Kodu" />
          <Column field="modelName" header="Model Adı" style={{minWidth:"300px"}}/>
          <Column field="colorName" header="Renk" />
          <Column field="year" header="Yıl" sortable />
          <Column field="sasiNumber" header="Şasi Numarası" />
          <Column field="motorNumber" header="Motor Numarası" />
          <Column field="listPrice" header="Liste Fiyatı" sortable
          body = {(rowData) => formatNumber(rowData.listPrice)}
          />
          <Column field="salePrice" header="Satış Fiyatı" sortable
          body = {(rowData) => formatNumber(rowData.salePrice)}
          />
          <Column field="netProfit" header="Net Kâr" sortable
          body = {(rowData) => formatNumber(rowData.netProfit)}
          />
          <Column field="netProfitRate" header="Kâr Oranı" />
          <Column field="netProfitMargin" header="Kâr Marjı" sortable />
          <Column field="status" header="Stok Durumu" />
          <Column field="otv" header="Ötv" />
          <Column field="kdv" header="Kdv" />
          <Column field="mtv" header="Mtv" />
          <Column field="plakaTescil" header="Plaka Tescil" />
          <Column field="otvPrice" header="Ötv Miktarı"
          body = {(rowData) => formatNumber(rowData.otvPrice)}
          />
          <Column field="kdvPrice" header="Kdv Miktarı"
          body = {(rowData) => formatNumber(rowData.kdvPrice)}
          />
          <Column field="priceWithKdvOtv" header="Ötv Kdvli Fiyat"
          body = {(rowData) => formatNumber(rowData.priceWithKdvOtv)}
          />
          <Column field="priceWithOtv" header="Ötvli Fiyat"
          body = {(rowData) => formatNumber(rowData.priceWithOtv)}
          />
          <Column field="priceWithKdv" header="Kdvli Fiyat"
          body = {(rowData) => formatNumber(rowData.priceWithKdv)}
          />
          <Column field="status" header="Stok Durumu" />
          <Column field="createdAt" header="Kayıt Tarihi" body={(rowData) => formatDateTime(rowData.createdAt)} sortable />

        </DataTable>
      </div>
      }
    </>
  )
}

export default ListDetailedStock