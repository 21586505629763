import Cookies from 'js-cookie';


const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

export async function getMuvafakatnameFields(saleId) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }
    return fetch(`${baseUrl}/api/Form/GetMuvafakatnameFields`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: saleId }),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
}

export async function getProformaFields(saleId) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }
    return fetch(`${baseUrl}/api/Form/GetProformaFields`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: saleId }),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
}

export async function getInfoFormFields(saleId) {
    const token = getToken();
    if (!token) {
        //TODO
        return;
    }
    return fetch(`${baseUrl}/api/Form/GetInfoFormFields`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: saleId }),
    })
        .then(response => response.json())
        .then(responseData => {
            return responseData;
        })
        .catch(error => {
            return null;
        });
}