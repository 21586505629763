import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { changePassword } from '../services/userService';

const ChangePassword = () => {
  const toast = useRef(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const passwordMatchControl = () => {
    return newPassword === confirmPassword;
  };

  const handleClean = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleSavePassword = async () => {
    const response = await changePassword(oldPassword, newPassword);
    if (response === null || !response.isSuccess) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: response.message
          ? response.message
          : 'Şifre değiştirilemedi. Lütfen tekrar deneyiniz.',
      });
    }
    else{
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı',
        detail: 'Şifreniz başarıyla değiştirildi.',
      });

      handleClean();
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles">
        <div className="d-flex align-items-center justify-content-center flex-column">
          <label className='fw-bold'>Eski Şifreniz</label>
          <Password
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            feedback={false}
            toggleMask={true}
            style={{maxWidth:"350px"}}
          />
          <label className="mt-3 fw-bold">Yeni Şifreniz</label>
          <Password
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            weakLabel="Zayıf"
            mediumLabel="Orta"
            strongLabel="Güçlü"
            promptLabel="Şifreniz en az 8 karakter olmalıdır."
            minLength={8}
            toggleMask={true}
            style={{maxWidth:"350px"}}
          />
          <label className="mt-3 fw-bold">Yeni Şifreniz Tekrar</label>
          <Password
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            weakLabel="Zayıf"
            mediumLabel="Orta"
            strongLabel="Güçlü"
            promptLabel="Şifrenizi tekrar giriniz."
            minLength={8}
            feedback={false}
            toggleMask={true}
            style={{maxWidth:"350px"}}
          />
        </div>

        <div className="d-flex justify-content-center">
          <Button
            label="Kaydet"
            className="mt-3 j-button bg-green"
            onClick={() => {
              if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
                toast.current.show({
                  severity: 'error',
                  summary: 'Hata',
                  detail: 'Lütfen tüm alanları doldurunuz.',
                });
              } else if (!passwordMatchControl()) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Hata',
                  detail: 'Şifreler uyuşmuyor.',
                });
                return;
              } else if (newPassword.length < 8) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Hata',
                  detail: 'Şifreniz en az 8 karakter olmalıdır.',
                });
              } else {
                handleSavePassword();
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
