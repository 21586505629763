import React, { useEffect, useRef, useState } from 'react'
import { getAllOtvOptions, getTaxValues, updateTaxValues } from '../services/systemService';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import SharedNameDialog from './SharedNameDialog';
import { deleteOtvOption } from '../services/taxServices';

const TaxSettings = () => {

  const toast = useRef(null);

  const [showDialog, setShowDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [value, setValue] = useState(null);
  const [header, setHeader] = useState(null);
  const [apiOption, setApiOption] = useState(null);

  const [taxData, setTaxData] = useState(null);
  const [otvOptionsData, setOtvOptionsData] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllOtvOptions();

        setOtvOptionsData(fetchedData);

      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Vergi verileri yüklenemedi. Sayfayı yenileyiniz.' });
      }
    }

    fetchData();
  }, [apiOption]);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getTaxValues();

        setTaxData(fetchedData);

      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Vergi verileri yüklenemedi. Sayfayı yenileyiniz.' });
      }
    }

    fetchData();
  }, []);

  const handleChange = (e, field) => {
    const { value } = e.target;
    setTaxData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleAddOrUpdateOtvOption = (rowData) => {
    if (rowData.id === undefined) {
      setHeader("ÖTV Seçeneği Ekle");
    } else {
      setHeader("ÖTV Seçeneği Düzenle");
    }
    setSelectedId(rowData.id);
    setValue(rowData.option);
    setApiOption("otvOptions");
    setShowDialog(true);
  };

  const handleSave = async () => {
    try {
      const response = await updateTaxValues(taxData);

      if (response === null) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Vergi verileri güncelenemedi. Tekrar deneyiniz.' });

      }
      else {
        if (response && response.isSuccess) {
          toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Vergi verileri güncellendi.' });

        }
        else {
          toast.current.show({ severity: 'error', summary: 'Hata', detail: response.message ? response.message : 'Vergi verileri güncelenemedi. Tekrar deneyiniz.' });

        }
      }

    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Vergi verileri güncelenemedi. Tekrar deneyiniz.' });
    }
  }

  const handleDelete = async (rowData) => {
    const response = await deleteOtvOption(rowData.id);
    
    if(response === null || (response && !response.isSuccess)){
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Vergi verisi silinemedi. Tekrar deneyiniz.' });
    }
    else{
      toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Ötv seçeneği silindi.' });
      setOtvOptionsData(otvOptionsData.filter((item) => item.id !== rowData.id));
    }
  }

  // Inside the component
  return (
    <>
      <Toast ref={toast} />
      <h4 className='mb-5'>Vergi Ayarları</h4>
      {taxData && ( // Conditionally render only if taxData is not null
        <div className="col-12 col-lg-8 col-xxl-5">
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="kdv"
                value={taxData.kdv}
                onChange={(e) => handleChange(e, 'kdv')}
              />
              <label htmlFor="kdv">KDV Oranı</label>
            </span>
          </div>
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="mtv"
                value={taxData.mtv}
                onChange={(e) => handleChange(e, 'mtv')}

              />
              <label htmlFor="mtv">MTV Değeri</label>
            </span>
          </div>
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="plakaTescil"
                value={taxData.plakaTescil}
                onChange={(e) => handleChange(e, 'plakaTescil')}
              />
              <label htmlFor="plakaTescil">Plaka Tescil</label>
            </span>
          </div>
          <div className="mb-4">
            <span className="p-float-label">
              <InputText
                className="col-12"
                id="outSource"
                value={taxData.outSource}
                onChange={(e) => handleChange(e, 'outSource')}
              />
              <label htmlFor="outSource">Takip Bedeli</label>
            </span>
          </div>
          <div className="d-flex justify-content-end gap-2 col-12">
            <Button
              label="Güncelle"
              className="p-button-success rounded-3"
              onClick={handleSave}
            />
          </div>
        </div>
      )}
      <div className="customerstyles">
        <div className="d-block d-lg-flex align-items-center justify-content-between my-5 col-12 col-lg-8 col-xxl-5">
          <h4>ÖTV Ayarları</h4>
          <Button label="Ekle" severity="success" className="rounded-3"  onClick={handleAddOrUpdateOtvOption} />
        </div>
        
        {otvOptionsData && (
          <DataTable value={otvOptionsData} sortMode="multiple" className='col-12 col-lg-8 col-xxl-5'>
            <Column field="option" header="ÖTV Oranı" sortable />
            <Column field="otvType" header="Tipi" sortable />
            <Column
              header="İşlem"
              body={(row) => (
                <div>
                  <Button
                    icon="pi pi-trash"
                    className="rounded-3 bg-blue"
                    onClick={() => handleDelete(row)}
                  />
                </div>
              )}
            />
          </DataTable>
        )}
      </div>
      <SharedNameDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        id={selectedId}
        setSelectedId={setSelectedId}
        header={header}
        setHeader={setHeader}
        value={value}
        setValue={setValue}
        apiOption={apiOption}
        setApiOption={setApiOption}
      />
    </>
  )

}

export default TaxSettings