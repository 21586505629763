import React, { useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie';
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { getAllBranches, getAllModelCodesByBranchId, getAllModelNamesByModelCodeId } from '../services/modelService';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import TestSurusFormu from '../sale/TestSurusFormu';
import html2pdf from 'html2pdf.js';

const baseUrl = process.env.REACT_APP_API_URL;
const getToken = () => {
  return Cookies.get('token'); 
};
const getAppUserName = () => {
  return (JSON.parse(Cookies.get('userInfo'))).userName; 
};


const AddTestDrive = ({customerData}) => {

  const navigate = useNavigate();
  const toast = useRef(null);

  const [branchList, setBranchList] = useState([]);
  const [modelCodeList, setModelCodeList] = useState([]);
  const [modelNameList, setModelNameList] = useState([]);

  const [visible, setVisible] = useState(false);

  const [testDriveData, setTestDriveData] = useState({
    branchId: null,
    modelName: null,
    modelNameId: null,
    modelCode: null,
    modelCodeId: null,
    customerId: customerData.customerId,
    customerName: customerData.name + ' ' + customerData.surname,
    advisorName: getAppUserName(),
    phoneNumber: customerData.phoneNumber,
    year: null,
  });

  const [download, setDownload] = useState(false);

  const [testFormData, setTestFormData] = useState({
    branch:null,
    model: null,
    year: null,
    customerName: null,
    phoneNumber: null,
    personalPhoneNumber: null,
    adress: null,
    email: null,
    userName: getAppUserName(),
  });



  const renderList = (dataList) => {
   
    if (!Array.isArray(dataList)) {
        return [];
    }
    return dataList.map((item) => ({
        id: item.id,
        name: item.name
    }));
};

    const handleChange = (e) => {

      const {name, value} = e.target;
      if (name === "branch") {
        setTestDriveData(prevState => ({
          ...prevState,
          branch: value,
          branchId: value.id
        }));    
      }
      else if(name === "modelCode"){
        setTestDriveData(prevState => ({
          ...prevState,
          modelCode: value,
          modelCodeId: value.id
        }));
      }
      else if(name === "modelName"){
        setTestDriveData(prevState => ({
          ...prevState,
          modelName: value,
          modelNameId: value.id
        }));  
      }
      else if(name === "year"){
        setTestDriveData(prevState => ({
          ...prevState,
          year: value,
        }));  
      };
    };

  // Lists all branches 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllBranches();
        setBranchList(data);
      } catch (error) {
        console.error(error);
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç markaları yüklenemedi. Sayfayı yenileyiniz.' });
      }
    };

    fetchData();
  }, []);

  // Lists the model codes by branchId
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllModelCodesByBranchId();
        setModelCodeList(data);
      } catch (error) {
        console.error(error);
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç model kodları yüklenemedi. Sayfayı yenileyiniz.' });
      }
    };

    fetchData();
  }, [testDriveData.branchId]);

  // Lists the model names by model code id
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!testDriveData.modelCodeId) return;
        const data = await getAllModelNamesByModelCodeId(testDriveData.modelCodeId);
        setModelNameList(data);
      } catch (error) {
        console.error(error);
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Araç model adları yüklenemedi. Sayfayı yenileyiniz.' });
      }
    };

    fetchData();
  }, [testDriveData.modelCodeId]);

  const handleSave = async () => {

      const requestBody = {
        customerId: testDriveData.customerId,
        modelNameId: testDriveData.modelNameId,
        year: testDriveData.year,
      };

      try {
        const token = getToken();
        if(!token){
          //TODO
          return;
        }

        const response = await fetch(`${baseUrl}/api/TestDrive/AddTestDrive`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
          return;
        }
  
        const data = await response.json();

        toast.current.show({ severity: "success", summary: "Kayıt Başarılı", detail: data.message });

        //test sürüş formu indirilecek.
        const formResponse = await fetch(`${baseUrl}/api/TestDrive/GetTestDriveDataByCustomer`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({id: testDriveData.customerId}),
        });
  
        if (!formResponse.ok) {
          toast.current.show({ severity: "error", summary: "Hata", detail: "Test sürüş formu verileri yüklenirken bir hata oluştu." });
          return;
        }
  
        const formResponseData = await formResponse.json();
        setTestFormData(prevState => ({
          ...prevState,
          branch: formResponseData.branch,
          model: formResponseData.model,
          year: formResponseData.year,
          customerName: formResponseData.customerName,
          phoneNumber: formResponseData.phoneNumber,
          personalPhoneNumber: formResponseData.personalPhoneNumber,
          adress: formResponseData.adress,
          email: formResponseData.email,
      }));
        // todo: pdf download
        setDownload(true);
      } catch (error) {
        toast.current.show({ severity: "error", summary: "Hata", detail: "Müşteri kaydı alınırken bir hata oluştu." });
      }
  };

  const handleDownloadTestForm = () => {
    const testFormHtml = document.getElementById('hiddenTestForm').innerHTML;
    const fileName = `${testDriveData.customerName}_TestSurusu.pdf`; // Constructing the filename
    const pdfOptions = {
      filename: fileName,
    };
    html2pdf().set(pdfOptions).from(testFormHtml).save();
    setDownload(false);
    setTimeout(() => {
      navigate('/dashboard');
    }, 3300);
  }

  useEffect(() => {
    if (download) {
      handleDownloadTestForm();
    }
  }, [download]);

  // İptal butonu Emin misiniz? Dialog
  const handleCancel = () => {
    setVisible(true);
  };
  // İptal butonu için onaylama "Evet" butonu
  const confirmCancel = () => {
    setVisible(false);
    window.location.reload(
      toast.current.show({
        severity: 'info',
        summary: 'İptal Edildi',
        detail: 'İşlem iptal edildi.',
      })
    );
  };
  // Dialog için footer butonları
  const cancelFooter = (
    <div>
      <Button
        label="Hayır"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="j-button bg-secondary text-light"
      />
      <Button
        label="Evet"
        icon="pi pi-check"
        onClick={confirmCancel}
        autoFocus
        className="j-button bg-green ms-2 text-light"
      />
    </div>
  );

  return (
    <>
    <div id="hiddenTestForm" style={{ display: 'none' }}>
        <TestSurusFormu data={testFormData} date={new Date()} />
      </div>
    <Toast ref={toast}/>
    <div className='customerstyles'>
      <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
        <h4>Test Sürüşü Formu</h4>
      </div>
      <div className='d-block d-lg-flex col-12 col-xxl-8'>
        <span className='p-float-label col-12 col-lg-6 me-4 mb-4'>
          <h5>Müşteri Adı Soyadı:</h5>
          <InputText
            disabled
            id="customerName"
            className="inputgap"
            value={testDriveData.customerName}
          />
        </span>
        <span className='p-float-label col-12 col-lg-6 me-4 mb-4'>
          <h5>Danışman Adı Soyadı:</h5>
          <InputText
            disabled
            id="advisorName"
            className="inputgap"
            value={testDriveData.advisorName}
          />
        </span>
        <span className='p-float-label col-12 col-lg-6 me-4 mb-4'>
          <h5>Müşteri Telefon Numarası:</h5>
          <InputText
            disabled
            id="phoneNumber"
            className="inputgap"
            value={testDriveData.phoneNumber}
          />
        </span>
      </div>
      <div className="d-block d-lg-flex col-12 col-xxl-8">
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Dropdown
            id="modelCode"
            name="modelCode"
            className="inputgap"
            value={testDriveData.modelCode}
            onChange={(e) => handleChange(e)}
            options={renderList(modelCodeList)}
            optionLabel="name"
          />
          <label htmlFor="brand">Model</label>
        </span>
        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
          <Dropdown
            id="modelName"
            name="modelName"
            className="inputgap"
            value={testDriveData.modelName}
            onChange={(e) => handleChange(e)}
            options={renderList(modelNameList)}
            optionLabel="name"
          />
          <label htmlFor="brand">Paket</label>
        </span>
        <span className="col-12 col-lg-6 me-4 mb-4">
          <InputText
            id="year"
            name="year"
            placeholder="Araç Yılı"
            className="inputgap"
            value={testDriveData.year}
            onChange={(e) => handleChange(e)}
          />
        </span>
      </div>
      {/* Butonlar */}
      <Button
        label="İptal"
        severity="secondary"
        className="rounded-3 me-2"
        onClick={() => window.location.reload()}
      />
      <Button
        label="Sonraki Adım"
        severity="success"
        className="rounded-3"
        onClick={handleSave}
        disabled={!testDriveData.year || !testDriveData.modelCode || !testDriveData.modelName}
      />
      <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header="İşlemi İptal Et"
            footer={cancelFooter}
            modal
            style={{ width: '450px' }}
            className="text-center"
          >
            <div className="confirmation-content d-flex align-items-center">
              <i
                className="pi pi-exclamation-triangle me-3 text-danger"
                style={{ fontSize: '3rem' }}
              />
              <span>Girilen bilgiler sıfırlanacak, Emin misiniz?</span>
            </div>
          </Dialog>
    </div>
    </>

  )
}

export default AddTestDrive;