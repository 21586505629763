import React, { useEffect, useRef, useState } from 'react';
import { getUpdReservedSaleDataById, }
    from '../services/saleService';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import ListSaleCarData from './ListSaleCarData';
import ListSaleCustomerData from './ListSaleCustomerData';
import AddExtraFuture from './AddExtraFuture';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import SaleTaxInfoForUpdate from './SaleTaxInfoForUpdate';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
}

const UpdateReservedSale = () => {
    const toast = useRef(null);

    const navigate = useNavigate();

    const location = useLocation();
    const stateId = location.state ? location.state.id : null;

    const [showExtraFuture, setShowExtraFuture] = useState(false);

    const [formData, setFormData] = useState({
        reservedUntil: null,
        deposit: null,
        isDocumented: false,
        isFeatured: false,
        isInsuranced: true,
        saleNote: null,
        customerName: null,
        saleId: (stateId) ? { id: stateId } : null,

        discount: null,
        stockSalePrice: null,
        salePrice: null,
        listPrice: null,
        basePrice: null,
        netProfit: null,
        raporluSalePrice: null,

        otvCheck: null,

        otv: null,
        kdv: null,
        mtv: null,
        plakaTescil: null,
        stockOtv: null,
        stockMtv: null,

    });


    const [extraFeatureForm, setExtraFeatureForm] = useState({
        // aksesuar 
        aksesuar: 0,
        tb: 0,
        miniRepair: 0,
        description: null,
    })

    useEffect(() => {
        async function fetchData() {
            try {

                if (formData.saleId === null) return;
                const fetchedData = await getUpdReservedSaleDataById(formData.saleId);
                if (fetchedData === null) {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Hata',
                        detail: 'Satış bilgileri yüklenemedi. Sayfayı yenileyiniz.',
                    });
                };

                setFormData((prevState) => ({
                    ...prevState,
                    reservedUntil: fetchedData.reservedUntil,
                    deposit: fetchedData.deposit,
                    isFeatured: fetchedData.isFeatured,
                    isInsuranced: fetchedData.isInsuranced,
                    customerName: fetchedData.customerName,
                    sasiNo: fetchedData.sasiNo,
                    salePrice: fetchedData.calcSalePriceWithTaxes,
                    lastSalePrice: fetchedData.salePrice,
                    raporluSalePrice: fetchedData.salePrice,
                    basePrice: fetchedData.stockBasePrice,
                    stockSalePrice: fetchedData.stockSalePrice,
                    listPrice: fetchedData.listPrice,
                    discount: fetchedData.discount,
                    totalSalePrice: fetchedData.totalSalePrice,
                    kdv: fetchedData.kdv,
                    otv: fetchedData.otv,
                    mtv: fetchedData.mtv,
                    plakaTescil: fetchedData.plakaTescil,
                    stockOtv: fetchedData.stockOtv,
                    stockMtv: fetchedData.stockMtv,
                    otvCheck: fetchedData.otv === 0 ? true : false,
                    netProfit: fetchedData.netProfit,
                }));

                if (fetchedData.isFeatured) {
                    setExtraFeatureForm({
                        ...formData,
                        ['aksesuar']: fetchedData.aksesuar,
                        ['tb']: fetchedData.tb,
                        ['miniRepair']: fetchedData.miniRepair,
                    });

                    setShowExtraFuture(true);
                }
                else setExtraFeatureForm(false);

            } catch (error) {
                return null;
            }
        }

        fetchData();
    }, [formData.stockId]);

    const handleDropdownChange = (e, fieldName) => {
        const selectedValue = e.value;

        setFormData({
            ...formData,
            [fieldName]: selectedValue,
        });
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        const newValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue,
        });

        if (name === 'isFeatured') {
            if (checked === true) {
                setShowExtraFuture(true);
            } else {
                setShowExtraFuture(false);
            }
        }
    };

    const handleSave = async () => {

        let FeatureDto = {};


        if (formData.isFeatured) {
            FeatureDto = {
                aksesuar: parseFloat(extraFeatureForm.aksesuar),
                tb: parseFloat(extraFeatureForm.tb),
                miniRepair: parseFloat(extraFeatureForm.miniRepair),
                description: extraFeatureForm.description
            };
        };

        const UpdateInPresaleSaleReqDto = {
            id: stateId,
            reservedUntil: formData.reservedUntil,
            deposit: formData.deposit,
            totalSalePrice: parseFloat(formData.totalSalePrice),
            noOtv: formData.otvCheck,
            SalePrice: parseFloat(formData.salePrice),
            kdv: formData.kdv,
            otv: formData.otv,
            mtv: formData.mtv,
            plakaTescil: formData.plakaTescil,
            isFeatured: formData.isFeatured,
            featureDto: (formData.isFeatured && FeatureDto) ? FeatureDto : null,
            isInsuranced: formData.isInsuranced
            //saleNote
        };

        try {
            const token = getToken();
            if (!token) {
                //TODO
                return;
            }
            const response = await fetch(`${baseUrl}/api/Sale/UpdateInPresaleSale`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(UpdateInPresaleSaleReqDto),
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
                return;
            }

            const data = await response.json();

            toast.current.show({ severity: "success", summary: "Güncelleme Başarılı", detail: data.message });

            navigate('/sale/reserved-list');

        } catch (error) {
            toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
        }

    };

    const handleCancel = () => {
        setFormData({
            reservedUntil: null,
            deposit: null,
            isDocumented: false,
            isFeatured: false,
            saleNote: null,
            customerId: null,
            stockId: null,
            discount: null,
            stockSalePrice: null,
            lastSalePrice: null,
            totalSalePrice: null,
            otvCheck: null,
            salePrice: null,
            basePrice: null,
            otvOptionsId: null,
            otv: null,
            kdv: null,
            mtv: null,
            plakaTescil: null,
        });
        toast.current.show({ severity: 'info', className: 'text-dark', summary: 'İptal', detail: 'İşlem iptal edildi.' });
    }

    return (
        <>
            <Toast ref={toast} />


            <div className="customerstyles">
                <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
                    <h4>Bağlantı Satış Yap</h4>
                </div>
                <div className="d-block d-lg-flex col-12 col-xxl-8">
                    <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                        <InputText
                            id="customer"
                            name="customer"
                            className="inputgap"
                            value={formData.customerName}
                            disabled
                        />
                        <label htmlFor="customer">Müşteri</label>
                    </span>
                    <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                        <InputText
                            id="stock"
                            name="stock"
                            className="inputgap"
                            value={formData.sasiNo}
                            disabled
                        />
                        <label htmlFor="stock">Şasi Numarası</label>
                    </span>
                </div>
                <div className="d-block d-lg-flex col-12 col-xxl-8">
                    <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                        <InputNumber
                            className="inputgap"
                            inputId="deposit"
                            name="deposit"
                            locale="tr-TR"
                            value={formData.deposit}
                            minFractionDigits={2}
                            maxFractionDigits={5}
                            onChange={(e) => handleDropdownChange(e, 'deposit')}
                        />
                        <label htmlFor="deposit">Alınan Kapora</label>
                    </span>
                    <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                        <Calendar
                            id="reservedUntil"
                            name="reservedUntil"
                            className="inputgap"
                            value={new Date(formData.reservedUntil)}
                            minDate={new Date()}
                            onChange={(e) => handleChange(e)}
                            dateFormat="dd/mm/yy"
                        />
                        <label htmlFor="reservedUntil">Bağlantı Süresi</label>
                    </span>
                </div>
                <div className="d-block d-lg-flex col-12 col-xxl-8">
                    <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                        <InputNumber
                            className="inputgap"
                            inputId="salePrice"
                            name="salePrice"
                            locale="tr-TR"
                            value={formData.salePrice}
                            minFractionDigits={2}
                            maxFractionDigits={5}
                            onChange={(e) => handleDropdownChange(e, 'salePrice')}
                        />
                        <label htmlFor="salePrice">Satış Fiyatı</label>
                    </span>
                    {formData.otvCheck && (
                        <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
                            <InputNumber
                                className="inputgap"
                                inputId="raporluSalePrice"
                                name="raporluSalePrice"
                                locale="tr-TR"
                                value={formData.raporluSalePrice}
                                minFractionDigits={2}
                                maxFractionDigits={5}
                                onChange={(e) => handleDropdownChange(e, 'raporluSalePrice')}
                            />
                            <label htmlFor="raporluSalePrice">Raporlu Satış Fiyatı</label>
                        </span>
                    )}
                </div>

                <div className="d-block d-lg-flex col-12 col-xxl-8">
                    <span className="col-12 col-lg-6 me-4 mb-4">
                        <Checkbox
                            id="isFeatured"
                            name="isFeatured"
                            className="me-3"
                            onChange={(e) => handleChange(e)}
                            checked={formData.isFeatured}
                        ></Checkbox>
                        <label htmlFor="isFeatured">Ek Aksesuar</label>
                    </span>
                    <span className="col-12 col-lg-6 ">
                        <Checkbox
                            id="isInsuranced"
                            name="isInsuranced"
                            className=" me-3"
                            onChange={(e) => handleChange(e)}
                            checked={formData.isInsuranced}
                        ></Checkbox>
                        <label htmlFor="isLoaned">Trafik/Kasko Teklifi</label>
                    </span>
                </div>

                {showExtraFuture && <AddExtraFuture extraFeatureForm={extraFeatureForm} setExtraFeatureForm={setExtraFeatureForm} />}

                <SaleTaxInfoForUpdate formData={formData} setFormData={setFormData} extraFeatureForm={extraFeatureForm} />

                {formData.stockId && <ListSaleCarData id={formData.stockId.id} />}
                {formData.customerId && (
                    <ListSaleCustomerData id={formData.customerId} />
                )}
                <div className="d-flex gap-2">
                    <Button className="rounded-3" severity="secondary" label="İptal" onClick={handleCancel} />
                    <Button className="rounded-3" severity="success" label="Kaydet" onClick={handleSave} />
                </div>
            </div>

        </>
    );
};

export default UpdateReservedSale;
