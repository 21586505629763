import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

export async function getAllPaymentTypes() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/PaymentType/GetAllPaymentTypes`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function getAllPaymentBanks() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/PaymentBank/GetAllPaymentBanks`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function addPaymentType(requestBody) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/PaymentType/AddPaymentType`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({name: requestBody.name, hasBank: requestBody.bank, hasLoan: requestBody.loan, hasExchange: requestBody.exchange})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function addPaymentBank(name) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/PaymentBank/AddPaymentBank`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({name: name})
        });

        if (!response.ok) {

            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function updatePaymentType(id, name) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/PaymentType/UpdatePaymentType`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id, name: name})
        });

        if (!response.ok) {
             return await response.json();   
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function updatePaymentBank(id, name) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        };

        const response = await fetch(`${baseUrl}/api/PaymentBank/UpdatePaymentBank`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id, name: name})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function deletePaymentType(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/PaymentType/DeletePaymentType`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};

export async function deletePaymentBank(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/PaymentBank/DeletePaymentBank`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch fuel types");
    }
};



