import React, { useEffect, useRef, useState } from 'react';
import { getAllCustomerNamesList, getAllSasiNoStocks,} 
from '../services/saleService';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { getStockCalculationById } from '../services/stockService';
import { Checkbox } from 'primereact/checkbox';
import { getLoanBanksNameList } from '../services/loanService';
import AddExchangeRequestDialog from './AddExchangeRequestDialog';
import ListSaleCarData from './ListSaleCarData';
import ListSaleCustomerData from './ListSaleCustomerData';
import AddExtraFuture from './AddExtraFuture';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import AddPaymentPlan from './AddPaymentPlan';
import { useLocation } from 'react-router-dom';
import SaleTaxInfo from './SaleTaxInfo';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
}

const AddReservedSale = () => {
  const toast = useRef(null);

  const location = useLocation();
  const stateId = location.state ? location.state.id : null;
  const sasiNo = location.state ? location.state.sasiNo : null;

  const [customerList, setCustomerList] = useState([]);
  const [stockSasiList, setStockSasiList] = useState([]);

  const [selectedStockData, setSelectedStockData] = useState(null);

  const [loanBankList, setLoanBankList] = useState([]);
  const [showLoanRequest, setShowLoanRequest] = useState(false);

  const [showExchangeRequest, setShowExchangeRequest] = useState(false);
  const [showExtraFuture, setShowExtraFuture] = useState(false);

  const [showPaymentScreen, setShowPaymentScreen] = useState(false);

  const [saleId, setSaleId] = useState();

  const [formData, setFormData] = useState({
    reservedUntil: null,
    deposit: null,
    isDocumented: false,
    isInsuranced: true,
    isExchanged: false,
    isFeatured: false,
    isLoaned: false,
    saleNote: null,
    customerId: null,
    stockId: (stateId && sasiNo) ? {id: stateId, sasiNumber: sasiNo} : null,

    discount: null,
    stockSalePrice: null,
    salePrice: null,
    listPrice: null,
    basePrice: null,
    raporluSalePrice: null,

    otvCheck: null,

    // tax details 
    otvOptionsId: null,
    otv: null,
    kdv: null,
    mtv: null,
    plakaTescil: null,

    //loan request
    loanBankId: null,
    amount: null,
    months: null,

  });

  const [monthList, setMonthList] = useState([
    { label: 3 },
    { label: 6 },
    { label: 9 },
    { label: 12 },
    { label: 24 },
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllSasiNoStocks();
        if (fetchedData === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
        setStockSasiList(fetchedData);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {

    if(!formData.isFeatured)
    setShowExtraFuture(false);
  }, [formData.isFeatured]);

  const [exchangeForm, setExchangeForm] = useState({
    // takas
    brand: null,
    model: null,
    year: null,
    package: null,
    gearType: null,
    fuelType: null,
    km: null,
    color: null,
    damage: null,
    note: null,
    formDoc: null,
    customerExpectation : null,
  });

  const [extraFeatureForm, setExtraFeatureForm] = useState({
    // aksesuar 
    aksesuar: 0,
    tb: 0,
    miniRepair: 0,
    description: null,
  })

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllCustomerNamesList();

        if (fetchedData === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Müşteri listesi yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
        setCustomerList(fetchedData);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşteri listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);



  useEffect(() => {
    async function fetchData() {
      try {

        if(formData.stockId.id === null) return;
        const fetchedData = await getStockCalculationById(formData.stockId.id);
        if (fetchedData === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
        setSelectedStockData(fetchedData);
        setFormData({
          ...formData,
          ['salePrice']: fetchedData.salePrice,
          ['basePrice'] : fetchedData.basePrice,
          ['stockSalePrice']: fetchedData.salePrice,
          ['ListPrice']: fetchedData.listPrice,
          ['kdv']: fetchedData.kdv,
          ['otv']: fetchedData.otv,
          ['mtv']: fetchedData.mtv,
          ['plakaTescil']: fetchedData.plakaTescil,
          ['otvOptionsId']: fetchedData.otvOptionsId,
          ['basePrice']: fetchedData.basePrice,
        });
      } catch (error) {
        return null;
      }
    }

    fetchData();
  }, [formData.stockId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getLoanBanksNameList();
        if (fetchedData === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Kredi bankaları yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
        setLoanBankList(fetchedData);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Kredi bankaları yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  const handleDropdownChange = (e, fieldName) => {
    const selectedValue = e.value;

    setFormData({
      ...formData,
      [fieldName]: selectedValue,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === 'checkbox' ? checked : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (name === 'isLoaned') {
      if (checked === true) {
        setShowLoanRequest(true);
      } else {
        setShowLoanRequest(false);
      }
    }

    if (name === 'isExchanged') {
      if (checked === true) {
        setShowExchangeRequest(true);
      } else {
        setShowExchangeRequest(false);
      }
    }

    if (name === 'isFeatured') {
      if (checked === true) {
        setShowExtraFuture(true);
      } else {
        setShowExtraFuture(false);
      }
    }
  };

  const handleSave = async () => {

    // Loan Request

    let LoanDto = {};
    let FeatureDto = {};
    let ExchangeRequestDto = {};

    if (formData.isLoaned) {
      LoanDto = {
        amount: formData.amount,
        months: formData.months.label,
        customerId: formData.customerId.id,
        stockId: formData.stockId.id,
        loanBankId: formData.loanBankId.id
      };
    };

    if (formData.isFeatured) {
      FeatureDto = {
        aksesuar: parseFloat(extraFeatureForm.aksesuar),
        tb: parseFloat(extraFeatureForm.tb),
        miniRepair: parseFloat(extraFeatureForm.miniRepair),
        description: extraFeatureForm.description
      };
    };

    if (formData.isExchanged) {
      ExchangeRequestDto = {
        brand: exchangeForm.brand,
        model: exchangeForm.model,
        year: exchangeForm.year,
        color: exchangeForm.color,
        package: exchangeForm.package,
        gearType: exchangeForm.gearType,
        fuelType: exchangeForm.fuelType,
        km: exchangeForm.km,
        damage: exchangeForm.damage,
        note: exchangeForm.note,
        customerExpectation: parseFloat(exchangeForm.customerExpectation),
        saleId: formData.customerId.id,
      };
    };

    const CreateSaleReqDto = {
      reservedUntil: formData.reservedUntil,
      deposit: formData.deposit,
      customerId: formData.customerId.id,
      stockId: formData.stockId.id,
      noOtv: formData.otvCheck,
      SalePrice: parseFloat(formData.salePrice),
      isDocumented: false,
      isInsuranced: formData.isInsuranced,
      isLoaned: formData.isLoaned,
      loanDto: (formData.isExchanged && LoanDto) ? LoanDto : null,
      isExchanged: formData.isExchanged,
      exchangeRequestDto: (formData.isExchanged && ExchangeRequestDto) ? ExchangeRequestDto : null,
      isFeatured: formData.isFeatured,
      featureDto: (formData.isFeatured && FeatureDto) ? FeatureDto : null
      //saleNote
    };

    try {
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
      const response = await fetch(`${baseUrl}/api/Sale/CreateInPresaleSale`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(CreateSaleReqDto),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
        return;
      }

      const data = await response.json();

      setSaleId(data.dataId);

      toast.current.show({ severity: "success", summary: "Kayıt Başarılı", detail: data.message });

      setShowPaymentScreen(true);

    } catch (error) {
      toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
    }

  };

  const handleCancel = () => {
    setFormData({
      reservedUntil: null,
      deposit: null,
      isDocumented: false,
      isExchanged: false,
      isFeatured: false,
      isLoaned: false,
      saleNote: null,
      customerId: null,
      stockId: null,
      discount: null,
      stockSalePrice: null,
      lastSalePrice: null,
      totalSalePrice: null,
      otvCheck: null,
      salePrice: null,
      basePrice: null,
      otvOptionsId: null,
      otv: null,
      kdv: null,
      mtv: null,
      plakaTescil: null,
      loanBankId: null,
      amount: null,
      months: null,
    });
    toast.current.show({ severity: 'info', className: 'text-dark', summary: 'İptal', detail: 'İşlem iptal edildi.' });
  }

  return (
    <>
      <Toast ref={toast} />

      {showPaymentScreen ? (<AddPaymentPlan totalPrice={formData.totalSalePrice} deposit={formData.deposit} saleId={saleId}/> ) : (
        <div className="customerstyles">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
          <h4>Bağlantı Satış Yap</h4>
        </div>
        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <Dropdown
              id="customer"
              name="customer"
              className="inputgap"
              value={formData.customerId}
              onChange={(e) => handleDropdownChange(e, 'customerId')}
              options={customerList}
              optionLabel="name"
              filter
              showClear
            />
            <label htmlFor="customer">Müşteri</label>
          </span>
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <Dropdown
              id="stock"
              name="stock"
              className="inputgap"
              value={formData.stockId}
              onChange={(e) => handleDropdownChange(e, 'stockId')}
              options={stockSasiList}
              optionLabel="sasiNumber"
              filter
              showClear
            />
            <label htmlFor="stock">Şasi Numarası</label>
          </span>
        </div>
        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <InputNumber
              className="inputgap"
              inputId="deposit"
              name="deposit"
              locale="tr-TR"
              value={formData.deposit}
              minFractionDigits={2}
              maxFractionDigits={5}
              onChange={(e) => handleDropdownChange(e, 'deposit')}
            />
            <label htmlFor="deposit">Alınan Kapora</label>
          </span>
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <Calendar
              id="reservedUntil"
              name="reservedUntil"
              className="inputgap"
              value={formData.reservedUntil}
              minDate={new Date()}
              onChange={(e) => handleChange(e)}
              dateFormat="dd/mm/yy"
            />
            <label htmlFor="reservedUntil">Bağlantı Süresi</label>
          </span>
        </div>
        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <InputNumber
              className="inputgap"
              inputId="salePrice"
              name="salePrice"
              locale="tr-TR"
              value={formData.salePrice}
              minFractionDigits={2}
              maxFractionDigits={2}
              onChange={(e) => handleDropdownChange(e, 'salePrice')}
            />
            <label htmlFor="salePrice">Satış Fiyatı</label>
          </span>
          {formData.otvCheck && (
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
            <InputNumber
              className="inputgap"
              inputId="raporluSalePrice"
              name="raporluSalePrice"
              locale="tr-TR"
              value={formData.raporluSalePrice}
              minFractionDigits={2}
              maxFractionDigits={2}
              onChange={(e) => handleDropdownChange(e, 'raporluSalePrice')}
            />
            <label htmlFor="raporluSalePrice">Raporlu Satış Fiyatı</label>
          </span>
          )}
        </div>
        {/* Kredi Teklifi Part */}
        <div className="d-block d-lg-flex col-12 col-xxl-8 mb-4">
          <span className="col-12 col-lg-6 ">
            <Checkbox
              id="isLoaned"
              name="isLoaned"
              className=" me-3"
              onChange={(e) => handleChange(e)}
              checked={formData.isLoaned}
            ></Checkbox>
            <label htmlFor="isLoaned">Kredi Teklifi</label>
          </span>
          <span className="col-12 col-lg-6 ">
            <Checkbox
              id="isInsuranced"
              name="isInsuranced"
              className=" me-3"
              onChange={(e) => handleChange(e)}
              checked={formData.isInsuranced}
            ></Checkbox>
            <label htmlFor="isLoaned">Trafik/Kasko Teklifi</label>
          </span>
        </div>
        {showLoanRequest && (
          <div className="col-12 col-xxl-8 border-2 border-top border-bottom border-success my-4">
            <div className="d-block d-lg-flex col-12">
              <span className="p-float-label col-12 col-lg-4 my-4">
                <Dropdown
                  id="loanBank"
                  name="loanBank"
                  className="inputgap"
                  value={formData.loanBankId}
                  onChange={(e) => handleDropdownChange(e, 'loanBankId')}
                  options={loanBankList}
                  optionLabel="name"
                  filter
                  showClear
                />
                <label htmlFor="loanBank">Banka</label>
              </span>
              <span className="p-float-label col-12 col-lg-4 my-4">
                <InputNumber
                  className="inputgap"
                  inputId="loanAmount"
                  name="loanAmount"
                  value={formData.amount}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  onChange={(e) => handleDropdownChange(e, 'amount')}
                />
                <label htmlFor="loanAmount">Kredi Miktarı</label>
              </span>
              <span className="p-float-label col-12 col-lg-4 my-4">
                <Dropdown
                  id="months"
                  name="months"
                  className="inputgap"
                  value={formData.months}
                  onChange={(e) => handleDropdownChange(e, 'months')}
                  options={monthList}
                  optionLabel="label"
                  filter
                  showClear
                />
                <label htmlFor="months">Vade</label>
              </span>
            </div>
          </div>
        )}

        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="col-12 col-lg-6 me-4 mb-4">
            <Checkbox
              id="isExchanged"
              name="isExchanged"
              className="me-3"
              onChange={(e) => handleChange(e)}
              checked={formData.isExchanged}
            ></Checkbox>
            <label htmlFor="isExchanged">Takas Teklifi</label>
          </span>
        </div>

        {showExchangeRequest && (
          <AddExchangeRequestDialog
            showDialog={showExchangeRequest}
            setShowDialog={setShowExchangeRequest}
            exchangeForm={exchangeForm}
            setExchangeForm={setExchangeForm}
          />
        )}

        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="col-12 col-lg-6 me-4 mb-4">
            <Checkbox
              id="isFeatured"
              name="isFeatured"
              className="me-3"
              onChange={(e) => handleChange(e)}
              checked={formData.isFeatured}
              disabled = {!formData.otvCheck}
            ></Checkbox>
            <label htmlFor="isFeatured">Ek Aksesuar</label>
          </span>
        </div>

        {showExtraFuture && <AddExtraFuture extraFeatureForm={extraFeatureForm} setExtraFeatureForm={setExtraFeatureForm} />}

        <SaleTaxInfo formData={formData} setFormData={setFormData} extraFeatureForm={extraFeatureForm} />

        {formData.stockId && <ListSaleCarData id={formData.stockId.id} />}
        {formData.customerId && (
          <ListSaleCustomerData id={formData.customerId} />
        )}
        <div className="d-flex gap-2">
          <Button className="rounded-3" severity="secondary" label="İptal" onClick={handleCancel} />
          <Button className="rounded-3" severity="success" label="Kaydet" onClick={handleSave} />
        </div>
      </div>
      )}
      
    </>
  );
};

export default AddReservedSale;
