import { useEffect, useRef, useState } from "react";
import "../../assets/styles/stock.scss";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { getAllModelCodesByBranchId, getAllModelNamesByModelCodeId } from '../services/modelService';
import { Toast } from 'primereact/toast';
import { getAllFuelTypes, getTaxValues } from '../services/systemService';
import CalculateTaxes from "../shared/CalculateTaxes";
import { getColorsByModelNameId } from "../services/stockService";
import Cookies from "js-cookie";
import * as XLSX from 'xlsx';
import { FileUpload } from 'primereact/fileupload';
import excel_icon from "../../assets/images/icons8-excel.svg";
import { Dialog } from 'primereact/dialog';
import { useLocation } from "react-router-dom";


const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};
const AddStock = () => {
    const toast = useRef(null);
    // update stok için state den id çeker.
    const location = useLocation();
    const id = location.state ? location.state.id : null;

    const [modelCodes, setModelCodes] = useState([]);
    const [modelNames, setModelNames] = useState([]);
    const [colors, setColors] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [visible, setVisible] = useState(false);

    //Kayıt atılacak stok datası
    const [formData, setFormData] = useState({
        id: id,
        modelCodeId: null,
        modelNameId: null,
        colorId: null,
        fuelTypeId: null,
        year: null,
        sasiNumber: null,
        motorNumber: null,
        valor: null,
        campaign: null,
        fiktif: false,
        listPrice: null,
        basePrice: null,
        salePrice: null,
        otvOptionsId: null,
        otv: null,
        kdv: null,
        mtv: null,
        plakaTescil: null,
        priceWithOtv: 0,
        priceWithKdv: 0,
        priceWithOtvKdv: 0,
        netProfit: 0,
        totalPrice: null,
        isReserved: false,
    });

    // Excel datası
    const [excelData, setExcelData] = useState(null);

    const handleFileUpload = async (event) => {
        const file = event.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workBook = XLSX.read(data, { type: 'array' });

            const sheetName = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(workSheet, { raw: true });
            await setTheData(jsonData);

        };

        reader.readAsArrayBuffer(file);
    };

    const setTheData = async (data) => {
        return new Promise((resolve) => {
            setExcelData({ data }, () => {
                resolve();
            });
        });
    };

    useEffect(() => {

        if (excelData === null || excelData?.data.length === 0) return;
        handleAddExcelStockData();

    }, [excelData]);

    const handleAddExcelStockData = async () => {

        const updatedData = excelData?.data.map(item => ({
            modelCode: item["Model Kodu"],
            modelName: item["Model Adı"],
            color: item["Renk"],
            year: item["Model Yılı"],
            sasiNumber: item["Şasi Numarası"],
            motorNumber: item["Motor Numarası"],
            fuelType: item["Yakıt Tipi"],
            valor: item["Valör"],
            campaign: item["Kampanya"],
            fiktif: item["Fiktif mi?"],
            isReserved: item["Rezerve?"],
            listPrice: item["Kdvli Alış Fiyatı"],
            salePrice: item["Satış Fiyatı"],
            otv: item["ÖTV Seçenekleri"],
            kdv: item["KDV"],
            mtv: item["MTV"],
            plakaTescil: item["Plaka Tescil"]
        }));

        console.log(updatedData);

        try {
            const token = getToken();
            if (!token) {
                //TODO
                return;
            }

            const response = await fetch(`${baseUrl}/api/Stock/AddBulkStockData`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
                return;
            }

            const data = await response.json();

            if (data && data.length > 0) {

                const rewrittenData = data.map(item => ({
                    ["Model Kodu"] : item["modelCode"],
                    ["Model Adı"] : item["modelName"],
                    ["Renk"] : item["color"],
                    ["Model Yılı"] : item["year"],
                    ["Şasi Numarası"] : item["sasiNumber"],
                    ["Motor Numarası"] : item["motorNumber"],
                    ["Yakıt Tipi"] : item["fuelType"],
                    ["Valör"] : item["valor"],
                    ["Kampanya"] : item["campaign"],
                    ["Fiktif mi?"] : item["fiktif"],
                    ["Rezerve?"] : item["isReserved"],
                    ["Kdvli Alış Fiyatı"] : item["listPrice"],
                    ["Satış Fiyatı"] : item["salePrice"],
                    ["Ötv"] : item["otv"],
                    ["Kdv"] : item["kdv"],
                    ["Mtv"] : item["mtv"],
                    ["PlakaTescil"] : item["plakaTescil"]
                }));

                // Convert unsaved data to Excel
                const worksheet = XLSX.utils.json_to_sheet(rewrittenData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


                const wbBlob = await new Blob([XLSX.writeFile(workbook, "KaydedilmeyenStok.xlsx")]);
                const wbUrl = URL.createObjectURL(wbBlob);
                const a = document.createElement("a");
                a.href = wbUrl;
                a.download = "KaydedilmeyenStok.xlsx";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            toast.current.show({ severity: "success", summary: "Kayıt Başarılı", detail: data.message });

            setTheData([]);

        } catch (error) {
            toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
        }

    };

    useEffect(() => {

        getAllModelCodes();

    }, []);

    useEffect(() => {

        getAllModelNames();

    }, [formData.modelCodeId]);

    useEffect(() => {

        getAllColors();

    }, [formData.modelNameId]);

    async function getAllModelCodes() {
        const data = await getAllModelCodesByBranchId();

        if (data && data.message && data.isSuccess === false) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: data.message });
        }
        else {
            setModelCodes(data);
        }
    }

    async function getAllModelNames() {

        if (!formData.modelCodeId) return;

        const data = await getAllModelNamesByModelCodeId(formData.modelCodeId);

        if (data && data.message && data.isSuccess === false) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: data.message });
        }
        else {
            setModelNames(data);
        }
    }

    async function getAllColors() {

        if (!formData.modelNameId) return;
        const data = await getColorsByModelNameId(formData.modelNameId);

        if (data && data.message && data.isSuccess === false) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: data.message });
        }
        else {
            setColors(data);
        };
    }

    useEffect(() => {
        async function fetchData() {
            const data = await getTaxValues();

            if (data && data.message && data.isSuccess === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: data.message });
            }
            else {
                handleSetTaxes(data);
            };
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const data = await getAllFuelTypes();

            if (data && data.message && data.isSuccess === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: data.message });
            }
            else {
                setFuelTypes(data);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getToken();
                if (!token) {
                    //TODO
                    return;
                }

                const response = await fetch(`${baseUrl}/api/Stock/GetUpdateStockInfoById`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify({ id: id }),
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();

                setFormData(prevState => ({
                    ...prevState,
                    modelCodeId: data.modelCodeId,
                    modelNameId: data.modelNameId,
                    colorId: data.colorId,
                    fuelTypeId: data.fuelTypeId,
                    year: data.year,
                    sasiNumber: data.sasiNo,
                    motorNumber: data.motorNo,
                    valor: data.valor,
                    fiktif: data.fiktif,
                    listPrice: data.listPrice,
                    basePrice: data.basePrice,
                    salePrice: data.salePrice,
                    otv: data.otv,
                    otvOptionsId: data.otvId,
                    kdv: data.kdv,
                    mtv: data.mtv,
                    plakaTescil: data.plakaTescil,
                    totalPrice: data.totalPrice,
                    campaign: data.campaign,
                    isReserved: data.isReserved
                }));

                setFormData(prevState => ({
                    ...prevState,
                    colorId: data.colorId,
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error
            }
        };

        if (id) {
            fetchData();
        }
    }, [id]);

    const handleSetTaxes = (taxData) => {
        setFormData(prevData => ({
            ...prevData,
            kdv: taxData.kdv,
            mtv: taxData.mtv,
            plakaTescil: taxData.plakaTescil
        }));
    };

    const handleDropdownChange = (e, fieldName) => {
        const selectedValue = e.value;

        setFormData({
            ...formData,
            [fieldName]: selectedValue
        });

        if (fieldName === "modelCodeId") getAllModelNamesByModelCodeId();
        if (fieldName === "modelNameId") getColorsByModelNameId();
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        const newValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue
        });
    };

    const handleSave = async () => {

        const requiredFields = [
            { id: 'modelCodeId', label: 'Model Kodu' },
            { id: 'modelNameId', label: 'Model Adı' },
            { id: 'colorId', label: 'Renk' },
            { id: 'fuelTypeId', label: 'Yakıt Tipi' },
            { id: 'year', label: 'Model Yılı' },
            { id: 'sasiNumber', label: 'Şasi Numarası' },
            { id: 'motorNumber', label: 'Motor Numarası' },
            { id: 'valor', label: 'Valör' },
            { id: 'listPrice', label: 'KDVli Alış Fiyatı' },
            { id: 'salePrice', label: 'Satış Fiyatı' },
            { id: 'otvOptionsId', label: 'ÖTV Seçenekleri' }
        ];

        const emptyFields = requiredFields.filter((field) => !formData[field.id]);

        if (emptyFields.length > 0) {
            const emptyFieldNames = emptyFields
                .map((field) => field.label)
                .join(', ');
            toast.current.show({
                severity: 'warn',
                summary: 'Uyarı',
                detail: `Lütfen ${emptyFieldNames} alanlarını doldurunuz.`,
                life: 8000,
            });
            return;
        }

        if (formData.id == null) {
            const AddStockReqDto = {
                modelCodeId: formData.modelCodeId,
                modelNameId: formData.modelNameId,
                colorId: formData.colorId,
                fuelTypeId: formData.fuelTypeId,
                year: formData.year,
                sasiNumber: formData.sasiNumber,
                motorNumber: formData.motorNumber,
                valor: formData.valor,
                listPrice: formData.listPrice,
                basePrice: formData.basePrice,
                salePrice: formData.salePrice,
                campaign: formData.campaign,
                fiktif: formData.fiktif,
                otvOptionsId: formData.otvOptionsId,
                otv: formData.otv,
                kdv: formData.kdv,
                mtv: formData.mtv,
                plakaTescil: formData.plakaTescil,
                priceWithKdv: 0,
                priceWithOtv: 0,
                priceWithOtvKdv: 0,
                totalPrice: formData.totalPrice,
                isReserved: formData.isReserved
            }

            try {
                const token = getToken();
                if (!token) {
                    //TODO
                    return;
                }

                const response = await fetch(`${baseUrl}/api/Stock/AddStock`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify(AddStockReqDto),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
                    return;
                }

                const data = await response.json();

                toast.current.show({ severity: "success", summary: "Kayıt Başarılı", detail: data.message });
                setFormData({
                    ...clearFormData,
                });

            } catch (error) {
                toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
            }
        }
        else {
            const UpdateStockReqDto = {
                id: formData.id,
                modelCodeId: formData.modelCodeId,
                modelNameId: formData.modelNameId,
                colorId: formData.colorId,
                fuelTypeId: formData.fuelTypeId,
                year: formData.year,
                sasiNumber: formData.sasiNumber,
                motorNumber: formData.motorNumber,
                valor: formData.valor,
                listPrice: formData.listPrice,
                basePrice: formData.basePrice,
                salePrice: formData.salePrice,
                campaign: parseFloat(formData.campaign),
                fiktif: formData.fiktif,
                otvOptionsId: formData.otvOptionsId,
                otv: formData.otv,
                kdv: formData.kdv,
                mtv: formData.mtv,
                plakaTescil: formData.plakaTescil,
                priceWithKdv: 0,
                priceWithOtv: 0,
                priceWithOtvKdv: 0,
                totalPrice: formData.totalPrice,
                isReserved: formData.isReserved
            }

            try {
                const token = getToken();
                if (!token) {
                    //TODO
                    return;
                }

                const response = await fetch(`${baseUrl}/api/Stock/UpdateStock`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                    body: JSON.stringify(UpdateStockReqDto),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
                    return;
                }

                const data = await response.json();

                toast.current.show({ severity: "success", summary: "Kayıt Başarılı", detail: data.message });
                setFormData({
                    ...clearFormData,
                });

            } catch (error) {
                toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
            }
        }


    };

    // İptal butonu Emin misiniz? Dialog
    const handleCancel = () => {
        setVisible(true);
    };
    // İptal butonu için onaylama "Evet" butonu
    const confirmCancel = () => {
        setVisible(false);
        // Tüm inputların ilk haline dönmesi
        setFormData({
            ...clearFormData,
        });
        toast.current.show({ severity: 'info', className: 'text-dark', summary: 'İptal', detail: 'İşlem iptal edildi.' });
    };

    const clearFormData = {
        modelCodeId: null,
        modelNameId: null,
        colorId: null,
        fuelTypeId: null,
        year: "",
        sasiNumber: "",
        motorNumber: "",
        valor: "",
        campaign: "",
        fiktif: false,
        listPrice: null,
        basePrice: null,
        salePrice: null,
        otvOptionsId: null,
        otv: null,
        kdv: formData.kdv,
        mtv: formData.mtv,
        plakaTescil: formData.plakaTescil,
        priceWithOtv: 0,
        priceWithKdv: 0,
        priceWithOtvKdv: 0,
        netProfit: 0,
        totalPrice: null,
        isReserved: false,
    };

    // Dialog için footer butonları
    const cancelFooter = (
        <div>
            <Button
                label="Hayır"
                icon="pi pi-times"
                onClick={() => setVisible(false)}
                className="j-button bg-secondary text-light"
            />
            <Button
                label="Evet"
                icon="pi pi-check"
                onClick={confirmCancel}
                autoFocus
                className="j-button bg-green ms-2 text-light"
            />
        </div>
    );

    return (
        <>
            <Toast ref={toast} />
            <div>
                <div className="stockstyles">
                    <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12">
                        <h4>Araç Stok Ekle</h4>
                        <FileUpload
                            mode="basic"
                            chooseOptions={{ icon: " " }}
                            chooseLabel={<span><img src={excel_icon} alt="Excel icon" className="pe-2" /> Toplu Stok Ekle</span>}
                            accept=".xlsx, .xls"
                            onSelect={handleFileUpload}
                        />
                    </div>
                    <div className="d-block d-lg-flex col-12">

                        <span className="p-float-label col-12 col-lg-4 mb-4">
                            <Dropdown
                                className='inputgap'
                                id="modelCode"
                                optionLabel="name"
                                optionValue="id"
                                value={formData.modelCodeId}
                                options={modelCodes}
                                onChange={(e) => handleDropdownChange(e, "modelCodeId")}
                                filter
                                showClear
                            />
                            <label htmlFor="brand">Model Kodu</label>
                        </span>
                        <span className="p-float-label col-12 col-lg-4 mb-4">
                            <Dropdown
                                className='inputgap'
                                id="modelName"
                                optionLabel="name"
                                optionValue="id"
                                value={formData.modelNameId}
                                options={modelNames}
                                onChange={(e) => handleDropdownChange(e, "modelNameId")}
                                filter
                                showClear
                            />
                            <label htmlFor="brand">Model adı</label>
                        </span>
                        <span className="p-float-label col-12 col-lg-4 mb-4">
                            <Dropdown
                                className='inputgap'
                                id="color"
                                optionLabel="name"
                                optionValue="id"
                                value={formData.colorId}
                                options={colors}
                                onChange={(e) => handleDropdownChange(e, "colorId")}
                                filter
                                showClear
                            />
                            <label htmlFor="brand">Renk</label>
                        </span>
                    </div>
                    <div className='d-block d-lg-flex col-12'>
                        <span className='p-float-label col-12 col-lg-4 mb-4'>
                            <InputText
                                id="year"
                                name="year"
                                className="inputgap"
                                value={formData.year}
                                onChange={handleChange} />
                            <label htmlFor="year">Model Yılı</label>
                        </span>
                        <span className='p-float-label col-12 col-lg-4 mb-4'>
                            <InputText
                                id="sasiNumber"
                                name="sasiNumber"
                                className="inputgap"
                                value={formData.sasiNumber}
                                onChange={handleChange}
                                maxLength={17}
                            />
                            <label htmlFor="sasiNumber">Şasi Numarası</label>
                        </span>
                        <span className='p-float-label col-12 col-lg-4 mb-4'>
                            <InputText
                                id="motorNumber"
                                name="motorNumber"
                                className="inputgap"
                                value={formData.motorNumber}
                                maxLength={17}
                                onChange={handleChange} />
                            <label htmlFor="engineNumber">Motor Numarası</label>
                        </span>
                    </div>
                    <div className='d-block d-lg-flex col-12'>
                        <span className='p-float-label col-12 col-lg-4 mb-4'>
                            <Dropdown
                                className='inputgap'
                                id="fuelType"
                                optionLabel="name"
                                optionValue="id"
                                value={formData.fuelTypeId}
                                options={fuelTypes}
                                onChange={(e) => handleDropdownChange(e, "fuelTypeId")}
                                filter
                                showClear
                            />
                            <label htmlFor="fuelType">Yakıt Tipi</label>
                        </span>
                        <span className='p-float-label col-12 col-lg-4 mb-4'>
                            <InputText
                                id="valor"
                                name="valor"
                                className="inputgap"
                                value={formData.valor ? formData.valor : "0"}
                                onChange={handleChange} />
                            <label htmlFor="valor">Valör</label>
                        </span>
                        <span className='p-float-label col-12 col-lg-4 mb-4'>
                            <InputText
                                id="campaign"
                                name="campaign"
                                className="inputgap"
                                value={formData.campaign ? formData.campaign : "0"}
                                onChange={handleChange} />
                            <label htmlFor="offer">Kampanya</label>
                        </span>
                    </div>
                    <div className="row col-12 col-lg-6 d-flex align-items-center">
                        <label htmlFor="fiktif" className="col-12 col-lg-6 mb-3">
                            Fiktif mi?
                            <input
                                id="fiktif"
                                type="checkbox"
                                className="ms-3"
                                name="fiktif"
                                checked={formData.fiktif}
                                onChange={handleChange}
                            />
                        </label>
                        <label htmlFor="isReserved" className="col-12 col-lg-6 mb-3">
                            Rezerve?
                            <input
                                id="isReserved"
                                type="checkbox"
                                className="ms-3"
                                name="isReserved"
                                checked={formData.isReserved}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <CalculateTaxes formData={formData} setFormData={setFormData} />
                    <div className="d-flex gap-2">
                        <Button className="rounded-3" severity="secondary" label="İptal" onClick={handleCancel} />
                        <Button className="rounded-3" severity="success" label="Kaydet" onClick={handleSave} />
                    </div>
                </div>
                <Dialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    header="İşlemi İptal Et"
                    footer={cancelFooter}
                    modal
                    style={{ width: '450px' }}
                    className='text-center'
                >
                    <div className="confirmation-content d-flex align-items-center">
                        <i
                            className="pi pi-exclamation-triangle me-3 text-danger"
                            style={{ fontSize: '3rem' }}
                        />
                        <span>İşlemi iptal etmek istediğinize emin misiniz?</span>
                    </div>
                </Dialog>

            </div >
        </>

    )
}

export default AddStock;
