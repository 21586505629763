import React, { useEffect, useRef, useState } from 'react';
import '../../assets/styles/_customer.scss';
import { InputSwitch } from 'primereact/inputswitch';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import AddIndividualCustomer from './AddIndividualCustomer';
import { getAllMarketingChanels } from '../services/systemService';
import { Toast } from 'primereact/toast';
import Cookies from 'js-cookie';
import AddTestDrive from './AddTestDrive';
import AddConfirmKvkkOtp from './AddConfirmKvkkOtp';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from "react-router-dom";

const getUserInfo = () => {
  return JSON.parse(Cookies.get('userInfo'));
};

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const getBranch = () => {
  return JSON.parse(Cookies.get('userInfo')).branchId;
};

const AddCorporateCustomer = ({ formData, setFormData, resendKvkkBody }) => {
  const [showOtpEnter, setShowOtpEnter] = useState(false);
  const [showTestDrive, setShowTestDrive] = useState(false);

  const toast = useRef(null);
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState();
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState();
  const [marketingChanelList, setMarketingChanelList] = useState([]);

  const [visible, setVisible] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  // Get the user data from Cookies.
  useEffect(() => {
    const userData = getUserInfo();

    if (userData !== null) setUserInfo(userData);
  }, []);

  // Cinsiyet
  const genderOptions = [{ name: 'Erkek' }, { name: 'Kadın' }];

  // Etkileşim alanlarını getiren API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllMarketingChanels();

        if (response === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail:
              'Etkileşim kanalları yüklenemedi. Lütfen sayfayı yenileyiniz.',
          });
        }

        setMarketingChanelList(response);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail:
            'Etkileşim kanalları yüklenemedi. Lütfen sayfayı yenileyiniz.',
        });
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      setPhoneNumber(value);

      const numericValue = value.replace(/\D/g, '');

      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else if (name === 'personalPhoneNumber') {
      setPersonalPhoneNumber(value);

      const numericValue = value.replace(/\D/g, '');

      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else if (name === 'marketingChanel') {
      setFormData((prevState) => ({
        ...prevState,
        marketingChanel: value,
        marketingChanelId: value.id,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // handleCheckboxs
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const updatedFormData = { ...formData };

    updatedFormData[name] = checked;
    updatedFormData['customerType'] = checked ? 200 : 2;

    setFormData(updatedFormData);
  };

  // Kayıt işlemi
  const handleSave = async () => {
    const requiredFields = [
      { id: 'idNumber', label: 'TC Kimlik Numarası' },
      { id: 'phoneNumber', label: 'Telefon Numarası' },
      { id: 'name', label: 'Ad' },
      { id: 'surname', label: 'Soyad' },
      { id: 'birthday', label: 'Doğum Tarihi' },
      { id: 'taxNumber', label: 'Vergi Numarası' },
      { id: 'taxOffice', label: 'Vergi Dairesi' },
      { id: 'job', label: 'Meslek' },
      { id: 'adress', label: 'Adres' },
      { id: 'gender', label: 'Cinsiyet' },
      { id: 'marketingChanel', label: 'Etkileşim' },
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field.id]);

    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields
        .map((field) => field.label)
        .join(', ');
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: `Lütfen ${emptyFieldNames} alanlarını doldurunuz.`,
      });
      return;
    }

    // Müşteri Kvkk onayı olmadan kaydediliyorsa gideceği request
    if (formData.noKVKK) {
      const CreateCustomerDto = {
        idNumber: formData.idNumber, // Access idNumber from customerData
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        personalEmail: formData.personalEmail,
        personalPhoneNumber: formData.personalPhoneNumber,
        birthday: formData.birthday,
        job: formData.job,
        adress: formData.adress,
        gender: formData.gender.name,
        taxNumber: formData.taxNumber,
        taxOffice: formData.taxOffice,
        company: formData.company,
        specialNote: formData.specialNote,
        marketingChanelId: formData.marketingChanelId,
        clientType: formData.customerType,
        branchId: getBranch(),
      };

      try {
        const token = getToken();
        if (!token) {
          //TODO
          return;
        }

        const response = await fetch(
          `${baseUrl}/api/Customer/AddCustomerNoKvkk`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(CreateCustomerDto),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: errorData.message,
          });
          return;
        }

        const data = await response.json();

        setFormData({
          ...formData,
          customerId: data.dataId,
        });

        toast.current.show({
          severity: 'success',
          summary: 'Kayıt Başarılı',
          detail: data.message,
        });

        // Kayıt Başarılı ise input alanlarını sıfırla
        setFormData({
          name: '',
          surname: '',
          company: '',
          job: '',
          idNumber: '',
          taxNumber: '',
          taxOffice: '',
          phoneNumber: '',
          email: '',
          birthday: '',
          personalPhoneNumber: '',
          personalEmail: '',
          adress: '',
        });
        setPersonalPhoneNumber('');
        setPhoneNumber('');

        if (formData.testDrive) {
          setShowTestDrive(true);
        } else {
          // Test Sürüşü pasifse Kayıttan sonra müşteri detay sayfasına yönlendirme
          setTimeout(() => {
            navigate('/customer/view', { state: { id: data.dataId, type: "Kurumsal" } });
            }, 3500);
        }
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
        });
      }
    }
    // Müşteri KVKK onayı verecekse gideceği request
    else {
      // Daha önce kvkk mesajı gitmiş olan müşteriye tekrar kayıt oluşturmak için gideceği request
      if (
        resendKvkkBody &&
        resendKvkkBody.name !== undefined &&
        resendKvkkBody.surname !== undefined &&
        resendKvkkBody.phoneNumber !== undefined
      ) {
        const fetchData = async () => {
          try {
            const token = getToken();

            if (!token) {
              //TODO
              return;
            }
            const response = await fetch(
              `${baseUrl}/api/Customer/ResendKvkkSmsToCustomer`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ phoneNumber: formData.phoneNumber }),
              }
            );
            if (!response.ok) {
              toast.current.show({
                severity: 'error',
                summary: 'Hata',
                detail:
                  'Müşteriye Kvkk onayı mesajı gönderilemedi. Lütfen tekrar deneyiniz.',
              });
              return;
            }

            toast.current.show({
              severity: 'success',
              summary: 'Başarılı',
              detail: response.message
                ? response.message
                : 'Müşteriye 6 haneli kod başarıyla gönderildi.',
            });
            setShowOtpEnter(true);
          } catch (error) {
            toast.current.show({
              severity: 'error',
              summary: 'Hata',
              detail:
                'Müşteriye Kvkk onayı mesajı gönderilemedi. Lütfen tekrar deneyiniz.',
            });
          }
        };

        fetchData();
      }

      // Yeni Kvkk Kaydı oluşturuluyorsa
      else {
        const SendKvkkSmsToCustomerReqDto = {
          idNumber: formData.idNumber,
          name: formData.name,
          surname: formData.surname,
          phone: formData.phoneNumber,
          branchId: getBranch(),
        };
        try {
          const token = getToken();
          if (!token) {
            //TODO
            return;
          }

          const response = await fetch(
            `${baseUrl}/api/Customer/SendKvkkSmsToCustomer`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(SendKvkkSmsToCustomerReqDto),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            toast.current.show({
              severity: 'error',
              summary: 'Hata',
              detail: errorData.message,
            });
            return;
          }

          const data = await response.json();

          toast.current.show({
            severity: 'success',
            summary: 'Kayıt Başarılı',
            detail: data.message,
          });

          setShowOtpEnter(true);
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
          });
        }
      }
    }
  };

  // İptal butonu Emin misiniz? Dialog
  const handleCancel = () => {
    setVisible(true);
  };
  // İptal butonu için onaylama "Evet" butonu
  const confirmCancel = () => {
    setVisible(false);
    setFormData({
      name: '',
      surname: '',
      company: '',
      job: '',
      idNumber: '',
      taxNumber: '',
      taxOffice: '',
      phoneNumber: '',
      email: '',
      birthday: '',
      personalPhoneNumber: '',
      personalEmail: '',
      adress: '',
    });
    setPersonalPhoneNumber('');
    setPhoneNumber('');
  };
  // Dialog için footer butonları
  const cancelFooter = (
    <div>
      <Button
        label="Hayır"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="j-button bg-secondary text-light"
      />
      <Button
        label="Evet"
        icon="pi pi-check"
        onClick={confirmCancel}
        autoFocus
        className="j-button bg-green ms-2 text-light"
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      {showTestDrive ? (
        <AddTestDrive customerData={formData} />
      ) : showOtpEnter ? (
        <AddConfirmKvkkOtp customerData={formData} />
      ) : checked ? (
        <div className="customerstyles">
          <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
            <h4>Kurumsal Müşteri Kayıt Formu</h4>
            <div className="d-flex align-items-center gap-1">
              <InputSwitch
                id="switch"
                checked={checked}
                onChange={(e) => setChecked(e.value)}
              />
              <label htmlFor="switch" className="small">
                Bireysel
              </label>
            </div>
          </div>
          {/* Kurumsal kayıt inputlar */}
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="name"
                name="name"
                className="inputgap"
                value={formData.name}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="nameCorporate">Ad</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="surname"
                name="surname"
                className="inputgap"
                value={formData.surname}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="surnameCorporate">Soyad</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="company"
                name="company"
                className="inputgap"
                value={formData.company}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="compNameCorporate">Şirket Adı</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="job"
                name="job"
                className="inputgap"
                value={formData.job}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="positionCorporate">Görevi</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputMask
                id="idNumber"
                name="idNumber"
                mask="99999999999"
                className="inputgap"
                value={formData.idNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="idNumberCorporate">TC Kimlik Numarası</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputMask
                id="taxNumber"
                name="taxNumber"
                mask="99999999999"
                className="inputgap"
                value={formData.taxNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="taxNumber">Vergi Numarası</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="taxOffice"
                name="taxOffice"
                className="inputgap"
                value={formData.taxOffice}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="taxOffice">Vergi Dairesi</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputMask
                id="phoneNumber"
                name="phoneNumber"
                mask="(999) 999 99 99"
                className="inputgap"
                value={phoneNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="phoneNumber">
                Şirket Telefon Numarası
              </label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="email"
                name="email"
                className="inputgap"
                value={formData.email}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="emailCorporate">Şirket E-Mail Adresi</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <Calendar
                id="birthday"
                name="birthday"
                className="inputgap"
                value={formData.birthday}
                onChange={(e) => handleChange(e)}
                dateFormat="dd/mm/yy"
              />
              <label htmlFor="birthdayCorporate">Doğum Tarihi</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputMask
                id="personalPhoneNumber"
                name="personalPhoneNumber"
                mask="(999) 999 99 99"
                className="inputgap"
                value={personalPhoneNumber}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="personalPhoneNumber">
                Şahsi Telefon Numarası
              </label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="personalEmail"
                name="personalEmail"
                className="inputgap"
                value={formData.personalEmail}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="emailPersonal">Şahsi Mail Adresi</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <Dropdown
                id="gender"
                name="gender"
                className="inputgap"
                value={formData.gender}
                onChange={(e) => handleChange(e)}
                options={genderOptions}
                optionLabel="name"
              />
              <label htmlFor="genderCorporate">Cinsiyet</label>
            </span>
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <Dropdown
                id="marketingChanel"
                name="marketingChanel"
                className="inputgap"
                value={formData.marketingChanel}
                onChange={(e) => handleChange(e)}
                options={marketingChanelList} // Pass options here
                optionLabel="name"
              />
              <label htmlFor="marketingChanel">Etkileşim</label>
            </span>
          </div>
          <div className="d-block d-lg-flex col-12 col-xxl-8">
            <span className="p-float-label col-12 col-lg-6 me-4 mb-4">
              <InputText
                id="adress"
                name="adress"
                className="inputgap"
                value={formData.adress}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="adress">Adres</label>
            </span>
          </div>

          {/* checkbox */}
          <div className="d-flex flex-column gap-2 mb-4">
            <div>
              <input
                type="checkbox"
                id="testDrive"
                name="testDrive"
                className="me-3"
                checked={formData.testDrive}
                onChange={handleCheckbox}
              />
              <label htmlFor="testDrive">Test Sürüşü</label>
            </div>
            {userInfo && (userInfo.isManager || userInfo.isAdmin) && (
              <div>
                <input
                  type="checkbox"
                  id="noKVKK"
                  name="noKVKK"
                  className="me-3"
                  checked={formData.noKVKK}
                  onChange={handleCheckbox}
                />
                <label htmlFor="noKVKK">KVKK Onaysız</label>
              </div>
            )}
          </div>

          {/* Butonlar */}
          <Button
            label="İptal"
            severity="secondary"
            className="rounded-3 me-2"
            onClick={handleCancel}
          />
          <Button
            label="Sonraki Adım"
            severity="success"
            className="rounded-3"
            onClick={handleSave}
          />
          <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header="İşlemi İptal Et"
            footer={cancelFooter}
            modal
            style={{ width: '450px' }}
            className="text-center"
          >
            <div className="confirmation-content d-flex align-items-center">
              <i
                className="pi pi-exclamation-triangle me-3 text-danger"
                style={{ fontSize: '3rem' }}
              />
              <span>Girilen bilgiler sıfırlanacak, Emin misiniz?</span>
            </div>
          </Dialog>
        </div>
      ) : (
        <AddIndividualCustomer />
      )}
    </>
  );
};

export default AddCorporateCustomer;
