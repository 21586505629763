import React, { useRef, useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown'; // Import Dropdown component
import {
  addPaymentBank,
  addPaymentType,
  updatePaymentBank,
  updatePaymentType,
} from '../services/paymentService';
import {
  addFuelType,
  updateFuelType,
  addMarketingChanel,
  updateMarketingChanel,
} from '../services/systemService';
import { Toast } from 'primereact/toast';
import {
  addModelCode,
  addModelName,
  getAllModelCodesByBranchId,
  updateModelCode,
  updateModelName,
} from '../services/modelService';
import Cookies from 'js-cookie';
import { addColor, updateColor } from '../services/colorService';
import { Checkbox } from 'primereact/checkbox';
import { addOtvOptions, updateOtvOptions } from '../services/taxServices';

const getBranch = () => {
  return JSON.parse(Cookies.get('userInfo')).branchId;
};

const SharedNameDialog = ({
  showDialog,
  setShowDialog,
  id,
  setSelectedId,
  header,
  setHeader,
  value,
  setValue,
  apiOption,
  setApiOption,
}) => {
  const toast = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [modelCodeData, setModelCodeData] = useState([]);

  const [otvTypeCheck, setOtvTypeCheck] = useState(false);
  const [bank, setBank] = useState(false);
  const [loan, setLoan] = useState(false);
  const [exchange, setExchange] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllModelCodesByBranchId();
        setModelCodeData(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Ödeme banka listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCheckbox = (e, fieldName) => {
    const { checked } = e.target;

    if (fieldName === 'otv') setOtvTypeCheck(checked);
    else if (fieldName === 'bank') {
      setBank(checked);
      setLoan(false);
      setExchange(false);
    } else if (fieldName === 'loan') {
      setLoan(checked);
      setBank(false);
      setExchange(false);
    } else if (fieldName === 'exchange') {
      setExchange(checked);
      setBank(false);
      setLoan(false);
    }
  };

  const handleSave = async () => {
    setShowDialog(false);
    try {
      let branchId = getBranch();
      let result = '';

      if (apiOption === 'paymentBank') {
        if (id === null) {
          result = await addPaymentBank(inputValue);
        } else {
          result = await updatePaymentBank(id, inputValue);
        }
      } else if (apiOption === 'paymentType') {
        if (id === null) {
          const requestBody = {
            name: inputValue,
            bank: bank,
            loan: loan,
            exchange: exchange,
          };
          result = await addPaymentType(requestBody);
        } else {
          result = await updatePaymentType(id, inputValue);
        }
      } else if (apiOption === 'color') {
        if (id === null) {
          result = await addColor(inputValue);
        } else {
          result = await updateColor(id, inputValue);
        }
      } else if (apiOption === 'fuelType') {
        if (id === null) {
          result = await addFuelType(inputValue);
        } else {
          result = await updateFuelType(id, inputValue);
        }
      } else if (apiOption === 'modelCode') {
        if (id === null) {
          result = await addModelCode(branchId, inputValue);
        } else {
          result = await updateModelCode(id, inputValue);
        }
      } else if (apiOption === 'modelName') {
        if (id === null) {
          result = await addModelName(selectedOption, inputValue);
        } else {
          result = await updateModelName(id, inputValue);
        }
      } else if (apiOption === 'modelCode') {
        if (id === null) {
          result = await addModelCode(branchId, inputValue);
        } else {
          result = await updateModelCode(id, inputValue);
        }
      } else if (apiOption === 'modelName') {
        if (id === null) {
          result = await addModelName(selectedOption, inputValue);
        } else {
          result = await updateModelName(id, inputValue);
        }
      } else if (apiOption === 'marketingChanel') {
        if (id === null) {
          result = await addMarketingChanel(inputValue);
        } else {
          result = await updateMarketingChanel(id, inputValue);
        }
      } else if (apiOption === 'otvOptions') {
        if (id === null || id === undefined) {
          const type = otvTypeCheck == true ? 1 : 0;
          result = await addOtvOptions(inputValue, type);
        }
      }

      if (result && result.isSuccess) {
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: result.message,
        });
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Hata',
          detail: result.message
            ? result.message
            : 'Kayıt alınamadı. Tekrar deneyiniz.',
        });
      }
      handleHide();
    } catch {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bir hata oluştu. Tekrar deneyiniz.',
      });
    }
  }

  const handleHide = () => {
    setSelectedOption(null);
    setSelectedId(null);
    setHeader(null);
    setValue(null);
    setInputValue('');
    setApiOption(null);
    setShowDialog(false);
  };

  const renderDropdown = () => {
    if (apiOption === 'modelName' && id === null) {
      const dropdownOptions = modelCodeData.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return (
        <Dropdown
          value={selectedOption}
          options={dropdownOptions}
          onChange={(e) => setSelectedOption(e.value)}
          placeholder="Model Kodu Seçiniz"
          filter
          showClear
        />
      );
    } else if (apiOption === 'otvOptions') {
      return (
        <div>
          <input
            type="checkbox"
            id="otvTypeCheck"
            name="otvTypeCheck"
            className="me-3 mb-4"
            checked={otvTypeCheck}
            onChange={(e) => handleCheckbox(e, 'otv')}
          />
          <label htmlFor="otvTypeCheck">Raporlu</label>
        </div>
      );
    } else if (apiOption === 'paymentType' && id === null) {
      return (
        <div className="d-flex flex-column gap-2 mb-3">
          <div className='d-flex align-items-center'>
            <input
              label="Banka"
              type="checkbox"
              id="bank"
              name="bank"
              className="me-3"
              checked={bank}
              onChange={(e) => handleCheckbox(e, 'bank')}
            />
            <label htmlFor="bank">Banka</label>
          </div>

          <div className='d-flex align-items-center'>
            <input
              label="Kredi"
              type="checkbox"
              id="loan"
              name="loan"
              className="me-3"
              checked={loan}
              onChange={(e) => handleCheckbox(e, 'loan')}
            />
            <label htmlFor="loan">Kredi</label>
          </div>
          <div className='d-flex align-items-center'>
            <input
              label="Takas"
              type="checkbox"
              id="exchange"
              name="exchange"
              className="me-3"
              checked={exchange}
              onChange={(e) => handleCheckbox(e, 'exchange')}
            />
            <label htmlFor="exchange">Takas</label>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="dialog"
        visible={showDialog}
        onHide={handleHide}
        header={header}
        modal
      >
        {renderDropdown()}
        <InputText value={inputValue} onChange={handleChange} />
        <div className="mt-3 text-end">
          <Button
            className="rounded-3"
            severity="success"
            label="Ekle"
            onClick={handleSave}
            disabled={!inputValue}
          />
        </div>
      </Dialog>
    </>
  );
};

export default SharedNameDialog;
