import Cookies from 'js-cookie';


const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const responseDto = {
  isSuccess: null,
  message: null,
  dataId: null
};

export async function getAllFuelTypes() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/FuelType/GetAllFuelTypes`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const res = await response.json();
      responseDto.isSuccess = res
    }

    return await response.json();

  } catch (error) {
    responseDto.isSuccess = false;
    responseDto.message = "Markalar yüklenirken bir hata oluştu.";
    responseDto.dataId = null;
    return responseDto;
  }
};

export async function addFuelType(name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/FuelType/AddFuelType`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ name: name })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types.");
  }
};

export async function updateFuelType(id, name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/FuelType/UpdateFuelType`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id, name: name })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types");
  }
};

export async function deleteFuelType(id) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/FuelType/DeleteFuelType`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types");
  }
};

export async function deleteMarketingChanel(id) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/MarketingChanel/DeleteMarketingChanel`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch Marketing Chanel");
  }
};

export async function addColorToModel(data) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/StockModelDetail/AddColorToModel`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types");
  }
};

export async function getTaxValues() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/TaxValues/GetTaxValues`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });


    if (!response.ok) {
      const res = await response.json();
      responseDto.isSuccess = res
    }

    return await response.json();

  } catch (error) {
    responseDto.isSuccess = false;
    responseDto.message = "Markalar yüklenirken bir hata oluştu.";
    responseDto.dataId = null;
    return responseDto;
  }
};


export async function updateTaxValues(requestBody) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/TaxValues/UpdateTaxValues`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: requestBody.id,
        kdv: requestBody.kdv,
        mtv: requestBody.mtv,
        plakaTescil: requestBody.plakaTescil,
        outSource: requestBody.outSource
      })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};

export async function getAllMarketingChanels() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/MarketingChanel/GetAllMarketingChanels`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null
  }
}

export async function addMarketingChanel(name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/MarketingChanel/AddMarketingChanel`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ name: name })
    });

    if (!response.ok) {
      throw new Error("Failed to fetch colors");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch colors");
  }
};

export async function updateMarketingChanel(id, name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/MarketingChanel/UpdateMarketingChanel`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id, name: name })
    });

    if (!response.ok) {
      throw new Error("Failed to fetch colors");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch colors");
  }
};

export async function getAllOtvOptions() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/OtvOption/GetAllOtvOptions`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};

export async function getAllLoanBanks() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/LoanBank/GetAllLoanBanks`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};

export async function addLoanBank(requestBody) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/LoanBank/AddLoanBank`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ name: requestBody.name, rate: requestBody.rate, comission: requestBody.comission }),
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};

export async function addInsuranceOffice(formData) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/InsuranceOffice/AddInsuranceOffice`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ name: formData.name, type: formData.type, amount: formData.amount }),
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};

export async function getAllInsuranceOffices() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/InsuranceOffice/GetAllInsuranceOffices`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};

export async function updateInsuranceOffice(id, name, amount) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/InsuranceOffice/UpdateInsuranceOffice`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id, name: name, amount: amount })
    });

    if (!response.ok) {
      throw new Error("Failed to Insurance Office");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to Insurance Office");
  }
};

export async function deleteInsuranceOffice(id) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/api/InsuranceOffice/DeleteInsuranceOffice`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch Insurance Office");
  }
};