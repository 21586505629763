import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const getToken = () => {
  return Cookies.get('token');
};

const getUserInfo = () => {
  return JSON.parse(Cookies.get('userInfo'));
};

const baseUrl = process.env.REACT_APP_API_URL;

const ListActiveSales = () => {

  const navigate = useNavigate();

  const token = getToken();
  const toast = useRef(null);

  const [saleData, setSaleData] = useState(null);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    async function fetchData() {
      axios.get(`${baseUrl}/api/Sale/GetActiveSalesByBranchId`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.data) {
            setSaleData(response.data);
          }
          else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
          }
        })
        .catch(error => {
          toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
        })
    }

    fetchData();
  }, []);

  useEffect(() => {
    const userData = getUserInfo();

    if (userData !== null) setUserInfo(userData);
  }, []);

  const optionsTemplate = (rowData) => {
    return (
      <Dropdown>
        <Dropdown.Toggle className="btn btn-success bg-green">
          <i class="pi pi-cog fs-5"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
          {(rowData.status !== 'Muhasebe Onayında') && (

            <div>
              <Dropdown.Item className="text-light border-bottom"> Bilgi Formu Yazdır </Dropdown.Item>
              <Dropdown.Item className="text-light">Proforma Yazdır</Dropdown.Item>
              <Dropdown.Item className="text-light"> Muvafakatname Yazdır </Dropdown.Item>
            </div>
          )}

          <Dropdown.Item className="text-light" onClick={() => handleViewSale(rowData)}>Satışı Gör</Dropdown.Item>
          {(userInfo.isManager || userInfo.isAdmin) && (rowData.status !== 'Muhasebe Onayında') && (
            <div>
              <Dropdown.Item className="text-light" onClick={() => handleCancelActiveSale(rowData)}>İptal Et</Dropdown.Item>
              <Dropdown.Item className="text-light" onClick={() => handleApprovePresale(rowData)}>Satışı Onayla</Dropdown.Item>
            </div>
          )}

        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleCancelActiveSale = (rowData) => {
    axios.post(`${baseUrl}/api/Sale/CancelSaleInActiveSale`, { id: rowData.id }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.data.isSuccess) {
          toast.current.show({ severity: 'success', summary: 'Başarılı', detail: response.data.message });
        }
        else {
          toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
      })
  }

  const handleApprovePresale = (rowData) => {
    axios.post(`${baseUrl}/api/Sale/ApproveActiveSale`, { id: rowData.id }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.data.isSuccess) {
          toast.current.show({ severity: 'success', summary: 'Başarılı', detail: response.data.message });
        }
        else {
          toast.current.show({ severity: 'error', summary: 'Hata', detail: response.data.message });
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message ? error.response.data.message : 'Bir hata oluştu. Tekrar deneyiniz.' });
      })
  }

  const handleViewSale = (rowData) => {
    navigate('/sale/view', {
      state: { id: rowData.id },
    });

  }

  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  return (
    <>
      <Toast ref={toast} />
      <div>
        <div className="customerstyles">
          <h4 className="mb-5">Aktif Satışlar</h4>
          <DataTable
            value={saleData}
            sortMode="multiple"
            paginator
            rows={9}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: '70rem' }}
            emptyMessage="Aktif satışınız bulunamadı."
          >
            <Column
              field="saleNumber"
              header="Satış Kodu"
              sortable
            />
            <Column
              field="customerName"
              header="Müşteri"
              body={(rowData) => `${rowData.customerName}`}
              sortable
            />
            <Column
              field="model"
              header="Araç Modeli"
              style={{ minWidth: '300px' }}
              sortable
            />
            <Column
              field="sasiNo"
              header="Şasi Numarası"
              sortable
            />
            <Column
              field="advisorName"
              header="Danışman"
              body={(rowData) => `${rowData.advisorName}`}
              sortable
            />
            <Column
              field="totalSalePrice"
              header="Satış Fiyatı"
              sortable
              body={(rowData) => formatNumber(rowData.totalSalePrice)}
            />
            <Column
              field="status"
              header="Durum"
              sortable
            />
            <Column
              field="isPaid"
              header="Ödendi mi"
              body={(rowData) => rowData.isPaid ? <i className="pi pi-check fw-bold text-success"></i> : <i className="pi pi-times fw-bold text-danger"></i>}
              className='text-center pe-4'
              sortable
            />
            <Column body={optionsTemplate} header="İşlem" />
          </DataTable>

        </div>
      </div>
    </>
  )
}

export default ListActiveSales