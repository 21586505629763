import React, { useEffect, useRef, useState } from 'react';
import { getAllSalesInPresale } from '../services/saleService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'react-bootstrap';
import Cookies from 'js-cookie';
import axios from 'axios';
import UpdatePaymentPlan from './UpdatePaymentPlan';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate } from 'react-router-dom';
import { getInfoFormFields, getMuvafakatnameFields, getProformaFields } from '../services/formService';
import html2pdf from 'html2pdf.js';
import Muvafakatname from './Muvafakatname';
import Proforma from './Proforma';
import BilgiFormu from './BilgiFormu';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const getUserInfo = () => {
  return JSON.parse(Cookies.get('userInfo'));
};

const ListReservedSales = () => {
  const navigate = useNavigate();
  const token = getToken();
  const toast = useRef(null);

  const [saleData, setSaleData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [showEditPaymentPlan, setShowEditPaymentPlan] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [bilgiFormuData, setBilgiformuData] = useState({
    branch: null,
    saleNumber: null,
    customerName: null,
    adress: null,
    phoneNumber: null,
    email: null,
    idNumber: null,
    birthday: null,
    job: null,
    model: null,
    year: null,
    color: null,
    motorNo: null,
    sasiNo: null,
    advisorName: null,
    totalPrice: null,
    paymentPlans: [{
      amount: null,
      paymentDate: null,
      paymentType: null
    }],
  });


  const [muvafakatnameData, setMuvafakatnameData] = useState({
    branch: null,
    sasiNumber: null,
    price: null,
    customer: null,
    customerIdNumber: null,
    customerName: null,
    customerSurname: null,
    advisorIdNumber: null,
    advisorName: null,
    advisorSurname: null,
  });

  const [proformaFields, setProformaFields] = useState({
    customerName: null,
    customerIdNumber: null,
    basePrice: null,
    otv: null,
    otvPrice: null,
    priceWithOtv: null,
    kdv: null,
    kdvPrice: null,
    priceWithKdv: null,
    priceWithOtvKdv: null,
    mtv: null,
    plakaTescil: null,
    totalPrice: null,
    model: null,
    year: null,
    color: null,
    logo: null,
    sasiNo: null,
    motorNo: null,
    branch: null,
  });

  const [download, setDownload] = useState(false);
  const [downloadPro, setDownloadPro] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState(false);

  const [downloadedDocuments, setDownloadedDocuments] = useState({});

  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    model: { value: null, matchMode: FilterMatchMode.CONTAINS },
    advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    totalPrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
    netProfit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    deposit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reservedUntil: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    const userData = getUserInfo();

    if (userData !== null) setUserInfo(userData);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllSalesInPresale();
        // Check if fetchedData is an array
        if (Array.isArray(fetchedData)) {
          setSaleData(fetchedData);
        } else {
          // Handle the case where data is not an array
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Bağlantıdaki satışlar yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bağlantıdaki satışlar yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
      }/${dateTime.getFullYear()}`;
    const formattedTime = `${dateTime.getHours()}:${dateTime.getMinutes() < 10 ? '0' : ''
      }${dateTime.getMinutes()}`;
    return `${formattedDate} ${formattedTime}`;
  };

  const handleDocumentDownload = (rowId, documentType) => {
    setDownloadedDocuments(prevState => ({
      ...prevState,
      [rowId]: {
        ...prevState[rowId],
        [documentType]: true
      }
    }));
  };

  const areAllDocumentsDownloaded = (rowId) => {
    const downloaded = downloadedDocuments[rowId];
    return downloaded && downloaded.info && downloaded.proforma && downloaded.muvafakatname;
  };

  const downloadBilgiFormu = async (id) => {
    try {
      const response = await getInfoFormFields(id);

      if (!response) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bilgi Formu indirilemedi. Bir hata oluştu.',
        });
      }
      else {
        setBilgiformuData(prevState => ({
          ...prevState,
          branch: response.branch,
          saleNumber: response.saleNumber,
          customerName: response.customerName,
          adress: response.adress,
          phoneNumber: response.phoneNumber,
          email: response.email,
          idNumber: response.idNumber,
          birthday: response.birthday,
          job: response.job,
          model: response.model,
          year: response.year,
          color: response.color,
          motorNo: response.motorNo,
          sasiNo: response.sasiNo,
          advisorName: response.advisorName,
          totalPrice: response.totalPrice,
          paymentPlans: response.paymentPlans,
        }));

        handleDocumentDownload(id, 'info');

        setDownloadInfo(true);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bilgi Formu indirilemedi. Bir hata oluştu.',
      });
    }
  };

  useEffect(() => {
    if (downloadInfo) {
      handleDownloadBilgiFormu();
    }
  }, [downloadInfo]);

  const handleDownloadBilgiFormu = () => {
    const muvafakatnameHtml = document.getElementById('hiddenBilgiFormu').innerHTML;
    const fileName = `${bilgiFormuData.customerName}_BilgiFormu.pdf`; // Constructing the filename
    const pdfOptions = {
      filename: fileName,
    };
    html2pdf().set(pdfOptions).from(muvafakatnameHtml).save();

    setDownloadInfo(false);
  };

  const downloadProforma = async (id) => {
    try {
      const response = await getProformaFields(id);

      if (!response) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Muvafakatname indirilemedi. Bir hata oluştu.',
        });
      }
      else {
        setProformaFields(prevState => ({
          ...prevState,
          customerName: response.customerName,
          customerIdNumber: response.customerIdNumber,
          basePrice: response.basePrice,
          otv: response.otv,
          otvPrice: response.otvPrice,
          priceWithOtv: response.priceWithOtv,
          kdv: response.kdv,
          kdvPrice: response.kdvPrice,
          priceWithKdv: response.priceWithKdv,
          priceWithOtvKdv: response.priceWithOtvKdv,
          mtv: response.mtv,
          plakaTescil: response.plakaTescil,
          totalPrice: response.totalPrice,
          model: response.model,
          year: response.year,
          color: response.color,
          logo: response.logo,
          motorNo: response.motorNo,
          sasiNo: response.sasiNo,
          branch: response.branch
        }));
        setDownloadPro(true);
        handleDocumentDownload(id, 'proforma');
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Muvafakatname indirilemedi. Bir hata oluştu.',
      });
    }
  };

  useEffect(() => {
    if (downloadPro) {
      handleDownloadProforma();
    }
  }, [downloadPro]);

  const handleDownloadProforma = () => {
    const proformaHtml = document.getElementById('hiddenProforma').innerHTML;
    const fileName = `${proformaFields.customerName}_Proforma.pdf`; // Constructing the filename
    const pdfOptions = {
      filename: fileName,
    };
    html2pdf().set(pdfOptions).from(proformaHtml).save();
    setDownloadPro(false);
  }

  const downloadMuvafakatname = async (id) => {
    try {
      const response = await getMuvafakatnameFields(id);

      if (!response) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Muvafakatname indirilemedi. Bir hata oluştu.',
        });
      }
      else {
        setMuvafakatnameData(prevState => ({
          ...prevState,
          branch: response.branch,
          sasiNumber: response.sasiNumber,
          price: response.price,
          customerIdNumber: response.customerIdNumber,
          customerName: response.customerName,
          customerSurname: response.customerSurname,
          advisorIdNumber: response.advisorIdNumber,
          advisorName: response.advisorName,
          advisorSurname: response.advisorSurname,
        }));
        setDownload(true);
        handleDocumentDownload(id, 'muvafakatname');
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Muvafakatname indirilemedi. Bir hata oluştu.',
      });
    }
  };

  useEffect(() => {
    if (download) {
      handleDownloadMuvafakat();
    }
  }, [download]);

  const handleDownloadMuvafakat = () => {
    const muvafakatnameHtml = document.getElementById('hiddenMuvafakatname').innerHTML;
    const fileName = `${muvafakatnameData.customerName + ' ' + muvafakatnameData.customerSurname}_Muvafakatname.pdf`; // Constructing the filename
    const pdfOptions = {
      filename: fileName,
    };
    html2pdf().set(pdfOptions).from(muvafakatnameHtml).save();

    setDownload(false);
  };

  // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  const handleViewSale = (rowData) => {
    navigate('/sale/view', {
      state: { id: rowData.id },
    });
  };

  const handleEditPaymentPlan = (rowData) => {
    setSelectedRowData(rowData);
    setShowEditPaymentPlan(true);
  };

  const handleCancelPresale = (rowData) => {
    axios
      .post(
        `${baseUrl}/api/Sale/CancelSaleInPresale`,
        { id: rowData.id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı',
            detail: response.data.message,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: response.data.message,
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: error.response.data.message
            ? error.response.data.message
            : 'Bir hata oluştu. Tekrar deneyiniz.',
        });
      });
  };

  const handleApprovePresale = (rowData) => {
    axios
      .post(
        `${baseUrl}/api/Sale/ApproveSaleInPresale`,
        { id: rowData.id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı',
            detail: response.data.message,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: response.data.message,
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: error.response.data.message
            ? error.response.data.message
            : 'Bir hata oluştu. Tekrar deneyiniz.',
        });
      });
  };

  const handleUpdateReservedSale = (rowData) => {
    navigate('/sale-reserved/update', {
      state: { id: rowData.id, sasiNo: rowData.sasiNumber },
    });
  };

  const optionsTemplate = (rowData) => {
    const rowId = rowData.id;
    const allDocumentsDownloaded = areAllDocumentsDownloaded(rowId);

    return (
      <Dropdown>
        <Dropdown.Toggle className="btn btn-success bg-green">
          <i class="pi pi-cog fs-5"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
          <div className="border-bottom">
            <Dropdown.Item className="text-light" onClick={() => downloadBilgiFormu(rowData.id)}>
              {' '}
              Bilgi Formu Yazdır{' '}
            </Dropdown.Item>
            <Dropdown.Item className="text-light"
              onClick={() => downloadProforma(rowData.id)}
            >
              Proforma Yazdır
            </Dropdown.Item>
            <Dropdown.Item
              className="text-light"
              onClick={() => downloadMuvafakatname(rowData.id)}
            >
              {' '}
              Muvafakatname Yazdır{' '}
            </Dropdown.Item>
          </div>

          <Dropdown.Item
            className="text-light"
            onClick={() => handleViewSale(rowData)}
          >
            Satışı Gör
          </Dropdown.Item>
          <Dropdown.Item
            className="text-light"
            onClick={() => handleEditPaymentPlan(rowData)}
          >
            Ödeme Planını Düzenle
          </Dropdown.Item>
          {(userInfo.isManager || userInfo.isAdmin) && (
            <div>
              {allDocumentsDownloaded && (
                <Dropdown.Item
                  className="text-light"
                  onClick={() => handleApprovePresale(rowData)}
                >
                  Satışı Onayla
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className="text-light"
                onClick={() => handleUpdateReservedSale(rowData)}
              >
                Düzenle
              </Dropdown.Item>
              <Dropdown.Item
                className="text-light"
                onClick={() => handleCancelPresale(rowData)}
              >
                İptal Et
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
    setFilters({
      ...filters,
      global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS },
    });
  };

  const onFilter = (value, field) => {
    let _filters = { ...filters };

    if (value === null || value === undefined) {
      delete _filters[field];
    } else {
      _filters[field] = { value: value, matchMode: FilterMatchMode.CONTAINS };
    }

    setFilters(_filters);
  };

  // datatable search input
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-start">
        {advancedSearch ? (
          <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'customerName')}
              placeholder="Ad Soyad"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'model')}
              placeholder="Araç Modeli"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'advisorName')}
              placeholder="Danışman"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'totalPrice')}
              placeholder="Satış Fiyatı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'netProfit')}
              placeholder="Net Kâr"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'deposit')}
              placeholder="Kapora"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'createdAt')}
              placeholder="Kayıt Tarihi"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'reservedUntil')}
              placeholder="Kalan Bağlantı Süresi"
              className="rounded-3"
            />
            <Button
              type="button"
              icon="pi pi-times-circle"
              className="p-button-success rounded-3"
              tooltip="Filtrelemeyi Kapat"
              tooltipOptions={{ position: 'top' }}
              onClick={() =>
                setAdvancedSearch(!advancedSearch) ||
                setGlobalFilterValue(null) ||
                setFilters({
                  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  customerName: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  model: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  advisorName: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  totalPrice: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  netProfit: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  deposit: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  createdAt: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  reservedUntil: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                })
              }
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Arama Yapın"
                className="rounded-3"
              />
            </div>
            <Button
              type="button"
              icon="pi pi-filter"
              className="p-button-success rounded-3 ms-2"
              tooltip="Detaylı Filtreleme"
              tooltipOptions={{ position: 'top' }}
              onClick={() =>
                setAdvancedSearch(!advancedSearch) ||
                setGlobalFilterValue(null) ||
                setFilters({
                  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  customerName: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  model: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  advisorName: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  totalPrice: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  netProfit: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  deposit: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  createdAt: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                  reservedUntil: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                })
              }
            />
          </div>
        )}
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <div id="hiddenBilgiFormu" style={{ display: 'none' }}>
        <BilgiFormu data={bilgiFormuData} date={new Date()} />
      </div>
      <div id="hiddenMuvafakatname" style={{ display: 'none' }}>
        <Muvafakatname data={muvafakatnameData} date={new Date()} />
      </div>
      <div id="hiddenProforma" style={{ display: 'none' }}>
        <Proforma data={proformaFields} date={new Date()} />
      </div>

      <Toast ref={toast} />

      {showEditPaymentPlan ? (
        <UpdatePaymentPlan
          totalPrice={selectedRowData.totalPrice}
          saleId={selectedRowData.id}
        />
      ) : (
        <div>
          <div className="customerstyles">
            <h4 className="mb-5">Bağlantıdaki Satışlar</h4>
            <DataTable
              value={saleData}
              sortMode="multiple"
              paginator
              header={header}
              className="p-datatable-sm"
              globalFilter={globalFilterValue}
              filters={filters}
              rows={9}
              rowsPerPageOptions={[5, 10, 25, 50]}
              tableStyle={{ minWidth: '70rem' }}
              emptyMessage="Bağlantıda satışınız bulunamadı."
            >
              <Column
                field="customerName"
                header="Müşteri Adı"
                sortable
                style={{ minWidth: '130px' }}
              />
              <Column
                field="model"
                header="Araç Modeli"
                style={{ minWidth: '12rem' }}
                sortable
              />
              <Column field="sasiNo" header="Şasi Numarası" sortable />
              <Column
                field="advisorName"
                header="Danışman Adı"
                body={(rowData) => `${rowData.advisorName}`}
                sortable
              />
              <Column
                field="totalPrice"
                header="Satış Fiyatı"
                body={(rowData) => formatNumber(rowData.totalPrice)}
                sortable
              />
              <Column
                field="netProfit"
                header="Net Kâr"
                body={(rowData) => formatNumber(rowData.netProfit)}
                sortable
              />
              <Column
                field="netProfitRate"
                header="Kâr Oranı"
                body={(rowData) => formatNumber(rowData.netProfitRate)}
                sortable
              />
              <Column
                field="netProfitMargin"
                header="Kâr Marjı"
                body={(rowData) => formatNumber(rowData.netProfitMargin)}
                sortable
              />
              <Column field="deposit" header="Kapora" />
              <Column
                field="createdAt"
                header="Kayıt Tarihi"
                body={(rowData) => formatDateTime(rowData.createdAt)}
                sortable
              />
              <Column
                field="reservedUntil"
                header="Kalan Bağlantı Süresi (Gün)"
                style={{ minWidth: '10rem', fontSize: '.9rem'}}
                sortable
              />
              <Column body={optionsTemplate} header="İşlem" />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
};

export default ListReservedSales;
