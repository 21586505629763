import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import '../../assets/styles/_customer.scss';
import AddExchangeRequestDialog from './AddExchangeRequestDialog';
import { getRequestDataForSaleView } from '../services/saleService';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const ViewReservedSale = () => {
  const toast = useRef(null);

  const location = useLocation();
  const saleId = location.state ? location.state.id : null;

  const [saleData, setSaleData] = useState({});
  const [paymentData, setPaymentData] = useState([]);

  const [showDialog, setShowDialog] = useState(false);

  const [exchangeForm, setExchangeForm] = useState({
    // takas
    brand: null,
    model: null,
    year: null,
    package: null,
    gearType: null,
    fuelType: null,
    km: null,
    color: null,
    damage: null,
    note: null,
    customerExpectation: null,
    saleId: null
  });

  const [saleRequestData, setSaleRequestData] = useState({
    exchange: null,
    insurance: null,
    kasko:null,
    loan:null
  });

  // satış datası getirir
  useEffect(() => {
    async function fetchData() {
      try {
        if (saleId === null) return;

        const token = getToken();
        if (!token) {
          // TODO: Handle token absence
          return;
        }

        const response = await fetch(
          `${baseUrl}/api/Sale/ViewSaleDetailBySaleId`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: saleId }),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          setSaleData(responseData);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Satış bilgileri yüklenemedi. Tekrar deneyiniz.',
          });
        }
      } catch (error) {
        // Handle other errors
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bir hata oluştu. Tekrar deneyiniz.',
        });
      }
    }

    fetchData();
  }, [saleId]);

  useEffect(() => {
    async function fetchData() {
        if (saleId) {
            // get the requestid's data
            const data = await getRequestDataForSaleView(saleId);
            //check if response ok
            if (data && data.message && data.isSuccess === false) {
                return toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail:
                        data.message ??
                        'Talep verileri yüklenirken hata oluştu. Tekrar deneyiniz.',
                });
            }
            setSaleRequestData(data);
        }
    }

    fetchData();
}, []);

  // satışın ödeme bilgilerini getirir.
  useEffect(() => {
    async function fetchData() {
      try {
        if (saleId === null || saleId === undefined) return;

        const token = getToken();
        if (!token) {
          // TODO: Handle token absence
          return;
        }

        const response = await fetch(
          `${baseUrl}/api/Payment/GetPaymentPlansBySaleId`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: saleId }),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          setPaymentData(responseData);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Satış bilgileri yüklenemedi. Tekrar deneyiniz.',
          });
        }
      } catch (error) {
        // Handle other errors
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bir hata oluştu. Tekrar deneyiniz.',
        });
      }
    }

    fetchData();
  }, [saleId]);

  const formatNumber = (number) => {
    if(number === 0) return 0;
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  const formatDate = (date) => {
    if (!date) return '';

    const formattedDate = new Date(date).toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return formattedDate;
  };

  const handleAddExchangeRequest = async () => {
    setExchangeForm((prevState) => ({
      ...prevState,
      saleId: saleData.saleId
    }));

    setShowDialog(true);
  }

  return (
    <>
      <div className="customerstyles">
        {showDialog && (
          <AddExchangeRequestDialog showDialog={showDialog} setShowDialog={setShowDialog} exchangeForm={exchangeForm} setExchangeForm={setExchangeForm} />
        )}
        <Toast ref={toast} />
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="mb-5">Satışı Gör</h4>
          </div>
          <div>
            {!saleData.isExchanged && (
              <Button
                className="j-button bg-green me-2"
                icon="pi pi-plus"
                label="Takas Ekle"
                onClick={handleAddExchangeRequest}
              ></Button>
            )}
            <Button
              className="j-button bg-green"
              icon="pi pi-pencil"
              label="Düzenlemeler Yap"
            ></Button>
          </div>
        </div>
        {/* Müşteri ve Araç bilgileri */}
        <div className="row view-sale-input">
          <div className="col-lg-3 mb-2">
            <div className="form-group">
              <label>Alıcı</label>
              <input
                type="text"
                className="form-control"
                value={saleData?.customerName}
                disabled
              />
            </div>
          </div>
          <div className="col-lg-3 mb-2">
            <div className="form-group">
              <label>Şasi No</label>
              <input
                type="text"
                className="form-control"
                value={saleData?.sasiNo}
                disabled
              />
            </div>
          </div>
          <div className="col-lg-3 mb-2">
            <div className="form-group">
              <label>Araç Adı</label>
              <input
                type="text"
                className="form-control"
                value={saleData?.model}
                disabled
              />
            </div>
          </div>
          <div className="col-lg-3 mb-2">
            <div className="form-group">
              <label>Danışman</label>
              <input
                type="text"
                className="form-control"
                value={saleData?.advisorName}
                disabled
              />
            </div>
          </div>
        </div>
        {/* Satış Bilgileri */}
        <h5 className="mt-5 col-12 fw-bolder">Satış Bilgileri</h5>
        <div className="col-12 col-xxl-9 d-flex flex-wrap p-3 view-sale">
          <div className="col-12 col-lg-5 me-3">
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">Satış Mal Bedeli: </span>
                  {saleData?.baseSalePrice === null
                    ? '-'
                    : formatNumber(saleData?.baseSalePrice)}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">İndirim Miktarı: </span>
                  {saleData?.discount === 0
                    ? '-'
                    : formatNumber(saleData?.discount)}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">Satış Fiyatı: </span>
                  {saleData?.salePrice === null
                    ? '-'
                    : formatNumber(saleData?.salePrice)}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">Net Kâr: </span>
                  {saleData?.netProfit === null
                    ? '-'
                    : formatNumber(saleData?.netProfit)}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">Anahtar Teslim Fiyat: </span>
                  {saleData?.totalSalePrice === null
                    ? '-'
                    : formatNumber(saleData?.totalSalePrice)}
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">MTV: </span>
                  {saleData?.mtv === null ? '-' : saleData?.mtv}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">Plaka Tescil: </span>
                  {saleData?.plakaTescil === null ? '-' : saleData?.plakaTescil}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">Aksesuar: </span>
                  {saleData?.aksesuar === null ? '-' : formatNumber(saleData?.aksesuar)}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-2">TB: </span>
                  {saleData?.tb === null ? '-' : formatNumber(saleData?.tb)}
                </label>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label>
                  <span className="fw-bold pe-3">Mini Onarım: </span>
                  {saleData?.mini === null ? '-' : formatNumber(saleData?.mini)}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap col-xxl-9 p-3 view-sale">
          <div className="col-12 col-lg-5  ">
            <div className="form-group mb-2">
              <label>
                <span className="fw-bold pe-3">Takas: </span>{saleRequestData.exchange}
              </label>
            </div>
            <div className="form-group">
              <label>
                <span className="fw-bold pe-3">Kredi: </span>{saleRequestData.loan}
              </label>
            </div>
          </div>

          <div className="col-12 col-lg-5  ps-3">
            <div className="form-group mb-2">
              <label>
                <span className="fw-bold pe-3">Kasko: </span>{saleRequestData.kasko}
              </label>
            </div>
            <div className="form-group">
              <label>
                <span className="fw-bold pe-3">Sigorta: </span>{saleRequestData.insurance}
              </label>
            </div>
          </div>
        </div>
        {/* Ödeme Bilgileri */}
        <h5 className="mt-3 col-12 fw-bolder">Ödeme Bilgileri</h5>
        <div className="d-flex flex-wrap col-xxl-9 p-3">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Ödeme</th>
                <th scope="col">Ödeme Şekli</th>
                <th scope="col">Ödeme Tarihi</th>
                <th scope="col">Banka</th>
              </tr>
            </thead>
            <tbody>
              {paymentData && paymentData.map((payment) => (
                <tr key={payment.id}>
                  <td>{formatNumber(payment.amount)}</td>
                  <td>{payment.paymentType}</td>
                  <td>{formatDate(payment.paymentDate)}</td>
                  <td>{payment.paymentBank}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewReservedSale;
