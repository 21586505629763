import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from "react-bootstrap";
import { Toast } from 'primereact/toast';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../assets/styles/_customer.scss";
import { getAllUsers } from '../services/userService';


const ListAllUsers = () => {
  const [userData, setUserData] = useState([]);
  const toast = useRef(null);
  const navigate = useNavigate();
   // datatable filter
   const [globalFilterValue, setGlobalFilterValue] = useState(null);
   const [filters, setFilters] = useState({
     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 });
 
   const onGlobalFilterChange = (e) => {
     const value = e.target.value;
     let _filters = { ...filters };
 
     _filters['global'].value = value;
 
     setFilters(_filters);
     setGlobalFilterValue(value);
 };
 // datatable search input
 const renderHeader = () => {
     return (
         <div className="d-flex justify-content-start">
             <span className="p-input-icon-left">
                 <i className="pi pi-search" />
                 <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Arama Yapın" />
             </span>
         </div>
     );
 };
 const header = renderHeader();

  //TODO
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllUsers();
        setUserData(data);
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Çalışan listesi yüklenemedi. Sayfayı yenileyiniz.' });
      }
    }

    fetchData();
  }, []);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const optionsTemplate = (rowData) => {
    return (
        <Dropdown>
            <Dropdown.Toggle className="btn btn-success bg-green">
            <i class="pi pi-cog fs-5"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
                <Dropdown.Item className="text-light border-bottom" onClick={() => editUser({ rowData })}>Düzenle</Dropdown.Item>
                <Dropdown.Item className="text-light" onClick={(e) => deleteUser(rowData)}>Sil</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const editUser = (rowData) => {
  // Destructure etmek yerine doğrudan rowData içindeki id'ye eriş
  const id = rowData.rowData.id;
  // Daha sonra id'yi kullanarak işlem yap
  navigate('/user/update', { state: { id } });
};


  const deleteUser = (rowData) => {
    // Handle delete action
  };

  return (
    <>
      <div>
      <div className="customerstyles">
            <h4 className="mb-5">Çalışan Listesi</h4>
            <DataTable value={userData} sortMode="multiple" paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: "70rem" }}
            stripedRows
            removableSort
            filters={filters}
            globalFilterFields={['username', 'email', 'phoneNumber', 'internalPhone', 'branch', 'roleName', 'createdAt']}
            header={header}
            emptyMessage="Eşleşme bulunamadı."
            >
              <Column field="username" header="Ad Soyad" body={(rowData) => rowData.name + ' ' + rowData.surname} sortable />
              <Column field="email" header="Email" />
              <Column field="phoneNumber" header="Telefon Numarası" />
              <Column field="internalPhone" header="Sabit Telefon" />
              <Column field="branch" header="Marka" sortable />
              <Column field="roleName" header="Görev" sortable />
              <Column field="managerName" header="Yönetici" sortable />
              <Column field="createdAt" header="Kayıt Tarihi" body={(rowData) => formatDateTime(rowData.createdAt)} sortable />
              <Column body={optionsTemplate} header="İşlem" />
            </DataTable>
          </div>
      <Toast ref={toast} />
    </div>    
    </>
    
  );
};

export default ListAllUsers;
