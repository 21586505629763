import React, { useEffect, useRef, useState } from 'react'
import { getAllInsuranceRequests } from '../services/insuranceService';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'react-bootstrap';
import SharedDeleteDialog from '../system/SharedDeleteDialog';
import { useNavigate } from 'react-router-dom';

const ListAllInsuranceRequest = () => {

    const toast = useRef();
    const navigate = useNavigate();

    const [insuranceList, setInsuranceList] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [header, setHeader] = useState(null);
    const [apiOption, setApiOption] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const data = await getAllInsuranceRequests();

            if (data && data.message && data.isSuccess === false) {
                return toast.current.show({ severity: 'error', summary: 'Hata', detail: data.message ?? "Sigorta/Kasko yüklenirken hata oluştu. Tekrar deneyiniz." });
            }
            else {
                setInsuranceList(data);
            }
        }

        fetchData();
    }, []);

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
            }/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
    };

  const handleDelete = (rowData) => {
    setHeader("Sigorta Talebi Sil");
    setSelectedId(rowData.id);
    setApiOption("deleteInsuranceRequest");
    setShowDeleteDialog(true);
  };

  const handleApprove = (rowData) => {
    navigate('/request/approve-insurance', {
      state: { id: rowData.id },
    });
  };

  // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

    const optionsTemplate = (rowData) => {
        return (
            <Dropdown>
                <Dropdown.Toggle className="btn btn-success bg-green">
                    <i class="pi pi-cog fs-5"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
                    <Dropdown.Item className="text-light" onClick={() => handleApprove(rowData)}>Onayla</Dropdown.Item>
                    <Dropdown.Item className="text-light"  onClick={() => handleDelete(rowData)} >Sil</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    return (
        <>
            <div className='customerstyles mb-5'>
                <h4 className="mb-5">Trafik Kasko Talep Listesi</h4>
                <DataTable value={insuranceList} sortMode="multiple" tableStyle={{ minWidth: "70rem" }}
                    emptyMessage="Trafik/Kasko talebi bulunmamaktadır"
                >
                    <Column field="customerName" header="Müşteri Adı" />
                    <Column field="phoneNumber" header="Telefon" />
                    <Column field="branch" header="Marka" />
                    <Column field="model" header="Model" style={{ minWidth: "200px" }} />
                    <Column field="sasiNumber" header="Şasi Numarası" />
                    <Column field="otvMuaf" header="Raporlu Ötv"
                        className='text-center ps-1'
                        body={(rowData) => rowData.otvMuaf ? <i className="pi pi-check fw-bold text-success"></i> : <i className="pi pi-times fw-bold text-danger"></i>}
                    />
                    <Column field="salePrice" header="Satış Fiyatı"
                    body={(rowData) => formatNumber(rowData.salePrice)} />
                    <Column field="advisorName" header="Satışı Yapan" />
                    <Column field="saleStatus" header="Satış Durumu" />
                    <Column field="createdAt" header="Satış Tarihi" body={(rowData) => formatDateTime(rowData.createdAt)} />
                    <Column body={optionsTemplate} header="İşlem" />
                </DataTable>
            </div>
            <Toast ref={toast} />

            <SharedDeleteDialog
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                id={selectedId}
                setSelectedId={setSelectedId}
                header={header}
                setHeader={setHeader}
                apiOption={apiOption}
                setApiOption={setApiOption}
            />
        </>
    )
}

export default ListAllInsuranceRequest