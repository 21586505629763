import React from 'react';
import '../../assets/styles/_wrapper.scss';
import Sidebar from '../shared/Sidebar';
import Navbar from '../shared/Navbar';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const Wrapper = ({ children, username }) => {
  const location = useLocation();
  return (
    <div>
      <div className="wrapper">
        <Sidebar />
        <Navbar />
        <main className="main-content">
          <div className="wrapper-main">
            <div className="card">
              <div className="card-body">{children}</div>
            </div>
          </div>
        </main>
        <div className="bottom-bar col-12 justify-content-around align-items-center">
          <div className={location.pathname.startsWith("/dashboard") ? "d-flex align-items-center justify-content-center col-3 h-100 active" : "d-flex align-items-center justify-content-center col-3 h-100" }>
            <Link to="/dashboard" className="text-decoration-none text-dark">
              <i className="pi pi-home fs-1"></i>
            </Link>
          </div>
          <div className={location.pathname.startsWith("/customer/") ? "d-flex align-items-center justify-content-center col-3 h-100 active" : "d-flex align-items-center justify-content-center col-3 h-100" }>
          <Dropdown drop="up-centered" autoClose="true">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <i className="pi pi-users fs-1"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/customer/add">
                  Müşteri Kartı Aç
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/customer/approval-waiting">
                  Onay Bekleyen Müşteriler
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/customer/list">
                  Müşteri Listesi
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/customer/sms">
                  Müşteri SMS İletişim
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/customer/call">
                  Müşteri Arama
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/customer/test-drive">
                  Test Sürüşleri
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={location.pathname.startsWith("/stock/") ? "d-flex align-items-center justify-content-center col-3 h-100 active" : "d-flex align-items-center justify-content-center col-3 h-100" }>
            <Dropdown drop="up-centered" autoClose="true">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <i className="pi pi-box fs-1"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/stock/add">
                  Stok Ekle
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/stock/list">
                  Stok Listesi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={location.pathname.startsWith("/sale/") ? "d-flex align-items-center justify-content-center col-3 h-100 active" : "d-flex align-items-center justify-content-center col-3 h-100" }>
          <Dropdown drop="up-centered" autoClose="true">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <i className="pi pi-shopping-cart fs-1"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/sale/create">
                  Satış Yap
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/sale/reserved-list">
                  Bağlantıdaki Satışlar
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/sale/waiting-list">
                  Aktif Satışlar
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/sale/features-list">
                  Aksesuar Listesi
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/sale/accounting-list">
                  Onay Bekleyenler
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
