import React from 'react'
import { useLocation } from 'react-router-dom';
import EditIndividualCustomer from './EditIndividualCustomer';
import EditCorporateCustomer from './EditCorporateCustomer';

const EditCustomer = () => {

    const location = useLocation();
    const id = location.state ? location.state.id : null;
    const type = location.state ? location.state.type : null;

    return (
        <>
            {type && type == "Kurumsal" ? <EditCorporateCustomer id = {id} /> : <EditIndividualCustomer id={id} /> }
        </>
    )
}

export default EditCustomer