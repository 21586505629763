import React from 'react';
import cherrylogo from '../../assets/images/cherylogo.png';
import hondalogo from '../../assets/images/hondalogo.png';

const TestSurusFormu = ({ data, date }) => {
  let image = null;
  
  if (data.branch === 'Chery') {
    image = cherrylogo;
  } else if (data.branch === 'Honda') {
    image = hondalogo;
  }

  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const formatTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedTime = `${dateTime.getHours()}:${
      dateTime.getMinutes() < 10 ? '0' : ''
    }${dateTime.getMinutes()}`;
    return `${formattedTime}`;
  };

  return (
    <div
      style={{
        maxWidth: '800px',
        backgroundColor: 'white',
        padding: '20px 40px',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        fontSize: '.9rem',
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h1
          style={{
            letterSpacing: '-2px',
            fontWeight: 'bold',
          }}
        >
          TEST SÜRÜŞ FORMU
        </h1>
        <img src={image} alt="logo" width="160px" />
      </div>
      <div className="my-2">
        <div className="d-flex align-items-start justify-content-center flex-column">
          <span className="fw-bold">TEST ARACI BİLGİLERİ</span>
        </div>
        <div className="d-flex flex-column">
          <div className="col-12 d-flex flex-row">
            <div className="col-2">MODEL/TİP</div>
            <div className="col-10 border border-dark ps-2">{data.model}</div>
          </div>
          <div className="col-12 d-flex flex-row">
            <div className="col-2">MODEL YILI</div>
            <div className="col-10 border border-dark border-top-0 border-bottom-0 ps-2">{data.year}</div>
          </div>
          <div className="col-12 d-flex flex-row">
            <div className="col-2">PLAKA</div>
            <div className="col-5 border border-dark ps-2"></div>
            <div className="col-5 border border-dark border-start-0 ps-2">
              KM:
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        <div className="d-flex align-items-start justify-content-center flex-column">
          <span className="fw-bold">MÜŞTERİ BİLGİLERİ</span>
        </div>
        <div className="d-flex flex-column">
          <div className="col-12 d-flex flex-row">
            <div className="col-2">AD SOYAD</div>
            <div className="col-10 border border-dark ps-2">{data.customerName}</div>
          </div>
          <div className="col-12 d-flex flex-row">
            <div className="col-2">TELEFON</div>
            <div className="col-5 border border-dark border-top-0 border-end-0 ps-2">
              TELEFON 1: <span className='ps-2'>{data.phoneNumber}</span> 
            </div>
            <div className="col-5 border border-dark border-top-0 border-start-0">
              TELEFON 2: <span className="ps-2">{data.personalPhoneNumber}</span>
            </div>
          </div>
          <div className="col-12 d-flex flex-row">
            <div className="col-2">ADRES</div>
            <div className="col-10 border border-dark border-top-0 border-bottom-0 ps-2">{data.adress}</div>
          </div>
          <div className="col-12 d-flex flex-row">
            <div className="col-2">E-MAIL</div>
            <div className="col-10 border border-dark ps-2">{data.email}</div>
          </div>
        </div>
      </div>
      <table className="table-bordered mt-4">
        <thead>
          <tr>
            <th colspan="6" className="text-center">
              GENEL DEĞERLENDİRME
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="col-5"></td>
            <td>Çok İyi (5)</td>
            <td>İyi (4)</td>
            <td>Orta (3)</td>
            <td>Kötü (2)</td>
            <td>Çok Kötü (1)</td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>MOTOR PERFORMANSI/SÜSPANSİYON</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>AKSESUAR DONANIMI</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>MALZEME VE İŞÇİLİK KALİTESİ</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>FREN PERFORMANSI</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>YOL TUTUŞ PERFORMANSI</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>GÜVENLİK DONANIMI</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>KABİN RAHATLIĞI VE KONFOR</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>BAGAJ HACMİ</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>YAKIT TÜKETİMİ</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>TASARIM VE DİZAYN</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className='ps-1 p-0'>FİYAT</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="py-2" colspan="12">DÜŞÜNCELER:</td>
          </tr>
          <tr>
            <td colspan="12" className='small p-1'>Böyle bir otomobil almayı düşünür müsünüz?</td>
          </tr>
          <tr>
            <td colspan="12" className='small p-1'>
              Cevabınız "HAYIR" ise başka hangi otomobili almayı düşünürsünüz?
            </td>
          </tr>
        </tbody>
      </table>
      <div className="mt-3">
        <h6 className="fw-bold">Önemli Uyarı</h6>
        <p className="small mb-0" style={{ textAlign: 'justify', lineHeight: "12px" }}>
          Kendi rızamla yukarıda tipi yazan otomobili denemek amacı ile
          kullanmak istediğimi, aracı trafik kurallarına ve trafik düzenine
          uygun kullanacağımı, test sürüşü esnasında olabilcek her türlü hukuki
          ve cezai şartlar ile, hasar ve kazalarda sigorta şartları
          çerçevesinde, test aracı ruhsat sahibi ilgili {data.branch} Otomobil Yetkili
          Satıcısı TEKBAŞ TİCARİ ARAÇLAR LTD. ŞTİ. ve 3.kişilere karşı sorumlu
          olduğumu, ilgili {data.branch} Otomobil Yetkili Satıcısı TEKBAŞ TİCARİ ARAÇLAR
          LTD. ŞTİ. ve test sürüşüne katılan {data.branch} Yetkili Satıcısı danışmanını
          hiçbir koşulda sorumlu tutmayacağımı kabul ve beyan ederim. Bunlara ek
          olarak paylaştığım iletişim bilgilerimin
          mal/hizmet/reklam/kampanya/promosyon faaliyetlerinde kullanılması için
          SMS/Otomatik arama/Mail/Telefonla Arama/Sosyal Medya iletişim araçları
          ile tarafıma iletiler gönderilmesini, tarafıma gönderilecek bu ticari
          iletileri okuduğumu anladığımı kabul ve beyan ediyorum. Bu kapsamda
          tarafıma ticari elektronik ileti gönderilmesine açık rıza ve onay
          veriyorum.
        </p>
      </div>
      <div className="d-flex align-items-start justify-content-around mb-5">
        <div className="d-flex flex-column align-items-center ">
          <span className='text-decoration-underline'>Test Sürüşü Talep Eden</span>
          <span>{data.customerName}</span>
        </div>
        <div className="d-flex flex-column align-items-center ">
          <span className='text-decoration-underline'>Test Sürüşü Yaptıran</span>
          <span>{data.userName}</span>
          </div>
        <div className="d-flex flex-column align-items-center ">
          <span className='text-decoration-underline'>Tarih</span>
          <span>{formatDate(date)}</span>
        </div>
        <div className="d-flex flex-column align-items-center ">
          <span className='text-decoration-underline'>Saat</span>
          <span>{formatTime(date)}</span>
        </div>
      </div>
      <span className="small fw-bold">Ek: Ehliyet Fotokopisi</span>
    </div>
  );
};

export default TestSurusFormu;
