import React, { useState, useRef, useEffect } from 'react'
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SharedNameDialog from './SharedNameDialog';
import SharedDeleteDialog from './SharedDeleteDialog';
import AddColorToModel from './AddColorToModel';
import { getAllColors } from '../services/colorService';

const ListColors = () => {
  const toast = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [colorsData, setColorsData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [value, setValue] = useState(null);
  const [header, setHeader] = useState(null);
  const [apiOption, setApiOption] = useState(null);

  useEffect(() => {

    async function fetchData() {
      try {
        const data = await getAllColors();
        setColorsData(data);
        console.log(colorsData);
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Renk listesi yüklenemedi. Sayfayı yenileyiniz.' });
      }
    }

    fetchData();
  }, [selectedId, value, showDialog, apiOption]);

  const handleEdit = (rowData) => {
    if (rowData.id === null) {
      setHeader('Renk Ekle');
    }
    else {
      setHeader("Renk Düzenle");
    }
    setSelectedId(rowData.id);
    setValue(rowData.name);
    setApiOption("color");
    setShowDialog(true);
  };

  const handleDelete = (rowData) => {
    setHeader("Renk Sil");
    setSelectedId(rowData.id);
    setApiOption("color");
    setShowDeleteDialog(true);
  };

  return (
    <>
      <AddColorToModel />
      <Toast ref={toast} />
      <div className='customerstyles'>
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
          <h4>Renk Ekle</h4>
          <Button severity="success" className="rounded-3" onClick={() => handleEdit({ id: null, name: null })}>Renk Ekle</Button>
        </div>
        <SharedDeleteDialog
          showDeleteDialog={showDeleteDialog}
          setShowDeleteDialog={setShowDeleteDialog}
          id={selectedId}
          setSelectedId={setSelectedId}
          header={header}
          setHeader={setHeader}
          apiOption={apiOption}
          setApiOption={setApiOption}
        />
        <SharedNameDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          id={selectedId}
          setSelectedId={setSelectedId}
          header={header}
          setHeader={setHeader}
          value={value}
          setValue={setValue}
          apiOption={apiOption}
          setApiOption={setApiOption}
        />
        {colorsData && (
          <div className="col-12 col-xxl-6">
            <DataTable value={colorsData}>
              <Column className="col-8" field="name" header="Renkler"></Column>
              <Column
                className="col-4 col-lg-2"
                header="İşlem"
                body={(rowData) => (
                  <>
                    <Button className="p-button-rounded rounded-3 p-button-success" icon="pi pi-pencil" onClick={() => handleEdit(rowData)} />
                    <Button className="p-button-rounded rounded-3 bg-blue ms-1" icon="pi pi-trash" onClick={() => handleDelete(rowData)} />
                  </>
                )}
              />
            </DataTable>
          </div>
        )}

      </div>
    </>
  );
};

export default ListColors