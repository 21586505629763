import React, { useEffect, useRef, useState } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { getCustomerSaleByCustomerId } from '../services/saleService';
const ListCustomerCar = ({ customerId }) => {

    const toast = useRef(null);

    const emptyMessage = "Müşterinin satın aldığı araç bulunmamaktadır";

    const [customerCar, setCustomerCar] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getCustomerSaleByCustomerId(customerId);
                setCustomerCar(data);
            } catch (error) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: 'Müşterinin satın alınan araç listesi yüklenemedi. Sayfayı yenileyiniz.',
                });
            }
        }
        fetchData();
    }, []);

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        const formattedTime = `${dateTime.getHours()}:${dateTime.getMinutes() < 10 ? '0' : ''}${dateTime.getMinutes()}`;
        return `${formattedDate} ${formattedTime}`;
    };

    const deleteRow = (row) => {

    }

    return (
        <>
            <div className='customerstyles mb-5'>
                <h4 className="mb-2">Satın Alınan Araç Listesi</h4>
                <DataTable value={customerCar} sortMode="multiple" tableStyle={{ minWidth: "70rem" }}
                emptyMessage= "Müşterinin satın aldığı araç bulunmamaktadır"
                >
                    <Column field="branch" header="Marka" />
                    <Column field="model" header="Model" />
                    <Column field="createdAt" header="Oluşturulma Tarihi" body={(rowData) => formatDateTime(rowData.createdAt)} />
                    <Column field="status" header="Durumu" />
                </DataTable>
            </div>
            <Toast ref={toast} />

        </>
    )
}

export default ListCustomerCar