import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import '../../assets/styles/_login.scss';
import {
  approveOtpConfirmation,
  sendOtpConfirmation,
  forgotPasswordChange,
} from '../services/userService';

const EnterPhoneNumberDialog = ({ showDialogue, setShowDialog }) => {
  const toast = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const [showPasswordInputs, setShowPasswordInputs] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userId, setUserId] = useState(null);

  const handleInputChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOk = async () => {
    if (phoneNumber === '' || phoneNumber < 10) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Lütfen geçerli bir telefon numarası giriniz.',
      });
    } else {
      const response = await sendOtpConfirmation(phoneNumber);
      if (response === null || !response.isSuccess) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: response.message
            ? response.message
            : 'Sms gönderilemedi. Lütfen tekrar deneyiniz.',
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: response.message
            ? response.message
            : 'Sms başarıyla gönderildi.',
        });
        setShowOtpDialog(true);
      }
    }
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  const confirmOtp = async () => {
    if (otp === '' || otp < 6) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Lütfen geçerli bir kod giriniz.',
      });
    } else {
      const response = await approveOtpConfirmation(phoneNumber, otp);
      if (response === null || !response.isSuccess) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: response.message
            ? response.message
            : 'Kod onaylanamadı. Lütfen tekrar deneyiniz.',
        });
      } else {
        setShowPasswordInputs(true);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: ' Kod onaylandı. Yeni şifrenizi oluşturabilirsiniz.',
        });
        setUserId(response.dataId);
      }
    }
  };

  const passwordChange = async () => {
    const response = await forgotPasswordChange(userId, newPassword);
    if (response === null || !response.isSuccess) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: response.message
          ? response.message
          : 'Şifre değiştirilemedi. Lütfen tekrar deneyiniz.',
      });
    } else {
      setSuccess(true);
    }
  };

  const handleSavePassword = () => {
    if (newPassword === '' || confirmPassword === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Lütfen tüm alanları doldurunuz.',
      });
    } else if (newPassword !== confirmPassword) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Şifreler uyuşmuyor. Lütfen tekrar deneyiniz.',
      });
    } else {
      setPassword(newPassword);
      passwordChange();
    }
  };

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog visible={success} onHide={() => setShowDialog(false)}
        header="Şifre Değiştirme"
        style={{ width: '50vw', maxWidth: '500px' }}
        modal={true}
        footer={
          <div className='text-center'>
            <Button
              className="bg-success j-button me-2"
              label="Tamam"
              icon="pi pi-check"
              onClick={() => setShowDialog(false)}
            />
          </div>
        }
      >
        <div className="d-flex flex-row align-items-center justify-content-center">
        <i className="pi pi-check-circle text-success h1 me-3"></i>
        <p className='text-center text-success h5'> Şifreniz başarıyla değiştirildi. </p>
        </div>
      </Dialog>
      {/* Password inputs */}
      <Dialog
        header="Şifre Oluştur"
        visible={showPasswordInputs}
        style={{ width: '50vw', maxWidth: '500px' }}
        modal={true}
        onHide={() => setShowPasswordInputs(false)}
        footer={
          <div className="d-flex align-items-center justify-content-center flex-wrap gap-1">
            <Button
              className="bg-secondary j-button me-2"
              label="İptal"
              icon="pi pi-times"
              onClick={() => setShowPasswordInputs(false)}
            />
            <Button
              className="bg-green j-button"
              label="Kaydet"
              icon="pi pi-check"
              onClick={handleSavePassword}
            />
          </div>
        }
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <label className="mt-3 fw-bold">Yeni Şifreniz</label>
          <Password
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            weakLabel="Zayıf"
            mediumLabel="Orta"
            strongLabel="Güçlü"
            promptLabel="Şifreniz en az 8 karakter olmalıdır."
            minLength={8}
          />
          <label className="mt-3 fw-bold">Yeni Şifreniz Tekrar</label>
          <Password
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            weakLabel="Zayıf"
            mediumLabel="Orta"
            strongLabel="Güçlü"
            promptLabel="Şifrenizi tekrar giriniz."
            minLength={8}
            feedback={false}
          />
        </div>
      </Dialog>

      {/* OTP Dialog */}
      <Dialog
        header="SMS Onay Kodu"
        visible={showOtpDialog}
        style={{ width: '50vw', maxWidth: '500px' }}
        modal={true}
        onHide={() => setShowOtpDialog(false)}
        footer={
          <div>
            <Button
              className="bg-secondary j-button me-2"
              label="İptal"
              icon="pi pi-times"
              onClick={() => setShowOtpDialog(false)}
            />
            <Button
              className="bg-green j-button"
              label="Onayla"
              icon="pi pi-check"
              onClick={confirmOtp}
            />
          </div>
        }
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <label>SMS ile gelen onay kodunu giriniz</label>
          <InputText
            id="otp"
            placeholder="000000"
            className="w-100 rounded-3"
            value={otp}
            onChange={(e) => handleOtpChange(e)}
          />
        </div>
      </Dialog>

      <Dialog
        header="Telefon numaranızı giriniz."
        visible={showDialogue}
        style={{
          width: '400px',
          borderRadius: '0.5rem',
          backgroundColor: '#f8f9fa',
          border: 'none',
        }}
        onHide={handleCancel}
      >
        <div>
          <div>
            <InputMask
              id="valueInput"
              mask="(999) 999 99 99"
              className="w-100 rounded-3"
              value={phoneNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="mt-4 d-flex justify-content-end gap-1">
            <Button
              label="İptal"
              severity="secondary"
              className="rounded-3"
              onClick={handleCancel}
            />
            <Button
              label="Gönder"
              severity="success"
              className="rounded-3"
              onClick={handleOk}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EnterPhoneNumberDialog;
