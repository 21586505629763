import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import Cookies from 'js-cookie'; // Import Cookies
import logoWebp from "../../assets/images/occo_car_logo.webp";
// import userImage from "../../assets/images/recep_gunay.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styles/_sidebar.scss";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const getToken = () => {
  return Cookies.get('token');
};
const Sidebar = () => {

  const token = getToken();

  const location = useLocation();

  const [openMenus, setOpenMenus] = useState({
    dashboard: false,
    customer: false,
    communication: false,
    stock: false,
    sales: false,
    request: false,
    system: false,
    reports: false,
    user: false
  });

  // Set the user info
  const [userInfo, setUserInfo] = useState(null);
  const [userImage, setUserImage] = useState(null);

  // Get user info from the Cookies
  useEffect(() => {
    const userInfoFromCookies = Cookies.get('userInfo');
    if (userInfoFromCookies) {
      setUserInfo(JSON.parse(userInfoFromCookies));
    }
  }, []);

  // get user photo
  useEffect(() => {
    async function fetchData() {
        axios.get(`${baseUrl}/api/User/GetUserImage`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data) {
                    setUserImage(response.data);
                }
                else {
                  console.log(response);
                }
            })
            .catch(error => {
              console.log(error);
            })
    }

    fetchData();
}, []);

  // check the users permission to show the menu
  const hasPermission = (permission) => {
    return userInfo && userInfo.permissions.includes(permission);
  };

  const hasTopMenuPermission = (permission) => {
    return userInfo.permissions.some(p => p.startsWith(permission));
  }

  const handleToggle = (menu) => {
    setOpenMenus((prevOpenMenus) => ({
      ...Object.fromEntries(
        Object.entries(prevOpenMenus).map(([key]) => [key, false])
      ),
      [menu]: !prevOpenMenus[menu],
    }));
  };

  return (
    <div className="sidebarstyle">
      <div className="sidebar-lg d-none d-md-block">
          <Link to="/dashboard" className="sidebar-header">
          <img src={logoWebp} alt="Logo" className="sidebar-logo" />
          </Link>
          <Link to="/user/view" className="sidebar-user"
          style={
            {
              textDecoration: 'none',
              color: 'black',
              cursor: 'pointer',
            }
          }
          >
          <img src={userImage} alt="User" className="user-image" style={
            {
              objectFit: 'cover',
            }
          
          } />
          <div className="user-details">
            <span className="username">{userInfo ? userInfo.userName : ''}</span>
            <span className="user-role">{userInfo ? userInfo.roleName : ''}</span>
          </div></Link>
        <div className="sidebar-menu mb-5">
          <div className="collapse-sidebar-menu">
            <div className="d-flex align-items-center ">
              <Link to="/dashboard" className={location.pathname === "/dashboard" || location.pathname === "/" ? "custactive" : "cust"}
              onClick={() => handleToggle("dashboard")} >
                <i className="pi pi-home"></i>{" "}
                <span className="menu-title">Ana Sayfa</span>
              </Link>
            </div>
          </div>
          {/* Customer Menu */}
          {userInfo && (hasTopMenuPermission('/customer')) && (
          <div className="collapse-sidebar-menu">
            <div className={location.pathname.startsWith("/customer/") ? "d-flex align-items-center custactive" : "d-flex align-items-center cust" } onClick={() => handleToggle("customer")} role="button" >
              <i className="pi pi-users"></i>
              <span className="menu-title">Müşteri İşlemleri</span>
              <i className={`pi pi-angle-${openMenus.customer ? "up" : "down"}`}></i>
            </div>
            <Collapse in={openMenus.customer}>
              <ul className="list-unstyled">
                {hasPermission('/customer/add') && (
                  <li>
                    <Link to="/customer/add" className={location.pathname === "/customer/add" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Müşteri Kartı Aç</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/customer/approval-waiting') && (
                  <li>
                    <Link to="/customer/approval-waiting" className={location.pathname === "/customer/approval-waiting" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Onay Bekleyen Müşteriler</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/customer/list') && (
                  <li>
                    <Link to="/customer/list" className={location.pathname === "/customer/list" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Müşterileri Listele</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/customer/sms') && (
                  <li>
                    <Link to="/customer/sms" className={location.pathname === "/customer/sms" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Müşteri Sms İletişim</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/customer/call') && (
                  <li>
                    <Link to="/customer/call" className={location.pathname === "/customer/call" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Müşteri Arama</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/customer/test-drive') && (
                  <li>
                    <Link to="/customer/test-drive" className={location.pathname === "/customer/test-drive" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Test Sürüşleri</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
          )}
          {/* Stok Menu */}
          {userInfo && (hasTopMenuPermission('/stock')) && (
          <div className="collapse-sidebar-menu">
            <div className={location.pathname.startsWith("/stock/") ? "d-flex align-items-center custactive" : "d-flex align-items-center cust" } onClick={() => handleToggle("stock")} role="button" >
              <i className="pi pi-box"></i>
              <span className="menu-title">Stok İşlemleri</span>
              <i className={`pi pi-angle-${openMenus.stock ? "up" : "down"}`}></i>
            </div>
            <Collapse in={openMenus.stock}>
              <ul className="list-unstyled">
                {hasPermission("/stock/add") && (
                  <li>
                    <Link to="/stock/add" className={location.pathname === "/stock/add" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Stok Ekle</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/stock/list') && (
                  <li>
                    <Link to="/stock/list" className={location.pathname === "/stock/list" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Stok Listesi</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
          )}
          {/* Sale Menu */}
          {userInfo && (hasTopMenuPermission('/sale')) && (
          <div className="collapse-sidebar-menu">
            <div className={location.pathname.startsWith("/sale/") ? "d-flex align-items-center custactive" : "d-flex align-items-center cust" } onClick={() => handleToggle("sales")} role="button" >
              <i className="pi pi-shopping-cart"></i>
              <span className="menu-title">Satış İşlemleri</span>
              <i className={`pi pi-angle-${openMenus.sales ? "up" : "down"}`}></i>
            </div>
            <Collapse in={openMenus.sales}>
              <ul className="list-unstyled">
                {hasPermission('/sale/create') && (
                  <li>
                    <Link to="/sale/create" className={location.pathname === "/sale/create" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Satış Yap</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/sale/reserved-list') && (
                  <li>
                    <Link to="/sale/reserved-list" className={location.pathname === "/sale/reserved-list" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Bağlantıdaki Satışlar</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/sale/waiting-list') && (
                  <li>
                    <Link to="/sale/waiting-list" className={location.pathname === "/sale/waiting-list" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Aktif Satışlar</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/sale/features-list') && (
                  <li>
                    <Link to="/sale/features-list" className={location.pathname === "/sale/features-list" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Aksesuar Listesi</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/sale/accounting-list') && (
                  <li>
                    <Link to="/sale/accounting-list" className={location.pathname === "/sale/accounting-list" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Onay Bekleyenler</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
          )}
          {/* Reports Menu */}
          {userInfo && (hasTopMenuPermission('/reports')) && (
          <div className="collapse-sidebar-menu">
            <div className={location.pathname.startsWith("/reports/") ? "d-flex align-items-center custactive" : "d-flex align-items-center cust" } onClick={() => handleToggle("reports")} role="button" >
              <i className="pi pi-chart-line"></i>
              <span className="menu-title">Satış Raporları</span>
              <i className={`pi pi-angle-${openMenus.reports ? "up" : "down"}`}></i>
            </div>
            <Collapse in={openMenus.reports}>
              <ul className="list-unstyled">
              {hasPermission('/reports/daily') && (
                  <li>
                    <Link to="/reports/daily" className={location.pathname === "/reports/daily" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Günlük Satış Raporu</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/reports/sale-graphs') && (
                  <li>
                    <Link to="/reports/sale-graphs" className={location.pathname === "/reports/sale-graphs" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Aylık Satış Raporu</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/reports/employee-graphs') && (
                  <li>
                    <Link to="/reports/employee-graphs" className={location.pathname === "/reports/employee-graphs" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Aylık Çalışan Raporu</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
          )}
          {/* Request Menu */}
          {userInfo && (hasTopMenuPermission('/request')) && (
          <div className="collapse-sidebar-menu">
            <div className={location.pathname.startsWith("/request/") ? "d-flex align-items-center custactive" : "d-flex align-items-center cust" } onClick={() => handleToggle("request")} role="button" >
              <i className="pi pi-copy"></i>
              <span className="menu-title">Talep İşlemleri</span>
              <i className={`pi pi-angle-${openMenus.request ? "up" : "down"}`}></i>
            </div>
            <Collapse in={openMenus.request}>
              <ul className="list-unstyled">
                {hasPermission('/request/create-insurance-kasko') && (
                  <li>
                    <Link to="/request/create-insurance-kasko" className={location.pathname === "/request/create-insurance-kasko" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Trafik/Kasko Talebi Ekle</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/request/create-loan') && (
                  <li>
                    <Link to="/request/create-loan" className={location.pathname === "/request/create-loan" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Kredi Talebi Ekle</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/request/create-car') && (
                  <li>
                    <Link to="/request/create-car" className={location.pathname === "/request/create-car" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Araç Talebi Ekle</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/request/list-insurances') && (
                  <li>
                    <Link to="/request/list-insurances" className={location.pathname === "/request/list-insurances" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Trafik/Kasko Bekleyenler</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/request/list-approved-insurances') && (
                  <li>
                    <Link to="/request/list-approved-insurances" className={location.pathname === "/request/list-approved-insurances" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Onaylanmış Sigorta Listesi</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
          )}
          {/* System Menu */}
          {userInfo && (hasTopMenuPermission('/system')) && (
          <div className="collapse-sidebar-menu">
            <div className={location.pathname.startsWith("/system/") ? "d-flex align-items-center custactive" : "d-flex align-items-center cust" } onClick={() => handleToggle("system")} role="button" >
              <i className="pi pi-wrench"></i>
              <span className="menu-title">Sistem Ayarları</span>
              <i className={`pi pi-angle-${openMenus.system ? "up" : "down"}`}></i>
            </div>
            <Collapse in={openMenus.system}>
              <ul className="list-unstyled">
                {hasPermission('/system/models') && (
                  <li>
                    <Link to="/system/models" className={location.pathname === "/system/models" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Araç Model Ekle</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/colors') && (
                  <li>
                    <Link to="/system/colors" className={location.pathname === "/system/colors" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Renk Ekle</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/fuel-types') && (
                  <li>
                    <Link to="/system/fuel-types" className={location.pathname === "/system/fuel-types" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Yakıt Tipi Ayarları</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/tax-settings') && (
                  <li>
                    <Link to="/system/tax-settings" className={location.pathname === "/system/tax-settings" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Vergi Ayarları</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/payment-banks') && (
                  <li>
                    <Link to="/system/payment-banks" className={location.pathname === "/system/payment-banks" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Ödeme Banka Ayarları</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/credit-banks') && (
                  <li>
                    <Link to="/system/credit-banks" className={location.pathname === "/system/credit-banks" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Kredi Banka Ayarları</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/sms-templates') && (
                  <li>
                    <Link to="/system/sms-templates" className={location.pathname === "/system/sms-templates" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Sms Mesajları</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/marketing-channels') && (
                  <li>
                    <Link to="/system/marketing-channels" className={location.pathname === "/system/marketing-channels" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Etkileşim Ayarları</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/insurance-offices') && (
                  <li>
                    <Link to="/system/insurance-offices" className={location.pathname === "/system/insurance-offices" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Sigorta Kurumu Ayarları</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/system/announcements') && (
                  <li>
                    <Link to="/system/announcements" className={location.pathname === "/system/announcements" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Duyuru Ayarları</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
          )}
          {/* User Menu */}
          {userInfo && (hasTopMenuPermission('/user')) && (
          <div className="collapse-sidebar-menu">
            <div className={location.pathname.startsWith("/user/") ? "d-flex align-items-center custactive" : "d-flex align-items-center cust" } onClick={() => handleToggle("user")} role="button" >
              <i className="pi pi-copy"></i>
              <span className="menu-title">Kullanıcı İşlemleri</span>
              <i className={`pi pi-angle-${openMenus.request ? "up" : "down"}`}></i>
            </div>
            <Collapse in={openMenus.user}>
              <ul className="list-unstyled">
                {hasPermission('/user/create') && (
                  <li>
                    <Link to="/user/create" className={location.pathname === "/user/create" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Kullanıcı Oluştur</span>
                    </Link>
                  </li>
                )}
                {hasPermission('/user/list') && (
                  <li>
                    <Link to="/user/list" className={location.pathname === "/user/list" ? "active" : ""} >
                      <i className="pi pi-chevron-right"></i>{" "}
                      <span className="menu-title">Çalışan Listesi</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};


export default Sidebar;
