import React, { useEffect, useRef, useState } from 'react';
import DashboardRow from './DashboardRow';
import DailyCustomers from './DailyCustomers';
import '../../assets/styles/_dashboard.scss';
import DailyCallCustomers from './DailyCallCustomers';
import DashboardCalendar from './DashboardCalendar';
import 'react-datepicker/dist/react-datepicker.css';
import HrAnnounceEvent from './HrAnnounceEvent';
import { Toast } from 'primereact/toast';
import { getTodaysCalls } from '../services/dashboardService';

const Dashboard = () => {
  //Dashboard içerisindeki tüm tablolar max height ile yüksekliği sınırlandırıldı ve scroll bar getirildi.

  const toast = useRef(null);

  const [dailyCallCustomersData, setDailyCallCustomersData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getTodaysCalls();
        setDailyCallCustomersData(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Günlük arama listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Toast ref={toast} />

      <div className="dashboard-main col-12">
        <div>
          <DashboardRow waitingCallCount={dailyCallCustomersData.length} />
        </div>
        <div className="double-element-row">
          <div className="element table-div">
            {' '}
            <DailyCustomers />
          </div>
          <div className="element table-div">
            <DailyCallCustomers callData={dailyCallCustomersData} />
          </div>
        </div>

        <div className="double-element-row">
          <div className="element">
            <DashboardCalendar />
          </div>
          <div className="element table-div">
            <HrAnnounceEvent />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
