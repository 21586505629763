import React, { useState, useEffect, useRef } from "react";
import Cookies from 'js-cookie';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "react-bootstrap";
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import "../../assets/styles/_customer.scss";
import AddIndividualCustomer from "./AddIndividualCustomer";
import { deleteKvkkWaitingCustomer, getAllKvkkWaitingCustomersList } from "../services/customerService";

const getUserInfo = () => {
    return JSON.parse(Cookies.get("userInfo"));
};

const ListCustomerApprovalWaiting = () => {

    const [data, setClients] = useState([]);
    const toast = useRef(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [visible, setVisible] = useState(false);
    const [addCustomer, setAddCustomer] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [advancedSearch, setAdvancedSearch] = useState(false);

    // if user resends kvkk, name & surname & phonenumber send to addCustomer form.
    const [resendKvkkBody, setResendKvkkBody] = useState({
        name: null,
        surname: null,
        phoneNumber: null
    });

    // Get the user data from Cookies.
    useEffect(() => {

        const userData = getUserInfo();

        if (userData !== null) setUserInfo(userData);

    }, []);

    // datatable filter
    const [globalFilterValue, setGlobalFilterValue] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onFilter = (value, field) => {
        let _filters = { ...filters };
    
        if (value === null || value === undefined) {
          delete _filters[field];
        } else {
          _filters[field] = { value: value, matchMode: FilterMatchMode.CONTAINS };
        }
    
        setFilters(_filters);
      };

    // datatable search input
    const renderHeader = () => {
        return (
        <div className="d-flex justify-content-start">
        {advancedSearch ? (
          <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'name')}
              placeholder="Ad"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'surname')}
              placeholder="Soyad"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'phoneNumber')}
              placeholder="Telefon Numarası"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'advisorName')}
              placeholder="Danışman"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'createdAt')}
              placeholder="Kayıt Tarihi"
              className="rounded-3"
            />
            <Button
              type="button"
              icon="pi pi-times-circle"
              className="p-button-success rounded-3"
              tooltip="Filtrelemeyi Kapat"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })
              }
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Arama Yapın"
                className='rounded-3'
              />
            </div>
            <Button
              type="button"
              icon="pi pi-filter"
              className="p-button-success rounded-3 ms-2"
              tooltip="Detaylı Filtreleme"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })}
            />
          </div>
        )}
      </div>
        );
    };
    const header = renderHeader();

    // Kvkk onayı bekleyen müşterileri getiren endpoint
    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedData = await getAllKvkkWaitingCustomersList();
                if (Array.isArray(fetchedData)) {
                    setClients(fetchedData);
                } else {
                    throw new Error("Data is not an array");
                }
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Onay bekleyen müşteri listesi yüklenemedi. Sayfayı yenileyiniz.' });
            }
        }

        fetchData();
    }, []);

    // Kayıt tarihini formatlar
    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        const formattedTime = `${dateTime.getHours()}:${dateTime.getMinutes() < 10 ? '0' : ''}${dateTime.getMinutes()}`;
        return `${formattedDate} ${formattedTime}`;
    };

    // Müşteri İşlemleri
    const optionsTemplate = (rowData) => {
        return (
            <Dropdown>
                <Dropdown.Toggle className="btn btn-success bg-green">
                    <i class="pi pi-cog fs-5"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
                    <Dropdown.Item className="text-light border-bottom" onClick={(e) => resendKvkk({ rowData })}>Tekrar Gönder</Dropdown.Item>
                    <Dropdown.Item className="text-light" onClick={(e) => showDialog({ rowData })}>Sil</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    // Tekrar mesaj göndermek için 
    const resendKvkk = ({ rowData }) => {

        // gönderilecek data
        setResendKvkkBody({
            ['name']: rowData.name,
            ['surname']: rowData.surname,
            ['phoneNumber']: rowData.phoneNumber
        });

        // AddIndividualCustomer component show
        setAddCustomer(true);

    };

    const showDialog = ({ rowData }) => {
        setSelectedId(rowData.phoneNumber);
        setSelectedCustomer(rowData.name + ' ' + rowData.surname);
        setVisible(true);
      };
    
      const hideDialog = () => {
        setVisible(false);
      };
    // TODO
    const deleteCustomer = async () => {
        try {
            const result = await deleteKvkkWaitingCustomer(selectedId);
            if (result && result.isSuccess) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Başarılı',
                    detail: result.message,
                });
                // Reload customer data after successful deletion
                const newData = await getAllKvkkWaitingCustomersList();
                setClients(newData);
            } else {
                toast.current.show({
                    severity: 'warn',
                    summary: 'Hata',
                    detail: result.message
                        ? result.message
                        : 'Kayıt Silinemedi. Tekrar deneyiniz.',
                });
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Hata',
                detail: 'İşlem sırasında bir hata oluştu. Tekrar deneyiniz.',
            });
        }
        hideDialog(); // Close the dialog after delete operation
    };

    return (
        <>
            <div>
                {addCustomer ? (<AddIndividualCustomer resendKvkkBody={resendKvkkBody} />) : (
                    <div className="customerstyles">
                        <h4 className="mb-5">KVKK Onayı Bekleyen Müşteriler</h4>
                        <DataTable value={data} sortMode="multiple" paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: "70rem" }}
                            stripedRows
                            removableSort
                            filters={filters}
                            globalFilterFields={['name', 'surname', 'phoneNumber', 'advisorName', 'createdAt']}
                            header={header}
                            emptyMessage="Eşleşme bulunamadı."
                        >
                            <Column field="name" header="Ad" sortable />
                            <Column field="surname" header="Soyad" sortable />
                            <Column field="phoneNumber" header="Telefon Numarası" />
                            <Column field="advisorName" header="Danışman" body={(rowData) => `${rowData.advisorName}`} sortable />
                            <Column field="createdAt" header="Kayıt Tarihi" body={(rowData) => formatDateTime(rowData.createdAt)} sortable />
                            {/* if user is admin, add branch */}
                            {userInfo && userInfo.isAdmin && (<Column field="branchName" header="Marka" sortable />)}
                            <Column body={optionsTemplate} header="İşlem" />
                        </DataTable>
                    </div>
                )}
                <Toast ref={toast} />
            </div>
            {visible && (
                <div>
                    <Dialog
                        header="Emin misiniz?"
                        visible={visible}
                        style={{ width: '22vw' }}
                        onHide={hideDialog}
                        footer={
                            <div>
                                <Button
                                    label="Hayır"
                                    icon="pi pi-times"
                                    className="j-button bg-secondary text-light"
                                    onClick={hideDialog}
                                />
                                <Button
                                    label="Evet"
                                    icon="pi pi-check"
                                    className="j-button bg-green ms-2 text-light"
                                    onClick={deleteCustomer}
                                />
                            </div>
                        }
                    >
                        <div>
                            <span className="text-success fw-bold">{selectedCustomer}</span> adlı müşteriyi sileceksiniz. <span className="text-danger fw-bold">Bu işlem geri
                            alınamaz.</span>  Emin misiniz?
                        </div>
                    </Dialog>
                </div>
            )}
        </>
    );
};

export default ListCustomerApprovalWaiting;
