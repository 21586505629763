import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { getProfileInformation, updateProfileImage } from '../services/userService';
import EditUser from './EditUser';
import ChangePassword from './ChangePassword';
import ChangePhone from './ChangePhone';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';

const baseUrl = process.env.REACT_APP_API_URL;
const getToken = () => {
  return Cookies.get('token');
};

const ViewUserInformation = () => {
  const toast = useRef(null);
  const [handleEditProfile, setHandleEditProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [changePhone, setChangePhone] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [saveImageButton, setSaveImageButton] = useState(false);
  const token = getToken();

  const [userData, setUserData] = useState({
    name: null,
    surname: null,
    compNameCorporate: null,
    phoneNumber: null,
    internalPhone: null,
    email: null,
    branchName: null,
    parentName: null,
    role: null,
    idNumber: null,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getProfileInformation();
        setUserData(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Profil bilgisi yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${baseUrl}/api/User/GetUserImage`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            setUserImage(response.data);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    fetchData();
  }, []);

  const onUpload = (event) => {
    const file = event.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUserImage(reader.result);
    };

    reader.readAsDataURL(file);

    setSaveImageButton(true);
  };

  const handleSaveImage = async() => {
    const response = await updateProfileImage(userImage);
    if (response === null || !response.isSuccess) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Profil fotoğrafı güncellenemedi. Lütfen tekrar deneyiniz.',
      });
    }
    else{
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı',
        detail: 'Profil fotoğrafı başarıyla güncellendi.',
      });
      setSaveImageButton(false);
  }
}

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles">
        <div className="col-6 mb-5">
          <h4>Profil Bilgileri</h4>
        </div>
        <div className="bg-white rounded-3 py-4 px-5 d-flex align-items-center justify-content-between flex-wrap shadow ">
          <div className="d-flex flex-column align-items-center gap-1 col-12 col-lg-auto">
            <span className="fw-bold h4">
              {userData.name} {userData.surname}
            </span>
            <span className='mb-3'>{userData.role}</span>
          </div>
          <div className="d-flex flex-row align-items-center gap-2 d-none d-lg-flex col-lg-auto">
            <Button
              label="Profil Ayarları"
              icon="pi pi-pencil"
              className="j-button px-3 bg-green small"
              onClick={
                handleEditProfile === true
                  ? () => setHandleEditProfile(false)
                  : () => setHandleEditProfile(true)
              }
            />
            <Button
              label="Şifre Ayarları"
              icon="pi pi-key"
              className="j-button px-3 bg-green small"
              onClick={
                changePassword === true
                  ? () => setChangePassword(false)
                  : () => setChangePassword(true)
              }
            />
            <Button
              label="Telefon Ayarları"
              icon="pi pi-phone "
              className="j-button px-3 bg-green small"
              onClick={
                changePhone === true
                  ? () => setChangePhone(false)
                  : () => setChangePhone(true)
              }
            />
          </div>
          {/* Responsive */}
          <div className="d-flex col-12 flex-row justify-content-center align-items-center gap-2 d-lg-none col-lg-auto">
            <Button
              icon="pi pi-pencil"
              className="j-button bg-green small"
              onClick={
                handleEditProfile === true
                  ? () => setHandleEditProfile(false)
                  : () => setHandleEditProfile(true)
              }
            />
            <Button
              icon="pi pi-key"
              className="j-button bg-green small"
              onClick={
                changePassword === true
                  ? () => setChangePassword(false)
                  : () => setChangePassword(true)
              }
            />
            <Button
              icon="pi pi-phone "
              className="j-button bg-green small"
              onClick={
                changePhone === true
                  ? () => setChangePhone(false)
                  : () => setChangePhone(true)
              }
            />
          </div>
        </div>
        <div className="mt-5">
          <div className="rounded-3 d-flex flex-row gap-2 flex-wrap flex-lg-nowrap" style={{minHeight:"392px"}}>
            <div className="bg-white col-12 p-2 col-lg-4 d-flex flex-column align-items-center justify-content-center gap-4 rounded-4">
              <div className="position-relative changepp ">
                <div className="position-absolute top-50 start-50 translate-middle badge hide d-none d-lg-flex">
                  {saveImageButton === false && (
                    <FileUpload
                    mode="basic"
                    name="image"
                    url="/api/upload"
                    accept="image/*"
                    maxFileSize={1000000}
                    onSelect={onUpload}
                    chooseLabel="Resim Yükle"
                    className="j-button px-2 bg-transparent"
                    auto
                  />)}
                </div>
                <img
                  src={userImage}
                  alt="user"
                  className="rounded-circle border border-2 border-success shadow mt-2"
                  width="200"
                  height="200"
                  style={{ objectFit: 'cover' }}
                />
                <div className="position-absolute top-50 start-50 translate-middle">
                  {saveImageButton === true && (
                    <Button
                      label="Kaydet"
                      severity="success"
                      className="rounded-3 border"
                      onClick={handleSaveImage}
                    />)}
                </div>
                <div className="position-absolute top-0 end-0 d-flex d-lg-none">
                  {saveImageButton === false && (
                      <FileUpload
                      chooseOptions={{ iconOnly: true, icon: "pi pi-upload"}}
                      mode="basic"
                      name="image"
                      url="/api/upload"
                      accept="image/*"
                      maxFileSize={1000000}
                      onSelect={onUpload}
                      className="text-center"
                      auto
                    />)}
                    {saveImageButton === true && (
                    <Button
                      icon="pi pi-check"
                      severity="success"
                      className="j-button bg-success w-50"
                      onClick={handleSaveImage}
                    />)}
                </div>
              </div>
              <div className="d-flex flex-column text-center gap-2">
                <span className="h4">
                  {userData.name} {userData.surname}
                </span>
                <span className="h6">{userData.role}</span>
                <span className="small">{userData.branchName}</span>
              </div>
            </div>
            <div className="bg-white col-12 col-lg-8 p-3 rounded-4">
              <div className="col-12 ms-auto me-auto">
                {handleEditProfile === true ? (
                  <Dialog
                    header="Profil Düzenle"
                    visible={handleEditProfile}
                    style={{ minWidth: '40%', maxWidth: '700px' }}
                    onHide={() => setHandleEditProfile(false)}
                  >
                    <EditUser />
                  </Dialog>
                ) : changePassword === true ? (
                  <Dialog
                    header="Şifre Değiştir"
                    visible={changePassword}
                    className='col-10 col-md-6 col-lg-4 col-xxl-3'
                    onHide={() => setChangePassword(false)}
                  >
                    <ChangePassword />
                  </Dialog>
                ) : changePhone === true ? (
                  <Dialog
                    header="Telefon Değiştir"
                    visible={changePhone}
                    style={{ minWidth: '400px', maxWidth: '700px' }}
                    onHide={() => setChangePhone(false)}
                  >
                    <ChangePhone />
                  </Dialog>
                ) : (
                  <table className="customercard-info-right-list">
                    <tbody>
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-user d-none d-lg-inline"></i> Ad
                        </td>
                        <td> {userData.name ? userData.name : '-'}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-user d-none d-lg-inline"></i> Soyad
                        </td>
                        <td>{userData.surname ? userData.surname : '-'}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-id-card d-none d-lg-inline"></i> T.C. Kimlik Numarası
                        </td>
                        <td>{userData.idNumber ? userData.idNumber : '-'}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-phone d-none d-lg-inline"></i> Telefon
                        </td>
                        <td>
                          {userData.phoneNumber ? userData.phoneNumber : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-phone d-none d-lg-inline"></i> Sabit Numara
                        </td>
                        <td>
                          {userData.internalPhone
                            ? userData.internalPhone
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-at d-none d-lg-inline"></i> Email
                        </td>
                        <td>{userData.email ? userData.email : '-'}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-car d-none d-lg-inline"></i> Çalıştığı Marka
                        </td>
                        <td>
                          {userData.branchName ? userData.branchName : '-'}
                        </td>
                      </tr>
                      {userData.parentName !== ' ' && (
                        <>
                          <tr>
                            <td className="fw-bold">
                              <i className="pi pi-users d-none d-lg-inline"></i> Yöneticisi
                            </td>
                            <td>
                              {userData.parentName ? userData.parentName : '-'}
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td className="fw-bold">
                          <i className="pi pi-briefcase d-none d-lg-inline"></i> Rolü
                        </td>
                        <td>{userData.role ? userData.role : '-'}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUserInformation;
