import React from 'react';

const BilgiFormu = ({ data, date }) => {
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  // Fiyat formatlama 111.111,11
  const formatNumber = (number) => {
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  return (
    <div className="customerstyles">
      <div className="bilgi-formu">
        <div className="d-flex flex-row col-12">
          <div className="p-2 border border-secondary border-end-0 col-3">
            <span>{formatDateTime(date)}</span>
          </div>
          <div className="p-2 border border-secondary border-end-0 col-3">
            {data.branch}
          </div>
          <div className="p-2 border border-secondary border-end-0 col-3">
            NO : {data.saleNumber}
          </div>
          <div className="p-2 border border-secondary col-3">
            <span>Bilgi Formu</span>
          </div>
        </div>
        <div className="d-flex flex-row col-12">
          <div className="p-2 border border-secondary border-end-0 border-top-0 col-6 d-flex flex-column">
            <span>Adı Soyadı: {data.customerName}</span>
            <span>Adres: {data.adress}</span>
            <span>Telefon: {data.phoneNumber}</span>
            <span>Email: {data.email}</span>
          </div>
          <div className="p-2 border border-secondary col-6 border-top-0 d-flex flex-column">
            <span>Tc No: {data.idNumber}</span>
            <span>Baba Adı: -</span>
            <span>Doğum Tarihi: {formatDateTime(data.birthday)}</span>
            <span>Meslek: {data.job}</span>
          </div>
        </div>
        <div className="d-flex justify-content-center border border-secondary border-top-0 col-12 p-2 ">
          <span className="fw-bold">Araç Bilgisi</span>
        </div>
        <div className="d-flex flex-row col-12">
          <div className="p-2 border border-secondary border-end-0 border-top-0 col-6 d-flex flex-column">
            <span>Tip: {data.model}</span>
            <span>Model: {data.year}</span>
            <span>Renk: {data.color}</span>
          </div>
          <div className="p-2 border border-secondary col-6 border-top-0 d-flex flex-column">
            <span>Motor No: {data.motorNo}</span>
            <span>Şasi No: {data.sasiNo}</span>
          </div>
        </div>
        <div className="d-flex justify-content-center border border-secondary border-top-0 col-12 p-2 ">
          <span> Müşteri Özel İsteği</span>
        </div>
        <div className="d-flex justify-content-center border border-secondary border-top-0 col-12 p-2 ">
          <span>MÜŞTERİNİN ÖZEL İSTEĞİ BULUNMUYOR</span>
        </div>
        <div className="d-flex justify-content-center border border-secondary border-top-0 col-12 p-2 ">
          <span>Toplam Fiyat : {formatNumber(data.totalPrice)} ₺</span>
        </div>
        <div className="d-flex justify-content-center border border-secondary border-top-0 col-12 p-2 ">
          <span className="fw-bold">
            TRAFİK SİGORTASI VE KASKO ARAÇ FİYATINA DAHİL DEĞİLDİR
          </span>
        </div>
        <div className="d-flex justify-content-start border border-secondary border-top-0 col-12 p-2 ">
          <span>DANIŞMANIN NOTU BULUNMUYOR</span>
        </div>
        <div className="d-flex justify-content-center border border-secondary border-top-0 col-12 p-2 ">
          <span>ÖDEME PLANI (TL)</span>
        </div>
        <div className="d-flex flex-row col-12">
          <div className="p-2 border border-secondary border-end-0 col-4 border-top-0">
            <span className="fw-bold">Miktar</span>
          </div>
          <div className="p-2 border border-secondary border-end-0 col-4 border-top-0">
            <span className="fw-bold">Tarih</span>
          </div>
          <div className="p-2 border border-secondary col-4 border-top-0">
            <span className="fw-bold">AÇIKLAMA</span>
          </div>
        </div>
          {data.paymentPlans.map((plan, index) => (
            <div key={index} className="d-flex flex-row col-12">
              <div className="p-2 border border-secondary border-end-0 col-4 border-top-0">
                <span>{formatNumber(plan.amount)}</span>
              </div>
              <div className="p-2 border border-secondary border-end-0 col-4 border-top-0">
                <span>{formatDateTime(plan.paymentDate)}</span>
              </div>
              <div className="p-2 border border-secondary col-4 border-top-0">
                <span>{plan.paymentType}</span>
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-start border border-secondary border-top-0 col-12 p-2 ">
          <span>
            MÜŞTERİ AÇIKLAMASI: Yukarıdaki"MÜŞTERİ BİLGİSİ" doğrultusunda,"ÖDEME
            ŞEKLİ"nin ifasını müteakip Ek'de sunduğum/sunacağım Nufüs
            Cüzdanı/Oda Sicil Sureti/Vergi Levhası fotokopileri ve
            Vekaletnameyle;"ARAÇ BİLGİSİ" yazılı araca ait ÖTV ve KDV'nin ilgili
            kurumlara ödenip faturasını düzenlenmesini ve Tescil işlemlerinin
            yapılıp MTV'nin de ödenmesini rica ederim.Vergilerde doğabilecek
            değişikliğin,fiyata aynen yansıtılacağının tarafıma
            bildirildiğini,"TEKBAŞ"ın vergileri ödeyip aracın tesliminden
            sorumlu olduğunu;Tescil işlemlerinin,Vekaletnamem doğrultusunda
            yapıldığını ve bu işlemden dolayı "TEKBAŞ"ı tek taraflı ibra
            ettiğimi kabul ederim.Üretici ve/veya disribütör otomobilde herhangi
            bir teknik özellik renk,model,tip vb.donanımını önceden haber
            vermeden değiştirme hakkını saklı tutar.
          </span>
        </div>
        <div className="d-flex flex-row col-12">
          <div className="p-2 border border-secondary border-end-0 col-4 border-top-0 d-flex align-items-center">
            <span className="fw-bold">Müşteri Adı Soyadı</span>
          </div>
          <div className="p-2 border border-secondary border-end-0 col-4 border-top-0 d-flex align-items-center">
            <span className="fw-bold">Müşterinin Satış Danışmanı</span>
          </div>
          <div className="p-2 border border-secondary col-4 border-top-0">
            <span className="fw-bold">Muhasebeyi Bilgilendirecek Sorumlu Personel</span>
          </div>
        </div>
        <div className="d-flex flex-row col-12">
          <div className="p-2 border border-secondary border-end-0 col-4 border-top-0">
            <span>
              {data.customerName}
            </span>
          </div>
          <div className="p-2 border border-secondary border-end-0 col-4 border-top-0">
            <span>
              {data.advisorName}
            </span>
          </div>
          <div className="p-2 border border-secondary col-4 border-top-0">
            <span>
            {data.advisorName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BilgiFormu;
