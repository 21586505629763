import Cookies from 'js-cookie';


const baseUrl = process.env.REACT_APP_API_URL + '/api/InsuranceRequest';

const getToken = () => {
    return Cookies.get('token');
};

export async function getAllInsuranceRequests() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/GetInsuranceRequestList`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export async function deleteInsuranceRequest(id) {
    try {
  
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
  
      const response = await fetch(`${baseUrl}/DeleteInsuranceRequest`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id })
      });
  
      if (!response.ok) {
        return await response.json();
      }
  
      return await response.json();
    } catch (error) {
      return null;
    }
  };

  export async function getApprovedInsuranceRequestsByMonth(month) {
    try {
  
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
  
      const response = await fetch(`${baseUrl}/GetAllApprovedInsurancesByMonth`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ month: month })
      });
  
      if (!response.ok) {
        return await response.json();
      }
  
      return await response.json();
    } catch (error) {
      return null;
    }
  };