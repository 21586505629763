import React, { useEffect, useRef, useState } from "react";
import SharedNameDialog from "./SharedNameDialog";
import { Button } from "primereact/button";
import { getAllPaymentBanks } from "../services/paymentService";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ListPaymentTypes from "./ListPaymentTypes";
import SharedDeleteDialog from "./SharedDeleteDialog";

const ListPaymentBanks = () => {
  const toast = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [banksData, setBanksData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [value, setValue] = useState(null);
  const [header, setHeader] = useState(null);
  const [apiOption, setApiOption] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllPaymentBanks();
        setBanksData(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Ödeme banka listesi yüklenemedi. Sayfayı yenileyiniz.",
        });
      }
    }

    fetchData();
  }, [selectedId, value, showDialog, apiOption]);

  const handleEdit = (rowData) => {
    if (rowData.id === null) {
      setHeader("Banka Ekle");
    } else {
      setHeader("Banka Düzenle");
    }
    setSelectedId(rowData.id);
    setValue(rowData.name);
    setApiOption("paymentBank");
    setShowDialog(true);
  };

  const handleDelete = (rowData) => {
    setHeader("Banka Sil");
    setSelectedId(rowData.id);
    setApiOption("paymentBank");
    setShowDeleteDialog(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-8">
          <h4>Banka Ayarları</h4>
          <Button
            severity="success"
            className="rounded-3"
            onClick={() => handleEdit({ id: null, name: null })}
          >
            Banka Ekle
          </Button>
        </div>
        <div className="col-12 col-xxl-8">
          <DataTable value={banksData}>
            <Column className="col-8" field="name" header="Bankalar"></Column>
            <Column
            className="col-4 col-lg-2"
              body={(rowData) => (
                <>
                  <Button
                    className="p-button-rounded rounded-3 p-button-success"
                    icon="pi pi-pencil"
                    onClick={() => handleEdit(rowData)}
                  />
                  <Button
                  className="p-button-rounded rounded-3 bg-blue ms-1"
                    icon="pi pi-trash"
                    onClick={() => handleDelete(rowData)}
                  />
                </>
              )}
            />
          </DataTable>

          <SharedDeleteDialog
            showDeleteDialog={showDeleteDialog}
            setShowDeleteDialog={setShowDeleteDialog}
            id={selectedId}
            setSelectedId={setSelectedId}
            header={header}
            setHeader={setHeader}
            apiOption={apiOption}
            setApiOption={setApiOption}
          />

          <SharedNameDialog
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            id={selectedId}
            setSelectedId={setSelectedId}
            header={header}
            setHeader={setHeader}
            value={value}
            setValue={setValue}
            apiOption={apiOption}
            setApiOption={setApiOption}
          />
        </div>
        </div>
        <ListPaymentTypes />
      
    </>
  );
};

export default ListPaymentBanks;
