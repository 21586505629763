import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getAllFeaturesByBranchId } from '../services/saleService';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const ListFeatures = () => {

  const toast = useRef(null);

  const [featureData, setFeatureData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        if (selectedMonth === null || selectedYear === null) return;
        const data = await getAllFeaturesByBranchId(selectedMonth, selectedYear);
        setFeatureData(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Aksesuar listesi yüklenemedi. Yeniden deneyiniz.",
        });
      }
    }
    fetchData();
  }, [selectedMonth, selectedYear]);

  // Ay ve yıl seçimleri için seçenekleri oluştur
  const months = [
    { label: 'Ocak', value: 1 },
    { label: 'Şubat', value: 2 },
    { label: 'Mart', value: 3 },
    { label: 'Nisan', value: 4 },
    { label: 'Mayıs', value: 5 },
    { label: 'Haziran', value: 6 },
    { label: 'Temmuz', value: 7 },
    { label: 'Ağustos', value: 8 },
    { label: 'Eylül', value: 9 },
    { label: 'Ekim', value: 10 },
    { label: 'Kasım', value: 11 },
    { label: 'Aralık', value: 12 },
  ];

  const formatNumber = (number) => {
    if(number === 0) return 0;
    if (!number || isNaN(number)) return '';

    const formattedNumber = parseFloat(number).toLocaleString('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Kuruş kısmı 00 ile bitiyorsa, sadece tam sayı olarak göster
    if (formattedNumber.endsWith('00')) {
      return parseFloat(number).toLocaleString('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return formattedNumber;
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    model: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sasiNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    aksesuar: { value: null, matchMode: FilterMatchMode.CONTAINS },
    tb: { value: null, matchMode: FilterMatchMode.CONTAINS },
    mini: { value: null, matchMode: FilterMatchMode.CONTAINS },
    note: { value: null, matchMode: FilterMatchMode.CONTAINS },
    saleStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
    advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
    totalSalePrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllFeaturesByBranchId();
        setFeatureData(data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Aksesuar listesi yüklenemedi. Yeniden deneyiniz.",
        });
      }
    }

    fetchData();
  }, []);

  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
    setFilters({ ...filters, global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS } });
  };

  const onFilter = (value, field) => {
    let _filters = { ...filters };

    if (value === null || value === undefined) {
      delete _filters[field];
    } else {
      _filters[field] = { value: value, matchMode: FilterMatchMode.CONTAINS };
    }

    setFilters(_filters);
  };

  // datatable search input
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-start">
        {advancedSearch ? (
          <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'customerName')}
              placeholder="Ad Soyad"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'model')}
              placeholder="Araç Modeli"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'sasiNo')}
              placeholder="Şasi Numarası"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'aksesuar')}
              placeholder="Aksesuar"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'tb')}
              placeholder="Tb"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'mini')}
              placeholder="Mini Onarım"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'note')}
              placeholder="Not"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'saleStatus')}
              placeholder="Satış Durumu"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'advisorName')}
              placeholder="Danışmanı"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'createdAt')}
              placeholder="Kayıt Tarihi"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'totalSalePrice')}
              placeholder="Anahtar Teslim Tutar"
              className="rounded-3"
            />
            <Button
              type="button"
              icon="pi pi-times-circle"
              className="p-button-success rounded-3"
              tooltip="Filtrelemeyi Kapat"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                model: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sasiNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
                aksesuar: { value: null, matchMode: FilterMatchMode.CONTAINS },
                tb: { value: null, matchMode: FilterMatchMode.CONTAINS },
                mini: { value: null, matchMode: FilterMatchMode.CONTAINS },
                note: { value: null, matchMode: FilterMatchMode.CONTAINS },
                saleStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
                totalSalePrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })
              }
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Arama Yapın"
                className='rounded-3'
              />
            </div>
            <Button
              type="button"
              icon="pi pi-filter"
              className="p-button-success rounded-3 ms-2"
              tooltip="Detaylı Filtreleme"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                model: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sasiNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
                aksesuar: { value: null, matchMode: FilterMatchMode.CONTAINS },
                tb: { value: null, matchMode: FilterMatchMode.CONTAINS },
                mini: { value: null, matchMode: FilterMatchMode.CONTAINS },
                note: { value: null, matchMode: FilterMatchMode.CONTAINS },
                saleStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
                totalSalePrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })}
            />
          </div>
        )}
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <Toast ref={toast} />
      <div className='customerstyles mb-5'>
        <h4 className="mb-5">Aksesuar Listesi</h4>
        <div className="p-grid d-flex mb-2">
          <div className="p-col-6 me-4">
            <Dropdown
              value={selectedMonth}
              options={months}
              onChange={(e) => setSelectedMonth(e.value)}
              placeholder="Ay Seçin"
            />
          </div>
          <div className="p-col-6">
            <Dropdown
              value={selectedYear}
              options={years.map(year => ({ label: String(year), value: year }))}
              onChange={(e) => setSelectedYear(e.value)}
              placeholder="Yıl Seçin"
            />
          </div>
        </div>
        <DataTable
          value={featureData}
          emptyMessage="Kayıt Bulunamadı"
          header={header}
          globalFilter={globalFilterValue}
          filters={filters}
          tableStyle={{ minWidth: "70rem" }}>
          <Column field="customerName" header="Ad Soyad" style={{ minWidth: '120px' }} />
          <Column field="model" header="Araç Modeli" style={{ minWidth: '240px' }} />
          <Column field="sasiNo" header="Şasi Numarası" />
          <Column field="aksesuar" header="Aksesuar" body={(rowData) => formatNumber(rowData.aksesuar)} />
          <Column field="tb" header="Tb" body={(rowData) => formatNumber(rowData.tb)} />
          <Column field="mini" header="Mini Onarım" body={(rowData) => formatNumber(rowData.mini)}/>
          <Column field="note" header="Not" />
          <Column field="saleStatus" header="Satış Durumu" />
          <Column field="advisorName" header="Danışmanı" />
          <Column field="createdAt" header="Kayıt Tarihi" body={(rowData) => formatDate(rowData.createdAt)} sortable />
          <Column field="totalSalePrice" header="Anahtar Teslim Tutar" />
        </DataTable>
      </div>
    </>
  )
}

export default ListFeatures;