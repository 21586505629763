import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { getProfileInformation, updateProfileInformation } from '../services/userService';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

const EditUser = () => {
  const toast = useRef(null);
  const [profileUpdateSuccessDialog, setProfileUpdateSuccessDialog] = useState(false);

  const [userData, setUserData] = useState({
    name: null,
    surname: null,
    compNameCorporate: null,
    phoneNumber: null,
    internalPhone: null,
    email: null,
    branchName: null,
    parentName: null,
    role: null,
    idNumber: null,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getProfileInformation();
        setUserData(data);
      } catch (error) {
        profileUpdateSuccessDialog(true);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Profil bilgisi yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  const handleSave = async () => {
    const response = await updateProfileInformation(userData);
    if (response === null || !response.isSuccess) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: response.message
          ? response.message
          : 'Profil bilgileri güncellenemedi. Lütfen tekrar deneyiniz.',
      });
    }
    else{
      setProfileUpdateSuccessDialog(true);
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Güncelleme"
        visible={profileUpdateSuccessDialog}
        style={{ width: '20vw' }}
        modal={true}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
        <i className="pi pi-check-circle mb-3" style={{ fontSize: '4rem', color: 'green' }}></i>
        <span>Profil bilgileriniz başarıyla güncellendi.</span>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            label="Tamam"
            className="mt-4 j-button bg-green"
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      </Dialog>
      <div className="customerstyles d-flex flex-column align-items-center justify-content-center">
        <table className="customercard-info-right-list">
          <tbody>
            <tr>
              <td className="fw-bold">
                <i className="pi pi-user"></i> Ad
              </td>
              <td className='w-auto'>
                <InputText
                  value={userData.name ? userData.name : '-'}
                  className="rounded-3"
                  onChange={(e) => {
                    setUserData({ ...userData, name: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <i className="pi pi-user"></i> Soyad
              </td>
              <td>
                <InputText
                  value={userData.surname ? userData.surname : '-'}
                  className="rounded-3"
                  onChange={(e) => {
                    setUserData({ ...userData, surname: e.target.value });
                  }
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <i className="pi pi-id-card"></i> T.C. Kimlik Numarası
              </td>
              <td>
                <InputText
                  value={userData.idNumber ? userData.idNumber : '-'}
                  className="rounded-3"
                  onChange={(e) => {
                    setUserData({ ...userData, idNumber: e.target.value });
                  }
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <i className="pi pi-phone"></i> Telefon
              </td>
              <td>
                <InputText
                  value={userData.phoneNumber ? userData.phoneNumber : '-'}
                  className="rounded-3"
                  onChange={(e) => {
                    setUserData({ ...userData, phoneNumber: e.target.value });
                  }}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <i className="pi pi-phone"></i> Sabit Numara
              </td>
              <td>
                <InputText
                  value={userData.internalPhone ? userData.internalPhone : '-'}
                  className="rounded-3"
                  onChange={
                    (e) => {
                      setUserData({ ...userData, internalPhone: e.target.value });
                    }
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <i className="pi pi-at"></i> Email
              </td>
              <td>
                <InputText
                  value={userData.email ? userData.email : '-'}
                  className="rounded-3"
                  onChange={
                    (e) => {
                      setUserData({ ...userData, email: e.target.value });
                  }
                }
                />
              </td>
            </tr>
            <tr>
              <td className="fw-bold">
                <i className="pi pi-car"></i> Çalıştığı Marka
              </td>
              <td>
                <InputText
                  value={userData.branchName ? userData.branchName : '-'}
                  className="rounded-3"
                  disabled
                />
              </td>
            </tr>
            {userData.parentName !== ' ' && (
              <>
                <tr>
                  <td className="fw-bold">
                    <i className="pi pi-users"></i> Yöneticisi
                  </td>
                  <td>
                    <InputText
                      value={userData.parentName ? userData.parentName : '-'}
                      className="rounded-3"
                      disabled
                    />
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td className="fw-bold">
                <i className="pi pi-briefcase"></i> Rolü
              </td>
              <td>
                <InputText
                  value={userData.role ? userData.role : '-'}
                  className="rounded-3"
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex justify-content-center">
          <Button
            label="Kaydet"
            className="mt-3 j-button bg-green w-100"
            onClick={() => {
              handleSave()
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditUser;
