import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};

const responseDto = {
    isSuccess: null,
    message: null,
    dataId: null
};

// Gets the Customers saved to the system.
export async function getAllCustomersList() {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetAllCustomersList`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error("Failed to fetch model codes");
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};


export async function getAllKvkkWaitingCustomersList() {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetKvkkWaitingCustomersList`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};

export async function getIndividualCustomerCardById(id) {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetIndividualCustomerCardById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};

export async function getCorporateCustomerCardById(id) {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetCorporateCustomerCardById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};

export async function getIndividualCustomerEditCardById(id) {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetIndividualCustomerEditCardById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};

export async function getCorporateCustomerEditCardById(id) {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetCorporateCustomerEditCardById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};

export async function deleteCustomer(id) {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/DeleteCustomer`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};

export async function deleteKvkkWaitingCustomer(phoneNumber) {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/DeleteKvkkWaitingCustomer`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ phoneNumber: phoneNumber }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Müşteri silinirken bir hata oluştu.");
    }
};

export async function getAllCustomerNamesList() {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetAllCustomerNamesList`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            const res = await response.json();
            responseDto.isSuccess = res
        }

        return await response.json();

    } catch (error) {
        responseDto.isSuccess = false;
        responseDto.message = "Müşteriler yüklenirken bir hata oluştu.";
        responseDto.dataId = null;
        return responseDto;
    }
};

export async function getCustomerInfoById(id) {
    try {
        const token = getToken();
        const response = await fetch(`${baseUrl}/api/Customer/GetCustomerInfoById`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch model codes");
    }
};