import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const baseUrl = process.env.REACT_APP_API_URL + '/api/Color';

const getToken = () => {
  return Cookies.get('token');
};

export async function getAllColors() {
  
  const token = getToken();
  if (!token) {
    Cookies.set('authorized', false);
  }

  return fetch(`${baseUrl}/GetAllColors`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
  })
    .then(response => {
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .catch(error => {
      return null;
    });
};

export async function addColor(name) {

  const token = getToken();
  if (!token) {
    Cookies.set('authorized', false);
  }

  return fetch(`${baseUrl}/AddColor`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({ name: name })
  })
    .then(response => {

       return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .catch(error => {
      return null;
    });
};

export async function updateColor(id, name) {

  const token = getToken();
  if (!token) {
    Cookies.set('authorized', false);
  }

  return fetch(`${baseUrl}/UpdateColor`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({ id: id, name: name })
  })
    .then(response => {
       return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .catch(error => {
      return null;
    });
};


export async function deleteColor(id) {

  const token = getToken();
  if (!token) {
    Cookies.set('authorized', false);
  }

  return fetch(`${baseUrl}/DeleteColor`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({ id: id })
  })
    .then(response => {

       return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .catch(error => {
      return null;
    });
};