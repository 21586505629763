import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL + '/api/ExchangeRequest';

const getToken = () => {
    return Cookies.get('token');
};

export async function RejectExchangeRequest(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/RejectExchangeRequest`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch Exchange Request")
    }
};

export async function CancelExchangeRequest(id) {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/CancelExchangeRequest`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({id: id})
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        throw new Error("Failed to fetch Exchange Request")
    }
};


export async function addExchangeRequest(requestBody) {

    const token = getToken();
    if (!token) {
        //TODO
        return;
    }

    return fetch(`${baseUrl}/AddExchangeRequest`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody)
    })
    .then(response => response.json())
    .then(responseData => {
        return responseData;
    })
    .catch(error => {
        return null;
    });
};