import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react'
import { addExchangeRequest } from '../services/exchangeRequestService';

const AddExchangeRequestDialog = ({ showDialog, setShowDialog, exchangeForm, setExchangeForm }) => {
    const toast = useRef(null);

    const handleHide = async () => {
        setExchangeForm(null);
        setShowDialog(false);
    };

    const handlleSave = async () => {
        // satış gör -> takas ekle gelen takas ekle
        if (exchangeForm.saleId) {

            const response = await addExchangeRequest(exchangeForm);
            if (response === null || !response.isSuccess) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: response.message
                        ? response.message
                        : 'Takas talebi oluşturulamadı. Lütfen tekrar deneyiniz.',
                });
            } else {
                toast.current.show({
                    severity: 'success',
                    summary: 'Başarılı',
                    detail: response.message
                        ? response.message
                        : ' Takas talebi basarıyla oluşturuldu.',
                });

                setShowDialog(false);
                setExchangeForm((prevState) => ({
                    ...prevState,
                    brand: null,
                    model: null,
                    year: null,
                    package: null,
                    gearType: null,
                    fuelType: null,
                    km: null,
                    color: null,
                    damage: null,
                    note: null,
                    customerExpectation: null,
                    saleId: null
                }));
            }
        }
        else {
            setShowDialog(false);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;


        setExchangeForm({
            ...exchangeForm,
            [name]: value
        });
    }

    return (
        <div className='customerstyles'>
            <Toast ref={toast} />
            <Dialog className='dialog' visible={showDialog} onHide={handleHide} header="Takas Formu" modal>

                <label htmlFor="brand">Marka</label>
                <InputText id="brand" name="brand" value={exchangeForm.brand} onChange={(e) => handleChange(e)} />

                <label htmlFor="model">Model</label>
                <InputText id="model" name="model" value={exchangeForm.model} onChange={(e) => handleChange(e)} />

                <label htmlFor="year">Yıl</label>
                <InputText id="year" name="year" value={exchangeForm.year} onChange={(e) => handleChange(e)} />

                <label htmlFor="package">Paket</label>
                <InputText id="package" name="package" value={exchangeForm.package} onChange={(e) => handleChange(e)} />

                <label htmlFor="gearType">Vites Tipi</label>
                <InputText id="gearType" name="gearType" value={exchangeForm.gearType} onChange={(e) => handleChange(e)} />

                <label htmlFor="fuelType">Yakıt Tipi</label>
                <InputText id="fuelType" name="fuelType" value={exchangeForm.fuelType} onChange={(e) => handleChange(e)} />

                <label htmlFor="color">Renk</label>
                <InputText id="color" name="color" value={exchangeForm.color} onChange={(e) => handleChange(e)} />

                <label htmlFor="km">KM</label>
                <InputText id="km" name="km" value={exchangeForm.km} onChange={(e) => handleChange(e)} />

                {/* //TODO InputNumber a çekilecek */}
                <label htmlFor="customerExpectation">Müşteri Beklentisi</label>
                <InputText id="customerExpectation" name="customerExpectation" value={exchangeForm.customerExpectation} onChange={(e) => handleChange(e)} />

                <label htmlFor="damage">Hasar Kaydı</label>
                <InputText id="damage" name="damage" value={exchangeForm.damage} onChange={(e) => handleChange(e)} />

                <label htmlFor="note">Not</label>
                <InputText id="note" name="note" value={exchangeForm.note} onChange={(e) => handleChange(e)} />

                <div className="mt-3 text-end">
                    <Button className="rounded-3" severity="success" label="Ekle" onClick={handlleSave} />
                </div>
            </Dialog>
        </div>
    )
}

export default AddExchangeRequestDialog