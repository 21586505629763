import React, { useEffect, useRef, useState } from 'react';
import {
  getAllCustomerNamesList,
  getAllSasiNoStocks,
  getAllSasiNoStocksForRequest,
} from '../services/saleService';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { Toast } from 'primereact/toast';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
  return Cookies.get('token');
};

const AddInsuranceRequest = () => {
  const toast = useRef(null);
  const [customerList, setCustomerList] = useState([]);
  const [stockSasiList, setStockSasiList] = useState([]);

  const [formData, setFormData] = useState({
    customerId: null,
    stockId: null,
    otvMuaf: false,
    status: 0,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllCustomerNamesList();

        if (fetchedData === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Müşteri listesi yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
        setCustomerList(fetchedData);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşteri listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllSasiNoStocksForRequest();
        if (fetchedData === null) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
        setStockSasiList(fetchedData);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Stok listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  const handleDropdownChange = (e, fieldName) => {
    const selectedValue = e.value;

    setFormData({
      ...formData,
      [fieldName]: selectedValue,
    });
  };

  const handleSetotvMuaf = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      ['otvMuaf']: e.checked,
    }));
  };

  const handleSave = async () => {
    const reqDto = {
      customerId: formData.customerId.id,
      stockId: formData.stockId.id,
      otvMuaf: formData.otvMuaf,
      status: 0,
    };

    try {
      const token = getToken();
      if (!token) {
        //TODO
        return;
      }
      const response = await fetch(
        `${baseUrl}/api/InsuranceRequest/AddInsuranceRequest`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(reqDto),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: errorData.message
            ? errorData.message
            : 'Talep kaydedilemedi. Lütfen tekrar deneyiniz.',
        });
        return;
      }

      const data = await response.json();

      toast.current.show({
        severity: 'success',
        summary: 'Kayıt Başarılı',
        detail: data.message ? data.message : 'Talep başarıyla oluşturuldu.',
      });

      handleCancel();
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bir hata oluştu. Lütfen kaydı tekrar alınız.',
      });
    }
  };

  const handleCancel = () => {
    setFormData({
      customerId: null,
      stockId: null,
      otvMuaf: false,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="customerstyles">
        <div className="d-block d-lg-flex align-items-center justify-content-between mb-5 col-12 col-xxl-6">
          <h4>Trafik/Kasko Talebi Ekle</h4>
        </div>
        <div className="d-block d-lg-flex col-12 col-xxl-8">
          <span className="p-float-label col-12 col-lg-4 mb-3">
            <Dropdown
              id="customer"
              name="customer"
              className="inputgap"
              value={formData.customerId}
              onChange={(e) => handleDropdownChange(e, 'customerId')}
              options={customerList}
              optionLabel="name"
              filter
              showClear
            />
            <label htmlFor="customer">Müşteri</label>
          </span>
          <span className="p-float-label col-12 col-lg-4 mb-3 me-3">
            <Dropdown
              id="stock"
              name="stock"
              className="inputgap"
              value={formData.stockId}
              onChange={(e) => handleDropdownChange(e, 'stockId')}
              options={stockSasiList}
              optionLabel="sasiNumber"
              filter
              showClear
            />
            <label htmlFor="stock">Şasi Numarası</label>
          </span>
          <div className="d-flex align-items-center justify-content-start mb-3">
            <Checkbox
              id="otvMuaf"
              name="otvMuaf"
              className="me-2"
              onChange={(e) => handleSetotvMuaf(e)}
              checked={formData.otvMuaf}
            />
            <label htmlFor="otvMuaf">Ötv Muaf</label>
          </div>
        </div>
        <div className="d-flex gap-2 mt-4">
          <Button
            className="rounded-3 j-button"
            severity="secondary"
            label="İptal"
            onClick={handleCancel}
          />
          <Button
            className="rounded-3 j-button"
            severity="success"
            label="Kaydet"
            onClick={handleSave}
          />
        </div>
      </div>
    </>
  );
};

export default AddInsuranceRequest;
