import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Cookies from 'js-cookie';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { getAllTestDrives } from '../services/testDriveService';
import '../../assets/styles/_customer.scss';
import { Button } from 'primereact/button';

const getUserInfo = () => {
    return JSON.parse(Cookies.get("userInfo"));
};

const ListTestDrives = () => {
    const [testDriveData, setTestDriveData] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const toast = useRef(null);

    // datatable filter
    const [globalFilterValue, setGlobalFilterValue] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    useEffect(() => {

        const userData = getUserInfo();

        if (userData !== null) setUserInfo(userData);

    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onFilter = (value, field) => {
        let _filters = { ...filters };
    
        if (value === null || value === undefined) {
          delete _filters[field];
        } else {
          _filters[field] = { value: value, matchMode: FilterMatchMode.CONTAINS };
        }
    
        setFilters(_filters);
      };
    // datatable search input
    const renderHeader = () => {
        return (
          <div className="d-flex justify-content-start">
            {advancedSearch ? (
              <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
                <InputText
                  type="text"
                  onInput={(e) => onFilter(e.target.value, 'customerName')}
                  placeholder="Ad Soyad"
                  className="rounded-3"
                />
                <InputText
                  type="text"
                  onInput={(e) => onFilter(e.target.value, 'model')}
                  placeholder="Araç Modeli"
                  className="rounded-3"
                />
                <InputText
                  type="text"
                  onInput={(e) => onFilter(e.target.value, 'advisorName')}
                  placeholder="Danışman"
                  className="rounded-3"
                />
                <InputText
                  type="text"
                  onInput={(e) => onFilter(e.target.value, 'createdAt')}
                  placeholder="Kayıt Tarihi"
                  className="rounded-3"
                />
                <Button
                  type="button"
                  icon="pi pi-times-circle"
                  className="p-button-success rounded-3"
                  tooltip="Filtrelemeyi Kapat"
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    model: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  })
                  }
                />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Arama Yapın"
                    className='rounded-3'
                  />
                </div>
                <Button
                  type="button"
                  icon="pi pi-filter"
                  className="p-button-success rounded-3 ms-2"
                  tooltip="Detaylı Filtreleme"
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    model: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  })}
                />
              </div>
            )}
          </div>
        );
      };
      const header = renderHeader();

    useEffect(() => {
        async function fetchData() {
          try {
            const data = await getAllTestDrives();
            setTestDriveData(data);
          } catch (error) {
            toast.current.show({
              severity: 'error',
              summary: 'Hata',
              detail: 'Test sürüşü listesi yüklenemedi. Sayfayı yenileyiniz.',
            });
          }
        }
        fetchData();
    }, []);

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
    };

    return (
        <>
            <div>
                <div className="customerstyles">
                    <h4 className="mb-5">Test Sürüşü Listesi</h4>
                    <DataTable
                        value={testDriveData}
                        sortMode="multiple"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '70rem' }}
                        stripedRows
                        removableSort
                        filters={filters}
                        globalFilterFields={[
                            'customerName',
                            'model',
                            'advisorName',
                        ]}
                        header={header}
                        emptyMessage="Eşleşme bulunamadı."
                    >
                        <Column
                            field="customerName"
                            header="Müşteri Adı"
                            sortable
                        />
                        {userInfo && userInfo.isAdmin && (<Column field="branchName" header="Marka" sortable />)}
                        <Column field="model" header="Araç Modeli" sortable />
                        <Column
                            field="advisorName"
                            header="Danışman"
                            body={(rowData) => `${rowData.advisorName}`}
                            sortable
                        />
                        <Column
                            field="createdAt"
                            header="Kayıt Tarihi"
                            body={(rowData) => formatDateTime(rowData.createdAt)}
                            sortable
                        />
                    </DataTable>
                </div>
                <Toast ref={toast} />
            </div>
        </>
    );
};

export default ListTestDrives;