import React from 'react';
import noPerm from "../../assets/images/noperm.png";

const NoPermission = () => {
  return (
    <div className='text-center'>
        <img src={noPerm} alt="Erişim Yetkiniz Bulunmuyor" style={{
        width: '80%',
        maxWidth: '278px',
        
    }} />
      <h1 className='my-4'>Erişim Yetkiniz Bulunmuyor</h1>
      <p>Bu sayfayı görüntülemek için gerekli izinlere sahip değilsiniz.</p>
    </div>
  );
};

export default NoPermission;