import React, { useRef, useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { deletePaymentType, deletePaymentBank } from '../services/paymentService';
import { Toast } from 'primereact/toast';
import { deleteFuelType, deleteInsuranceOffice, deleteMarketingChanel } from '../services/systemService';
import { deleteModelCode, deleteModelName } from '../services/modelService';
import { deleteColor } from '../services/colorService';
import { CancelExchangeRequest, RejectExchangeRequest } from '../services/exchangeRequestService';
import { closeCarRequest, deleteCarRequest } from '../services/carRequestService';
import { deleteInsuranceRequest } from '../services/insuranceService';

const SharedDeleteDialog = ({ showDeleteDialog, setShowDeleteDialog, id, setSelectedId, header, setHeader, apiOption, setApiOption }) => {
  const toast = useRef(null);

  const handleSave = async () => {
    setShowDeleteDialog(false);
    try {
      let result = '';
      if (apiOption === 'paymentBank') {
        result = await deletePaymentBank(id);
      } else if (apiOption === 'paymentType') {
        result = await deletePaymentType(id);
      } else if (apiOption === 'color') {
        result = await deleteColor(id);
      } else if (apiOption === 'modelCode') {
        result = await deleteModelCode(id);
      } else if (apiOption === 'modelName') {
        result = await deleteModelName(id);
      } else if (apiOption === 'fuelType') {
        result = await deleteFuelType(id);
      } else if (apiOption === 'marketingChanel') {
        result = await deleteMarketingChanel(id);
      } else if (apiOption === 'insuranceOffice') {
        result = await deleteInsuranceOffice(id);
      } else if (apiOption === 'rejectExchangeReq') {
        result = await RejectExchangeRequest(id);
      } else if (apiOption === 'cancelExchangeReq') {
        result = await CancelExchangeRequest(id);
      } else if (apiOption === 'carRequest') {
        result = await deleteCarRequest(id);
      } else if (apiOption === 'closeCarRequest') {
        result = await closeCarRequest(id);
      } else if (apiOption === 'deleteInsuranceRequest') {
        result = await deleteInsuranceRequest(id);
      }

      if (result && result.isSuccess) {
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: result.message });
      } else {
        toast.current.show({ severity: 'warn', summary: 'Hata', detail: result.message ? result.message : 'Kayıt Silinemedi. Tekrar deneyiniz.' });
      };
      handleHide();
    } catch {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu. Tekrar deneyiniz.' });
    }
  }

  const handleHide = () => {
    setSelectedId(null);
    setHeader(null);
    setApiOption(null);
    setShowDeleteDialog(false);
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog className='text-center' visible={showDeleteDialog} onHide={handleHide} header={header} modal>
        <span className='mx-2 fs-5'> Emin misiniz? <span className='text-danger fw-bold'>Bu işlem geri alınamaz.</span> </span>
        <div className="mt-4 text-end">
          <Button className="rounded-3" severity="secondary" label="İptal" onClick={handleHide} />
          <Button className="rounded-3 ms-2" severity="success" label="Onayla" onClick={handleSave} />
        </div>
      </Dialog>
    </>
  );
};

export default SharedDeleteDialog;
