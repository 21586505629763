import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    return Cookies.get('token');
};


export async function getLoanBanksNameList() {
    try {

        const token = getToken();
        if (!token) {
            //TODO
            return;
        }

        const response = await fetch(`${baseUrl}/api/LoanBank/GetLoanBanksNameList`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return await response.json();
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};