import React from 'react';
import noPerm from "../../assets/images/404.png";

const NotFound = () => (
    <div className='text-center'>
    <img src={noPerm} alt="Sayfa Bulunamadı" style={{
        width: '80%',
        maxWidth: '590px',
        
    }}/>
  <h1 className='my-4'>Sayfa Bulunamadı</h1>
  <p>Aradığınız sayfa bulunamadı. Lütfen URL'yi kontrol edin veya ana sayfaya dönün.</p>
</div>
);

export default NotFound;