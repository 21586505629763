import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_URL + '/api';

const getToken = () => {
  return Cookies.get('token');
};

const responseDto = {
  isSuccess: null,
  message: null,
  dataId: null
}

// Branches
export async function getAllBranches() {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/Branch/GetAllBranches`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const res = await response.json();
      responseDto.isSuccess = res
    }

    return await response.json();

  } catch (error) {
    responseDto.isSuccess = false;
    responseDto.message = "Markalar yüklenirken bir hata oluştu.";
    responseDto.dataId = null;
    return responseDto;
  }
};

// Model Codes
export async function getAllModelCodesByBranchId() {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/ModelCode/GetAllModelCodesByBranchId`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const res = await response.json();
      responseDto.isSuccess = res
    }

    return await response.json();

  } catch (error) {
    responseDto.isSuccess = false;
    responseDto.message = "Markalar yüklenirken bir hata oluştu.";
    responseDto.dataId = null;
    return responseDto;
  }
};

export async function addModelCode(branchId, name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelCode/AddModelCode`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ branchId: branchId, name: name })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch colors.");
  }
};

export async function updateModelCode(id, name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelCode/UpdateModelCode`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id, name: name })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types");
  }
};

export async function getModelCodesWithModelNamesByBranchId(id) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelCode/GetModelCodesWithModelNamesByBranchId`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    return null;
  }
};

export async function deleteModelCode(id) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelCode/DeleteModelCode`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types");
  }
};

// Model Names
export async function getAllModelNamesByModelCodeId(modelCodeId) {
  try {
    const token = getToken();
    const response = await fetch(`${baseUrl}/ModelName/GetAllModelNamesByModelCodeId`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: modelCodeId }),
    });

    if (!response.ok) {
      const res = await response.json();
      responseDto.isSuccess = res
    }

    return await response.json();

  } catch (error) {
    responseDto.isSuccess = false;
    responseDto.message = "Markalar yüklenirken bir hata oluştu.";
    responseDto.dataId = null;
    return responseDto;
  }
};

export async function addModelName(modelCodeId, name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelName/AddModelName`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ modelCodeId: modelCodeId, modelName: name })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch colors.");
  }
};

export async function updateModelName(id, name) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelName/UpdateModelName`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id, name: name })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types");
  }
};

export async function deleteModelName(id) {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelName/DeleteModelName`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id })
    });

    if (!response.ok) {
      return await response.json();
    }

    return await response.json();
  } catch (error) {
    throw new Error("Failed to fetch fuel types");
  }
};

export async function getModelNamesList() {
  try {

    const token = getToken();
    if (!token) {
      //TODO
      return;
    }

    const response = await fetch(`${baseUrl}/ModelName/GetModelNamesList`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const res = await response.json();
      responseDto.isSuccess = res
    }

    return await response.json();

  } catch (error) {
    responseDto.isSuccess = false;
    responseDto.message = "Modeller yüklenirken bir hata oluştu.";
    responseDto.dataId = null;
    return responseDto;
  }
};