import React from 'react';

const Muvafakatname = ({data, date}) => {

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1
      }/${dateTime.getFullYear()}`;
    return `${formattedDate}`;
  };

  return (
    <div className="customerstyles">
      <div className="pdfs">
        <div className="d-flex align-items-center justify-content-center flex-column mb-4">
          <h3>MUVAFAKATNAME</h3>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column mb-4">
          <p
            style={{
              maxWidth: '800px',
              textAlign: 'justify',
              fontSize: '1rem',
            }}
          >
            Seyhan Vergi Dairesinde <b>8330058117</b> Vergi Sicil numarasında
            kayıtlı TEKBAŞ TİCARİ ARAÇLAR SANAYİ VE TİCARET LTD.ŞTİ den "0"
            olarak satın almış olduğum <b>{data.branch}</b> Marka <b>{data.sasiNumber}</b> şase numaralı
            aracın satın alınması ile ilgili olarak şirketinize araç alım
            hesabına istinaden toplam ödediğim tutardan ; Sıfır alınan araçların
            Trafik Şube Müdürlüğünde yapılması zorunlu olan Araç Ruhsat Tescili
            işlemlerini adıma takibini yapacak olan , şirketiniz ile bir ticari
            bağı (personel ,taşeron v.b ) olmayan, şirket dışında bu işlemelerin
            takip bedelleri için <b>{data.price}</b> TL şahsım adına aşağıdaki bilgileri
            olan firmaya / kişiye ödenmesini rica ederim. Yukarıda ödenen
            tutarın ödenmesi ile ilgili olarak belgelendirilmesinden dolayı
            ayrıca TEKBAŞ TİCARİ ARAÇLAR PAZ LTD.ŞTİ yi sorumlu tutmayacağımı,
            tutarın geri iadesini istemeyeceğimi ,işlem bedelinin ve
            belgelendirilmesi sorumluluğunun tamamen parayı ödeme emri veren
            şahsım <b>{data.customerName + ' ' + data.customerSurname}</b>‘ a ait olduğunu, tek taraflı olarak kabul ve
            muvafakat ederim.
          </p>
        </div>
        <div className="mb-4">
          <h6 className="fw-bold">ARACI ALAN VE TALİMATI VEREN</h6>
          <div className="d-flex flex-column gap-1 mt-3">
            <span>
              TARİH: <span>{formatDateTime(date)}</span>
            </span>
            <span>
              TC KİMLİK NO: <span>{data.customerIdNumber}</span>
            </span>
            <span>
              ADI: <span>{data.customerName}</span>
            </span>
            <span>
              SOYADI: <span>{data.customerSurname}</span>
            </span>
            <span>
              İMZA: <span></span>
            </span>
          </div>
        </div>
        <div>
          <h6 className="fw-bold">TESCİL İŞLEMİ YAPANIN</h6>
          <div className="d-flex flex-column gap-1 mt-3 mb-3">
            <span>
              TARİH: <span>{formatDateTime(date)}</span>
            </span>
            <span>
              TC KİMLİK NO: <span>{data.advisorIdNumber}</span>
            </span>
            <span>
              ADI: <span>{data.advisorName}</span>
            </span>
            <span>
              SOYADI: <span>{data.advisorSurname}</span>
            </span>
            <span>
              İMZA: <span></span>
            </span>
            <span className="mt-4">MUHASEBE ONAYI</span>
            <span>İMZA:</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Muvafakatname;
