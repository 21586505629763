import React, { useEffect, useRef, useState } from 'react'
import { getDailyCustomers } from '../services/dashboardService';
import { Toast } from 'primereact/toast';
import Cookies from 'js-cookie';
import '../../assets/styles/_dashboard.scss';

const getUserInfo = () => {
    return JSON.parse(Cookies.get("userInfo"));
};

const DailyCustomers = () => {


    const toast = useRef(null);

    const [userInfo, setUserInfo] = useState(null);

    const [dailyCustomerData, setDailyCustomerData] = useState([]);

    // Get the user data from Cookies.
    useEffect(() => {

        const userData = getUserInfo();

        if (userData !== null) setUserInfo(userData);

    }, []);


    useEffect(() => {

        async function fetchData() {
            try {
                const data = await getDailyCustomers();
                setDailyCustomerData(data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Renk listesi yüklenemedi. Sayfayı yenileyiniz.' });
            }
        }

        fetchData();
    }, []);


    return (
        <>
            <Toast ref={toast} />
            <div className="dashboard-rows col-12">
                <div className="col-12 col-lg-6 dashboard-row">
                    <h5 className="pb-3">Günlük Müşterilerim</h5>
                    <table>
                        <thead>
                            <tr>
                                {/* If user is Admin, all customers of occocar will be rendered */}
                                {userInfo && userInfo.isAdmin && (<th>Marka</th>)}
                                <th>Müşteri</th>
                                <th>Telefon Numarası</th>
                                <th>Danışman</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dailyCustomerData.length === 0 ? 
                            <tr className='text-center'>
                                <td colSpan="4" className='py-4'>Günlük müşteri bulunmamaktadır.</td>
                            </tr>
                             : dailyCustomerData.map((customer, index) => (
                                <tr key={index}>
                                    {userInfo && userInfo.isAdmin && ( <td>{customer.branchName}</td>)}
                                    <td>{customer.customerName}</td>
                                    <td>{customer.phoneNumber}</td>
                                    <td>{customer.advisorName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}

export default DailyCustomers
