import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import { getCustomerInfoById } from '../services/customerService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ListSaleCustomerData = ({ id }) => {

    const [customerInfo, setCustomerInfo] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getCustomerInfoById(id.id);
                setCustomerInfo(data);
            } catch (error) {
                toast.current.show({
                    severity: "error",
                    summary: "Hata",
                    detail: "Müşterinin detay bilgisi yüklenemedi. Yeniden deneyiniz.",
                });
            }
        }

        fetchData();
    }, [id]);

    const formatDate = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
        return `${formattedDate}`;
      };

    return (
        <>
            <Toast ref={toast} />
            <div className='customerstyles mb-5'>
                <h6 className="mb-2">Bağlantı Yapılacak Müşteri Detayı</h6>
                {customerInfo && ( // Check if customerInfo is not null
                    <DataTable value={customerInfo} tableStyle={{ minWidth: "70rem" }}>
                        <Column field="customerName" header="Ad Soyad" />
                        <Column field="phoneNumber" header="Telefon Numarası" />
                        <Column field="createdAt" header="Kayıt Tarihi" body={(rowData) => formatDate(rowData.createdAt)} sortable />
                        <Column field="adress" header="Adresi" />
                        <Column field="advisorName" header="Danışmanı" />
                    </DataTable>
                )}
            </div>
        </>
    )
}

export default ListSaleCustomerData;
