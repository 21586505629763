import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/styles/_customer.scss';
import {
  getAllCustomersList,
  deleteCustomer,
} from '../services/customerService'; // Assuming you have a deleteCustomer function
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const ListCustomer = () => {
  const [visible, setVisible] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const toast = useRef(null);

  // datatable filter
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onFilter = (value, field) => {
    let _filters = { ...filters };

    if (value === null || value === undefined) {
      delete _filters[field];
    } else {
      _filters[field] = { value: value, matchMode: FilterMatchMode.CONTAINS };
    }

    setFilters(_filters);
  };

  // datatable search input
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-start">
        {advancedSearch ? (
          <div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'customerName')}
              placeholder="Ad Soyad"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'email')}
              placeholder="Email"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'phoneNumber')}
              placeholder="Telefon Numarası"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'advisorName')}
              placeholder="Danışman"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'customerType')}
              placeholder="Müşteri Tipi"
              className="rounded-3"
            />
            <InputText
              type="text"
              onInput={(e) => onFilter(e.target.value, 'createdAt')}
              placeholder="Kayıt Tarihi"
              className="rounded-3"
            />
            <Button
              type="button"
              icon="pi pi-times-circle"
              className="p-button-success rounded-3"
              tooltip="Filtrelemeyi Kapat"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })
              }
            />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Arama Yapın"
                className='rounded-3'
              />
            </div>
            <Button
              type="button"
              icon="pi pi-filter"
              className="p-button-success rounded-3 ms-2"
              tooltip="Detaylı Filtreleme"
              tooltipOptions={{ position: 'top' }}
              onClick={() => setAdvancedSearch(!advancedSearch) || setGlobalFilterValue(null) || setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                email: { value: null, matchMode: FilterMatchMode.CONTAINS },
                phoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
                advisorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                customerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
              })}
            />
          </div>
        )}
      </div>
    );
  };
  const header = renderHeader();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllCustomersList();
        setCustomerData(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Müşteri listesi yüklenemedi. Sayfayı yenileyiniz.',
        });
      }
    }
    fetchData();
  }, []);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()}`;
    const formattedTime = `${dateTime.getHours()}:${
      dateTime.getMinutes() < 10 ? '0' : ''
    }${dateTime.getMinutes()}`;
    return `${formattedDate} ${formattedTime}`;
  };

  const optionsTemplate = (rowData) => {
    return (
      <Dropdown>
        <Dropdown.Toggle className="btn btn-success bg-green">
          <i className="pi pi-cog fs-5"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-green px-2 py-2 border border-dark">
          <Dropdown.Item
            as={Link}
            to="/customer/view"
            state={{ id: rowData.id, type: rowData.customerType }}
            className="text-light border-bottom"
          >
            Görüntüle
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="/customer/edit"
            state={{ id: rowData.id, type: rowData.customerType }}
            className="text-light border-bottom"
          >
            Düzenle
          </Dropdown.Item>
          <Dropdown.Item
            className="text-light"
            onClick={() => showDialog(rowData.id, rowData.customerName)}
          >
            Sil
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const showDialog = (id, customerName) => {
    setSelectedId(id);
    setSelectedCustomer(customerName);
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const deleteCustomerConfirm = async () => {
    try {
      const result = await deleteCustomer(selectedId);
      if (result && result.isSuccess) {
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: result.message,
        });
        // Reload customer data after successful deletion
        const newData = await getAllCustomersList();
        setCustomerData(newData);
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Hata',
          detail: result.message
            ? result.message
            : 'Kayıt Silinemedi. Tekrar deneyiniz.',
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'İşlem sırasında bir hata oluştu. Tekrar deneyiniz.',
      });
    }
    hideDialog(); // Close the dialog after delete operation
  };

  return (
    <>
      <div>
        <div className="customerstyles">
          <h4 className="mb-5">Müşteri Listesi</h4>
          <DataTable
            value={customerData}
            sortMode="multiple"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: '70rem' }}
            stripedRows
            removableSort
            filters={filters}
            globalFilterFields={[
              'customerName',
              'email',
              'phoneNumber',
              'advisorName',
              'createdAt',
            ]}
            header={header}
            emptyMessage="Eşleşme bulunamadı."
          >
            <Column field="customerName" header="Ad Soyad" sortable />
            <Column field="email" header="Email" />
            <Column field="phoneNumber" header="Telefon Numarası" />
            <Column
              field="advisorName"
              header="Danışman"
              body={(rowData) => `${rowData.advisorName}`}
              sortable
            />
            <Column field="customerType" header="Müşteri Tipi" sortable />
            <Column
              field="createdAt"
              header="Kayıt Tarihi"
              body={(rowData) => formatDateTime(rowData.createdAt)}
              sortable
            />
            <Column body={optionsTemplate} header="İşlem" />
          </DataTable>
        </div>
        <Toast ref={toast} />
      </div>
      {visible && (
        <div>
          <Dialog
            header="Emin misiniz?"
            visible={visible}
            style={{ width: '450px' }}
            className="text-center"
            onHide={hideDialog}
            footer={
              <div className="text-center">
                <Button
                  label="Hayır"
                  icon="pi pi-times"
                  className="j-button bg-secondary text-light"
                  onClick={hideDialog}
                />
                <Button
                  label="Evet"
                  icon="pi pi-check"
                  className="j-button bg-green ms-2 text-light"
                  onClick={deleteCustomerConfirm}
                />
              </div>
            }
          >
            <div className="confirmation-content d-flex align-items-center">
              <i
                className="pi pi-exclamation-triangle me-3 text-danger"
                style={{ fontSize: '3rem' }}
              />
              <span>
                {selectedCustomer} adlı müşteriyi sileceksiniz. Bu işlem geri
                alınamaz. Emin misiniz?
              </span>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ListCustomer;
