import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { changePhoneNumber, getProfileInformation, sendOtpConfirmation, sendOtpConfirmationForUser } from '../services/userService';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

const ChangePhone = () => {
  const toast = useRef(null);
  const [newNumber, setNewNumber] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [userData, setUserData] = useState({
    phoneNumber: null,
  });
  const [otp, setOtp] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getProfileInformation();
        setUserData(data);
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Profil bilgisi yüklenemedi. Yeniden deneyiniz.',
        });
      }
    }

    fetchData();
  }, []);

  const handleSavePhoneNumber = async () => {
    if (otp === '' || otp < 6) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Lütfen geçerli bir telefon numarası giriniz.',
      });
    } else {
      const response = await changePhoneNumber(newNumber, otp);
      if (response === null || !response.isSuccess) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: response.message
            ? response.message
            : 'Sms gönderilemedi. Lütfen tekrar deneyiniz.',
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: response.message
            ? response.message
            : 'Sms başarıyla gönderildi.',
        });
        setShowDialog(false);
      }
    }
  };

  const sendConfirmationCode = async () => {
    if (newNumber === '' || newNumber < 10) {
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Lütfen geçerli bir telefon numarası giriniz.',
      });
    } else {
      const response = await sendOtpConfirmationForUser(newNumber);
      if (response === null || !response.isSuccess) {
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: response.message
            ? response.message
            : 'Sms gönderilemedi. Lütfen tekrar deneyiniz.',
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı',
          detail: response.message
            ? response.message
            : 'Sms başarıyla gönderildi.',
        });
        setShowDialog(true);
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="SMS Onay Kodu"
        visible={showDialog}
        style={{ width: '50vw', maxWidth: '500px' }}
        modal={true}
        onHide={() => setShowDialog(false)}
        footer={
          <div>
            <Button
              className='bg-secondary j-button me-2'
              label="İptal"
              icon="pi pi-times"
              onClick={() => setShowDialog(false)}
            />
            <Button
              className='bg-green j-button'
              label="Onayla"
              icon="pi pi-check"
              onClick={handleSavePhoneNumber}
            />
          </div>
        }
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <label>SMS ile gelen onay kodunu giriniz</label>
          <InputText
            className="rounded-3"
            placeholder="000000"
            onChange={
              (e) => {
                setOtp(e.target.value);
              }
            }
            maxLength={6}
          />
        </div>
      </Dialog>
      <div className="customerstyles d-flex flex-column align-items-center justify-content-center">
        <label className='fw-bold'>Telefon Numarası</label>
        <InputText
          value={userData.phoneNumber ? userData.phoneNumber : '-'}
          className="rounded-3"
          onChange={(e) => {
            setUserData({ ...userData, phoneNumber: e.target.value });
          }}
          disabled
        />
        <label className="mt-3 fw-bold">Yeni Telefon Numarası</label>
        <InputText
          className="rounded-3"
          onChange={
            (e) => {
              setNewNumber(e.target.value);
            }
          }
          maxLength={11}
          placeholder="Örn: 5123456789"
        />
        <div className="d-flex justify-content-center">
          <Button
            label="Onay Kodu Gönder"
            className="mt-3 j-button bg-green w-100"
            onClick={ sendConfirmationCode}
          />
        </div>
      </div>
    </>
  );
};

export default ChangePhone;
