import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react'

const baseUrl = process.env.REACT_APP_API_URL + '/api';

const getToken = () => {
  return Cookies.get('token');
};

const ApproveExchangeRequestDialog = ({ approveForm, setApproveForm, showApproveDialog, setShowApproveDialog }) => {

    const toast = useRef(null);

    const handleHide = () => {
        setShowApproveDialog(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setApproveForm({
            ...approveForm,
            [name]: value
        });
    };

    const handleNumberChange = (e, fieldName) => {
        const inputValue = e.value;

        setApproveForm({
            ...approveForm,
            [fieldName]: inputValue
        });
    };

    const handleSave = async () => {

        try {
            const token = getToken();
            if (!token) {
                //TODO
                return;
            }
            const response = await fetch(`${baseUrl}/ExchangeRequest/ApproveExchangeRequest`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(approveForm),
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.current.show({ severity: "error", summary: "Hata", detail: errorData.message });
                return;
            }

            const data = await response.json();

            toast.current.show({ severity: "success", summary: "Kayıt Başarılı", detail: data.message });
            
            handleHide();
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Hata", detail: "Bir hata oluştu. Lütfen kaydı tekrar alınız." });
        }

    };

    return (
        <div className='customerstyles'>
            <Toast ref={toast} />
            <Dialog className='dialog' visible={showApproveDialog} onHide={handleHide} header="Takas Onay Formu" modal>
                <div className="d-flex flex-column">
                    <label htmlFor="paidPrice">Ödenen Miktar</label>
                    <InputNumber id="paidPrice" name="paidPrice" value={approveForm.paidPrice} onChange={(e) => handleNumberChange(e, 'paidPrice')} />
                </div>
                <div className="d-flex flex-column">
                <label htmlFor="expenditure">Yapılan Harcama</label>
                <InputNumber id="expenditure" name="expenditure" value={approveForm.expenditure} onChange={(e) => handleNumberChange(e, 'expenditure')} />
                </div>
                <div className="d-flex flex-column">
                <label htmlFor="totalPrice">Toplam Tutar</label>
                <InputNumber id="totalPrice" name="totalPrice" value={approveForm.totalPrice} onChange={(e) => handleNumberChange(e, 'totalPrice')} />
                </div>
                <div className="d-flex flex-column">
                <label htmlFor="plaka">Plaka</label>
                <InputText id="plaka" name="plaka" className='bg-light' value={approveForm.plaka} onChange={(e) => handleChange(e)} />
                </div>
                <div className="mt-3 d-flex gap-2 justify-content-end">
                    <Button className="rounded-3" severity="secondary" label="İptal" onClick={handleHide} />
                    <Button className="rounded-3" severity="success" label="Ekle" onClick={handleSave} />
                </div>
            </Dialog>
        </div>
    )
}

export default ApproveExchangeRequestDialog