import React, { useEffect, useRef, useState } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import '../../assets/styles/_customer.scss';
import { GetLastFiveCallNotesByCustomerId } from '../services/customerCallService';

const ListCustomerCallNotes = ({ customerId }) => {
    const toast = useRef(null);

    const [callNote, setCallNote] = useState([]);
  
    useEffect(() => {
      async function fetchData() {
        try {
          const data = await GetLastFiveCallNotesByCustomerId(customerId);
          setCallNote(data);
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Müşterinin kredi talebi listesi yüklenemedi. Sayfayı yenileyiniz.',
          });
        }
      }
      fetchData();
    }, [])

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = `${dateTime.getDate()}/${
          dateTime.getMonth() + 1
        }/${dateTime.getFullYear()}`;
        const formattedTime = `${dateTime.getHours()}:${
          dateTime.getMinutes() < 10 ? '0' : ''
        }${dateTime.getMinutes()}`;
        return `${formattedDate} ${formattedTime}`;
      };

  return (
    <>
        <div className="customerstyles">
          <h4 className="mb-2">Son 5 Arama Notu</h4>
          <DataTable
            value={callNote}
            sortMode="multiple"
            tableStyle={{ minWidth: '70rem' }}
            emptyMessage= "Müşteriye ait arama notu bulunmamaktadır."
          >
            <Column field="callDate" header="Arama Tarihi" body={(rowData) => formatDateTime(rowData.callDate)} />
            <Column field="note" header="Arama Notu" />
          </DataTable>
          <Toast ref={toast} />
        </div>
    </>
  )
}

export default ListCustomerCallNotes;