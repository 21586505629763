import React, { useEffect, useState, useRef } from 'react'
import { Toast } from 'primereact/toast';
import { getTodaysCalls } from '../services/dashboardService';
import '../../assets/styles/_dashboard.scss';

const DailyCallCustomers = ({callData}) => {

    const toast = useRef(null);



    return (
        <>
        <Toast ref={toast} />
        <div className="dashboard-rows col-12">
            <div className="col-12 col-lg-6 dashboard-row">
                <h5 className="pb-3">Müşteri Geri Arama</h5>
                <table>
                    <thead>
                        <tr>
                            <th>Müşteri</th>
                            <th>Telefon Numarası</th>
                            <th>Ara</th>
                        </tr>
                    </thead>
                    <tbody>
                        {callData.length === 0 ? 
                        <tr className='text-center'>
                            <td colSpan="3" className='py-4'>Arama yapılacak müşteri bulunmamaktadır.</td>
                        </tr>
                        : callData.map((customer, index) => (
                            <tr key={index}>
                                <td>{customer.customerName}</td>
                                <td>{customer.phoneNumber}</td>
                                <td>
                                    <i className="pi pi-phone"></i>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    );
}

export default DailyCallCustomers
